import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  PillType,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { TaggingStatus } from '@portal/shared/enums/tag.enum';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { cloneDeep } from 'lodash';
import { TimelineWidgetActionsComponent } from '../timeline-widget-actions/timeline-widget-actions.component';
import { TimelineTagActionsComponent } from '../timeline-tag-actions/timeline-tag-actions.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'itq-timeline-widget-header',
  templateUrl: './timeline-widget-header.component.html',
  styleUrls: ['./timeline-widget-header.component.scss'],
  standalone: true,
  imports: [
    MatTooltipModule,
    FAwesomeModule,
    CoreModule,
    CommonModule,
    TimelineWidgetActionsComponent,
    TimelineTagActionsComponent,
    MatIconModule,
  ],
})
export class TimelineWidgetHeaderComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() count: number;
  @Input() timelineStatus: TaggingStatus;
  @Input() displayTypesTemplate: TemplateRef<unknown>;
  @Input() selectedDisplayType: IDisplayType;
  @Input() board: Board;
  @Input() initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @Output() explore = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  readonly PillType = PillType;
  readonly TaggingStatus = TaggingStatus;
  readonly Sizes = Sizes;
  readonly ProfileDrildownScope = ProfileDrildownScope;

  constructor(
    private customOverlayService: CustomOverlayService,
    readonly userService: UserService,
  ) {}

  ngOnInit() {}

  public onExplore(): void {
    this.explore.emit();
  }

  public onEdit(): void {
    const navigationItem = new NavigationHistoryItem(
      `${this.widget.widgetId}_${WidgetActions.EDIT}`,
      cloneDeep(this.widget),
      WidgetActions.EDIT,
      undefined,
      this.initialState.cloneDeep(),
      new FormGroup({}),
      'pencil',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      this.board.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onDeleteWidget(): void {
    this.delete.emit();
  }
}

export enum EventBusUrls {
  SASS = 'io.intorqa.saas',
  CORE_REACTOR = 'io.intorqa.core.reactor',
}

export enum EventBusScope {
  TAGGING = 'tagging.complete',
  UPDATE_WIDGET = 'update.widget',
  DELETE_WIDGET = 'delete.widget',
  CREATE_WIDGET = 'create.widget',
  DELETE_BOARD = 'delete.board',
  UPDATE_BOARD = 'update.board',
  CREATE_BOARD = 'create.board',
  UPDATE_TREE = 'boards.tree',
  CREATE_GROUP = 'create.group',
  UPDATE_GROUP = 'update.group',
  DELETE_GROUP = 'delete.group',
  UPDATE_LAYOUT = 'update.layout',
}

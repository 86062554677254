import { Injectable } from "@angular/core";
import { ApiRequestService, DTOTypeConverter } from "@intorqa-ui/api";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CurrencyDescription, CurrencyPrefix } from "../enums/currencies.enum";
import { ExchangeRate } from "../models/exchange-rate";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  private _exchangeRates: Array<ExchangeRate>;

  public get exchangeRates(): Array<ExchangeRate> {
    return this._exchangeRates;
  }

  public set exchangeRates(v: Array<ExchangeRate>) {
    this._exchangeRates = v;
  }

  constructor(private apiRequestService: ApiRequestService) {}

  public getExchangeRates(): Observable<Array<ExchangeRate>> {
    return this.apiRequestService
      .getToObservable(
        "/currency/rates",
        new DTOTypeConverter<{ [key: string]: number }>()
      )
      .pipe(
        map((response: { [key: string]: number }) => {
          this.exchangeRates = Object.keys(response).map(
            (key: string) =>
              new ExchangeRate(
                key as CurrencyPrefix,
                response[key],
                CurrencyDescription[key]
              )
          );
          return this.exchangeRates;
        })
      );
  }

  public getExchangeRate(prefix: CurrencyPrefix): ExchangeRate {
    return this.exchangeRates.find(
      (rate: ExchangeRate) => rate.prefix === prefix
    );
  }

  public getExchangeRateByCurrency(
    currency: CurrencyDescription
  ): ExchangeRate {
    return this.exchangeRates.find(
      (rate: ExchangeRate) => rate.currency === currency
    );
  }

  public convertToUSD(price: number, currency: CurrencyPrefix): number {
    if (!price || currency === CurrencyPrefix.USD) return price;
    const exchangeRate = this.getExchangeRate(currency);
    return Number((price / exchangeRate.value).toFixed(2));
  }

  public convertFromUSD(
    price: number,
    currency: CurrencyPrefix
  ): number | undefined {
    if (!price) return undefined;
    const exchangeRate = this.getExchangeRate(currency);
    return Number((price * exchangeRate.value).toFixed(2));
  }
}

export const environment = {
  production: true,
  apiBase: '/api/',
  evBusUrl: '/eventbus',
  externalApiBase: `${window.location.protocol}//${window.location.host.replace(
    'saas',
    'api',
  )}`,
  apiRequestTimeout: 5000,
  gTranslateApi: {
    token: 'AIzaSyBByIqIserkELS3_v6_NlHWZo6dKVMv6e8',
    translateUrl:
      'https://translation.googleapis.com/language/translate/v2?key=',
    detectLanguage:
      'https://translation.googleapis.com/language/translate/v2/detect?key=',
  },
  slackConfig: {
    clientId:
      window.location.host === 'saas.intorqa.io'
        ? '302272051877.2452608631191'
        : '302272051877.3132677901217',
    clientSecret:
      window.location.host === 'saas.intorqa.io'
        ? '4ad4e9669b84e956dfcbe36dc90ae287'
        : 'eba5d9a4d485913c6cd3b1e0d373ae0e',
  },
  posthog: {
    isEnabled:
      window.location.host === 'saas.intorqa.io' ||
      window.location.host === 'saas.intorqa-test.com',
    apiKey:
      window.location.host === 'saas.intorqa.io'
        ? 'xGgNLwxf6KtPMc8fXwEfgun4l_-6tFaPjKupmCmpBKU'
        : 'phc_1LNZ1qVxXsXAvMsqfEcNrmVsAiJVIHRG9Er8YLQW18S',
    apiHost:
      window.location.host === 'saas.intorqa.io'
        ? 'https://t.intorqa.io'
        : 'https://t.intorqa-test.com',
  },
  authConfig: {
    enabled: true,
    config: {
      url: `${window.location.protocol}//${window.location.host.replace(
        'saas',
        'auth2',
      )}`,
      realm: 'intorqa',
      clientId: 'intorqa-saas',
    },
    initOptions: {
      onLoad: 'login-required',
      checkLoginIframe: false,
    },
    enableBearerInterceptor: true,
    bearerExcludedUrls: ['/assets', '/clients/public'],
  },
  errorTracking: false,
  version: require('../../../../package.json').version,
};

import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "itq-simple-info-panel",
  templateUrl: "./simple-info-panel.component.html",
  styleUrls: ["./simple-info-panel.component.scss"],
})
export class SimpleInfoPanelComponent implements OnInit {
  @Input() message: string;

  constructor() {}

  ngOnInit(): void {}
}

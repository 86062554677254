import { Pipe, PipeTransform } from '@angular/core';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';

@Pipe({
  name: 'getFontAwesomeIcon',
})
export class GetFontAwesomeIconPipe implements PipeTransform {
  constructor() {}

  transform(item: string): IconProp {
    if (item === 'discord') {
      return ['fab', item as IconName];
    } else {
      return ['far', item as IconName];
    }
  }
}

@Pipe({
  name: 'getSVGIcon',
})
export class GetSVGIconPipe implements PipeTransform {
  constructor() {}

  transform(item: string): string {
    return `assets/icons/sprite.svg#${item}`;
  }
}

import { Injectable } from '@angular/core';
import { Utils } from '@intorqa-ui/core';
import { ECharts } from 'echarts';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  public reloadChart$ = new Subject<string>();
  public removeSegment$ = new Subject<void>();

  constructor() {}

  public exportChart(title: string, chartInstance: ECharts): void {
    Utils.exportImage(
      chartInstance.getDataURL({
        pixelRatio: 2,
        backgroundColor: 'white',
      }),
      title,
    );
  }
}

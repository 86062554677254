import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Profile } from '@portal/profiles/models/profile';
import { TextNote } from '@portal/profiles/models/text-note';

@Component({
  selector: "itq-view-text-note",
  templateUrl: "./view-text-note.component.html",
  styleUrls: ["./view-text-note.component.scss"],
})
export class ViewTextNoteComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: TextNote;

  constructor() {}

  ngOnInit() {}
}

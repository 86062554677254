<div class="flex items-center space-x-1">
  @for (item of dataSource; track item.id) {
    <itq-mat-button
      class="mr-1"
      [materialStyle]="item.id === value ? 'flat' : 'basic'"
      [style]="item.id === value ? 'primary' : undefined"
      [type]="'button'"
      [align]="direction === Directions.VERTICAL ? Align.START : Align.CENTER"
      [disabled]="item.disabled"
      (clickEvent)="onClick(item)"
    >
      @if (item.template) {
        <ng-container *ngTemplateOutlet="item.template"></ng-container>
      } @else {
        <div class="flex items-center">
          @if (item.icon) {
            <fa-icon class="mr-1" [icon]="item.icon" [size]="'lg'"></fa-icon>
          } @else if (item.svgIcon) {
            <itq-svg class="w-5 h-5 mr-1" [icon]="item.svgIcon"></itq-svg>
          }
          <span class="whitespace-nowrap">
            {{ item.tooltip }}
          </span>
        </div>
      }
    </itq-mat-button>
  }
</div>

import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  Align,
  ComponentType,
  CustomOverlayService,
  DialogComponent,
  DialogTypes,
  PillType,
  QueryFilters,
  SearchFieldType,
  Sizes,
  Utils,
  TableColumn,
  TagCategory,
} from '@intorqa-ui/core';
import {
  ILinksData,
  ILoadLink,
} from '@portal/profiles/interfaces/profile-tags.interface';
import { Profile } from '@portal/profiles/models/profile';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-profiles-links',
  templateUrl: './profiles-links.component.html',
  styleUrls: ['./profiles-links.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProfilesLinksComponent,
    },
  ],
})
export class ProfilesLinksComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() action: WidgetActions;
  @Input() showTitle = true;

  public dataSource: ILinksData = { items: [], totalCount: undefined };
  public tableColumns: Array<TableColumn> = [];
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public link: LinkTag = new LinkTag(
    Utils.generateUUID(),
    undefined,
    undefined,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  private linksSubscription: Subscription;
  private resetSubscription: Subscription;
  private selection: Array<LinkTag>;
  private touched = false;
  private disabled = false;
  public isFilterable = false;

  @ViewChild('actionsButtonTemplate')
  actionsButtonTemplate: TemplateRef<unknown>;
  @ViewChild('ownerTemplate') ownerTemplate: TemplateRef<unknown>;
  @ViewChild('dateTemplate') dateTemplate: TemplateRef<unknown>;
  @ViewChild('defaultButtonTemplate')
  defaultButtonTemplate: TemplateRef<unknown>;

  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly WidgetActions = WidgetActions;
  readonly PillType = PillType;

  constructor(
    private linkTagsService: LinkTagsService,
    private customOverlayService: CustomOverlayService,
    readonly widgetSettingsService: WidgetSettingsService,
  ) {}

  ngOnInit() {
    this.linksSubscription = this.linkTagsService.links$.subscribe(
      (response: ILoadLink) => {
        this.form.controls.links?.setValue(response.data);
        this.dataSource = cloneDeep(response.data);
      },
    );
    this.resetSubscription = this.linkTagsService.reset$.subscribe(() => {
      this.dataSource = { items: [], totalCount: undefined };
    });
    if (
      this.navigationItem.action === WidgetActions.EDIT ||
      this.navigationItem.action === WidgetActions.EXPLORE
    ) {
      this.onDataBound(this.initialState);
    }
    if (this.navigationItem.linkTag) {
      this.linkTagsService.links = {
        items: [this.navigationItem.linkTag],
        totalCount: 1,
      };
      this.dataSource = cloneDeep(this.linkTagsService.links);
    }
  }

  ngAfterViewInit(): void {
    this.initTableColumns();
  }

  ngOnDestroy(): void {
    this.linksSubscription.unsubscribe();
    this.resetSubscription.unsubscribe();
  }

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(items: Array<LinkTag>): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private initTableColumns(): void {
    this.tableColumns = [
      {
        name: 'Type',
        dataKey: 'type',
        searchField: 'type',
        width: '200px',
        isFilterable: true,
        isSortable: true,
        searchFieldType: SearchFieldType.ID,
        searchComponent: ComponentType.DROPDOWN,
        dataSource: [
          { name: TagCategory['My Tags'], value: TagCategory['My Tags'] },
          { name: TagCategory.Actor, value: TagCategory.Actor },
          { name: TagCategory.Channel, value: TagCategory.Channel },
        ],
      },
      {
        name: 'Tag',
        dataKey: 'tagName',
        searchField: 'tagName',
        width: '200px',
        isFilterable: true,
        isSortable: true,
        searchFieldType: SearchFieldType.TEXT,
        searchComponent: ComponentType.INPUT,
        dataSource: undefined,
      },
      {
        name: 'Description',
        dataKey: 'description',
        searchField: 'description',
        width: 'fit',
        isFilterable: true,
        isSortable: true,
        searchFieldType: SearchFieldType.TEXT,
        dataSource: undefined,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: 'Default',
        dataKey: 'isDefault',
        searchField: 'isDefault',
        width: '100px',
        position: 'center',
        isFilterable: false,
        isSortable: true,
        customRender: true,
        template: this.defaultButtonTemplate,
        searchFieldType: SearchFieldType.TEXT,
        dataSource: undefined,
        searchComponent: ComponentType.INPUT,
      },
      {
        name: undefined,
        dataKey: 'actions',
        position: 'center',
        customRender: true,
        template: this.actionsButtonTemplate,
        width: '68px',
      },
    ];
    if (this.navigationItem.action === WidgetActions.EXPLORE) {
      this.tableColumns = [
        {
          name: 'Updated date',
          dataKey: 'updatedDate',
          searchField: 'updatedDate',
          isSortable: true,
          width: '150px',
          customRender: true,
          template: this.dateTemplate,
          sticky: true,
          isFilterable: true,
          searchFieldType: SearchFieldType.DATE,
          searchComponent: ComponentType.DATE,
        },
        {
          name: 'Updated by',
          dataKey: 'updatedBy',
          searchField: 'updatedBy',
          isSortable: true,
          position: 'center',
          customRender: true,
          template: this.ownerTemplate,
          width: '120px',
          sticky: true,
          httpBinding: true,
          isFilterable: true,
          searchFieldType: SearchFieldType.TEXT,
          searchComponent: ComponentType.DROPDOWN,
          dataSource: undefined,
          dataBound: () => {
            this.onGetUsers();
          },
        },
        ...this.tableColumns,
      ];
    }
  }

  private onGetUsers(): void {
    this.tableColumns = this.tableColumns.map((column: TableColumn) => {
      if (column.searchField === 'updatedBy') {
        this.linkTagsService
          .getUsers(this.profile.profileId)
          .subscribe((response: Array<string>) => {
            column.dataSource = response.map((item: string) => ({
              name: item,
              value: item,
            }));
          });
      }
      return column;
    });
  }

  public onEditLink(link: LinkTag): void {
    this.action = WidgetActions.EDIT;
    this.link = cloneDeep(link);
  }

  public onUnlinkTag(link: LinkTag): void {
    if (this.navigationItem.action === WidgetActions.EXPLORE) {
      if (link.isDefault) {
        this.customOverlayService.openCustom(
          {
            title: 'Oooops!',
            message: "You can't delete the default tag!",
            icon: ['far', 'exclamation-circle'],
            size: '4x',
            dialog: {
              type: DialogTypes.ALERT,
            },
          },
          DialogComponent,
        );
      } else {
        this.linkTagsService
          .unLinkTags(this.profile.profileId, [link])
          .subscribe(() => {
            this.form.controls.links.updateValueAndValidity();
          });
      }
    } else {
      this.dataSource.items = this.dataSource.items.filter(
        (item: LinkTag) => item.id !== link.id,
      );
      this.linkTagsService.links.items =
        this.linkTagsService.links.items.filter(
          (item: LinkTag) => item.id !== link.id,
        );
      this.form.controls.links.updateValueAndValidity();
    }
    this.form.controls.links?.markAsTouched();
  }

  public onLinkTag(): void {
    this.action = WidgetActions.CREATE;
    this.linkTagsService.showLinkTag$.next(true);
    const defaultTag = this.linkTagsService.getDefaultTag();
    this.link = new LinkTag(
      Utils.generateUUID(),
      undefined,
      undefined,
      !defaultTag ? true : false,
      undefined,
      undefined,
      undefined,
      undefined,
    );
  }

  public onDataBound(params: QueryFilters): void {
    this.widgetSettingsService.loader$.next(true);
    this.linkTagsService
      .getLinkedTags(this.profile.profileId, params)
      .subscribe(() => {
        this.widgetSettingsService.loader$.next(false);
      });
  }

  public onCloseAddLink(): void {
    this.action = undefined;
    if (this.navigationItem.action === WidgetActions.CREATE) {
      this.linkTagsService.showLinkTag$.next(false);
    }
  }

  public onExit(): void {
    this.linkTagsService.showLinkTag$.next(false);
  }
}

<ng-container [formGroup]="form">
  <itq-form-row class="search__type_container">
    <itq-form-element>
      <span class="mb-1 required font-semibold">Search type:</span>
      <itq-button-thumbnail
        formControlName="searchType"
        [dataSource]="SearchTypes"
        (change)="onChangeDisplayType()"
      ></itq-button-thumbnail>
      <mat-error
        *ngIf="
          form.controls['searchType']?.touched &&
          form.controls['searchType']?.invalid
        "
      >
        Search type is required
      </mat-error>
    </itq-form-element>
  </itq-form-row>
  <itq-form-row>
    <itq-form-element>
      <span class="mb-1 required">Name:</span>
      <itq-input
        [required]="true"
        [formControlName]="'name'"
        [placeholder]="'Please add your tag name...'"
      ></itq-input>
      <mat-error
        *ngIf="form.controls['name']?.touched && form.controls['name']?.invalid"
      >
        Tag name is required
      </mat-error>
    </itq-form-element>
  </itq-form-row>
  <itq-form-row>
    <itq-form-element>
      <span class="mb-1">Description:</span>
      <itq-input
        [formControlName]="'description'"
        [placeholder]="'Please add your tag description...'"
      ></itq-input>
    </itq-form-element>
  </itq-form-row>
</ng-container>

import { IEcosystem } from '../interfaces/ecosystem';

export class Ecosystem implements IEcosystem {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public communityIds: Array<string>,
    public eventsEnabled: boolean,
  ) {}

  public findCommunity(id: string): string {
    return this.communityIds.find((item: string) => id === item);
  }

  public updateCommunity(id: string): void {
    this.communityIds = this.communityIds.filter((item: string) => {
      return item !== id;
    });
  }

  public addCommunity(id: string): void {
    this.communityIds.push(id);
  }

  public removeCommunity(id: string): void {
    this.communityIds = this.communityIds.filter((item: string) => id! == item);
  }

  public cloneDeep(): Ecosystem {
    return new Ecosystem(
      this.id,
      this.name,
      this.description,
      this.communityIds,
      this.eventsEnabled,
    );
  }
}

<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addNote'">
    <itq-headline-simple
      *ngIf="showTitle"
      class="mb-7"
      [iconName]="action === WidgetActions.CREATE ? 'plus' : 'pencil'"
      [iconSize]="'2x'"
      [iconType]="IconType.FONT_AWESOME"
      [title]="action === WidgetActions.CREATE ? 'Add note' : 'Edit note'"
    ></itq-headline-simple>
    <div class="add_note__container">
      <ng-container
        *ngIf="
          navigationItem?.action === WidgetActions.EXPLORE ||
          navigationItem?.action === WidgetActions.EDIT
        "
      >
        <itq-form-row>
          <itq-form-element>
            <span class="label mb-1 required">Vendor type</span>
            <itq-dropdown
              [formControlName]="'profileType'"
              [showHeader]="true"
              [dataFields]="{ name: 'name', value: 'id' }"
              [clearSelection]="false"
              [dataSource]="profilesTypesDataSource"
              (changeValue)="onChangeProfileType($event)"
            ></itq-dropdown>
            <mat-error
              *ngIf="
                form?.get('addNote.profileType')?.touched &&
                form?.get('addNote.profileType')?.invalid
              "
            >
              Vendor type is required
            </mat-error>
          </itq-form-element>
        </itq-form-row>
        <itq-form-row
          *ngIf="
            form?.get('addNote.profileType')?.value ||
            navigationItem?.action === WidgetActions.CREATE
          "
        >
          <itq-form-element>
            <span
              [ngClass]="{
                label: true,
                'mb-1': true,
                required: form
                  ?.get('addNote.profile')
                  ?.hasValidator(Validators.required)
                  ? true
                  : false
              }"
              >Vendor</span
            >
            <itq-dropdown
              [formControlName]="'profile'"
              [showHeader]="true"
              [clearSelection]="false"
              [dataSource]="profilesDataSource"
              [dataFields]="{ name: 'name', value: 'profileId' }"
              [httpBinding]="true"
              (dataBound)="onDataBoundProfiles($event)"
              (changeValue)="onChangeProfile()"
            ></itq-dropdown>
            <mat-error
              *ngIf="
                form?.get('addNote.profile')?.touched &&
                form?.get('addNote.profile').invalid
              "
            >
              Vendor is required
            </mat-error>
          </itq-form-element>
        </itq-form-row>
      </ng-container>
      <ng-container
        *ngIf="
          form?.get('addNote.profileType')?.value ||
          navigationItem?.action === WidgetActions.CREATE
        "
      >
        <itq-form-row>
          <itq-form-element>
            <span class="label mb-1 required">Type</span>
            <itq-dropdown
              [formControlName]="'type'"
              [showHeader]="true"
              [clearSelection]="false"
              [dataSource]="typesDataSource"
              [dataFields]="{ name: 'name', value: 'id' }"
              (changeValue)="onChangeType($event)"
            ></itq-dropdown>
            <mat-error
              *ngIf="
                form?.get('addNote.type')?.touched &&
                form?.get('addNote.type')?.invalid
              "
            >
              Note type is required
            </mat-error>
          </itq-form-element>
        </itq-form-row>
      </ng-container>
      <ng-template dynamicPlaceholder></ng-template>
    </div>
    <div class="add_note__footer">
      <div class="note__footer_left">
        <itq-mat-button [materialStyle]="'basic'" (clickEvent)="onCancel()">
          Cancel
        </itq-mat-button>
      </div>
      <div class="note__footer_right">
        <ng-container
          *ngIf="
            (form?.get('addNote.profileType')?.value ||
              navigationItem?.action === WidgetActions.CREATE) &&
              profilesDataSource?.length === 0;
            else profileTemplate
          "
        >
          <itq-mat-button
            [disabled]="!this.form?.get('addNote')?.valid"
            [materialStyle]="'flat'"
            [style]="'primary'"
            (clickEvent)="onCreateProfile()"
          >
            <span class="text-wrapper">Create profile</span>
          </itq-mat-button>
        </ng-container>
        <ng-template #profileTemplate>
          <ng-container
            *ngIf="action === WidgetActions.CREATE; else updateTemplate"
          >
            <itq-nav-button
              [materialStyle]="'flat'"
              [openDirection]="{
                vertical: 'up',
                horizontal: 'left'
              }"
              [color]="'primary'"
              [padding]="Sizes.MD"
              [width]="200"
              [openPosition]="'inside'"
              [disabled]="!this.form?.get('addNote')?.valid"
              [template]="saveTemplate"
              >SAVE</itq-nav-button
            >
            <ng-template #saveTemplate>
              <itq-mat-button
                [materialStyle]="'basic'"
                [padding]="Sizes['X-SM']"
                [size]="Sizes.BLOCK"
                [align]="Align.START"
                (clickEvent)="onCreate()"
              >
                <fa-icon [icon]="['far', 'plus']"></fa-icon>
                <span class="text-wrapper">Add another note</span>
              </itq-mat-button>

              <itq-mat-button
                [materialStyle]="'basic'"
                [padding]="Sizes['X-SM']"
                [size]="Sizes.BLOCK"
                [align]="Align.START"
                (clickEvent)="onAdd()"
              >
                <fa-icon [icon]="['far', 'plus']"></fa-icon>
                <span class="text-wrapper">Add to profile</span>
              </itq-mat-button>
            </ng-template>
          </ng-container>
          <ng-template #updateTemplate>
            <itq-mat-button
              [disabled]="!this.form?.get('addNote')?.valid"
              [materialStyle]="'flat'"
              [style]="'primary'"
              (clickEvent)="onUpdate()"
            >
              <span class="text-wrapper">Update</span>
            </itq-mat-button>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>

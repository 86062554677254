<itq-mat-button
  #button
  [size]="Sizes.BLOCK"
  [ngClass]="{ invalid }"
  [type]="'button'"
  [padding]="padding"
  [style]="color"
  [disabled]="disabled"
  [materialStyle]="materialStyle"
  (click)="$event.stopImmediatePropagation()"
  (clickEvent)="onClick()"
>
  <div class="flex w-full items-center justify-between h-full">
    @if (icon) {
      <fa-icon [icon]="['far', icon | castIconName]"></fa-icon>
    }
    <div class="wrapper_text font-normal">
      @if (httpBinding) {
        @if (selection && selection[dataFields?.name]) {
          {{ selection[dataFields?.name] }}
        } @else {
          <span class="font-normal">{{ placeholder }} </span>
        }
      } @else {
        @if (dataSource | getLabel: dataFields : selection) {
          {{ dataSource | getLabel: dataFields : selection }}
        } @else {
          <span class="font-normal">{{ placeholder }} </span>
        }
      }
    </div>
    <div class="flex items-center h-full">
      @if (showLoader) {
        <itq-loader [type]="'controls'"></itq-loader>
      }
      @if (clearSelection && selection) {
        <fa-icon
          [icon]="['far', 'times']"
          [size]="'lg'"
          (mousedown)="onClear($event)"
        ></fa-icon>
      }
      <fa-icon
        class="border-l border-border h-full pl-1 ml-2"
        [icon]="isOpen ? ['far', 'chevron-up'] : ['far', 'chevron-down']"
      ></fa-icon>
    </div>
  </div>
</itq-mat-button>
<ng-template #portalContent>
  <div
    [ngStyle]="{ 'width.px': dropdownWidth }"
    [ngClass]="['navigation__container', direction]"
  >
    @if (dataSource) {
      <itq-dropdown-results
        [emptyDataSourceMessage]="emptyDataSourceMessage"
        [showHeader]="showHeader"
        [direction]="direction"
        [dataSource]="dataSource"
        [httpBinding]="httpBinding"
        [selection]="selection"
        [showLoader]="showLoader"
        [dataFields]="dataFields"
        [initialState]="initialState"
        [selection]="selection"
        (dataBound)="onDataBound($event)"
        (changeValue)="onChangeValue($event)"
      ></itq-dropdown-results>
    }
  </div>
</ng-template>

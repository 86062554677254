import {
  CollectionViewer,
  DataSource,
  SelectionChange,
} from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CommonModule, NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import {
  CUSTOM_OVERLAY_DATA,
  CoreModule,
  CustomOverlayConfig,
  CustomOverlayService,
  DialogComponent,
  DialogTypes,
  FAwesomeModule,
  HeadLineSimpleComponent,
  IButtonThumbnail,
  IError,
  IconType,
  SvgComponent,
} from '@intorqa-ui/core';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { TagNodeType } from '@portal/shared/enums/tag.enum';
import { ITagTreeNode } from '@portal/shared/interfaces/tag.interface';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { TagService } from '@portal/shared/services/tag.service';
import { DynamicFlatNode } from './tag-dependencies.model';
import { TooltipNodePipe } from './tag-dependencies.pipe';
import { DynamicDatabase } from './tag-dependencies.service';

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _database: DynamicDatabase,
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe((change) => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(
      map(() => this.data),
    );
  }

  disconnect(): void {}

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const children = this._database.getChildren(node.id);
    const index = this.data.indexOf(node);
    if (!children || index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    node.isLoading = true;

    setTimeout(() => {
      if (expand) {
        this.data.splice(index + 1, 0, ...children);
      } else {
        let count = 0;
        for (
          let i = index + 1;
          i < this.data.length && this.data[i].level > node.level;
          i++, count++
        ) {}
        this.data.splice(index + 1, count);
      }

      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }, 1000);
  }

  removeNode(node: DynamicFlatNode): void {
    const nodesArray = this.data;

    // Find the index of the node to remove in the data source
    const nodeIndex = nodesArray.findIndex((n) => n === node);

    if (nodeIndex >= 0) {
      // Remove the node from the data source
      nodesArray.splice(nodeIndex, 1);

      // Refresh the tree data source to reflect the changes
      this.data = nodesArray;
      this.dataChange.next(this.data);
    }
  }
}

/**
 * @title Tree with dynamic data
 */
@Component({
  selector: 'itq-tag-dependencies',
  templateUrl: 'tag-dependencies.component.html',
  styleUrls: ['tag-dependencies.component.scss'],
  standalone: true,
  imports: [
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
    MatProgressBarModule,
    CoreModule,
    ReactiveFormsModule,
    FAwesomeModule,
    CommonModule,
    MatTooltipModule,
    TooltipNodePipe,
    SvgComponent,
    HeadLineSimpleComponent,
  ],
})
export class TagDependenciesComponent {
  readonly IconType = IconType;
  readonly TagNodeType = TagNodeType;

  public node: DynamicFlatNode;

  public dependencies: Array<IButtonThumbnail> = [
    {
      id: 'dependants',
      icon: ['far', 'arrow-trend-up'],
      tooltip: 'Dependants',
    },
    {
      id: 'dependencies',
      icon: ['far', 'arrow-trend-down'],
      tooltip: 'Dependencies',
    },
  ];
  public form: FormGroup;
  public dataset: Map<string, DynamicFlatNode[]>;
  private tagTreeDataset: ITagTreeNode;

  constructor(
    readonly database: DynamicDatabase,
    readonly tagService: TagService,
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    readonly widgetService: WidgetService,
    readonly boardService: BoardService,
    readonly customOverlayService: CustomOverlayService,
  ) {
    this.node = new DynamicFlatNode(
      this.config.data.tagId,
      undefined,
      undefined,
      0,
      TagNodeType.TAG,
      false,
      false,
    );
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(
      this.getLevel,
      this.isExpandable,
    );
    this.dataSource = new DynamicDataSource(this.treeControl, this.database);
    this.onGetDependants();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      direction: new FormControl('dependants'),
    });
  }

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => {
    return _nodeData.expandable;
  };

  private onGetDependants(): void {
    if (
      this.node.type === TagNodeType.TAG ||
      this.node.type == TagNodeType.SYSTEM_TAG
    ) {
      this.tagService
        .getDependants(this.node.id)
        .subscribe((response: ITagTreeNode) => {
          this.dataBind(response);
        });
    } else if (this.node.type === TagNodeType.BOARD) {
      this.onGetDependencies();
    } else {
      this.widgetService
        .getDependants(this.node.id)
        .subscribe((response: ITagTreeNode) => {
          this.dataBind(response);
        });
    }
  }

  private dataBind(response: ITagTreeNode): void {
    this.tagTreeDataset = response;
    const node = new DynamicFlatNode(
      response.id,
      response.name,
      response.organisation,
      0,
      response.type,
      response.children?.length > 0,
      false,
    );
    this.dataset = new Map<string, DynamicFlatNode[]>();
    this.getNode(node, 0, response.children);
    this.database.dataMap = this.dataset;
    this.database.rootLevelNodes = [node];
    this.dataSource.data = this.database.initialData();
  }

  private getNode(
    node: DynamicFlatNode,
    level: number,
    children: ITagTreeNode[],
  ): void {
    this.dataset.set(
      node.id,
      children?.map((item: ITagTreeNode) => {
        return new DynamicFlatNode(
          item.id,
          item.name,
          item.organisation,
          level + 1,
          item.type,
          item.children?.length > 0,
          false,
        );
      }),
    );
    children?.forEach((item: ITagTreeNode) => {
      const nodeItem = new DynamicFlatNode(
        item.id,
        item.name,
        item.organisation,
        level + 1,
        item.type,
        item.children?.length > 0,
        false,
      );
      this.getNode(nodeItem, level + 1, item.children);
    });
  }

  private onGetDependencies(): void {
    if (
      this.node.type === TagNodeType.TAG ||
      this.node.type == TagNodeType.SYSTEM_TAG
    ) {
      this.tagService
        .getDependencies(this.node.id)
        .subscribe((response: ITagTreeNode) => {
          this.dataBind(response);
        });
    } else if (this.node.type === TagNodeType.BOARD) {
      this.boardService
        .getDependencies(this.node.id)
        .subscribe((response: ITagTreeNode) => {
          this.dataBind(response);
        });
    } else {
      this.widgetService
        .getDependencies(this.node.id)
        .subscribe((response: ITagTreeNode) => {
          this.dataBind(response);
        });
    }
  }

  public onDataBound(): void {
    this.dataset?.clear();
    this.database.clear();
    this.dataSource = new DynamicDataSource(this.treeControl, this.database);

    if (this.form?.get('direction')?.value === 'dependants') {
      this.onGetDependants();
    } else {
      this.onGetDependencies();
    }
  }

  public onLoadNode(node: DynamicFlatNode): void {
    this.node = node;
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(
      this.getLevel,
      this.isExpandable,
    );
    this.dataSource = new DynamicDataSource(this.treeControl, this.database);
    this.onDataBound();
  }

  public onUnlink(node: DynamicFlatNode): void {
    const parentNode = this.findNodeAndParentInTree(
      this.tagTreeDataset,
      node.id,
    );
    const unlinkType = node.type;
    const dependencyType = parentNode.type;
    const unlinkId = node.id;
    const dependencyId = parentNode.id;
    if (
      unlinkType === TagNodeType.TAG ||
      unlinkType === TagNodeType.SYSTEM_TAG
    ) {
      this.tagService.unlinkTag(unlinkId, dependencyId).subscribe(
        () => {
          this.removeNode(node);
        },
        (error: IError) => {
          this.customOverlayService.openCustom(
            {
              title: 'Oooops!',
              message: error.description,
              icon: ['far', 'exclamation-circle'],
              size: '4x',
              dialog: {
                type: DialogTypes.ALERT,
              },
            },
            DialogComponent,
          );
        },
      );
    }
    if (unlinkType === TagNodeType.BOARD) {
      if (
        dependencyType === TagNodeType.TAG ||
        dependencyType === TagNodeType.SYSTEM_TAG
      ) {
        this.boardService.unlinkTag(unlinkId, dependencyId).subscribe(() => {
          this.removeNode(node);
        });
      }
      if (dependencyType === TagNodeType.WIDGET) {
        this.boardService.unlinkWidget(unlinkId, dependencyId).subscribe(() => {
          this.removeNode(node);
        });
      }
    }
    if (unlinkType === TagNodeType.WIDGET) {
      this.widgetService.unlinkTag(unlinkId, dependencyId).subscribe(() => {
        this.removeNode(node);
      });
    }
  }

  private removeNode(node: DynamicFlatNode): void {
    this.database.deleteNode(node);
    this.dataset = this.database.dataMap;
    this.dataSource.removeNode(node);
  }

  private findNodeAndParentInTree(
    rootNode: ITagTreeNode,
    targetId: string,
    parent: ITagTreeNode | null = null,
  ): ITagTreeNode | null {
    if (rootNode.id === targetId) {
      // If the current node's ID matches the target ID, return its parent.
      return parent;
    }

    for (const childNode of rootNode.children) {
      // Recursively search for the target node in the children of the current node.
      const result = this.findNodeAndParentInTree(
        childNode,
        targetId,
        rootNode,
      );

      if (result !== null) {
        // If the target node is found in a child node, return the result.
        return result;
      }
    }

    // If the target node is not found in the current node or its children, return null.
    return null;
  }
}

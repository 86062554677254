import { ChartExploreEventTimelineComponent } from './../widgets/components/chart-explore/components/chart-explore-event-timeline/chart-explore-event-timeline.component';
import { Type } from '@angular/core';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import { ChartExploreTagAnalysisComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-tag-analysis/chart-explore-tag-analysis.component';
import { ChartExploreTagComparisonComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-tag-comparison/chart-explore-tag-comparison.component';
import { ChartExploreTagTrendsComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-tag-trend/chart-explore-tag-trend.component';
import { ChartExploreTopActorsComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-top-actors/chart-explore-top-actors.component';
import { ChartExploreTopChannelsComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-top-channels/chart-explore-top-channels.component';
import { ChartExploreTopicClustersComponent } from '@portal/widgets/components/chart-explore/components/chart-explore-topic-clusters/chart-explore-topic-clusters.component';
import { ChartWizardEventTimelineComponent } from '@portal/widgets/components/chart-wizard/components/chart-wizard-event-timeline/chart-wizard-event-timeline.component';
import { ChartWizardTagAnalysisComponent } from '@portal/widgets/components/chart-wizard/components/chart-wizard-tag-analysis/chart-wizard-tag-analysis.component';
import { ChartWizardTagComparisonComponent } from '@portal/widgets/components/chart-wizard/components/chart-wizard-tag-comparison/chart-wizard-tag-comparison.component';
import { ChartWizardTagTrendsComponent } from '@portal/widgets/components/chart-wizard/components/chart-wizard-tag-trends/chart-wizard-tag-trends.component';
import { ChartWizardTimelineComponent } from '@portal/widgets/components/chart-wizard/components/chart-wizard-timeline/chart-wizard-timeline.component';
import { ChartWizardTopActorsComponent } from '@portal/widgets/components/chart-wizard/components/chart-wizard-top-actors/chart-wizard-top-actors.component';
import { ChartWizardTopChannelsComponent } from '@portal/widgets/components/chart-wizard/components/chart-wizard-top-channels/chart-wizard-top-channels.component';
import { ChartWizardTopicClusterComponent } from '@portal/widgets/components/chart-wizard/components/chart-wizard-topic-cluster/chart-wizard-topic-cluster.component';

export class WidgetSettingsFactory {
  static getWizardComponent(
    type: AnalysisTypes,
  ): Type<
    | ChartWizardTimelineComponent
    | ChartWizardTopicClusterComponent
    | ChartWizardTagAnalysisComponent
    | ChartWizardTagTrendsComponent
    | ChartWizardTagComparisonComponent
    | ChartWizardTopActorsComponent
    | ChartWizardTopChannelsComponent
    | ChartWizardEventTimelineComponent
  > {
    if (type === AnalysisTypes.TIMELINE) {
      return ChartWizardTimelineComponent;
    } else if (type === AnalysisTypes.TOPIC_CLUSTERS) {
      return ChartWizardTopicClusterComponent;
    } else if (type === AnalysisTypes.TAG_ANALYSIS) {
      return ChartWizardTagAnalysisComponent;
    } else if (type === AnalysisTypes.TAG_COMPARISON) {
      return ChartWizardTagComparisonComponent;
    } else if (type === AnalysisTypes.TIME_SERIES) {
      return ChartWizardTagTrendsComponent;
    } else if (type === AnalysisTypes.TOP_ACTORS) {
      return ChartWizardTopActorsComponent;
    } else if (type === AnalysisTypes.TOP_CHANNELS) {
      return ChartWizardTopChannelsComponent;
    } else if (type === AnalysisTypes.EVENT_TIMELINE) {
      return ChartWizardEventTimelineComponent;
    }
  }

  static getExploreComponent(
    type: AnalysisTypes,
  ): Type<
    | ChartExploreTagAnalysisComponent
    | ChartExploreTagTrendsComponent
    | ChartExploreTagComparisonComponent
    | ChartExploreTopActorsComponent
    | ChartExploreTopChannelsComponent
    | ChartExploreTopicClustersComponent
    | ChartExploreEventTimelineComponent
  > {
    if (type === AnalysisTypes.TAG_ANALYSIS) {
      return ChartExploreTagAnalysisComponent;
    } else if (type === AnalysisTypes.TIME_SERIES) {
      return ChartExploreTagTrendsComponent;
    } else if (type === AnalysisTypes.TAG_COMPARISON) {
      return ChartExploreTagComparisonComponent;
    } else if (type === AnalysisTypes.TOP_ACTORS) {
      return ChartExploreTopActorsComponent;
    } else if (type === AnalysisTypes.TOP_CHANNELS) {
      return ChartExploreTopChannelsComponent;
    } else if (type === AnalysisTypes.TOPIC_CLUSTERS) {
      return ChartExploreTopicClustersComponent;
    } else if (type === AnalysisTypes.EVENT_TIMELINE) {
      return ChartExploreEventTimelineComponent;
    }
  }
}

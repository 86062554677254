<itq-form-row>
  <itq-input
    class="w-full px-2 py-1"
    [padding]="Sizes.NONE"
    [icon]="'search'"
    [placeholder]="'Add search term...'"
    [(ngModel)]="filter"
    (search)="onSearch()"
    (clear)="onSearch()"
  ></itq-input>
</itq-form-row>
<ng-container
  *ngIf="query?.selections | filterByCategory: category : section as selections"
>
  <itq-form-row
    *ngIf="selections.length > 0"
    class="dropdown-container__selections mx-2"
  >
    <ng-container
      *ngIf="selections | getIncludedSelections as includedSelections"
    >
      <div class="selections__container" *ngIf="includedSelections.length > 0">
        <span *ngIf="showSelectionsLabel" class="label font-semibold mr-2"
          >Included:</span
        >
        <div class="selections_pill_container">
          <ng-container *ngFor="let item of includedSelections">
            <itq-pill
              [class]="'secondary rounded-md'"
              [type]="PillType.BUTTON"
              [stopPropagation]="true"
              (select)="onChangeValue(item)"
            >
              <span>
                {{ item?.tagName }}
              </span>
              <fa-icon [icon]="['far', 'circle-xmark']" [size]="'sm'"></fa-icon>
            </itq-pill>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="selections | getExcludedSelections as excludedSelections"
    >
      <div class="selections__container" *ngIf="excludedSelections.length > 0">
        <span *ngIf="showSelectionsLabel" class="label font-semibold mr-2"
          >Excluded:</span
        >
        <div class="selections_pill_container">
          <ng-container *ngFor="let item of excludedSelections">
            <itq-pill
              [class]="'secondary rounded-md'"
              [type]="PillType.BUTTON"
              [stopPropagation]="true"
              (select)="onChangeValue(item)"
            >
              <span>
                {{ item?.tagName }}
              </span>
              <fa-icon [icon]="['far', 'circle-xmark']" [size]="'sm'"></fa-icon>
            </itq-pill>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </itq-form-row>
</ng-container>
<itq-form-row class="list__container">
  <ng-container *ngIf="dataSource?.length > 0; else noResultsTemplate">
    <ul #listSearch (scroll)="onScroll()" class="overflow-auto">
      <ng-content></ng-content>
    </ul>
  </ng-container>
  <ng-template #noResultsTemplate>
    <itq-panel-info
      *ngIf="dataSource"
      [label]="'No results found'"
      [message]="'Please update your query...'"
      [icon]="'exclamation-circle'"
    ></itq-panel-info>
  </ng-template>
</itq-form-row>

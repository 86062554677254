import { TemplateRef } from '@angular/core';
import {
  DTOQuery,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  QueryFilters,
  TableColumn,
  TagCategory,
} from '@intorqa-ui/core';
import { TAG_ANALYSIS_CHART_TYPES } from '@portal/boards/const/tag-analysis.const';
import {
  ChartOption,
  ChartOrientation,
  ChartType,
} from '@portal/shared/enums/chart.enum';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import {
  IDataPoint,
  IDisplayType,
  ISerie,
  ITagAnalysis,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Widget } from './widget';

export class TagAnalysis extends Widget implements ITagAnalysis {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public options: { [key: string]: any },
    public dataSource: Array<string>,
    public dataPoints: Array<TagCategory>,
    public top: number,
    public chartType: ChartType,
    public ecosystemId: string,
    public updatedDate: number,
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public getMinWidth(): number {
    return 25;
  }

  public getMinHeight(): number {
    return 5;
  }

  public getDisplayType(): IDisplayType {
    if (this.options.orientation || this.options.mode) {
      return TAG_ANALYSIS_CHART_TYPES.find((displayType: IDisplayType) => {
        return (
          displayType.type === this.chartType &&
          ((this.options.orientation &&
            this.options.orientation === displayType.options?.orientation) ||
            (this.options.mode &&
              this.options.mode === displayType.options?.mode))
        );
      });
    } else {
      return TAG_ANALYSIS_CHART_TYPES.find((displayType: IDisplayType) => {
        return displayType.type === this.chartType;
      });
    }
  }

  public hasMetrics(): boolean {
    return this.dataSource?.length > 0 &&
      this.top > 0 &&
      this.dataPoints?.length > 0 &&
      this.chartType
      ? true
      : false;
  }

  public resetMetrics(): void {
    this.dataSource = undefined;
    this.top = 10;
    this.dataPoints = undefined;
  }

  public generateGetDocsState(params: { [key: string]: any }): any {
    return {
      pageSize: 30,
      page: params.initialState?.page || 1,
      where: params.initialState?.where,
    };
  }

  public getDocsQueryModel(initialState: QueryFilters, segment: any): DTOQuery {
    const queryModel = new Query();
    if (initialState?.query) {
      queryModel.addRule(
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [initialState.query],
        ),
      );
    }

    queryModel.addRule(
      new QueryRule(this.getDataPointField(), DTOQueryConditionOperator.in, [
        this.getDataPointValue(segment),
      ]),
    );

    return queryModel.modelToDTO();
  }

  public getDataPointField(): DTOQueryFieldType {
    if (
      this.dataPoints[0] === TagCategory.Channel ||
      this.dataPoints[0] === TagCategory.Actor ||
      this.dataPoints[0] === TagCategory.Source ||
      this.dataPoints[0] === TagCategory['Post Type'] ||
      this.dataPoints[0] === TagCategory.Community
    ) {
      return DTOQueryFieldType.filter;
    }
    return DTOQueryFieldType.tag;
  }

  public canLoadDocuments(action: WidgetActions): boolean {
    return action === WidgetActions.EXPLORE;
  }

  public transformData(series: Array<ISerie>): Array<ISerie> {
    const filteredSeries = series.filter((item: ISerie) => {
      return item.data.length > 0;
    });
    return filteredSeries.map((serie: ISerie) => {
      return {
        ...serie,
        data: serie.data.sort((a: IDataPoint, b: IDataPoint) => {
          if (this.options.orientation === ChartOrientation.VERTICAL) {
            return a.count > b.count ? -1 : 1;
          } else {
            return a.count > b.count ? 1 : -1;
          }
        }),
      };
    });
  }

  public getDataPointValue(segment: any): string {
    return segment?.data?.tagId;
  }

  public getTagId(): string {
    return this.dataSource[0];
  }

  public getTableColumns(template: TemplateRef<unknown>): Array<TableColumn> {
    return [
      {
        name: this.dataPoints[0],
        dataKey: 'category',
        width: 'fit',
        isSortable: true,
      },
      {
        name: 'Count',
        dataKey: 'count',
        position: 'right',
        width: '100px',
        customRender: true,
        template: template,
        isSortable: true,
      },
    ];
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return TAG_ANALYSIS_CHART_TYPES;
  }

  public getRuleValue(): Array<string> {
    return [this.dataSource[0]];
  }

  public getTagIds(): Array<string> {
    return this.dataSource;
  }

  public getOption(option: ChartOption): any {
    let result: any;
    if (this.options) {
      Object.keys(this.options)?.forEach((key: ChartOption) => {
        if (key === option) {
          result = this.options[key];
        }
      });
    }
    return result;
  }

  public cloneDeep(): TagAnalysis {
    return new TagAnalysis(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.width,
      this.height,
      this.x,
      this.y,
      this.options,
      this.dataSource,
      this.dataPoints,
      this.top,
      this.chartType,
      this.ecosystemId,
      this.updatedDate,
    );
  }
}

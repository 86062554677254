import { Injectable } from '@angular/core';
import { FoamTree } from '@carrotsearch/foamtree';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import { DateRangeHelper, DTOQuery, QueryFilters } from '@intorqa-ui/core';
import { ISearchResults } from '@portal/shared/interfaces/document.interface';
import { map, Observable } from 'rxjs';
import { DateQueryType } from '../../enums/date-range.enum';
import { ICluster, IFoamTreeGroup, IRiplResults } from './foamtree.interface';

@Injectable({
  providedIn: 'root',
})
export class FoamtreeService {
  constructor(private apiRequestService: ApiRequestService) {}

  getData(
    state: QueryFilters,
    query: DTOQuery,
    ecosystemId: string,
  ): Observable<IRiplResults> {
    let pageQuery = '';
    if (state?.where) {
      if (state.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${state.where?.start}`;
        pageQuery += `&dateTo=${state.where?.end}`;
      } else {
        let preset = DateRangeHelper.findPresetByLabel(state.where.label);
        pageQuery += `&dateFrom=${DateRangeHelper.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRangeHelper.convertToEpochSec(preset?.end.toDate())}`;
      }
    }
    if (ecosystemId) {
      pageQuery += `&ecosystemId=${ecosystemId}`;
    }
    return this.apiRequestService
      .postToObservable(
        `/document/cluster/search?size=5000${pageQuery}`,
        new DTOTypeConverter<IRiplResults>(),
        JSON.stringify(query),
      )
      .pipe(
        map((data: IRiplResults) => {
          data.clusters = this.addIdsToClusters(data.clusters);
          return data;
        }),
      );
  }

  private addIdsToClusters(
    clusters: ICluster[],
    parentId: string = '',
  ): ICluster[] {
    return clusters.map((cluster, index) => {
      const id = parentId ? `${parentId}-${index}` : `${index}`;
      return {
        ...cluster,
        id,
        clusters: this.addIdsToClusters(cluster.clusters, id),
      };
    });
  }

  getClusterDocumentIndex(cluster: Array<any>): Array<number> {
    if (cluster) {
      let documentIndex = [];
      cluster.map((item: any) => {
        if (item) {
          documentIndex = [...documentIndex, ...item.documents];
          item.groups.map((elem: any) => {
            documentIndex = [...documentIndex, ...elem.documents];
            if (elem.groups.length > 0) {
              const docIds = this.getClusterDocumentIndex(elem.groups);
              documentIndex = [...documentIndex, ...docIds];
            }
          });
        }
      });

      return documentIndex;
    }
  }

  removeDocuments(documents: Array<number>): Array<number> {
    documents = documents.slice().sort();
    let copyDocuments = [...documents];
    for (let i = 0; i < documents.length - 1; i++) {
      if (documents[i + 1] === documents[i]) {
        copyDocuments = copyDocuments.filter((d: number) => {
          return d !== documents[i];
        });
      }
    }
    return copyDocuments;
  }

  getClusterDocumentIds(cluster: Array<any>, data: any): Array<number> {
    const result = [];
    const documentIndex = this.getClusterDocumentIndex(cluster);
    const nonDuplicateDocs = this.removeDocuments(documentIndex);

    nonDuplicateDocs.map((item: number) => {
      if (
        !result.includes(data.source_documents[item]) &&
        data.source_documents[item]
      ) {
        result.push(data.source_documents[item]);
      }
    });

    return result;
  }

  public convert(clusters): IFoamTreeGroup {
    const result = clusters?.map((cluster) => {
      return {
        id: cluster.id,
        label: cluster.labels.join(', '),
        weight: cluster.score,
        groups: cluster.clusters ? this.convert(cluster.clusters) : [],
        documents: cluster.documents,
      };
    });
    return result;
  }

  exportImage(name: string, foamtreeRef: FoamTree): void {
    const imageBase64 = foamtreeRef.get('imageData', 'image/png');
    const element = document.createElement('a');
    element.setAttribute('href', imageBase64);
    element.setAttribute('download', name + '.png');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public filterCluster(query: {
    documentIds: Array<number>;
  }): Observable<ISearchResults> {
    return this.apiRequestService.postToObservable(
      '/document/ids',
      new DTOTypeConverter<ISearchResults>(),
      JSON.stringify(query),
    );
  }
}

export enum ChartType {
  BAR = 'BAR',
  LINE = 'LINE',
  PIE = 'PIE',
  TABLE = 'TABLE',
  FOAMTREE = 'FOAMTREE',
  TIMELINE = 'TIMELINE',
  EVENT_TIMELINE = 'EVENT_TIMELINE',
  NETWORK = 'NETWORK',
}

export enum ChartOrientation {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum FoamtreeMode {
  RECTANGULAR = 'squarified',
  POLYGONAL = 'relaxed',
}

export enum ChartOption {
  ORIENTATION = 'orientation',
  MODE = 'mode',
  X_AXIS = 'xAxis',
  Y_AXIS = 'yAxis',
  DATA_ZOOM = 'dataZoom',
  SERIES = 'series',
  TOOLTIP = 'tooltip',
}

export enum PieMode {
  PIE = 'pie',
  DOUGHTNUT = 'doughtnut',
}

export enum LineMode {
  LINE = 'line',
  AREA = 'area',
}

export enum BarMode {
  STACK = 'stack',
  GROUP = 'group',
}

@if (
  widget &&
  (timelineStatus === TaggingStatus.COMPLETED ||
    (timelineStatus === TaggingStatus.IN_PROGRESS && data?.count > 0))
) {
  <itq-timeline-feed-v2
    class="h-full flex flex-col"
    [widget]="widget"
    [data]="data"
    [initialState]="initialState"
    [segment]="segment"
    [query]="query"
    [showFooter]="true"
    (scroll)="onDataBound($event)"
    (drilldown)="onDrilldown($event)"
    (createProfile)="onCreateProfile($event)"
  ></itq-timeline-feed-v2>
} @else {
  @if (timelineStatus === TaggingStatus.IN_PROGRESS) {
    <itq-panel-info
      [label]="noContentTitle"
      [message]="noContentMessage"
      [icon]="'exclamation-circle'"
    ></itq-panel-info>
  }
}

import { Component, Input, OnInit } from '@angular/core';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';

import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import {
  CoreModule,
  DateRangeComponent,
  EventTeams,
  IEventType,
  IPresetQuery,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { EventTimeline } from '@portal/boards/models/widgets/event-timeline';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetService } from '@portal/boards/services/widget.service';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { AllTagsDatasourceComponent } from '../all-tags-datasource/all-tags-datasource.component';
import { SpecificTagDatasourceComponent } from '../specific-tag-datasource/specific-tag-datasource.component';
import { ICustomTag } from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { CategoryService } from '@portal/shared/services/category.service';

@Component({
  selector: 'itq-event-timeline-metrics',
  templateUrl: './event-timeline-metrics.component.html',
  styleUrls: ['./event-timeline-metrics.component.scss'],
  standalone: true,
  imports: [
    MatRadioModule,
    ReactiveFormsModule,
    AllTagsDatasourceComponent,
    SpecificTagDatasourceComponent,
    CoreModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    DateRangeComponent,
  ],
})
export class EventTimelineMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.eventTimelineWidget = widget as EventTimeline;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public cheaterTeamDataSource: Array<IEventType>;
  public securityTeamDataSource: Array<IEventType>;
  public cheaterTeamInitialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    { active: 'name', direction: 'asc' },
    undefined,
  );
  public securityTeamInitialState = new QueryFilters(
    5000,
    1,
    undefined,
    undefined,
    { active: 'name', direction: 'asc' },
    undefined,
  );
  public eventTimelineWidget: EventTimeline;
  public tagsInitialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    { active: 'name', direction: 'asc' },
    undefined,
  );
  private _tagsDataSource: { items: Array<ICustomTag>; totalCount: number };
  public get tagsDataSource(): {
    items: Array<ICustomTag>;
    totalCount: number;
  } {
    return this._tagsDataSource;
  }

  public set tagsDataSource(value: {
    items: Array<ICustomTag>;
    totalCount: number;
  }) {
    this._tagsDataSource = {
      items:
        this.tagsInitialState.page > 1
          ? [...this.tagsDataSource?.items, ...value.items]
          : value.items,
      totalCount: value.totalCount,
    };
  }

  constructor(
    public widgetService: WidgetService,
    readonly eventsService: EventsService,
    readonly userService: UserService,
    private fb: FormBuilder,
    readonly widgetSettingsService: WidgetSettingsService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.onGetSecurityEvents(this.securityTeamInitialState);
    this.onGetCheaterEvents(this.cheaterTeamInitialState);
    if (this.eventTimelineWidget?.dataCorrelation?.length > 0) {
      this.onGetTags();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataCorrelation');
    this.form.removeControl('securityTeam');
    this.form.removeControl('cheaterTeam');
    this.form.removeControl('selectAllCheater');
    this.form.removeControl('selectAllSecurity');
  }
  public onChangeDataCorrelation(): void {
    this.eventTimelineWidget.dataCorrelation = [
      this.form.get('dataCorrelation').value?.id,
    ];
    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      reloadData: false,
    });
  }

  public onClearDataCorrelation(): void {
    this.eventTimelineWidget.dataCorrelation = [];
    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      reloadData: false,
    });
  }

  public onDataBoundGetTags(params: QueryFilters): void {
    this.tagsInitialState = params;
    this.onGetTags();
  }

  public onGetTags(): void {
    this.categoryService
      .getTags(
        this.tagsInitialState.query,
        this.tagsInitialState,
        new Query().modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        this.tagsInitialState.page > 1
          ? this.tagsDataSource?.items[this.tagsDataSource?.items?.length - 1]
              .name
          : undefined,
      )
      .then((response: { items: Array<ICustomTag>; totalCount: number }) => {
        this.tagsDataSource = response;
        if (this.eventTimelineWidget.dataCorrelation?.length > 0) {
          this.form
            .get('dataCorrelation')
            .setValue(
              this.tagsDataSource.items.find(
                (tag: ICustomTag) =>
                  tag.id === this.eventTimelineWidget.dataCorrelation[0],
              ),
            );
        }
      });
  }

  private createForm(): void {
    this.form = this.fb.group(
      {
        securityTeam: [undefined, Validators.required],
        cheaterTeam: [undefined, Validators.required],
        selectAllCheater: [false],
        selectAllSecurity: [false],
        dataCorrelation: [],
      },
      { validators: this.atLeastOneFieldValidator },
    );
  }

  private atLeastOneFieldValidator(
    group: FormGroup,
  ): { [key: string]: boolean } | null {
    const securityTeam = group.get('securityTeam')?.value;
    const cheaterTeam = group.get('cheaterTeam')?.value;
    return securityTeam && cheaterTeam
      ? null
      : { atLeastOneFieldRequired: true };
  }

  public onGetCheaterEvents(params: QueryFilters): void {
    this.cheaterTeamInitialState = params;
    this.eventsService
      .getEventTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IEventType>) => {
        this.cheaterTeamDataSource = response?.filter((event: IEventType) => {
          return event.team === EventTeams.CHEATER_TEAM;
        });
        if (this.eventTimelineWidget.widgetId) {
          const widgetTypeIds = this.cheaterTeamDataSource.filter(
            (event: IEventType) => {
              return this.eventTimelineWidget.dataSource.includes(event.id);
            },
          );
          if (widgetTypeIds.length === this.cheaterTeamDataSource.length) {
            this.form.get('selectAllCheater').setValue(true);
          }
          this.form.get('cheaterTeam').setValue(widgetTypeIds);
        }
      });
  }

  public onGetSecurityEvents(params: QueryFilters): void {
    this.securityTeamInitialState = params;
    this.eventsService
      .getEventTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<IEventType>) => {
        this.securityTeamDataSource = response?.filter((event: IEventType) => {
          return event.team === EventTeams.SECURITY_TEAM;
        });
        if (this.eventTimelineWidget.widgetId) {
          const widgetTypeIds = this.securityTeamDataSource.filter(
            (event: IEventType) => {
              return this.eventTimelineWidget.dataSource.includes(event.id);
            },
          );

          if (widgetTypeIds.length === this.securityTeamDataSource.length) {
            this.form.get('selectAllSecurity').setValue(true);
          }
          this.form.get('securityTeam').setValue(widgetTypeIds);
        }
      });
  }

  public onChangeSecurityEvents(): void {
    const securityTypeIds =
      this.form
        .get('securityTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const cheaterTypeIds =
      this.form
        .get('cheaterTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const eventTypeIds = [...securityTypeIds, ...cheaterTypeIds];
    this.eventTimelineWidget.dataSource = eventTypeIds;

    const widgetTypeIds = this.securityTeamDataSource.filter(
      (event: IEventType) => {
        return this.eventTimelineWidget.dataSource.includes(event.id);
      },
    );

    if (widgetTypeIds.length === this.securityTeamDataSource.length) {
      this.form.get('selectAllSecurity').setValue(true);
    } else {
      this.form.get('selectAllSecurity').setValue(false);
    }

    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      reloadData: true,
    });
  }

  public onChangeCheaterEvents(): void {
    const securityTypeIds =
      this.form
        .get('securityTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const cheaterTypeIds =
      this.form
        .get('cheaterTeam')
        .value?.map((eventType: IEventType) => eventType.id) || [];
    const eventTypeIds = [...securityTypeIds, ...cheaterTypeIds];
    this.eventTimelineWidget.dataSource = eventTypeIds;

    const widgetTypeIds = this.cheaterTeamDataSource.filter(
      (event: IEventType) => {
        return this.eventTimelineWidget.dataSource.includes(event.id);
      },
    );
    if (widgetTypeIds.length === this.cheaterTeamDataSource.length) {
      this.form.get('selectAllCheater').setValue(true);
    } else {
      this.form.get('selectAllCheater').setValue(false);
    }

    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      reloadData: true,
    });
  }

  public onToggleCheater(params: MatSlideToggleChange): void {
    let eventTypeIds: Array<IEventType>;
    if (params.checked) {
      eventTypeIds = this.cheaterTeamDataSource;
    } else {
      eventTypeIds = [];
    }
    this.form.get('cheaterTeam').setValue(eventTypeIds);
    this.onChangeCheaterEvents();
  }

  public onToggleSecurity(params: MatSlideToggleChange): void {
    let eventTypeIds: Array<IEventType>;
    if (params.checked) {
      eventTypeIds = this.securityTeamDataSource;
    } else {
      eventTypeIds = [];
    }
    this.form.get('securityTeam').setValue(eventTypeIds);
    this.onChangeSecurityEvents();
  }

  public onChangeDate(params: IPresetQuery): void {
    this.cheaterTeamInitialState.where = params;
    this.securityTeamInitialState.where = params;
    this.dates = params;
    this.onGetSecurityEvents(this.securityTeamInitialState);
    this.onGetCheaterEvents(this.cheaterTeamInitialState);
    this.widgetService.updateWidget$.next({
      widget: this.eventTimelineWidget,
      dates: this.dates,
      reloadData: true,
    });
  }
}

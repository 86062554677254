import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { IconName } from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: "itq-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent implements OnInit {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { text: string; icon: IconName }
  ) {}

  ngOnInit(): void {}
}

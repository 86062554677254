import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "itq-modal-footer",
  templateUrl: "./modal-footer.component.html",
  styleUrls: ["./modal-footer.component.scss"],
})
export class ModalFooterComponent implements OnInit {
  @Input() icon: string;

  constructor() {}

  ngOnInit(): void {}
}

import { Pipe, PipeTransform } from "@angular/core";
import { ToolbarActions } from "./toolbar.enum";
import { IToolbarAction } from "./toolbar.interface";

@Pipe({
  name: "findAction",
  pure: false,
})
export class FindActionPipe implements PipeTransform {
  constructor() {}

  transform(
    actions: Array<IToolbarAction>,
    action: ToolbarActions
  ): IToolbarAction {
    return actions.find((item: IToolbarAction) => {
      return item.action === action;
    });
  }
}

<div id="notfound">
  <div class="notfound">
    <div class="notfound-404">
      <h3>{{ error.title }}</h3>
      <h1>
        <span>{{ error.code.toString().substring(0, 1) }}</span
        ><span>{{ error.code.toString().substring(1, 2) }}</span
        ><span>{{ error.code.toString().substring(2, 3) }}</span>
      </h1>
    </div>
    <h2>{{ error.description }}</h2>
  </div>
</div>

import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Sizes } from "../../enums/shared.enum";

@Component({
  selector: "itq-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnChanges {
  public Sizes = Sizes;

  @Input() isExpanded = true;
  @Input() title: string;
  @Input() width: number;

  @Output() toggle = new EventEmitter();

  @HostBinding("class") classes = "isExpanded";
  @HostBinding("style.width") styleWidth: string;

  constructor() {}

  ngOnInit(): void {
    this.setStyle();
    if (this.width) {
      this.styleWidth = this.width + "px";
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.isExpanded?.previousValue !== changes?.isExpanded?.currentValue
    ) {
      this.setStyle();
    }
  }

  public onToggle(): void {
    this.isExpanded = !this.isExpanded;
    this.styleWidth = (this.isExpanded ? this.width : 30) + "px";

    this.setStyle();
    this.toggle.emit();
  }

  private setStyle(): void {
    this.classes = this.isExpanded ? "isExpanded" : "";
  }
}

import { Injectable } from "@angular/core";
import {
  ApiRequestService,
  DTOMessage,
  DTOTypeConverter,
} from "@intorqa-ui/api";

@Injectable({
  providedIn: "root",
})
export class ApiSettingsService {
  constructor(private apiRequestService: ApiRequestService) {}

  generateKey(tagId: string): Promise<any> {
    return this.apiRequestService.put(
      "/external/key/" + tagId,
      new DTOTypeConverter<DTOMessage>(),
      tagId
    );
  }
}

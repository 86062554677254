import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'itq-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HelpComponent implements OnInit {
  public showLoader = true;
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.showLoader = false;
    }, 3000);
  }
}

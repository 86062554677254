import { Component, Input, OnInit } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: "itq-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
  standalone: true,
  imports: [MatTooltipModule],
})
export class AvatarComponent implements OnInit {
  @Input() tooltip: string;

  constructor() {}

  ngOnInit() {}
}

import { Component, Input, OnInit } from "@angular/core";
import { IconName } from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: "itq-panel-action",
  templateUrl: "./panel-action.component.html",
  styleUrls: ["./panel-action.component.scss"],
})
export class PanelActionComponent implements OnInit {
  @Input() label = "";
  @Input() description = "";
  @Input() icon: IconName = "info-circle";

  constructor() {}

  ngOnInit(): void {}
}

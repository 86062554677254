import { Pipe, PipeTransform } from '@angular/core';
import { IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';

@Pipe({
  name: 'castIconName',
  standalone: true,
})
export class CastIconNamePipe implements PipeTransform {
  transform(value: string): IconName {
    return value as IconName;
  }
}

@Pipe({
  name: 'castIconSize',
  standalone: true,
})
export class CastIconSizePipe implements PipeTransform {
  transform(value: string): SizeProp {
    return value as SizeProp;
  }
}

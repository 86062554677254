import { ComponentType } from '@intorqa-ui/core';
import { IProfileTypeMetadata } from '../interfaces/profile-type-metadata.interface';

export class ProfileTypeMetadata implements IProfileTypeMetadata {
  constructor(
    public id: string,
    public name: string,
    public component: ComponentType,
    public values: any,
    public required: boolean,
    public groupName: string
  ) {}
}

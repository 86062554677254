// Override addEventListener to set passive to false by default
(function () {
  const originalAddEventListener = EventTarget.prototype.addEventListener;

  EventTarget.prototype.addEventListener = function (type, listener, options) {
    if (typeof options === 'object') {
      options.passive = false;
    } else {
      options = { passive: false };
    }
    originalAddEventListener.call(this, type, listener, options);
  };
})();

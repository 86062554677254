import { FormGroup } from "@angular/forms";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "itq-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Output() submitForm = new EventEmitter();

  @Input() formGroup: FormGroup;
  @Input() inline = true;

  constructor() {}

  ngOnInit(): void {}

  onSubmitForm(): void {
    this.submitForm.emit();
  }
}

import { ChartUtils, Utils } from '@intorqa-ui/core';
import { ChartOrientation, LineMode } from '@portal/shared/enums/chart.enum';
import { IDataPoint, ISerie } from '@portal/shared/interfaces/widget.interface';
import { EChartsOption } from 'echarts';
import { Chart } from './chart';

export class LineChart extends Chart {
  public defaultSettings = {
    yAxis: { splitLine: { show: true }, show: true },
    xAxis: { show: true },
    tooltip: { show: true },
    series: {
      lineStyle: { width: 1 },
      label: { show: true },
      showSymbol: true,
      symbolSize: 10,
    },
  };

  constructor() {
    super();
  }

  public getOptions(
    data: Array<ISerie>,
    settings: { [key: string]: any },
    segment: any,
  ): EChartsOption {
    settings = { ...this.defaultSettings, ...settings };
    return {
      tooltip: this.getTooltip(data, settings),
      textStyle: ChartUtils.getTextStyle(),
      xAxis: this.getXAxis(data, settings),
      yAxis: this.getYAxis(data, settings),
      series: this.getSeries(data, settings, segment),
      grid: ChartUtils.getGrid(
        data.length > 1,
        settings.yAxis?.show && settings.xAxis?.show,
      ),
      dataZoom: this.getDataZoom(data, settings),
      legend: ChartUtils.getLegend(data.length > 1),
    };
  }

  private getSeries(
    data: Array<ISerie>,
    settings: { [key: string]: any },
    segment: any,
  ): { [key: string]: any } {
    return data.map((serie: ISerie, index: number) => {
      return {
        name: serie.name,
        data: serie.data.map((item: IDataPoint) => {
          return ChartUtils.getDataPoint(item, segment, serie.tagId, index);
        }),
        type: 'line',
        symbol: 'circle',
        showSymbol: settings.series?.showSymbol,
        symbolSize: settings.series?.symbolSize,
        areaStyle: settings.mode === LineMode.AREA ? {} : undefined,
        animation: false,
        lineStyle: {
          width: settings.series?.lineStyle.width,
        },
        label: {
          show: settings.series?.label.show
            ? data?.length > 1 || serie.data?.length > 15
              ? false
              : true
            : false,
          position: 'top',
          labelLayout: {
            hideOverlap: true,
          },
          formatter: (params: any) => {
            return params.value === 0 ? '' : params.value;
          },
        },
        tooltip: this.getSerieTooltip(data),
        smooth: settings?.series?.smooth !== false ? true : false,
      };
    });
  }

  public getYAxis(data: Array<ISerie>, settings: { [key: string]: any }): any {
    if (settings.orientation === ChartOrientation.HORIZONTAL) {
      return {
        show: settings.yAxis?.show,
        data: data[0]?.data.map((item: IDataPoint) => {
          return item.category;
        }),
        splitLine: {
          show: settings.yAxis?.splitLine?.show,
        },
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          rotate: -320,
        },
      };
    } else {
      return {
        splitLine: {
          show: settings.yAxis?.splitLine?.show,
        },
        show: settings.yAxis?.show,
        type: 'value',
        axisLabel: {
          formatter: (value: number) => {
            return Utils.numFormatter(value);
          },
        },
      };
    }
  }

  private getTooltip(
    data: Array<ISerie>,
    settings: { [key: string]: any },
  ): any {
    return {
      show: settings.tooltip?.show,
      trigger: data?.length > 1 ? 'item' : 'axis',
      extraCssText: 'overflow: auto; max-height: 250px; border: 0;',
      enterable: true,
      axisPointer: {
        type: 'shadow',
      },
    };
  }

  private getXAxis(
    data: Array<ISerie>,
    settings: { [key: string]: any },
  ): { [key: string]: any } {
    if (settings.orientation === ChartOrientation.HORIZONTAL) {
      return {
        show: settings.xAxis?.show,
        type: 'value',
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) => {
            return Utils.numFormatter(value);
          },
        },
      };
    } else {
      return {
        show: settings.xAxis?.show,
        data: data[0]?.data.map((item: IDataPoint) => {
          return item.category;
        }),
        type: 'category',
        splitLine: {
          show: false,
        },
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          rotate: -320,
        },
      };
    }
  }

  private getDataZoom(
    data: Array<ISerie>,
    settings: { [key: string]: any },
  ): {
    [key: string]: any;
  } {
    if (settings.markLines) {
      return [
        {
          show: true,
          realtime: true,
          type: 'slider',
          start: 0,
          height: 30,
          bottom: 10,
          handleStyle: {
            color: '#1de62a',
            borderColor: '#1de62a',
          },
          moveHandleStyle: {
            color: '#1de62a',
            borderColor: '#1de62a',
          },
          backgroundColor: 'rgba(29, 230, 42, 0.1)',
          dataBackground: {
            lineStyle: {
              color: '#1de62a',
            },
            areaStyle: {
              color: 'rgba(29, 230, 42, 0.3)',
            },
          },
          fillerColor: 'rgba(29, 230, 42, 0.4)',
        },
      ];
    }
  }

  private getSerieTooltip(data: Array<ISerie>): any {
    return {
      confine: false,
      formatter: (value: any) => {
        let result = '<div>' + value.name + '</div>';
        let resultObj = data.map((item: ISerie, index: number) => {
          const dataPointValue = item.data.find(
            (dataPoint: IDataPoint) => dataPoint.category === value.name,
          );
          let tooltip = ChartUtils.buildTooltip(
            item,
            value,
            index,
            dataPointValue,
          );
          return {
            value: dataPointValue,
            text: tooltip,
          };
        });
        resultObj = resultObj.sort((a, b) => {
          return b.value.count > a.value.count ? 1 : -1;
        });
        return result + resultObj.map((item) => item.text).join('');
      },
    };
  }
}

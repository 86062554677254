import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DateQueryType,
  DateRangeComponent,
  DateRangeHelper,
  FAwesomeModule,
  HeadLineSimpleComponent,
  IButtonThumbnail,
  IDropdownItem,
  IPreset,
  IPresetQuery,
  IconType,
  PillType,
  QueryFilters,
  SharedService,
  Sizes,
  TableColumn,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { WidgetService } from '@portal/boards/services/widget.service';
import { Priority_DataSource } from '@portal/notifications/const/alerts.const';
import { AlertType } from '@portal/notifications/models/alert-type';
import {
  GroupNotification,
  Notification,
} from '@portal/notifications/models/notifications';
import { GroupByDatePipe } from '@portal/notifications/pipes/notifications.pipe';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { DocumentItemTileV2Component } from '@portal/shared/components/document-item-tile-v2/document-item-tile-v2.component';
import { ToolbarActions } from '@portal/shared/components/toolbar/toolbar.enum';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { ISearchResults } from '@portal/shared/interfaces/document.interface';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { DocumentItemService } from '@portal/shared/services/document-item.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { DiscordNavigationItem } from '@portal/widget-settings/models/discord-navigation-item.model';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsTimelineComponent } from '@portal/widget-settings/modules/widget-settings-timeline/widget-settings-timeline.component';
import moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
import { NotificationResearchDetailComponent } from '../notification-research-detail/notification-research-detail.component';
import { NotificationsTimelineComponent } from '../notifications-timeline/notifications-timeline.component';

@Component({
  selector: 'itq-notifications-explore',
  templateUrl: './notifications-explore.component.html',
  styleUrls: ['./notifications-explore.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    CommonModule,
    NotificationsTimelineComponent,
    ReactiveFormsModule,
    SharedModule,
    MatTooltipModule,
    HeadLineSimpleComponent,
    NotificationResearchDetailComponent,
    FAwesomeModule,
    DocumentItemTileV2Component,
    DateRangeComponent,
  ],
  providers: [NotificationsService, GroupByDatePipe, SharedService],
})
export class NotificationsExploreComponent implements OnInit {
  @Input() widget: Notification;
  @Input() raisedAlertId: string;
  @Input() type: string;
  @Input() query: string;
  @Input() showAll: string;
  @Input() priority: string;
  @Input() initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  public expandedFilters = true;
  public priorityDataSource: Array<IDropdownItem> = [];

  public buttonDataSource: Array<IButtonThumbnail>;
  public unreadCount: number;
  public notificationsDataSource: {
    items: Array<GroupNotification>;
    totalCount: number;
  };
  public tableColumns: TableColumn[] = [];
  public matchesDataSource: ISearchResults;
  public toolbarActions = [
    {
      action: ToolbarActions.DATE,
    },
  ];
  public alertTypesDataSource: Array<AlertType> = [];
  private subscriptions = new Subscription();
  public form: FormGroup;
  public showLoader = false;

  @ViewChild('unreadTemplate')
  unreadTemplate: TemplateRef<unknown>;

  @ViewChild('template')
  template: TemplateRef<unknown>;

  readonly PillType = PillType;
  readonly Sizes = Sizes;

  constructor(
    readonly notificationsService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly groupByDate: GroupByDatePipe,
    readonly widgetService: WidgetService,
    readonly alertsService: AlertsService,
    readonly virtualScrollService: VirtualScrollService,
    readonly userService: UserService,
    readonly documentService: DocumentItemService,
    readonly customOverlayRef: CustomOverlayRef,
  ) {
    this.priorityDataSource = Priority_DataSource;
  }

  ngOnInit() {
    this.createForm();
    this.getAlertTypes();
    this.bindGetUnreadCountSubscription();
    this.bindCreateProfileSubscription();
    this.bindLoadNotificationsSubscription();
    this.bindOtherMatchesSubscription();
    this.createForm();
    this.onDataBound();
    this.loadMatches();
  }

  ngAfterViewInit(): void {
    this.tableColumns = [
      {
        name: undefined,
        dataKey: 'result',
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
    this.buttonDataSource = [
      {
        id: 'read',
        tooltip: 'All',
      },
      {
        id: 'unread',
        tooltip: 'Unread',
        template: this.unreadTemplate,
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private bindOtherMatchesSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.loadOtherMatches$.subscribe(
        (response: { filters: QueryFilters; raisedAlertId: string }) => {
          this.raisedAlertId = response.raisedAlertId;
          this.showLoader = true;
          this.loadMatches();
        },
      ),
    );
  }

  private bindLoadNotificationsSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.loadNotifications$.subscribe(
        (response: { items: Array<Notification>; totalCount: number }) => {
          this.notificationsDataSource = {
            items: this.groupByDate.transform(response.items),
            totalCount: response.totalCount,
          };
          this.showLoader = false;
        },
      ),
    );
  }

  private bindCreateProfileSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.createProfile$.subscribe(
        (params: {
          profileDrilldown: ProfileDrilldown;
          notification: Notification;
        }) => {
          this.createProfile(params);
        },
      ),
    );
  }

  private bindGetUnreadCountSubscription(): void {
    this.subscriptions.add(
      this.notificationsService.getUnreadCount$.subscribe(
        (response: number) => {
          this.unreadCount = response;
          this.buttonDataSource = this.buttonDataSource.map(
            (item: IDisplayType) => {
              if (item.id === 'unread') {
                if (this.unreadCount === 0) {
                  item = { ...item, ...{ disabled: true } };
                } else {
                  delete item.disabled;
                }
                return item;
              } else {
                return item;
              }
            },
          );
        },
      ),
    );
  }

  private getAlertTypes(): void {
    this.alertsService.getTypes().subscribe((response: Array<AlertType>) => {
      this.alertTypesDataSource = response;
    });
  }

  public onChangeDates(params: IPresetQuery): void {
    this.initialState.where = params;
    this.onDataBound();
  }

  public onMarkAllRead(): void {
    this.showLoader = true;
    this.notificationsService.markAllAsRead().subscribe(() => {
      this.showLoader = false;
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const notification = this.notificationsService.findNotificationById(
      this.raisedAlertId,
    );
    this.createProfile({ profileDrilldown, notification });
  }

  private createProfile(params: {
    profileDrilldown: ProfileDrilldown;
    notification: Notification;
  }): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      params.profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      params.profileDrilldown.profileType.id,
      params.profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        params.profileDrilldown.value,
        undefined,
        undefined,
        params.profileDrilldown.scope === ProfileDrildownScope.TAG
          ? params.profileDrilldown.value
          : `Field field:${params.profileDrilldown.value}:${
              params.profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onDataBoundOtherMatches(params: QueryFilters): void {
    this.initialState = params;
    this.loadMatches();
  }

  private loadMatches(): void {
    if (this.raisedAlertId) {
      this.notificationsService
        .getMatches(
          new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
          this.raisedAlertId,
        )
        .subscribe((matches: ISearchResults) => {
          this.documentService.toggleDocument$.next({
            documentId: undefined,
            isExpanded: false,
          });
          this.matchesDataSource = matches;
          this.showLoader = false;
        });
    }
  }

  public createForm(): void {
    this.form = new FormGroup({
      alertType: new FormControl(this.type),
      priority: new FormControl(this.priority),
      showAll: new FormControl(this.showAll || 'read'),
      query: new FormControl(this.query),
    });
  }

  public onDataBound(params?: QueryFilters): void {
    this.showLoader = true;
    if (params) {
      this.initialState = params;
    }
    this.initialState.query = this.form.get('query').value;
    const getNotificationsObservable =
      this.notificationsService.getNotifications(
        params || this.initialState,
        this.form.get('showAll').value === 'unread' ? false : undefined,
        this.form.get('priority').value,
        this.form.get('alertType').value,
      );
    const getUnreadCountObservable =
      this.notificationsService.getUnreadNotificationsCount(
        this.initialState,
        this.form.get('priority').value,
        this.form.get('alertType').value,
      );

    forkJoin([getNotificationsObservable, getUnreadCountObservable]).subscribe(
      (response) => {
        this.notificationsDataSource = {
          items: this.groupByDate.transform(
            response[0]?.items as Array<Notification>,
          ),
          totalCount: response[0].totalCount,
        };
        this.showLoader = false;
        this.unreadCount = response[1];
        this.buttonDataSource = this.buttonDataSource.map(
          (item: IDisplayType) => {
            if (item.id === 'unread') {
              if (this.unreadCount === 0) {
                item = { ...item, ...{ disabled: true } };
              } else {
                delete item.disabled;
              }
              return item;
            } else {
              return item;
            }
          },
        );
      },
    );
  }

  public onSearch(): void {
    this.raisedAlertId = undefined;
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
      this.onDataBound(this.initialState);
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onDrilldownMatches(segment: ISegment): void {
    const notification = this.notificationsService.findNotificationById(
      this.raisedAlertId,
    );
    this.onDrilldown(segment, notification);
  }

  public onDrilldown(segment?: ISegment, notification?: Notification): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment, notification);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment, notification);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment, notification);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment, notification);
        break;
      case SegmentScope.REPLIES:
        this.drilldownReplies(segment, notification);
      default:
        break;
    }
  }

  private drilldownContext(
    segment: ISegment,
    notification: Notification,
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(
        30,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      segment,
      undefined,
      segment.context,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownReplies(
    segment: ISegment,
    notification: Notification,
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitType === 'Comment'
        ? `${segment.value.emitType} by ${segment.value.emitActor} `
        : `Replies of ${segment.value.emitHeadline}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.id}:ReplyTo`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'file',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownDiscord(
    segment: ISegment,
    notification: Notification,
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownChannel(
    segment: ISegment,
    notification: Notification,
  ): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment, notification: Notification): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: WidgetSettingsTimelineComponent,
          inputs: {
            navigationItem,
            form: new FormGroup({}),
          },
          outputs: {
            close: () => {
              customOverlay.close();
            },
          },
        },
      },
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onDateRangeChange(args: IPreset): void {
    let payload: IPresetQuery;
    if (args.start) {
      payload = this.getPayload(args);
    }
    this.initialState.where = payload;

    this.onSearch();
  }

  private getPayload(dates: IPreset): IPresetQuery {
    return {
      label: DateRangeHelper.getDateQueryText(dates.label),
      start: DateRangeHelper.convertToEpochSec(moment(dates.start).toDate()),
      end:
        dates.label === DateQueryType.Custom
          ? DateRangeHelper.convertToEpochSec(moment(dates.end).toDate())
          : undefined,
    };
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FoamTree } from '@carrotsearch/foamtree';
import { CoreModule, FAwesomeModule } from '@intorqa-ui/core';
import { ChartService } from '@portal/shared/services/chart.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { ICluster, IRiplResults } from './foamtree.interface';
import { FoamtreeService } from './foamtree.service';
import { WidgetViewMode } from '@portal/shared/enums/widget.enum';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'itq-foamtree',
  templateUrl: './foamtree.component.html',
  styleUrls: ['./foamtree.component.scss'],
  standalone: true,
  imports: [CoreModule, MatChipsModule, FAwesomeModule],
})
export class FoamtreeComponent implements OnInit, OnDestroy {
  @Input() dataSource: IRiplResults;
  @Input() id: string;
  @Input() settings: { [key: string]: any };
  @Input() action: WidgetViewMode;
  @Input() cluster: ICluster;
  @Input() allowDrilldown = true;

  @Output() updateRef = new EventEmitter<FoamTree>();
  @Output() drilldown = new EventEmitter();

  private foamtree: FoamTree;
  private preventDefault = false;
  private subscriptions = new Subscription();
  private resizeObserver: ResizeObserver;
  private resizeSubject = new Subject<void>();

  @ViewChild('foamtreeElement', { static: false }) foamtreeElement: ElementRef;

  readonly MIN_NUMBER_ITEMS = 50;

  constructor(
    private readonly foamtreeService: FoamtreeService,
    private readonly chartService: ChartService,
  ) {}

  ngOnInit(): void {
    this.bindReloadChartSubscription();
    this.bindResizeSubscription();
  }

  ngAfterViewInit(): void {
    this.observeResize();
  }

  ngOnDestroy(): void {
    this.foamtree?.dispose();
    this.subscriptions.unsubscribe();
    window.removeEventListener('orientationchange', this.resize);
    window.removeEventListener('resize', this.resize);
  }

  public onRemoveSegment(): void {
    this.cluster = undefined;
    this.chartService.removeSegment$.next();
    this.foamtree.select({ groups: [], keepPrevious: false });
    this.foamtree.reset();
  }

  private bindReloadChartSubscription(): void {
    this.subscriptions.add(
      this.chartService.reloadChart$.subscribe((widgetId: string) => {
        if (widgetId === this.id) {
          this.initialize();
        }
      }),
    );
  }

  private observeResize(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.resizeSubject.next();
    });

    this.resizeObserver.observe(this.foamtreeElement.nativeElement);
  }

  private bindResizeSubscription(): void {
    this.subscriptions.add(
      this.resizeSubject.pipe(debounceTime(50)).subscribe(() => {
        if (this.foamtree) {
          this.foamtree.resize();
        }
      }),
    );
  }

  initialize(): void {
    if (!this.foamtree) {
      let config = {
        element: this.foamtreeElement.nativeElement,
        pixelRatio: window.devicePixelRatio || 1,
        dataObject: {
          groups: this.foamtreeService.convert(this.dataSource?.clusters),
        },
        fadeDuration: 0,
        pullbackDuration: 0,
        rolloutDuration: 0,
        zoomMouseWheelDuration: 0,
        openCloseDuration: 0,
        onGroupClick: (event: any) => {
          if (!this.allowDrilldown) {
            event.preventDefault();
          }
          setTimeout(() => {
            if (!this.preventDefault && event.group) {
              this.drilldown.emit(event.group);
            }
            this.preventDefault = false;
          }, 200);
        },
      };

      if (this.action === WidgetViewMode.EXPANDED) {
        config = {
          ...config,
          ...{
            onGroupDoubleClick: (event: any) => {
              if (!this.allowDrilldown) {
                event.preventDefault();
              }
              this.preventDefault = true;
              if (event && event.group) {
                this.drilldown.emit(event.group);
              }
            },
          },
        };
      }
      this.foamtree = new FoamTree(config);

      this.updateRef.emit(this.foamtree);

      window.addEventListener('orientationchange', this.resize);
      window.addEventListener('resize', () => {
        this.resize(this.foamtree);
      });
    }
    this.setData();

    if (this.cluster) {
      this.foamtree.select(this.cluster.id);
    }
  }

  private setData(): void {
    this.foamtree.set({
      dataObject: {
        groups: this.foamtreeService.convert(this.dataSource?.clusters),
      },
      layout: this.settings?.layout,
      rainbowStartColor:
        this.settings.theme === 'intorqa' ? '#95fa9b' : '#000000',
      rainbowEndColor:
        this.settings.theme === 'intorqa' ? '#05f912' : '#000000',
    });
  }

  resize(foamtree: FoamTree): () => void {
    let timeout: number;
    return () => {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(foamtree.resize, 300);
    };
  }
}

import { IResponsivePanels } from '../interfaces/responsive-panels.interface';

export class ResponsivePanels implements IResponsivePanels {
  constructor(
    public foamtree: boolean,
    public filters: boolean,
    public articleDetail: boolean,
    public navigationHistory: boolean
  ) {}
}

import { Pipe, PipeTransform } from '@angular/core';
import { IDataPoint } from '@portal/shared/interfaces/widget.interface';

@Pipe({
  name: 'sortData',
  standalone: true,
})
export class SortDataPipe implements PipeTransform {
  constructor() {}

  transform(data: Array<IDataPoint>): Array<any> {
    return data?.sort((a: IDataPoint, b: IDataPoint) => {
      return a.count > b.count ? -1 : 1;
    });
  }
}

import { IProfileConnection } from '../interfaces/profile-connection.interface';

export class ProfileConnection implements IProfileConnection {
  constructor(
    public id: string,
    public typeId: string,
    public typeName: string,
    public originProfileId: string,
    public originProfileName: string,
    public originProfileTypeName: string,
    public targetProfileId: string,
    public targetProfileName: string,
    public targetProfileTypeName: string,
    public description: string,
    public updatedDate: string,
    public updatedBy: string
  ) {}

  public isValid(): boolean {
    return this.originProfileId && this.targetProfileId && this.typeId
      ? true
      : false;
  }
}

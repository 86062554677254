<div class="flex flex-col w-full py-4">
  <div class="flex px-4 pb-4 w-full items-center">
    <itq-mat-button
      class="mr-2"
      [type]="'button'"
      [padding]="Sizes.NONE"
      [materialStyle]="'stroked'"
      (mouseDownEvent)="onNavigate()"
    >
      <fa-icon class="font-semibold" [icon]="['far', 'chevron-left']"></fa-icon>
    </itq-mat-button>
    <span class="text-xl font-semibold">LIBRARY</span>
  </div>
  <ul>
    <li
      class="flex justify-center h-[48px] items-center border-b border-t border-border"
    >
      <itq-library-sidebar-item
        class="flex w-full h-full"
        [url]="'/manager/tags'"
        [totalCount]="tagsTotalCount"
        [title]="'Tags'"
        [icon]="'tag'"
        [iconType]="IconType.FONT_AWESOME"
      ></itq-library-sidebar-item>
    </li>
    <li
      class="flex justify-center h-[48px] items-center border-b border-border"
    >
      <itq-library-sidebar-item
        class="flex w-full h-full"
        [url]="'/manager/vendors'"
        [totalCount]="vendorsTotalCount"
        [title]="'Vendors'"
        [icon]="'vendor'"
        [iconType]="IconType.SVG"
      ></itq-library-sidebar-item>
    </li>
    @if (userService.userPreferences.defaultEcosystemId | hasEventsEnabled) {
      <li
        class="flex justify-center h-[48px] items-center border-b border-border"
      >
        <itq-library-sidebar-item
          class="flex w-full h-full"
          [url]="'/manager/events'"
          [totalCount]="eventsTotalCount"
          [title]="'Events'"
          [icon]="'calendar-plus'"
          [iconType]="IconType.FONT_AWESOME"
        ></itq-library-sidebar-item>
      </li>
    }
  </ul>
</div>

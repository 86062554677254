import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Query } from '@portal/shared/models/query-model';
import { cloneDeep } from 'lodash';

import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  EventBusService,
  FAwesomeModule,
  FoamtreeService,
  IconType,
  QueryFilters,
  Sizes,
  SvgComponent,
  TableColumn,
  Utils,
} from '@intorqa-ui/core';
import { TAG_TRENDS_CHART_TYPES } from '@portal/boards/const/time-series.const';
import { EChartTypes } from '@portal/boards/const/widget.const';
import { Board } from '@portal/boards/models/board';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ChartType } from '@portal/shared/enums/chart.enum';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import {
  IDataPoint,
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { QueryRule } from '@portal/shared/models/query-rule';
import { ChartService } from '@portal/shared/services/chart.service';
import { EventsService } from '@portal/shared/services/events.service';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { ChartWizardComponent } from '@portal/widgets/components/chart-wizard/chart-wizard/chart-wizard.component';
import { ECharts } from 'echarts';
import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import { ChartComponent } from '../../../../../shared/components/chart/chart.component';
import { ModalContainerComponent } from '../../../modal-container/modal-container.component';
import { WidgetDisplayTypesComponent } from '../../../widget-display-types/widget-display-types.component';
import { WidgetHeaderComponent } from '../../../widget-header/widget-header.component';
@Component({
  selector: 'itq-widget-tag-trends',
  templateUrl: './widget-tag-trends.component.html',
  styleUrls: ['./widget-tag-trends.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    MatTooltipModule,
    CommonModule,
    ChartComponent,
    WidgetHeaderComponent,
    WidgetDisplayTypesComponent,
  ],
})
export class WidgetTagTrendsComponent implements OnInit {
  @Input() segment: any;
  @Input() set widget(value: Widget) {
    this.tagTrendsWidget = value as TimeSeries;
  }
  get widget(): TimeSeries {
    return this.tagTrendsWidget;
  }
  @Input() action: WidgetActions;
  @Input() board: Board;
  @Input() query: string;

  @ViewChild('chart') chart: ComponentRef<ChartComponent>;
  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;
  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;

  public dataSource: IWidgetData;
  public showLoader = true;
  public chartInstance: ECharts;
  public displayTypes = TAG_TRENDS_CHART_TYPES;
  public selectedDisplayType: IDisplayType;
  private subscriptions = new Subscription();
  public initialState: QueryFilters;
  public tagTrendsWidget: TimeSeries;
  public count: number;

  readonly Sizes = Sizes;
  readonly ChartType = ChartType;
  readonly Align = Align;

  constructor(
    private widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    readonly cdr: ChangeDetectorRef,
    readonly userService: UserService,
    readonly eventBusService: EventBusService,
    readonly boardService: BoardService,
    readonly chartService: ChartService,
    readonly eventsService: EventsService,
    readonly foamtreeService: FoamtreeService,
  ) {}

  ngOnInit(): void {
    this.bindReloadWidgetSubscription();
    this.registerEventBusEvents();
    this.selectedDisplayType = this.tagTrendsWidget.getDisplayType();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterEventBusDeleteWidgetEvent();
    this.unRegisterEventBusUpdateWidgetEvent();
  }

  private registerEventBusEvents(): void {
    this.registerEventBusDeleteWidgetEvent();
    this.registerEventBusUpdateWidgetEvent();
  }

  private bindReloadWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.reload$.subscribe(
        (params: { widget: TimeSeries; initialState: QueryFilters }) => {
          if (
            !params.widget ||
            params.widget.widgetId === this.tagTrendsWidget.widgetId
          ) {
            this.initialState = params.initialState;
            this.getWidgetData();
          }
        },
      ),
    );
  }

  public onEdit(): void {
    this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartWizardComponent,
          inputs: {
            widget: cloneDeep(this.tagTrendsWidget),
            action: WidgetActions.EDIT,
            initialState: this.initialState.cloneDeep(),
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
  }

  public onDeleteWidget(): void {
    this.boardService
      .addWidgetsToBoard(this.board.id, {
        delete: [this.tagTrendsWidget.widgetId],
      })
      .subscribe();
  }

  public onExportWidget(): void {
    if (EChartTypes.includes(this.tagTrendsWidget.chartType)) {
      Utils.exportImage(
        this.chartInstance.getDataURL({
          pixelRatio: 2,
          backgroundColor: 'transparent',
        }),
        this.tagTrendsWidget.name,
      );
    } else {
      html2canvas(this.chartContainer.nativeElement).then((canvas: any) => {
        Utils.exportImage(
          canvas.toDataURL('image/png'),
          this.tagTrendsWidget.name,
        );
      });
    }
  }

  public onChangeDisplay(params: IDisplayType): void {
    this.tagTrendsWidget.options = params.options;
    this.tagTrendsWidget.chartType = params.type;
    this.widgetService.updateWidget(this.tagTrendsWidget).subscribe();
  }

  public onLoadCount(row: IDataPoint): void {
    const queryModel = new Query();
    const widgetCopy = cloneDeep(this.tagTrendsWidget);
    widgetCopy.name = `${widgetCopy.name}: ${row.category}`;
    if (this.initialState.query) {
      const rule = new QueryRule(
        DTOQueryFieldType.content,
        DTOQueryConditionOperator.contains,
        [this.initialState.query],
      );
      queryModel.addRule(rule);
    }
    const rules: Array<QueryRule> = [
      new QueryRule(
        row.tagId.search('Field Filter') > -1
          ? DTOQueryFieldType.filter
          : DTOQueryFieldType.tag,
        DTOQueryConditionOperator.in,
        [row.tagId],
      ),
    ];
    const navigationItem = new NavigationHistoryItem(
      `${this.tagTrendsWidget.widgetId}_${WidgetActions.EXPLORE}`,
      cloneDeep(this.tagTrendsWidget),
      WidgetActions.EXPLORE,
      rules,
      new QueryFilters(
        30,
        1,
        this.initialState.where,
        undefined,
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'search',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
        initialState: this.initialState,
        segment: {
          data: {
            tagId: row.tagId,
            name: row.category,
            value: row.count,
          },
        },
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onExplore(segment?: any): void {
    const widget = cloneDeep(this.tagTrendsWidget);
    const navigationItem = new NavigationHistoryItem(
      `${this.tagTrendsWidget.widgetId}_${WidgetActions.EXPLORE}`,
      widget,
      WidgetActions.EXPLORE,
      undefined,
      this.initialState,
      new FormGroup({}),
      'magnifying-glass',
      IconType.FONT_AWESOME,
      this.selectedDisplayType,
      segment,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
        segment,
      },
      closeBtnStyle: 'basic',
      size: "'lg'",
      type: CustomOverlayType['almost-full'],
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private getWidgetData(): void {
    if (this.tagTrendsWidget.hasMetrics()) {
      this.showLoader = true;
      const queryModel = new Query();
      if (this.initialState?.query) {
        queryModel.addRule(
          new QueryRule(
            DTOQueryFieldType.content,
            DTOQueryConditionOperator.contains,
            [this.initialState?.query],
          ),
        );
      }
      const state = {
        where: this.initialState?.where,
      };

      this.widgetService
        .getData(
          {
            widget: this.tagTrendsWidget,
            filters: queryModel.modelToDTO(),
          },
          state,
        )
        .subscribe((response: IWidgetData) => {
          this.dataSource = response;
          this.cdr.detectChanges();
          this.count = response.totalHits;
          this.chartService.reloadChart$.next(this.tagTrendsWidget.widgetId);
          this.showLoader = false;
        });
    }
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  private registerEventBusDeleteWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.tagTrendsWidget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private unRegisterEventBusDeleteWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.tagTrendsWidget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private deleteWidgetCallback(): (
    err: Error,
    msg: {
      body: { result: boolean };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { result: boolean };
        address: string;
        type: string;
      },
    ) => {
      this.widgetService.delete$.next(this.tagTrendsWidget);
    };
  }

  private registerEventBusUpdateWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.tagTrendsWidget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private unRegisterEventBusUpdateWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.tagTrendsWidget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private updateWidgetCallback(): (
    err: Error,
    msg: {
      body: TimeSeries;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: TimeSeries;
        address: string;
        type: string;
      },
    ) => {
      this.tagTrendsWidget = WidgetFactory.createWidget(msg.body) as TimeSeries;
      this.selectedDisplayType = this.tagTrendsWidget.getDisplayType();
      this.initialState.resetPagination();
      this.getWidgetData();
    };
  }
}

@if (dataSource?.totalHits > 0) {
  <itq-table
    class="overflow-hidden p-4 h-full"
    [tableData]="dataSource?.series[0]?.data | sortData"
    [tableColumns]="tableColumns"
    [trackBy]="trackBy"
    [active]="segment?.data?.name"
  >
  </itq-table>
} @else {
  @if (dataSource) {
    <itq-panel-info
      [label]="'No results found!'"
      [message]="'Please update your query and try again...'"
    ></itq-panel-info>
  }
}

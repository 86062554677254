import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SEARCH_TYPES } from '@portal/shared/const/timeline.const';
import { QueryType } from '@portal/shared/enums/timeline-query.enum';
import { Query } from '@portal/shared/models/query-model';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { TagService } from '@portal/shared/services/tag.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';

@Component({
  selector: 'itq-timeline-wizard-details',
  templateUrl: './timeline-wizard-details.component.html',
  styleUrls: ['./timeline-wizard-details.component.scss'],
})
export class TimelineWizardDetailsComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() form: FormGroup;
  @Input() metadataForm: FormGroup;
  @Input() query: Query;
  @Input() navigationItem: NavigationHistoryItem;

  @Output() changeQueryType = new EventEmitter<void>();

  readonly SearchTypes = SEARCH_TYPES;

  constructor(readonly tagService: TagService) {}

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form.addControl(
      'searchType',
      new FormControl(this.query.type || QueryType.QUICK_SEARCH, [
        Validators.required,
      ]),
    );
    this.form.addControl(
      'name',
      new FormControl(this.widget?.name, [Validators.required]),
    );
    this.form.addControl(
      'description',
      new FormControl(this.widget?.description),
    );
  }

  public onChangeDisplayType(): void {
    this.changeQueryType.emit();
  }
}

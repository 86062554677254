import { Pipe, PipeTransform } from "@angular/core";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";

@Pipe({
  name: "isOptional",
})
export class IsOptionalPipe implements PipeTransform {
  constructor() {}

  transform(form: AbstractControl): boolean {
    if (!form) return true;
    return Object.keys((form as FormGroup).controls).some((key) => {
      const control = (form as FormGroup).controls[key];
      return (
        control.validator &&
        control.validator({} as FormControl) &&
        control.validator({} as FormControl).required
      );
    });
  }
}

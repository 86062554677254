<itq-form-row class="data__container">
  <itq-form-element>
    <span class="font-bold">{{ label }}</span>
    <div class="list__container">
      <itq-input
        [(ngModel)]="query"
        [placeholder]="'Please type to filter...'"
        (search)="onSearch()"
        (clear)="onSearch()"
      ></itq-input>

      <div class="select_all__container">
        <mat-checkbox
          [checked]="selections.hasValue() && isAllSelected()"
          [indeterminate]="selections.hasValue() && !isAllSelected()"
          (change)="$event ? masterToggle($event) : null"
        >
          <ng-container *ngIf="selectAllTooltip">
            ({{ selectAllTooltip }})
          </ng-container>
        </mat-checkbox>
      </div>
      <mat-selection-list
        #selectionsList
        (selectionChange)="onSelectionChange($event)"
        (scroll)="onScroll()"
      >
        <mat-list-option
          *ngFor="let item of dataSource"
          checkboxPosition="before"
          [value]="item"
          [selected]="selections.isSelected(item)"
        >
          {{ item }}
        </mat-list-option>
      </mat-selection-list>
      <itq-panel-info
        *ngIf="dataSource && dataSource.length === 0"
        [label]="'No items found!'"
        [message]="'Please update your query...'"
        [icon]="'exclamation-circle'"
      ></itq-panel-info>
    </div>
  </itq-form-element>
</itq-form-row>

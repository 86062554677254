import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Align, Sizes } from '../../enums/shared.enum';

@Component({
  selector: 'itq-mat-button',
  templateUrl: './mat-button.component.html',
  styleUrls: ['./mat-button.component.scss'],
})
export class MatButtonComponent implements OnInit, AfterViewInit {
  @Input() style: string;
  @Input() size = Sizes.MD;
  @Input() type = 'button';
  @Input() disabled: boolean;
  @Input() rounded = false;
  @Input() align: Align;
  @Input() materialStyle: string;
  @Input() defaultColor: string;
  @Input() padding = Sizes.MD;

  @Output() clickEvent = new EventEmitter<MouseEvent>();
  @Output() mouseDownEvent = new EventEmitter<MouseEvent>();
  @Output() updateRef = new EventEmitter<ElementRef>();
  @Output() blurEvent = new EventEmitter<void>();

  @ViewChild('dropdownButton') dropdownButton: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.updateRef && this.dropdownButton) {
      this.updateRef.emit(this.dropdownButton?._elementRef);
    }
  }

  onClick(event: MouseEvent): void {
    this.clickEvent.emit(event);
  }

  onMouseDown(event: MouseEvent): void {
    this.mouseDownEvent.emit(event);
  }

  onBlur(): void {
    this.blurEvent.emit();
  }
}

import {
  IPresetQuery,
  IQueryColumn,
  IQueryFilter,
  ISort,
} from '../interfaces/query-filters';

export class QueryFilters implements IQueryFilter {
  constructor(
    public pageSize: number,
    public page: number,
    public where: IPresetQuery,
    public query: string,
    public sort: ISort,
    public columnFilters: Array<IQueryColumn>,
  ) {}

  public cloneDeep(): QueryFilters {
    return new QueryFilters(
      this.pageSize,
      this.page,
      this.where,
      this.query,
      this.sort,
      this.columnFilters,
    );
  }

  public addQueryColumn(column: IQueryColumn): void {
    const item = this.findQueryColumnByField(column);
    if (item) {
      this.columnFilters = this.columnFilters?.map((item: IQueryColumn) => {
        if (item.searchField === column.searchField) {
          return column;
        } else {
          return item;
        }
      });
    } else {
      this.columnFilters = [...(this.columnFilters || []), column];
    }
  }

  public removeQueryColumn(searchField: string): void {
    this.columnFilters = this.columnFilters?.filter((item: IQueryColumn) => {
      return item.searchField !== searchField;
    });
    if (this.columnFilters?.length === 0) {
      this.columnFilters = undefined;
    }
  }

  public findQueryColumnByField(column: IQueryColumn) {
    const columns = this.columnFilters;
    return columns?.find(
      (item: IQueryColumn) => item.searchField === column.searchField,
    );
  }

  public resetPagination(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.page = 1;
      resolve();
    });
  }

  public reset(): QueryFilters {
    return new QueryFilters(100, 1, undefined, undefined, undefined, undefined);
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { NotesService } from '@portal/profiles/services/notes.service';

@Pipe({
  name: "getNoteValue",
})
export class GetNoteValuePipe implements PipeTransform {
  constructor(readonly notesService: NotesService) {}

  transform(note: ProfileNote): any {
    return this.notesService.getTypeById(note?.typeId)?.name;
  }
}

import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LibrarySidebarComponent } from '../library-sidebar/library-sidebar.component';

@Component({
  selector: 'itq-library-manager',
  templateUrl: './library-manager.component.html',
  styleUrls: ['./library-manager.component.scss'],
  standalone: true,
  imports: [RouterModule, LibrarySidebarComponent],
})
export class LibraryManagerComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  PillType,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widgets/widget';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

@Component({
  selector: 'itq-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss'],
  standalone: true,
  imports: [
    MatTooltipModule,
    FAwesomeModule,
    CoreModule,
    SvgComponent,
    CommonModule,
    MatIconModule,
  ],
})
export class WidgetHeaderComponent implements OnInit {
  @Input() widget: Widget;
  @Input() displayTypesTemplate: TemplateRef<unknown>;
  @Input() widgetMoreTemplate: TemplateRef<unknown>;
  @Input() selectedDisplayType: IDisplayType;
  @Input() count: number;

  readonly PillType = PillType;
  readonly Sizes = Sizes;
  readonly ProfileDrildownScope = ProfileDrildownScope;

  constructor() {}

  ngOnInit() {}
}

import { Component, HostBinding, Input, OnInit } from "@angular/core";

@Component({
  selector: "itq-container",
  templateUrl: "./container.component.html",
  styleUrls: ["./container.component.scss"],
})
export class ContainerComponent implements OnInit {
  @Input() style: string;
  @Input() size: "x-sm";

  @HostBinding("class") componentCssClass: string;

  constructor() {}

  ngOnInit(): void {
    if (this.style) {
      this.componentCssClass = this.style;
    }
    if (this.size) {
      this.componentCssClass += ` ${this.size}`;
    }
  }
}

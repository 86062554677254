import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
} from '@intorqa-ui/core';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { DocumentItem } from '@portal/shared/interfaces/document.interface';

@Component({
  selector: 'itq-share-email',
  templateUrl: './share-email.component.html',
  styleUrls: ['./share-email.component.scss'],
})
export class ShareEmailComponent implements OnInit {
  public form: FormGroup;
  public showLoader = false;
  public document: DocumentItem;

  @ViewChild('Editor', { static: false })
  editorElementRef: any;

  constructor(
    private customOverlayRef: CustomOverlayRef,
    private snackBar: MatSnackBar,
    private notificationService: NotificationsService,
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
  ) {}

  ngOnInit(): void {
    this.document = this.config.data?.item;
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      content: new FormControl(''),
    });
  }

  public onSubmit(): void {
    this.showLoader = true;
    const message = {
      itemId: this.document.id,
      destination: this.form.controls.email.value,
      message: this.form.controls.content.value,
    };
    this.notificationService.shareEmail(message).then(() => {
      this.snackBar.open('Document has been shared succssefully', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.customOverlayRef.close();
    });
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}

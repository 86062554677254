<ng-container
  *ngIf="
    query?.selections
      | getSelectionsByCategory: TagCategory.content as contentSelections
  "
>
  <itq-form-row
    *ngIf="contentSelections?.length > 0"
    class="content_search__container"
  >
    <div class="content_search__container_data">
      <span class="label mr-2 font-semibold">Content search:</span>
      {{ contentSelections[0]?.tagId }}
    </div>
    <itq-mat-button
      [padding]="Sizes.NONE"
      [materialStyle]="'basic'"
      [matTooltip]="'Remove tag'"
      (clickEvent)="onRemoveSelection(contentSelections[0])"
    >
      <fa-icon [icon]="['far', 'times']" [size]="'lg'"></fa-icon>
    </itq-mat-button>
  </itq-form-row>
</ng-container>
<ng-container
  *ngIf="
    query?.selections
      | filterSelectionsByCategory: TagCategory.content as querySelections
  "
>
  <itq-form-row class="flex-col" *ngIf="querySelections?.length > 0">
    <itq-form-element>
      <span class="mb-1">Selected tags:</span>
      <itq-table
        *ngIf="selectionsTableColumns"
        [tableData]="querySelections"
        [tableColumns]="selectionsTableColumns"
        [emptyMessage]="'No selection found!'"
        [emptyMessageDescription]="'Please change your search term...'"
      >
      </itq-table>
    </itq-form-element>
  </itq-form-row>
</ng-container>
<ng-template #deleteButtonTemplate let-row>
  <itq-mat-button
    [materialStyle]="'basic'"
    [matTooltip]="'Remove tag'"
    (clickEvent)="onRemoveSelection(row)"
  >
    Delete
  </itq-mat-button>
</ng-template>
<ng-template #includedTickTemplate let-row>
  <fa-icon
    *ngIf="row.included"
    [icon]="['far', 'check-circle']"
    [size]="'lg'"
  ></fa-icon>
</ng-template>
<ng-template #excludedTickTemplate let-row>
  <fa-icon
    *ngIf="row.excluded"
    [icon]="['far', 'check-circle']"
    [size]="'lg'"
  ></fa-icon>
</ng-template>

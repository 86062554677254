import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "itq-multiple-dropdown-item",
  templateUrl: "./multiple-dropdown-item.component.html",
  styleUrls: ["./multiple-dropdown-item.component.scss"],
})
export class MultipleDropdownItemComponent implements OnInit {
  @Input() dataFields: { name: string; value: string };
  @Input() item: any;
  @Input() checked: boolean;
  @Input() color: "primary" | "accent";

  @Output() changeValue = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public onChangeValue(): void {
    this.changeValue.emit(this.item);
  }
}

<mat-list-item role="listitem">
  <div [ngClass]="{ noresults: item?.count === 0 }">
    <mat-checkbox
      (click)="$event.stopImmediatePropagation()"
      (change)="onChangeValue()"
      [checked]="checked"
      [color]="'primary'"
    >
      <span [ngClass]="{ noresults: item?.count === 0 }">
        <span> {{ item[dataFields?.name] }} </span
        ><strong *ngIf="item?.count">({{ item.count }})</strong>
      </span>
    </mat-checkbox>
  </div>
</mat-list-item>

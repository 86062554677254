import { ProfileDrildownScope } from "../enums/profile.enum";
import { IProfileDrilldown } from "../interfaces/profile-drilldown.interface";
import { ProfileType } from "./profile-type";

export class ProfileDrilldown implements IProfileDrilldown {
  constructor(
    public scope: ProfileDrildownScope,
    public profileType: ProfileType,
    public value: string
  ) {}
}

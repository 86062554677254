import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { Component, HostListener, Input } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Align } from '../../enums/shared.enum';

@Component({
  selector: 'itq-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
})
export class UserAccountComponent {
  @Input() direction = 'down';
  @Input() size: SizeProp = 'lg';

  public userProfile: KeycloakProfile;
  public open = false;
  public isSuperAdmin: boolean;
  public role: string;

  readonly Align = Align;

  @HostListener('document:click', ['$event'])
  click(): void {
    this.open = false;
  }

  constructor(private keycloakService: KeycloakService) {
    this.isSuperAdmin = this.keycloakService.isUserInRole('super-admin');
    this.keycloakService
      .loadUserProfile()
      .then((userProfile: KeycloakProfile) => {
        this.userProfile = userProfile;
      });
    this.role =
      this.isSuperAdmin || this.keycloakService.isUserInRole('admin')
        ? 'Admin'
        : 'User';
  }

  accountManagement(): void {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  public logOut(): void {
    document.cookie =
      'intorqa-session=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
    setTimeout(() => {
      this.keycloakService.logout(`${window.location.origin}/boards`);
    }, 500);
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPrefix } from "../enums/currencies.enum";
import { CurrencyService } from "../services/currency.service";

@Pipe({
  name: "convertToUSD",
})
export class ConvertToUDSPipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {}

  transform(price: number, currency: CurrencyPrefix): number | undefined {
    return this.currencyService.convertToUSD(price, currency);
  }
}

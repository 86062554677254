import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import {
  CoreModule,
  IAlertsTrendData,
  IDropdownItem,
  TagThresholdAlert,
} from '@intorqa-ui/core';
import {
  Condition_DataSource,
  Delays_DataSource,
  Priority_DataSource,
} from '@portal/notifications/const/alerts.const';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import {
  ChartOrientation,
  LineMode,
  ChartType,
} from '@portal/shared/enums/chart.enum';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { TagService } from '@portal/shared/services/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-tag-threshold-alert',
  templateUrl: './tag-threshold-alert.component.html',
  styleUrls: ['./tag-threshold-alert.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CommonModule,
    MatFormFieldModule,
    SharedModule,
    ChartComponent,
  ],
})
export class TagThresholdAlertComponent implements OnInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() form: FormGroup;
  @Input() widget: Timeline;

  public conditionDataSource: Array<IDropdownItem>;
  public periodDataSource: Array<{ name: string; value: number }>;
  private loadAlertSubscription: Subscription;
  public priorityDataSource: Array<IDropdownItem>;
  public trendDataSource: IAlertsTrendData;
  private trendSubscription: Subscription;
  public options = {
    orientation: ChartOrientation.VERTICAL,
    mode: LineMode.LINE,
    tooltip: {
      show: false,
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      show: true,
    },
    series: {
      showSymbol: false,
      label: {
        show: false,
      },
      lineStyle: {
        width: 2,
      },
    },
  };

  readonly WidgetActions = WidgetActions;
  readonly ChartType = ChartType;

  constructor(
    readonly alertsService: AlertsService,
    readonly tagService: TagService,
    readonly userService: UserService,
  ) {
    this.conditionDataSource = Condition_DataSource;
    this.periodDataSource = Delays_DataSource;
    this.priorityDataSource = Priority_DataSource;
  }

  ngOnInit() {
    this.trendSubscription = this.alertsService.trend$.subscribe(
      (response: IAlertsTrendData) => {
        this.trendDataSource = response;
      },
    );
    this.loadAlertSubscription = this.alertsService.loadAlert$.subscribe(
      (response: TagThresholdAlert) => {
        this.form.get('alerts.dailyAverage').setValue(response.dailyAverage);
        this.form.get('alerts.active').setValue(response.active);
        this.form.get('alerts.priority').setValue(response.priority);
        this.form.get('alerts.condition').setValue(response.condition);
        this.form.get('alerts.count').setValue(response.count);
        this.form.get('alerts.period').setValue(response.period);
        if (response.dailyAverage) {
          this.form.get('alerts.count').disable();
          this.form.get('alerts.period').disable();
        }
      },
    );
    this.createForm();
    const { _extras } = this.widget;
    this.alertsService
      .getTrend({
        ecosystemId: this.userService.userPreferences.defaultEcosystemId,
        query: _extras.modelToDTO(),
      })
      .subscribe();
  }

  ngOnDestroy(): void {
    this.trendSubscription.unsubscribe();
    this.loadAlertSubscription.unsubscribe();
    const alertControls = (this.form?.get('alerts') as FormGroup)?.controls;
    for (const name in alertControls) {
      if (alertControls.hasOwnProperty(name)) {
        (this.form?.get('alerts') as FormGroup).removeControl(name);
      }
    }
  }

  private createForm(): void {
    if (this.navigationItem?.action !== WidgetActions.CREATE) {
      (this.form.get('alerts') as FormGroup).addControl(
        'active',
        new FormControl(true),
      );
    }
    (this.form.get('alerts') as FormGroup).addControl(
      'dailyAverage',
      new FormControl(undefined),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'priority',
      new FormControl(undefined, [Validators.required]),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'condition',
      new FormControl(undefined, [Validators.required]),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'count',
      new FormControl(undefined, [Validators.required]),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'period',
      new FormControl(undefined, [Validators.required]),
    );
  }

  public onChangeDailyAverage(event: MatSlideToggleChange): void {
    if (event.checked) {
      this.form?.get('alerts.count')?.disable();
      this.form?.get('alerts.period')?.disable();
      this.form
        ?.get('alerts.count')
        ?.setValue(this.trendDataSource?.dailyAverage);
      const hour = Delays_DataSource.find(
        (item: { name: string; value: number }) => item.name === 'Day',
      ).value;
      this.form?.get('alerts.period')?.setValue(hour);
    } else {
      this.form?.get('alerts.count')?.enable();
      this.form?.get('alerts.period')?.enable();
      this.form?.get('alerts.period')?.setValue(undefined);
      this.form?.get('alerts.count')?.setValue(undefined);
    }
  }
}

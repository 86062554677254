import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CoreModule,
  DateRangeComponent,
  IPresetQuery,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { TopOptions } from '@portal/boards/const/widget.const';
import { TopActor } from '@portal/boards/models/widgets/top-actor';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ICustomTag } from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { Tag } from '@portal/shared/models/tag';
import { CategoryService } from '@portal/shared/services/category.service';
import { TagService } from '@portal/shared/services/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { ChartWizardTagsListComponent } from '../chart-wizard-tags-list/chart-wizard-tags-list.component';

@Component({
  selector: 'itq-top-actors-metrics',
  templateUrl: './top-actors-metrics.component.html',
  styleUrls: ['./top-actors-metrics.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    DateRangeComponent,
    ChartWizardTagsListComponent,
  ],
})
export class TopActorsMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.topActor = widget as TopActor;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public tagsDataSource: { items: Array<ICustomTag>; totalCount: number };
  public topDataSource = TopOptions;
  public initialState = new QueryFilters(
    50,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public topActor: TopActor;

  constructor(
    public widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly widgetSettingsService: WidgetSettingsService,
    readonly categoryService: CategoryService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.addControls();
    this.onGetTags();
    this.getTagById();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
  }

  public getTagById(): void {
    if (this.topActor?.dataSource?.length > 0) {
      this.tagService
        .getTagById(this.topActor.dataSource[0])
        .subscribe((tag: Tag) => {
          this.form.patchValue({
            dataSource: [
              {
                name: tag.name,
                id: tag.tagId,
              },
            ],
          });
        });
    }
  }

  public onSearch(params: QueryFilters): void {
    this.initialState.query = params.query;
    this.onGetTags();
  }

  public onGetTags(): void {
    this.categoryService
      .getTags(
        this.initialState.query,
        this.initialState,
        new Query().modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        this.initialState.page > 1
          ? this.tagsDataSource?.items[this.tagsDataSource?.items?.length - 1]
              .name
          : undefined,
      )
      .then((response: { items: Array<ICustomTag>; totalCount: number }) => {
        this.tagsDataSource = {
          items:
            this.initialState.page > 1
              ? [...this.tagsDataSource?.items, ...response.items]
              : response.items,
          totalCount: response.totalCount,
        };
        this.widgetSettingsService.loader$.next(false);
      });
  }

  private addControls(): void {
    this.form.addControl(
      'dataSource',
      new FormControl([], [Validators.required]),
    );
    this.form.addControl('top', new FormControl(10, [Validators.required]));
  }
  public onChangeDataSources(tag: ICustomTag): void {
    this.topActor.dataSource = [tag.id];
    this.widgetService.updateWidget$.next({
      widget: this.topActor,
      reloadData: true,
    });
  }

  public onChangeTop(top: number): void {
    this.topActor.top = top;
    this.widgetService.updateWidget$.next({
      widget: this.topActor,
      reloadData: true,
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.dates = params;
    this.initialState.where = this.dates;
    this.widgetSettingsService.loader$.next(true);
    this.onGetTags();
    this.widgetService.updateWidget$.next({
      widget: this.topActor,
      dates: this.dates,
      reloadData: true,
    });
  }
}

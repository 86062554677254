import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DynamicPlaceholderDirective } from "../../../directives/dynamic-placeholder.directive";

@Component({
  selector: "itq-modal-body",
  templateUrl: "./modal-body.component.html",
  styleUrls: ["./modal-body.component.scss"],
})
export class ModalBodyComponent implements OnInit, OnChanges {
  @Input() message: string;
  @Input() component: any;
  @Input() title: string;
  @Input() data: any;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit(): void {
    if (this.component) {
      this.createComponent();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes?.component?.previousValue &&
      changes?.component?.currentValue
    ) {
      setTimeout(() => {
        this.createComponent();
      }, 10);
    }
  }

  private createComponent(): void {
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const dynamicComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(this.component);

    const componentRef = viewContainerRef.createComponent<any>(
      dynamicComponentFactory
    );

    componentRef.instance.data = this.data;
  }
}

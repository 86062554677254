export enum Condition {
  EXACTLY = "Exactly",
  BIGGER = "More than",
  SMALLER = "Less than",
}

export enum Condition_Notification {
  Exactly = "exactly",
  "More than" = "more than",
  "Less than" = "less than",
}

export enum Priority {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export enum PriorityText {
  HIGH = "High",
  LOW = "Low",
  MEDIUM = "Medium",
}

export enum PriorityLabel {
  HIGH = "High",
  LOW = "Low",
  MEDIUM = "Medium",
}

export enum PriorityColor {
  HIGH = "#e57373",
  LOW = "#aed581",
  MEDIUM = "#ffb74d",
}

<div class="flex flex-col" [formGroup]="form">
  <h2 class="flex font-bold pt-7 pb-2 text-xl">Data source</h2>
  <div class="flex mb-4 mt-4 flex-col flex-1">
    <div class="mb-1 text-13 font-medium justify-between items-center flex">
      SECURITY TEAM
      <mat-slide-toggle
        [formControlName]="'selectAllSecurity'"
        (change)="onToggleSecurity($event)"
      >
        <span class="font-medium">SelectAll</span>
      </mat-slide-toggle>
    </div>
    <itq-multiple-dropdown
      [dataSource]="securityTeamDataSource"
      [placeholder]="'Event type...'"
      formControlName="securityTeam"
      [dataFields]="{ name: 'name', value: 'id' }"
      [initialState]="securityTeamInitialState"
      [emptyDataSourceMessage]="'No event types found!'"
      (changeValue)="onChangeSecurityEvents()"
    >
    </itq-multiple-dropdown>
    @if (
      form.controls['securityTeam']?.touched &&
      form.controls['securityTeam']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"
        >Security team events is required
      </mat-error>
    }
  </div>
  <div class="flex flex-col flex-1">
    <div class="mb-1 text-13 font-medium justify-between items-center flex">
      CHEATER TEAM
      <mat-slide-toggle
        [formControlName]="'selectAllCheater'"
        (change)="onToggleCheater($event)"
      >
        <span class="font-medium">SelectAll</span>
      </mat-slide-toggle>
    </div>
    <itq-multiple-dropdown
      [dataSource]="cheaterTeamDataSource"
      formControlName="cheaterTeam"
      [placeholder]="'Event type...'"
      [initialState]="cheaterTeamInitialState"
      [dataFields]="{ name: 'name', value: 'id' }"
      [emptyDataSourceMessage]="'No event types found!'"
      (changeValue)="onChangeSecurityEvents()"
    >
    </itq-multiple-dropdown>
    @if (
      form.controls['cheaterTeam']?.touched &&
      form.controls['cheaterTeam']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"
        >Cheater team events is required
      </mat-error>
    }
  </div>
  <h2 class="flex font-bold pt-7 pb-2 text-xl">Filters</h2>
  <div class="flex mb-4 flex-col flex-1">
    <span class="mb-1 text-13 font-medium">
      <div
        class="mb-1 text-13 font-medium justify-between w-full flex items-center"
      >
        CORRELATE TAG
      </div></span
    >
    <itq-dropdown
      formControlName="dataCorrelation"
      [clearSelection]="false"
      [httpBinding]="true"
      [initialState]="tagsInitialState"
      [clearSelection]="true"
      [dataSource]="tagsDataSource?.items"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Tag...'"
      (changeValue)="onChangeDataCorrelation()"
      (clear)="onClearDataCorrelation()"
      (dataBound)="onDataBoundGetTags($event)"
    ></itq-dropdown>
  </div>
  <div class="flex mb-4 flex-col flex-1">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      [dates]="this.dates"
      [openDirection]="{
        vertical: 'up',
        horizontal: 'right'
      }"
      [openPosition]="'outside'"
      (search)="onChangeDate($event)"
    ></itq-date-range>

    @if (
      form.controls['dataPoints']?.touched &&
      form.controls['dataPoints']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1">Data points is required </mat-error>
    }
  </div>
</div>

<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addConnections'">
    <itq-headline-simple
      *ngIf="showTitle"
      class="mb-7"
      [iconName]="action === WidgetActions.CREATE ? 'plus' : 'pencil'"
      [iconSize]="'2x'"
      [iconType]="IconType.FONT_AWESOME"
      [title]="
        action === WidgetActions.CREATE ? 'Add connection' : 'Edit connection'
      "
    ></itq-headline-simple>
    <div class="add_connection__container">
      <itq-form-row>
        <itq-form-element>
          <span class="label mb-1 required">Connection type:</span>
          <itq-dropdown
            [formControlName]="'type'"
            [dataSource]="typesDataSource | filterDuplicates"
            [dataFields]="{ name: 'name', value: 'id' }"
            (changeValue)="onChangeConnectionType()"
            (clear)="onChangeConnectionType()"
          ></itq-dropdown>
          <mat-error
            *ngIf="form.controls?.type?.touched && form.controls?.type?.invalid"
          >
            Connection type is a required field
          </mat-error>
        </itq-form-element>
      </itq-form-row>

      <itq-form-row>
        <itq-form-element>
          <span
            [ngClass]="{
              label: true,
              'mb-1': true,
              required: form
                ?.get('addConnections.profile')
                ?.hasValidator(Validators.required)
                ? true
                : false
            }"
            >Vendor</span
          >
          <itq-dropdown
            [formControlName]="'profile'"
            [dataSource]="
              profilesDataSource | filterProfiles: connection : action
            "
            [dataFields]="{ name: 'name', value: 'profileId' }"
            [httpBinding]="true"
            (changeValue)="onChangeProfile()"
            (dataBound)="onGetProfiles($event)"
            (clear)="onClearProfiles()"
          ></itq-dropdown>
          <mat-error
            *ngIf="
              form.controls?.profiles?.touched &&
              form.controls?.profiles?.invalid
            "
          >
            Vendor is a required field
          </mat-error>
        </itq-form-element>
      </itq-form-row>
      <itq-form-row>
        <itq-form-element>
          <span class="label mb-1">Description</span>
          <itq-input
            [formControlName]="'description'"
            [placeholder]="'Please add connection description...'"
            (search)="onChangeDescription()"
          ></itq-input>
        </itq-form-element>
      </itq-form-row>
    </div>
    <div class="add_connection__footer">
      <div class="connection__footer_left">
        <itq-mat-button [materialStyle]="'basic'" (clickEvent)="onCancel()">
          Cancel
        </itq-mat-button>
      </div>
      <div class="connection__footer_right">
        <ng-container
          *ngIf="
            action === WidgetActions.EDIT ||
              profilesDataSource?.length > 0 ||
              !connection?.typeId;
            else noProfileTemplate
          "
        >
          <ng-container
            *ngIf="action === WidgetActions.CREATE; else updateTemplate"
          >
            <itq-nav-button
              [materialStyle]="'flat'"
              [openDirection]="{
                vertical: 'up',
                horizontal: 'left'
              }"
              [width]="250"
              [color]="'primary'"
              [padding]="Sizes.MD"
              [disabled]="!this.form?.get('addConnections')?.valid"
              [openPosition]="'inside'"
              [template]="saveTemplate"
            >
              SAVE
            </itq-nav-button>
            <ng-template #saveTemplate>
              <itq-mat-button
                *ngIf="typesDataSource"
                [materialStyle]="'basic'"
                [padding]="Sizes['X-SM']"
                [size]="Sizes.BLOCK"
                [align]="Align.START"
                (clickEvent)="onCreate()"
              >
                <fa-icon [icon]="['far', 'plus']"></fa-icon>
                <span class="text-wrapper">Add another connection</span>
              </itq-mat-button>
              <itq-mat-button
                [materialStyle]="'basic'"
                [padding]="Sizes['X-SM']"
                [size]="Sizes.BLOCK"
                [align]="Align.START"
                (clickEvent)="onAdd()"
              >
                <fa-icon [icon]="['far', 'plus']"></fa-icon>
                <span class="text-wrapper">Add to profile</span>
              </itq-mat-button>
            </ng-template>
          </ng-container>
          <ng-template #updateTemplate>
            <itq-mat-button
              [disabled]="!this.form?.get('addConnections')?.valid"
              [materialStyle]="'flat'"
              [style]="'primary'"
              (clickEvent)="onUpdate()"
            >
              <span class="text-wrapper">Update</span>
            </itq-mat-button>
          </ng-template>
        </ng-container>
        <ng-template #noProfileTemplate>
          <itq-mat-button
            [disabled]="!this.form?.get('addConnections')?.valid"
            [materialStyle]="'flat'"
            [style]="'primary'"
            (clickEvent)="onCreateProfile()"
          >
            <span class="text-wrapper">Create profile</span>
          </itq-mat-button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>

import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayRef,
  FAwesomeModule,
  IPresetQuery,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
} from '@intorqa-ui/core';
import { CreateWidgetTypeComponent } from '@portal/boards/components/create-widget-type/create-widget-type.component';
import { ModalContainerService } from '@portal/boards/components/modal-container/modal-container.service';
import { TAG_COMPARISON_CHART_TYPES } from '@portal/boards/const/tag-comparison.const';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import {
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { ChartService } from '@portal/shared/services/chart.service';
import { TagComparisonMetricsComponent } from '@portal/widgets/components/tag-comparison-metrics/tag-comparison-metrics.component';
import { Subscription } from 'rxjs';
import {
  HasDisplayTypesPipe,
  MapLabelPipe,
  SelectMetricsLabelPipe,
} from '../../pipes/chart-wizard.pipe';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { TableChartComponent } from '@portal/shared/components/table-chart/table-chart.component';

@Component({
  selector: 'itq-chart-wizard-tag-comparison.component',
  templateUrl: './chart-wizard-tag-comparison.component.html',
  styleUrls: ['./chart-wizard-tag-comparison.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTooltipModule,
    MapLabelPipe,
    CommonModule,
    SelectMetricsLabelPipe,
    TagComparisonMetricsComponent,
    ChartComponent,
    TableChartComponent,
  ],
  providers: [HasDisplayTypesPipe],
})
export class ChartWizardTagComparisonComponent implements OnInit {
  @Input() widget: TagComparison;
  @Input() action = WidgetActions.CREATE;
  @Input() initialState: QueryFilters;

  public form: FormGroup;
  public showLoader = false;
  public chartTypesDataSource = TAG_COMPARISON_CHART_TYPES;
  private subscription = new Subscription();
  public count: number;
  public dataSource: IWidgetData;
  public tableColumns: Array<TableColumn>;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly WidgetActions = WidgetActions;
  readonly ChartType = ChartType;

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  constructor(
    readonly widgetService: WidgetService,
    readonly chartService: ChartService,
    readonly cdr: ChangeDetectorRef,
    readonly customOverlayRef: CustomOverlayRef,
    readonly snackBar: MatSnackBar,
    readonly modalContainerService: ModalContainerService,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.bindOnDataBoundSubscription();
    this.onDataBound(this.initialState);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initTableColumns(): void {
    if (this.widget.chartType === ChartType.TABLE) {
      this.tableColumns = this.widget?.getTableColumns(this.countTemplate);
    }
  }

  public onDataBound(params: QueryFilters): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;
      this.initialState = params;
      this.getWidgetData();
    }
  }

  private getWidgetData(): void {
    if (this.widget.hasMetrics()) {
      this.showLoader = true;

      this.widgetService
        .getData(
          {
            widget: this.widget,
            filters: new Query().modelToDTO(),
          },
          {
            where: this.initialState?.where,
          },
        )
        .subscribe((response: IWidgetData) => {
          this.initTableColumns();
          this.dataSource = response;
          this.count = response.totalHits;
          this.cdr.detectChanges();
          this.chartService.reloadChart$.next(
            this.widget.widgetId + '_expanded',
          );
          this.showLoader = false;
        });
    }
  }

  private bindOnDataBoundSubscription(): void {
    this.subscription.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: TagComparison;
          dates?: IPresetQuery;
          reloadData?: boolean;
        }) => {
          if (params.dates) {
            this.initialState.where = params.dates;
          }
          this.widget = params.widget;
          this.cdr.detectChanges();
          if (params.reloadData) {
            this.onDataBound(this.initialState);
          } else {
            this.chartService.reloadChart$.next(
              this.widget.widgetId + '_expanded',
            );
          }
        },
      ),
    );
  }

  private createForm(): void {
    this.form = new FormGroup({
      name: new FormControl(
        {
          value: this.widget?.name,
          disabled: this.widget?.type === AnalysisTypes.TIMELINE,
        },
        [Validators.required],
      ),
      description: new FormControl({
        value: this.widget?.description,
        disabled: this.widget?.type === AnalysisTypes.TIMELINE,
      }),
    });
    this.form.addControl(
      'chartType',
      new FormControl(this.widget.getDisplayType()?.id, [Validators.required]),
    );
  }

  public onChangeChartType(params: IDisplayType): void {
    this.widget.options = { ...this.widget.options, ...params.options };
    this.widget.chartType = params.type;
    this.initTableColumns();
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(this.widget.widgetId + '_expanded');
  }

  public onPrev(): void {
    this.modalContainerService.navigate$.next({
      component: CreateWidgetTypeComponent,
    });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.showLoader = true;
      if (this.action === WidgetActions.EDIT) {
        this.onEdit();
      } else {
        this.onSave();
      }
    }
  }

  private onEdit(): void {
    this.widgetService.updateWidget(this.widget).subscribe(() => {
      this.snackBar.open('Your widget has been updated!', 'Close', {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      });
      this.customOverlayRef.close();
    });
  }

  private onSave(): void {
    this.widgetService
      .createWidget(this.widget)
      .subscribe(
        (response: TimeSeries | TagComparison | TagAnalysis | Timeline) => {
          this.customOverlayRef.close({ refresh: true, widget: response });
        },
      );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }
}

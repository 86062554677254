<itq-form [formGroup]="form">
  <ng-container *ngIf="navigationItem?.action === WidgetActions.DRILLDOWN">
    <itq-timeline-drilldown
      [navigationItem]="navigationItem"
      [articleDetail]="articleDetail"
      [form]="form"
    ></itq-timeline-drilldown>
  </ng-container>
  <ng-container *ngIf="navigationItem?.action === WidgetActions.EXPLORE">
    <itq-timeline-explore
      [navigationItem]="navigationItem"
      [articleDetail]="articleDetail"
      [form]="form"
    ></itq-timeline-explore>
  </ng-container>
  <ng-container
    *ngIf="
      navigationItem?.action === WidgetActions.CREATE ||
      navigationItem?.action === WidgetActions.EDIT ||
      navigationItem?.action === WidgetActions.CLONE
    "
  >
    <itq-timeline-wizard
      [navigationItem]="navigationItem"
      [form]="form"
      [articleDetail]="articleDetail"
    ></itq-timeline-wizard>
  </ng-container>
</itq-form>

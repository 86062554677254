<div
  [ngClass]="[
    'flex',
    'items-center',
    'border',
    'border-solid',
    'border-border',
    'rounded',
    'bg-white',
    'date-range-container',
    'relative',
    'w-full',
    class
  ]"
  (click)="$event.stopPropagation()"
>
  <itq-nav-button
    class="w-full"
    [materialStyle]="'basic'"
    [size]="Sizes.BLOCK"
    [openDirection]="openDirection"
    [openPosition]="openPosition"
    [template]="presetsTemplate"
  >
    <div class="flex items-center justify-between w-full">
      @if (dates?.label !== DateQueryType.Custom) {
        <span class="font-normal">{{ DateQueryLabel[dates?.label] }}</span>
      } @else {
        @if (dates?.start && dates?.end) {
          <span class="font-normal"
            >{{ dates?.start | formatMoment }}
            -
            {{ dates?.end | formatMoment }}
          </span>
        } @else {
          <span class="font-normal text-metadata">start date - end date </span>
        }
      }
      <fa-icon class="ml-2" [size]="'lg'" [icon]="['far', 'calendar']">
      </fa-icon>
    </div>
  </itq-nav-button>
  <ng-template #presetsTemplate>
    <itq-date-range-presets [dates]="dates"></itq-date-range-presets>
  </ng-template>
</div>

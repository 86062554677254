import { EVENT_TIMELINE_CHART_TYPES } from '@portal/boards/const/event-timeline.const';
import { ChartOption, ChartType } from '@portal/shared/enums/chart.enum';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import {
  IDisplayType,
  IEventTimeline,
} from '@portal/shared/interfaces/widget.interface';
import { Widget } from './widget';
import { TimelineOrientation } from '@intorqa-ui/core';

export class EventTimeline extends Widget implements IEventTimeline {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public options: { [key: string]: any },
    public dataSource: Array<string>,
    public chartType: ChartType,
    public ecosystemId: string,
    public updatedDate: number,
    public dataCorrelation: Array<string>,
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public getMinWidth(): number {
    return this.options?.orientation === TimelineOrientation.VERTICAL ? 50 : 35;
  }

  public getMinHeight(): number {
    return this.options?.orientation === TimelineOrientation.HORIZONTAL
      ? 10
      : 5;
  }

  public hasMetrics(): boolean {
    return this.dataSource?.length > 0 ? true : false;
  }

  public getDisplayType(): IDisplayType {
    return EVENT_TIMELINE_CHART_TYPES.find(
      (displayType: IDisplayType) =>
        displayType.type === this.chartType &&
        this.options.orientation === displayType.options.orientation,
    );
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return EVENT_TIMELINE_CHART_TYPES;
  }

  public getOption(option: ChartOption): any {
    let result: any;
    if (this.options) {
      Object.keys(this.options)?.forEach((key: ChartOption) => {
        if (key === option) {
          result = this.options[key];
        }
      });
    }
    return result;
  }

  public cloneDeep(): EventTimeline {
    return new EventTimeline(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.width,
      this.height,
      this.x,
      this.y,
      this.options,
      this.dataSource,
      this.chartType,
      this.ecosystemId,
      this.updatedDate,
      this.dataCorrelation,
    );
  }
}

<ng-container *ngIf="navigationHistory?.items?.length > 1">
  <itq-sidebar
    class="navigation_history__container"
    [isExpanded]="expandedNavigation"
    (toggle)="onToggle()"
  >
    <itq-navigation-history
      class="p-4"
      [selected]="selectedNavigationHistory"
      [dataFields]="{ name: 'name', value: 'name' }"
      [dataSource]="navigationHistory?.items"
      (loadHistory)="onLoadHistoryItem($event)"
      (deleteItem)="onDeleteHistoryItem($event)"
    ></itq-navigation-history>
  </itq-sidebar>
</ng-container>

<ng-container [ngSwitch]="selectedNavigationHistory?.item?.type">
  <ng-container *ngSwitchCase="AnalysisTypes.TIMELINE">
    <itq-widget-settings-timeline
      [navigationItem]="selectedNavigationHistory"
      [navigationHistory]="navigationHistory"
      [articleDetail]="document"
      [form]="form"
    ></itq-widget-settings-timeline>
  </ng-container>
  <ng-container *ngSwitchCase="AnalysisTypes.PROFILE">
    <itq-widget-settings-profiles
      [articleDetail]="document"
      [navigationItem]="selectedNavigationHistory"
      [navigationHistory]="navigationHistory"
      [form]="form"
    ></itq-widget-settings-profiles>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <itq-chart-explore
      class="overflow-hidden"
      [navigationItem]="selectedNavigationHistory"
      [segment]="segment"
      [form]="form"
      [widget]="selectedNavigationHistory.item"
      [initialState]="selectedNavigationHistory?.initialState"
    ></itq-chart-explore>
  </ng-container>
  @if (showLoader) {
    <itq-loader></itq-loader>
  }
</ng-container>

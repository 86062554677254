import { Pipe, PipeTransform } from '@angular/core';
import { ICustomTag } from '@portal/shared/interfaces/tag.interface';

@Pipe({
  name: 'isSelected',
  standalone: true,
})
export class IsSelectedPipe implements PipeTransform {
  transform(item: ICustomTag, selectedItems: Array<ICustomTag>): boolean {
    return selectedItems?.some((selectedItem) => selectedItem.id === item.id);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  FAwesomeModule,
  Sizes,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { TaggingStatus } from '@portal/shared/enums/tag.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

@Component({
  selector: 'itq-timeline-widget-footer',
  templateUrl: './timeline-widget-footer.component.html',
  styleUrls: ['./timeline-widget-footer.component.scss'],
  standalone: true,
  imports: [MatTooltipModule, CoreModule, FAwesomeModule],
})
export class TimelineWidgetFooterComponent implements OnInit {
  @Input() selectedDisplayType: IDisplayType;
  @Input() timelineStatus: TaggingStatus;
  @Input() count: number;

  @Output() explore = new EventEmitter<void>();

  readonly TaggingStatus = TaggingStatus;
  readonly Sizes = Sizes;
  readonly ChartType = ChartType;

  constructor(private virtualScrollService: VirtualScrollService) {}

  ngOnInit() {}

  public onExplore(): void {
    this.explore.emit();
  }

  public scrollToTop(): void {
    this.virtualScrollService.dataBoundObservable.next();
  }
}

import { Condition, Priority } from '@intorqa-ui/core';

const milliSecondsConvertions = 60 * 60;
const oneHour = 1000;
const oneDay = 24;
const oneWeek = 7;

export const Delays_DataSource = [
  {
    name: 'Hour',
    value: oneHour * milliSecondsConvertions,
  },
  {
    name: '4 Hours',
    value: 4 * oneHour * milliSecondsConvertions,
  },
  {
    name: '12 Hours',
    value: 12 * oneHour * milliSecondsConvertions,
  },
  {
    name: 'Day',
    value: oneDay * oneHour * milliSecondsConvertions,
  },
  {
    name: 'Week',
    value: oneWeek * oneDay * oneHour * milliSecondsConvertions,
  },
  {
    name: '2 Weeks',
    value: 2 * oneWeek * oneDay * oneHour * milliSecondsConvertions,
  },
  {
    name: 'Month',
    value: 4 * oneWeek * oneDay * oneHour * milliSecondsConvertions,
  },
];

export const Priority_DataSource = [
  {
    name: 'High',
    value: Priority.HIGH,
  },
  {
    name: 'Medium',
    value: Priority.MEDIUM,
  },
  {
    name: 'Low',
    value: Priority.LOW,
  },
];

export const Condition_DataSource = [
  {
    name: 'Exactly',
    value: Condition.EXACTLY,
  },
  {
    name: 'More than',
    value: Condition.BIGGER,
  },
  {
    name: 'Less than',
    value: Condition.SMALLER,
  },
];

import { Component, Input, OnInit } from '@angular/core';
import { Widget } from '@portal/boards/models/widgets/widget';
import { IBoard } from '@portal/boards/interfaces/board.interface';
import { Tag } from '@portal/shared/models/tag';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

@Component({
  selector: 'itq-delete-tag-dependencies',
  templateUrl: './delete-tag-dependencies.component.html',
  styleUrls: ['./delete-tag-dependencies.component.scss'],
  standalone: true,
  imports: [CommonModule, MatChipsModule],
})
export class DeleteTagDependenciesComponent implements OnInit {
  @Input() data: {
    tags: Array<Tag>;
    boards: Array<IBoard>;
    widgets: Array<Widget>;
  };

  constructor() {}

  ngOnInit(): void {}
}

import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  DateRangeHelper,
  FAwesomeModule,
  Sizes,
} from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { UserService } from '@portal/shared/services/user.service';
import { ChartWizardComponent } from '@portal/widgets/components/chart-wizard/chart-wizard/chart-wizard.component';
import moment from 'moment';
import { BoardHeaderComponent } from '../board-header/board-header.component';
import { BoardToolbarComponent } from '../board-toolbar/board-toolbar.component';
import { ModalContainerComponent } from '../modal-container/modal-container.component';
import { WidgetTypeThumbnailListComponent } from '../widget-type-thumbnail-list/widget-type-thumbnail-list.component';

@Component({
  selector: 'itq-board-settings',
  templateUrl: './board-settings.component.html',
  styleUrls: ['./board-settings.component.scss'],
  standalone: true,
  imports: [
    BoardHeaderComponent,
    CoreModule,
    ReactiveFormsModule,
    FAwesomeModule,
    MatTooltipModule,
    MatError,
    WidgetTypeThumbnailListComponent,
    BoardToolbarComponent,
  ],
})
export class BoardSettingsComponent implements OnInit {
  @Input() board = new Board(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  readonly Sizes = Sizes;

  public form: FormGroup;

  constructor(
    readonly boardService: BoardService,
    readonly customOverlayService: CustomOverlayService,
    readonly userService: UserService,
    readonly widgetService: WidgetService,
    readonly customOverlayRef: CustomOverlayRef,
    private router: Router,
  ) {}

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.form = new FormGroup({
      name: new FormControl(this.board.name, [Validators.required]),
      description: new FormControl(this.board.description),
    });
  }

  public onSubmit(): void {
    this.createBoard();
  }

  private createBoard(widget?: Widget): void {
    this.boardService
      .createBoard(
        {
          name: this.form.get('name').value,
          description: this.form.get('description').value,
          default: this.board.defaultBoard,
          filter: {
            date: {
              label: DateRangeHelper.getDateQueryText(DateQueryType.LastMonth),
              start: DateRangeHelper.convertToEpochSec(
                moment(new Date()).subtract(1, 'month').toDate(),
              ),
            },
          },
          widgetIds: widget?.widgetId ? [widget.widgetId] : [],
        },
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe();
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  public onDeleteBoard(): void {
    this.router.navigateByUrl(`/boards/${this.board.id}`);
  }

  public onToggleDefault(): void {
    this.board.defaultBoard = !this.board.defaultBoard;
  }

  public onAdd(type: AnalysisTypes): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: ChartWizardComponent,
          inputs: {
            widget: WidgetFactory.createWidgetByType(
              type,
              this.userService.userPreferences.defaultEcosystemId,
            ),
            action: WidgetActions.CREATE,
          },
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[1100px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe(
      (response: { refresh: boolean; widget: Widget }) => {
        if (response?.refresh) {
          this.createBoard(response.widget);
        }
      },
    );
  }
}

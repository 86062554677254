import { Injectable } from '@angular/core';
import { IPresetQuery } from '@intorqa-ui/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateRangeService {
  public changeDates$ = new Subject<{
    refresh: boolean;
    preset: IPresetQuery;
  }>();

  constructor() {}
}

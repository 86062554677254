import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  FAwesomeModule,
  IconType,
  Event,
  IEventData,
  QueryFilters,
  SearchFieldType,
  DateRangeHelper,
} from '@intorqa-ui/core';
import { ModalContainerComponent } from '@portal/boards/components/modal-container/modal-container.component';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { ToolbarActions } from '@portal/shared/components/toolbar/toolbar.enum';
import { FilterType } from '@portal/shared/enums/tag.enum';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { Tag } from '@portal/shared/models/tag';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { LibraryService } from '@portal/shared/services/library.service';
import { TagService } from '@portal/shared/services/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import moment from 'moment';
import { LibraryThumbnailComponent } from '../library-thumbnail/library-thumbnail.component';
import { ILibraryItem } from './../library-thumbnail/library-thumbnail.interface';
import { EventsService } from '@portal/shared/services/events.service';
import { ThumbnailTitle } from '../library-thumbnail/library-thumbnail.enum';
import { EventWizardComponent } from '@portal/events/components/event-wizard/event-wizard.component';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { forkJoin, map, Observable, Subscription } from 'rxjs';
import { HasEventsEnabledPipe } from '@portal/shared/pipes/events.pipe';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';

@Component({
  selector: 'itq-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    FAwesomeModule,
    LibraryThumbnailComponent,
    HasEventsEnabledPipe,
  ],
})
export class LibraryComponent implements OnInit {
  public vendorsDataSource: Array<ILibraryItem>;
  public tagsDataSource: Array<ILibraryItem>;
  public eventsDataSource: Array<ILibraryItem>;
  private tagsInitialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'createdDate',
    },
    undefined,
  );
  private vendorsInitialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'createdDate',
    },
    undefined,
  );
  private eventsInitialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'createdDate',
    },
    undefined,
  );
  public vendorsTotalCount: number;
  public tagsTotalCount: number;
  public eventsTotalCount: number;
  private subscriptions = new Subscription();
  public showLoader = true;

  readonly IconType = IconType;
  readonly ThumbnailTitle = ThumbnailTitle;

  constructor(
    readonly tagService: TagService,
    readonly userService: UserService,
    readonly datePipe: DatePipe,
    readonly profileService: ProfileService,
    readonly router: Router,
    readonly customOverlayService: CustomOverlayService,
    readonly libraryService: LibraryService,
    readonly eventsService: EventsService,
    readonly ecosystemService: EcosystemsService,
  ) {}

  ngOnInit() {
    this.onDataBound();
    this.bindChangeEcosystemSubscription();
  }

  private onDataBound(): void {
    this.showLoader = true;
    this.onGetTotalCounts(),
      forkJoin([
        this.onGetTags(),
        this.onGetVendors(),
        this.onGetEvents(),
      ]).subscribe(() => {
        this.showLoader = false;
      });
  }

  private bindChangeEcosystemSubscription(): void {
    this.subscriptions.add(
      this.ecosystemService.changeEcosystem$.subscribe(() => {
        this.onDataBound();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private onGetTotalCounts(): void {
    forkJoin([
      this.tagService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
      this.profileService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
      this.eventsService.getTotalCount(
        this.userService.userPreferences.defaultEcosystemId,
      ),
    ]).subscribe((response: [number, number, number]) => {
      this.tagsTotalCount = response[0];
      this.vendorsTotalCount = response[1];
      this.eventsTotalCount = response[2];
    });
  }

  public onGetTags(query?: string): Observable<void> {
    return this.tagService
      .getTags(
        this.tagsInitialState,
        query,
        this.userService.userPreferences.defaultEcosystemId,
        FilterType.ALL,
      )
      .pipe(
        map(
          (response: {
            items: Array<Tag>;
            page: number;
            pageSize: number;
            totalCount: number;
          }) => {
            //todo we need to bring created date
            this.tagsDataSource = response.items.map((tag: Tag) => ({
              id: tag.tagId,
              title: tag.name,
              description: this.datePipe.transform(
                tag.createdDate,
                'yyyy dd MMM',
              ),
            }));
          },
        ),
      );
  }

  public onGetVendors(query?: string): Observable<void> {
    if (query) {
      this.vendorsInitialState.addQueryColumn({
        searchValues: [query],
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
      });
    } else {
      this.vendorsInitialState.removeQueryColumn('name');
    }
    return this.profileService
      .getProfiles(
        this.vendorsInitialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .pipe(
        map((response: IProfileData) => {
          //todo we need to bring created date
          this.vendorsDataSource = response.items.map((vendor: Profile) => ({
            id: vendor.profileId,
            title: vendor.name,
            description: this.datePipe.transform(
              vendor.createdDate,
              'yyyy dd MMM',
            ),
          }));
        }),
      );
  }

  public onGetEvents(query?: string): Observable<Array<ILibraryItem>> {
    if (query) {
      this.eventsInitialState.addQueryColumn({
        searchValues: [query],
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
      });
    } else {
      this.eventsInitialState.removeQueryColumn('name');
    }
    return this.eventsService
      .getEvents(
        this.eventsInitialState,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .pipe(
        map((response: IEventData) => {
          //todo we need to bring created date
          this.eventsDataSource = response.items.map((item: Event) => ({
            id: item.id,
            title: item.name,
            description: this.datePipe.transform(item.date, 'yyyy dd MMM'),
          }));
          return this.eventsDataSource;
        }),
      );
  }

  public onLoadVendors(): void {
    this.router.navigateByUrl('/manager/vendors');
  }

  public onLoadTags(): void {
    this.router.navigateByUrl('/manager/tags');
  }

  public onLoadEvents(): void {
    this.router.navigateByUrl('/manager/events');
  }

  public onCreateTag(): void {
    const widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      undefined,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.CREATE}_tags`,
      widget,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(
        30,
        1,
        {
          label: DateQueryType['Last month'],
          start: DateRangeHelper.convertToEpochSec(
            moment(new Date()).subtract(1, 'month').toDate(),
          ),
        },
        undefined,
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        navigationItem,
        actions: [
          {
            action: ToolbarActions.RESET_FILTERS,
          },
          {
            action: ToolbarActions.DATE,
            expanded: false,
          },
          {
            action: ToolbarActions.REFRESH,
          },
        ],
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.showLoader = true;
        this.onGetTags().subscribe(() => {
          this.showLoader = false;
        });
      }
    });
  }

  public onCreateEvent(): void {
    const customOverlay = this.customOverlayService.open({
      data: {
        componentConfig: {
          component: EventWizardComponent,
        },
      },
      closeBtnStyle: 'basic',
      closeBtnClass: 'hidden',
      cssClass: 'min-w-[600px]',
      type: CustomOverlayType['almost-full'],
      component: ModalContainerComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.showLoader = true;
        this.onGetEvents().subscribe(() => {
          this.showLoader = false;
        });
      }
    });
  }

  public onCreateVendor(profileTypeId: string): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      undefined,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileTypeId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
    customOverlay.afterClosed.subscribe((response: { refresh: boolean }) => {
      if (response?.refresh) {
        this.showLoader = true;
        this.onGetVendors().subscribe(() => {
          this.showLoader = false;
        });
      }
    });
  }
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  QueryFilters,
  SearchFieldType,
  Sizes,
  SvgComponent,
  TagCategory,
} from '@intorqa-ui/core';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { GetProfileTypeIconPipe } from '@portal/profiles/pipes/vendors.pipe';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { UserService } from '@portal/shared/services/user.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { KeycloakService } from 'keycloak-angular';
import { cloneDeep } from 'lodash';
import { forkJoin, map, Observable } from 'rxjs';

@Component({
  selector: 'itq-timeline-tag-actions',
  templateUrl: './timeline-tag-actions.component.html',
  styleUrls: ['./timeline-tag-actions.component.scss'],
  standalone: true,
  imports: [
    MatTooltipModule,
    CoreModule,
    FAwesomeModule,
    GetProfileTypeIconPipe,
    CommonModule,
    SvgComponent,
  ],
})
export class TimelineTagActionsComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() initialState: QueryFilters;

  @Output() edit = new EventEmitter<void>();
  @Output() explore = new EventEmitter<void>();

  public profileInitialState: QueryFilters;
  public showProfilesList = false;
  public showProfileTypes = false;
  public isLoadingProfiles = false;
  public profileTypesDataSource: Array<ProfileType>;
  public profilesDataSource: Array<Profile>;

  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly IconType = IconType;

  constructor(
    readonly profileService: ProfileService,
    readonly linkTagsService: LinkTagsService,
    readonly customOverlayService: CustomOverlayService,
    readonly userService: UserService,
    public keycloakService: KeycloakService,
    readonly snackBar: MatSnackBar,
    readonly widgetService: WidgetService,
  ) {
    this.profileTypesDataSource = this.profileService.types;
  }

  ngOnInit() {}

  public onExplore(): void {
    this.explore.emit();
  }

  public onEdit(): void {
    this.edit.emit();
  }

  public onClone(): void {
    const clonedWidget = cloneDeep(this.widget);
    clonedWidget.name = `Clone ${this.widget.name}`;
    const navigationItem = new NavigationHistoryItem(
      `${clonedWidget.widgetId}_${WidgetActions.CLONE}`,
      cloneDeep(clonedWidget),
      WidgetActions.CLONE,
      undefined,
      this.initialState,
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
    );
    const customOverlay = this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
      size: 'lg',
    });
    customOverlay.afterClosed.subscribe(
      (response: { refresh: boolean; widget: Timeline }) => {
        if (response.refresh) {
          this.widgetService.addWidget$.next(response.widget);
        }
      },
    );
  }

  public onMouseOverLinkProfile(): void {
    if (!this.isLoadingProfiles) {
      this.isLoadingProfiles = true;
      this.profileInitialState = new QueryFilters(
        30,
        1,
        undefined,
        undefined,
        {
          direction: 'desc',
          active: 'updatedDate',
        },
        undefined,
      );
      forkJoin([
        this.getSelections(),
        this.onGetProfiles(this.profileInitialState),
      ]).subscribe((response: Array<any>) => {
        this.profilesDataSource = this.profilesDataSource.filter(
          (item: Profile) => !response[0].includes(item.profileId),
        );
        this.isLoadingProfiles = false;
      });
    }
  }

  private getSelections(): Observable<Array<string>> {
    return this.linkTagsService.getTargetLinks(this.widget.dataSource[0]);
  }

  public onProfileDataBound(params: QueryFilters): void {
    this.onGetProfiles(params).subscribe();
  }

  public onGetProfiles(params: QueryFilters): Observable<IProfileData> {
    if (params.query) {
      params.columnFilters = [
        {
          searchValues: [params.query],
          searchField: 'name',
          searchFieldType: SearchFieldType.TEXT,
        },
      ];
    }
    return this.profileService
      .getProfiles(params, this.userService.userPreferences.defaultEcosystemId)
      .pipe(
        map((response: IProfileData) => {
          this.profilesDataSource = cloneDeep(response.items);
          return response;
        }),
      );
  }

  public onCreateProfileTag(profileType: ProfileType): void {
    const profileDrilldown = new ProfileDrilldown(
      ProfileDrildownScope.TAG,
      profileType,
      this.widget.dataSource[0],
    );
    this.onCreateProfile(profileDrilldown);
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      this.widget.name,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? this.widget.name
          : profileDrilldown.value,
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? profileDrilldown.value
          : `Field field:${profileDrilldown.value}:${
              profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'md',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onGetProfileTypes(): void {
    this.profileService
      .getProfileTypes(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<ProfileType>) => {
        this.profileTypesDataSource = response;
      });
  }

  public onLinkProfile(profile: Profile): void {
    const link = new LinkTag(
      undefined,
      undefined,
      TagCategory['My Tags'],
      true,
      this.widget.name,
      undefined,
      undefined,
      this.widget.dataSource[0],
    );
    this.linkTagsService.linkTags(profile.profileId, [link]).subscribe(() => {
      this.snackBar.open(
        `${this.widget.name} has been linked to profile ${profile.name}!`,
        'Close',
        {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        },
      );
      this.profilesDataSource = this.profilesDataSource.filter(
        (item: Profile) => {
          return item.profileId !== profile.profileId;
        },
      );
    });
  }
}

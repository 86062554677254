import { INavigationHistory } from "../interfaces/navigation-history.interface";
import { NavigationHistoryItem } from "./navigation-history-item.model";

export class NavigationHistory implements INavigationHistory {
  constructor(public items: Array<NavigationHistoryItem>) {}

  public includes(item: NavigationHistoryItem): boolean {
    const items = this.items.find((elem: NavigationHistoryItem) => {
      return item.id === elem.id;
    });
    return items ? true : false;
  }

  public add(item: NavigationHistoryItem): void {
    this.items = [...this.items, item];
  }

  public clear(): void {
    this.items = [];
  }

  public removeItem(item: NavigationHistoryItem): void {
    this.items = this.items.filter((elem: NavigationHistoryItem) => {
      return elem.id === item.id;
    });
  }

  public find(item: NavigationHistoryItem): NavigationHistoryItem {
    return this.items.find((navigationItem: NavigationHistoryItem) => {
      return navigationItem.id === item.id;
    });
  }
}

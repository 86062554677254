import { Pipe, PipeTransform } from "@angular/core";
import {
  ExtensionDataFields,
  ExtensionDataFieldsText,
} from "./document-item-detail.enum";

@Pipe({
  name: "customSortExtensionFields",
  pure: true,
})
export class CustomSortExtensionFieldsPipe implements PipeTransform {
  constructor() {}

  transform(data: { [key: string]: string }): Array<{ [key: string]: string }> {
    let result: Array<{ [key: string]: string }>;
    const order = [
      ExtensionDataFields.ID,
      ExtensionDataFields.ISBOT,
      ExtensionDataFields.NICKNAME,
      ExtensionDataFields.NAME,
      ExtensionDataFields.DISCRIMINATOR,
      ExtensionDataFields.TOPIC,
      ExtensionDataFields.CATEGORY,
    ];
    const sortedExtensionFields = Object.keys(data).sort(
      (a: ExtensionDataFields, b: ExtensionDataFields) =>
        order.indexOf(a) - order.indexOf(b)
    );

    result = sortedExtensionFields.map((item: ExtensionDataFields) => ({
      [item]: data[item],
    }));
    return result;
  }
}

@Pipe({
  name: "filterEmptyExtensionFields",
  pure: true,
})
export class FilterEmptyExtensionFieldsPipe implements PipeTransform {
  constructor() {}

  transform(data: { [key: string]: string }): Array<{ [key: string]: string }> {
    let result: Array<{ [key: string]: string }>;
    const order = [
      ExtensionDataFields.ID,
      ExtensionDataFields.ISBOT,
      ExtensionDataFields.NICKNAME,
      ExtensionDataFields.NAME,
      ExtensionDataFields.DISCRIMINATOR,
      ExtensionDataFields.TOPIC,
      ExtensionDataFields.CATEGORY,
    ];
    const sortedExtensionFields = Object.keys(data).sort(
      (a: ExtensionDataFields, b: ExtensionDataFields) =>
        order.indexOf(a) - order.indexOf(b)
    );

    result = sortedExtensionFields.map((item: ExtensionDataFields) => ({
      [item]: data[item],
    }));
    return result;
  }
}

@Pipe({
  name: "getExtensionFieldName",
})
export class GetExtensionFieldNamePipe implements PipeTransform {
  constructor() {}

  transform(data: { [key: string]: { [key: string]: string } }): Array<string> {
    return Object.keys(data).map((key: string) => ExtensionDataFieldsText[key]);
  }
}

@Pipe({
  name: "getExtensionFieldValues",
})
export class GetExtensionFieldValuesPipe implements PipeTransform {
  constructor() {}

  transform(data: {
    [key: string]: { [key: string]: string };
  }): Array<Array<any>> {
    const key = Object.keys(data)[0];
    if (key === "userDetails") {
      return Object.entries(data[key]);
    } else if (key === "topic") {
      return [[key, data[key]]];
    } else if (key === "urls") {
      return [[key, [data[key], data[key]].join("<br>")]];
    }
  }
}

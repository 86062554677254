import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Event, EventTeams, IEventData } from '@intorqa-ui/core';
import moment from 'moment';
import { EcosystemsService } from '../services/ecosystems.service';

@Pipe({
  name: 'formatEvents',
  standalone: true,
})
export class FormatEventsPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(data: Array<any>): Array<any> {
    return data.map((item: any) => {
      return {
        ...item,
        ...{
          createdDate: this.datePipe.transform(item.createdDate, 'yyyy dd MMM'),
          date: this.datePipe.transform(item.date, 'yyyy dd MMM'),
        },
        ...{
          team:
            item.team === EventTeams.SECURITY_TEAM
              ? 'Security Team'
              : 'Cheater Team',
        },
      };
    });
  }
}

@Pipe({
  name: 'groupByMonth',
  standalone: true,
})
export class GroupByMonthPipe implements PipeTransform {
  transform(events: Event[]): Array<Array<Event>> {
    const groupedByMonth = events.reduce(
      (acc, event) => {
        const month = moment(event.date).format('MMMM YYYY');
        if (!acc[month]) {
          acc[month] = [];
        }
        acc[month].push(event);
        return acc;
      },
      {} as { [key: string]: Event[] },
    );

    const sortedGroup = Object.keys(groupedByMonth)
      .sort((a, b) =>
        moment(a, 'MMMM YYYY').isBefore(moment(b, 'MMMM YYYY')) ? -1 : 1,
      )
      .map((month) => ({
        month,
        events: groupedByMonth[month],
      }));

    return sortedGroup.map((group) => group.events);
  }
}

@Pipe({
  name: 'groupByTeam',
  standalone: true,
})
export class GroupEventsByTeamPipe implements PipeTransform {
  transform(events: Array<Event>, team: EventTeams): Array<Event> {
    return events.filter((event) => event.team === team);
  }
}

@Pipe({
  name: 'groupByDate',
  standalone: true,
})
export class GroupEventsByDatePipe implements PipeTransform {
  transform(
    events: Array<Event>,
  ): Array<{ date: string; events: Array<Event> }> {
    const groupedEvents = events?.reduce((acc, event) => {
      const eventDate = moment(event.date).format('DD/MM/YYYY');
      if (!acc[eventDate]) {
        acc[eventDate] = [];
      }
      acc[eventDate].push(event);
      return acc;
    }, {});

    return Object.entries(groupedEvents)
      .map(([date, events]) => ({
        date,
        events: events as Array<Event>,
      }))
      .sort((a, b) =>
        moment(a.date, 'DD/MM/YYYY').isBefore(moment(b.date, 'DD/MM/YYYY'))
          ? -1
          : 1,
      );
  }
}

@Pipe({
  name: 'hasEventsEnabled',
  standalone: true,
})
export class HasEventsEnabledPipe implements PipeTransform {
  constructor(private readonly ecosystemsService: EcosystemsService) {}

  transform(ecosysteId: string): boolean {
    const ecosystem = this.ecosystemsService.findEcosystemById(ecosysteId);
    return ecosystem.eventsEnabled;
  }
}

@Pipe({
  name: 'sortDescending',
  standalone: true,
})
export class SortDescendingPipe implements PipeTransform {
  transform(data: IEventData): IEventData {
    if (!data) return data;
    return {
      items: data?.items?.sort((a, b) => (a.date > b.date ? -1 : 1)),
      totalCount: data.totalCount,
    };
  }
}

export enum WidgetActions {
  CREATE = 'create',
  EXPLORE = 'explore',
  EDIT = 'edit',
  DELETE = 'delete',
  EXPORT = 'export',
  DRILLDOWN = 'drilldown',
  CLONE = 'clone',
}

export enum WidgetViewMode {
  EXPANDED = 'EXPANDED',
  NORMAL = 'NORMAL',
}

export enum AnalysisTypes {
  TAG_ANALYSIS = 'TAG_ANALYSIS',
  TIME_SERIES = 'TIME_SERIES',
  TAG_COMPARISON = 'TAG_COMPARISON',
  TIMELINE = 'TIMELINE',
  PROFILE = 'PROFILE',
  TOPIC_CLUSTERS = 'TOPIC_CLUSTERS',
  TOP_ACTORS = 'TOP_ACTORS',
  TOP_CHANNELS = 'TOP_CHANNELS',
  EVENT_TIMELINE = 'EVENT_TIMELINE',
}

export enum AnalysisTypesLabel {
  TAG_ANALYSIS = 'Tag analysis',
  TIME_SERIES = 'Tag trends',
  TAG_COMPARISON = 'Tag comparison',
  TIMELINE = 'Timeline',
  TOPIC_CLUSTERS = 'Topic clusters',
  TOP_ACTORS = 'Top actors',
  TOP_CHANNELS = 'Top channels',
  EVENT_TIMELINE = 'Event timeline',
}

export enum DateInterval {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'alerts'">
    <ng-container
      *ngIf="alertType === AlertTypes.TAG_MATCH; else tagThreesholdTemplate"
    >
      <itq-tag-match-alert
        [form]="form"
        [navigationItem]="navigationItem"
      ></itq-tag-match-alert>
    </ng-container>
    <ng-template #tagThreesholdTemplate>
      <itq-tag-threshold-alert
        [form]="form"
        [navigationItem]="navigationItem"
        [widget]="widget"
      ></itq-tag-threshold-alert>
    </ng-template>
    <itq-form-row class="mb-4">
      <itq-form-element>
        <span class="mb-1">Add contextual message:</span>
        <itq-input
          [formControlName]="'message'"
          [placeholder]="'Please add your tag message...'"
        ></itq-input>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row>
      <itq-form-element [inline]="true">
        <mat-checkbox
          *ngIf="metadataForm?.get('sharedTag')?.value"
          class="mr-4"
          [color]="'primary'"
          [formControlName]="'postSlack'"
        >
          Share to Slack
        </mat-checkbox>
        <!-- <mat-checkbox [color]="'primary'" [formControlName]="'emailMe'">
          Email me
        </mat-checkbox> -->
      </itq-form-element>
    </itq-form-row>
  </ng-container>
</ng-container>

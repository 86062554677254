<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'alerts'">
    <itq-form-row
      class="mb-4"
      *ngIf="navigationItem?.action !== WidgetActions.CREATE"
    >
      <itq-form-element [inline]="true" [size]="'auto'">
        <span class="mr-3">Active:</span>
        <mat-slide-toggle
          [color]="'primary'"
          [formControlName]="'active'"
        ></mat-slide-toggle>
      </itq-form-element>
    </itq-form-row>
    <itq-form-row class="mb-4">
      <itq-form-element [inline]="true" [size]="'auto'" class="mr-4">
        <span class="mr-3 required">Check every:</span>
        <itq-dropdown
          [formControlName]="'delay'"
          [dataSource]="delayDataSource"
          [dataFields]="{ name: 'name', value: 'value' }"
        ></itq-dropdown>
        <mat-error
          *ngIf="
            form?.controls['delay']?.touched &&
            form?.controls['delay']?.hasError('required')
          "
        >
          Delay is <strong>required</strong>
        </mat-error>
      </itq-form-element>
      <itq-form-element [inline]="true">
        <span class="mr-3 required">Priority:</span>
        <div class="flex items-center">
          <itq-dropdown
            [placeholder]="'Please select a priority'"
            [formControlName]="'priority'"
            [dataSource]="priorityDataSource"
            [dataFields]="{ name: 'name', value: 'value' }"
          ></itq-dropdown>
        </div>
        <mat-error
          *ngIf="
            form?.controls['priority']?.touched &&
            form?.controls['priority']?.hasError('required')
          "
        >
          Priority is <strong>required</strong>
        </mat-error>
      </itq-form-element>
    </itq-form-row>
  </ng-container></ng-container
>

import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  CUSTOM_OVERLAY_DATA,
  CustomOverlayConfig,
  CustomOverlayRef,
} from "@intorqa-ui/core";
import { ProfileNote } from "@portal/profiles/models/profile-note";
import { ConnectionsService } from "@portal/profiles/services/connections.service";
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';

@Component({
  selector: "itq-post-notes",
  templateUrl: "./post-notes.component.html",
  styleUrls: ["./post-notes.component.scss"],
})
export class PostNotesComponent implements OnInit {
  @Input() segment: ISegment;
  @Input() note: ProfileNote;
  @Input() navigationItem: NavigationHistoryItem;

  readonly WidgetActions = WidgetActions;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    readonly connectionsService: ConnectionsService,
    readonly customOverlayRef: CustomOverlayRef
  ) {}

  ngOnInit() {}
}

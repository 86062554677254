import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Profile } from '@portal/profiles/models/profile';
import { RepoLinkNote } from '@portal/profiles/models/repo-link';

@Component({
  selector: "itq-view-repo-link",
  templateUrl: "./view-repo-link.component.html",
  styleUrls: ["./view-repo-link.component.scss"],
})
export class ViewRepoLinkComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: RepoLinkNote;

  constructor() {}

  ngOnInit() {}
}

import { Component, Input, OnInit } from '@angular/core';
import { CoreModule, TableColumn } from '@intorqa-ui/core';
import { IWidgetData } from '@portal/shared/interfaces/widget.interface';
import { SortDataPipe } from './table-chart.pipe';

@Component({
  selector: 'itq-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: ['./table-chart.component.scss'],
  standalone: true,
  imports: [CoreModule, SortDataPipe],
})
export class TableChartComponent implements OnInit {
  @Input() dataSource: IWidgetData;
  @Input() segment: any;
  @Input() trackBy: string;
  @Input() tableColumns: Array<TableColumn>;

  constructor() {}

  ngOnInit() {}
}

<itq-mat-button
  [disabled]="disabled"
  [style]="active ? 'primary' : undefined"
  [size]="size"
  [type]="'button'"
  [align]="align"
  [padding]="padding"
  [matTooltip]="active ? activeTooltip : inactiveTooltip"
  [materialStyle]="materialStyle"
  (clickEvent)="onClick()"
>
  <ng-content></ng-content>
  <fa-icon *ngIf="active" [icon]="['far', 'check']" [size]="'sm'"></fa-icon>
</itq-mat-button>

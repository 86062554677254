import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ContactDetailsNote } from "@portal/profiles/models/contact-details-note";
import { Profile } from "@portal/profiles/models/profile";

@Component({
  selector: "itq-view-contact-details",
  templateUrl: "./view-contact-details.component.html",
  styleUrls: ["./view-contact-details.component.scss"],
})
export class ViewContactDetailsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: ContactDetailsNote;

  constructor() {}

  ngOnInit() {}
}

import { Sections } from "../enums/category.enum";
import { ISection } from "../interfaces/section";
import { Category } from "./category";

export class Section implements ISection {
  constructor(
    public id: string,
    public name: Sections,
    public categories: Array<Category>
  ) {}
}

<div class="headline-simple">
  <div class="simple__headline">
    <itq-icon
      [iconType]="iconType"
      [iconSize]="iconSize"
      [iconName]="iconName"
    ></itq-icon>
    <h1 class="title">{{ title }}</h1>
  </div>
  <ng-container class="simple__content">
    <ng-content></ng-content>
  </ng-container>
</div>
@if (description && description !== '') {
  <div class="description__container" [innerHTML]="description"></div>
}

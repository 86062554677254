<div #dropdownButton>
  <itq-mat-button
    class="btn__nav_button"
    [disabled]="disabled"
    [style]="color"
    [size]="size"
    [type]="'button'"
    [align]="align"
    [padding]="padding"
    [materialStyle]="materialStyle"
    (click)="$event.stopImmediatePropagation()"
    (mouseDownEvent)="onMouseDown($event)"
  >
    <ng-content></ng-content>
  </itq-mat-button>
</div>
<div class="dropdown__container" #container>
  <ng-template #portalContent>
    <div
      [ngClass]="{
        navigation__container: true,
        open: isOpen ? true : false,
        down: openDirection.vertical === 'down',
        right: openDirection.horizontal === 'right',
        up: openDirection.vertical === 'up',
        inside: openPosition === 'inside',
        outside: openPosition === 'outside'
      }"
      [ngStyle]="{ width: width ? width + 'px' : 'auto' }"
    >
      <div>
        <ng-container
          *ngTemplateOutlet="template; context: { $implicit: templateData }"
        ></ng-container>
      </div>
    </div>
  </ng-template>
</div>

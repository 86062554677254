export enum ToolbarActions {
  DASHBOARD = 'DASHBOARD',
  TOGGLE_VIEWS = 'TOGGLE_VIEWS',
  REFRESH = 'REFRESH',
  ADD = 'ADD',
  SEARCH = 'SEARCH',
  API = 'API',
  FILTER = 'FILTER',
  DATE = 'DATE',
  RESET_FILTERS = 'RESET_FILTERS',
  EXPORT = 'EXPORT',
}
export enum ViewMode {
  DASHBOARD = 'DASHBOARD',
  RIPL = 'RIPL',
  RELATIONSHIPS = 'RELATIONSHIPS',
  INTEL = 'INTEL',
  TABLE = 'TABLE',
  RELATIONSHIPS_GRAPH = 'RELATIONSHIPS_GRAPH',
}

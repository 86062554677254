<ng-container [formGroup]="form">
  <div
    class="links__toolbar"
    *ngIf="navigationItem.action !== WidgetActions.CREATE"
  >
    <itq-pill [type]="PillType.CUSTOM" [class]="'secondary rounded-md'">{{
      dataSource?.totalCount | number
    }}</itq-pill>
    <div class="content__switch">
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        (clickEvent)="onExit()"
      >
        <fa-icon [icon]="['far', 'eye']"></fa-icon>
        <span class="text-wrapper">View content</span>
      </itq-mat-button>
      <itq-mat-button
        class="ml-x-sm"
        [style]="'primary'"
        [type]="'button'"
        [materialStyle]="'flat'"
        [padding]="Sizes.SM"
      >
        <fa-icon [icon]="['far', 'cog']"></fa-icon>
        <span class="text-wrapper">Settings</span>
      </itq-mat-button>
    </div>
    <div class="toolbar__wrapper">
      <itq-button-toggle
        class="collape__btn"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        [activeTooltip]="'Collapse filters'"
        [inactiveTooltip]="'Expand filters'"
        (clickEvent)="isFilterable = !isFilterable"
      >
        <fa-icon
          class="mr-1"
          [size]="'lg'"
          [icon]="['far', 'filter-list']"
        ></fa-icon>
        <span class="text-wrapper">Filters</span>
      </itq-button-toggle>
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onLinkTag()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="text-wrapper">Add link</span>
      </itq-mat-button>
    </div>
  </div>
  <div class="links__body">
    <itq-virtual-table
      *ngIf="
        navigationItem.action !== WidgetActions.CREATE
          ? true
          : action
            ? false
            : true
      "
      [ngStyle]="{
        flex: navigationItem.action !== WidgetActions.CREATE ? 1 : undefined
      }"
      [showFilters]="isFilterable"
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [footerTemplate]="
        navigationItem.action === WidgetActions.CREATE
          ? footerTemplate
          : undefined
      "
      [dataSource]="dataSource?.items"
      [totalCount]="dataSource?.totalCount"
      [trackBy]="'id'"
      [emptyMessage]="'You haven\'t linked any tags yet!'"
      [emptyMessageDescription]="'To link a tag click the button below.'"
      (dataBound)="onDataBound($event)"
      (sort)="onDataBound(this.initialState)"
    ></itq-virtual-table>

    <ng-template #footerTemplate>
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onLinkTag()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="text-wrapper">Add link</span>
      </itq-mat-button>
    </ng-template>

    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [openDirection]="{ vertical: 'down', horizontal: 'left' }"
        [openPosition]="'inside'"
        [materialStyle]="'basic'"
        [padding]="Sizes.NONE"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [width]="72"
        [template]="actionsTemplate"
      >
        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <itq-mat-button
          [materialStyle]="'basic'"
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          (clickEvent)="onEditLink(row)"
        >
          <fa-icon
            [icon]="['far', 'pencil']"
            class="mr-2"
            [size]="'lg'"
          ></fa-icon>
          Edit
        </itq-mat-button>
        <itq-mat-button
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          [materialStyle]="'basic'"
          [matTooltip]="
            row.isDefault ? 'You can\'t delete default tag' : undefined
          "
          [disabled]="row.isDefault ? true : false"
          (clickEvent)="onUnlinkTag(row)"
        >
          <fa-icon
            [icon]="['far', 'trash-alt']"
            class="mr-2"
            [size]="'lg'"
          ></fa-icon>
          Delete
        </itq-mat-button>
      </ng-template>
    </ng-template>
    <ng-template #dateTemplate let-row>
      <span>
        {{ row.updatedDate | date: 'yyyy MMM dd' }}
      </span>
    </ng-template>
    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>
    <ng-template #defaultButtonTemplate let-row>
      <fa-icon
        *ngIf="row.isDefault"
        [icon]="['far', 'check-circle']"
        [size]="'lg'"
      ></fa-icon>
    </ng-template>

    <itq-link-tags
      *ngIf="action"
      [ngStyle]="{
        width:
          navigationItem.action !== WidgetActions.CREATE ? '380px' : undefined
      }"
      [ngClass]="{ 'ml-4': navigationItem.action === WidgetActions.EXPLORE }"
      [profile]="profile"
      [navigationItem]="navigationItem"
      [action]="action"
      [form]="form"
      [link]="link"
      [showTitle]="showTitle"
      (close)="onCloseAddLink()"
    ></itq-link-tags>
  </div>
</ng-container>

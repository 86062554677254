import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "isSelectionChecked",
})
export class IsSelectionCheckedPipe implements PipeTransform {
  transform(
    item: any,
    selections: Array<any>,
    dataField: { name: string; value: string }
  ): boolean {
    if (!selections || selections?.length === 0) return false;
    const selectionValues = selections.map(
      (item: any) => item[dataField.value]
    );
    return selectionValues.includes(item[dataField.value]) ? true : false;
  }
}

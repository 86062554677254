import { Component, Input, OnInit } from "@angular/core";
import { ErrorMessages } from "./error.enum";
import { IError } from "./error.interface";

@Component({
  selector: "itq-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {
  @Input() error: IError = {
    error: ErrorMessages.PAGE_NOT_FOUND,
    code: 404,
    title: ErrorMessages.NOT_FOUND_TITLE,
    description: ErrorMessages.NOT_FOUND_DESCRIPTION,
    stopPropagation: true,
  };

  constructor() {}

  ngOnInit(): void {}
}

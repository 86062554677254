<form [formGroup]="form" class="flex flex-col px-7 py-4 bg-background w-full">
  <div class="flex w-full items-center justify-between flex-col">
    <div
      class="flex items-center bg-white rounded-md border border-border w-full p-7"
    >
      <div class="flex items-center flex-1">
        <fa-icon class="pr-2" [icon]="['far', 'tag']" [size]="'3x'"></fa-icon>
        <span class="font-bold text-28">Tags</span>
        @if (dataSource?.totalCount) {
          <itq-pill
            class="pl-4"
            [class]="
              'rounded-2xl px-4 py-2  shadow-none font-semibold bg-primary-over'
            "
            [type]="PillType.CUSTOM"
            >{{ dataSource.totalCount | number }}</itq-pill
          >
        }
      </div>
      <div class="flex flex-1">
        This area contains all the tags that have been created for this
        ecosystem. <br />
        Here you can search for the tag you need, create new tags or delete tags
        that are no longer required.
      </div>
    </div>

    <div class="flex justify-between my-7 w-full items-center">
      <div class="flex items-center justify-between">
        <itq-input
          [width]="300"
          [type]="'text'"
          [icon]="'search'"
          formControlName="searchTerm"
          [placeholder]="'Search tags...'"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>
        <itq-dropdown
          class="ml-4 pl-4 border-l border-border w-[200px] filter__type__dropwdown"
          [icon]="'filter-list'"
          [matTooltip]="'Filter by tag type'"
          formControlName="type"
          [showHeader]="false"
          [clearSelection]="false"
          [dataSource]="tagManagerFilters"
          [dataFields]="{ name: 'value', value: 'id' }"
          (changeValue)="onSearch()"
        ></itq-dropdown>
      </div>
      <itq-mat-button
        [materialStyle]="'flat'"
        [style]="'primary'"
        [type]="'button'"
        (clickEvent)="onCreate()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        CREATE TAG
      </itq-mat-button>
    </div>
  </div>
  @if (dataSource) {
    <div class="flex overflow-hidden flex-1">
      <itq-virtual-table
        [tableColumns]="tableColumns"
        [initialState]="initialState"
        [dataSource]="dataSource?.items | formatDates"
        [totalCount]="dataSource?.totalCount"
        [trackBy]="'id'"
        [emptyMessage]="'No tags found!'"
        [emptyMessageDescription]="'Please update search term.'"
        (dataBound)="onDataBound()"
        (sort)="onDataBound()"
      ></itq-virtual-table>
    </div>
  }
</form>

<ng-template #sharedTickTemplate let-row>
  @if (row.sharedTag) {
    <fa-icon [icon]="['far', 'check-circle']" [size]="'lg'"></fa-icon>
  }
</ng-template>
<ng-template #alertsTemplate let-row>
  @if (row.alertTypeId) {
    <fa-icon [icon]="['far', 'bell']" [size]="'lg'"></fa-icon>
  }
</ng-template>
<ng-template #intorqaTickTemplate let-row>
  @if (row.categoryId) {
    <fa-icon [icon]="['far', 'check-circle']" [size]="'lg'"></fa-icon>
  }
</ng-template>
<ng-template #editTemplate let-row let-index="index">
  <div class="flex justify-center">
    <itq-mat-button
      [padding]="Sizes['X-SM']"
      [materialStyle]="'stroked'"
      [matTooltip]="
        (row | isTagEnabled)
          ? 'Edit tag'
          : 'You don\'t have permissions to edit this tag'
      "
      [matTooltipPosition]="'below'"
      [disabled]="!(row | isTagEnabled)"
      (clickEvent)="onEdit(row)"
    >
      <fa-icon [icon]="['far', 'pencil']" [size]="'lg'"></fa-icon>
    </itq-mat-button>
  </div>
</ng-template>

<ng-template #actionsButtonTemplate let-row let-index="index">
  <div class="flex justify-center">
    <itq-nav-button
      [openDirection]="{ vertical: 'down', horizontal: 'left' }"
      [openPosition]="'inside'"
      [materialStyle]="'stroked'"
      [width]="200"
      [template]="actionsTemplate"
      [matTooltip]="'More actions'"
      [matTooltipPosition]="'below'"
    >
      <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
    </itq-nav-button>
  </div>
  <ng-template #actionsTemplate>
    <ng-container *ngIf="row | isTagEnabled as isTagEnabled">
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [padding]="Sizes.NONE"
        [materialStyle]="'basic'"
        [matTooltip]="
          isTagEnabled
            ? 'Clone tag'
            : 'You don\'t have permissions to clone this tag'
        "
        [disabled]="!isTagEnabled"
        [matTooltipPosition]="'below'"
        (clickEvent)="onClone(row)"
      >
        <fa-icon class="pr-2" [icon]="['far', 'clone']" [size]="'lg'"></fa-icon>
        Clone tag
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [padding]="Sizes.NONE"
        [matTooltipPosition]="'below'"
        [disabled]="!isTagEnabled"
        [matTooltip]="
          isTagEnabled
            ? 'Delete tag'
            : 'You don\'t have permissions to delete this tag'
        "
        (clickEvent)="onDelete(row)"
      >
        <fa-icon
          class="pr-2"
          [icon]="['far', 'trash-alt']"
          [size]="'lg'"
        ></fa-icon>
        Delete tag
      </itq-mat-button>
    </ng-container>
    @if (
      this.keycloakService.isUserInRole('super-admin') ||
      this.keycloakService.isUserInRole('admin')
    ) {
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [padding]="Sizes.NONE"
        [materialStyle]="'basic'"
        [matTooltip]="'Tag hierarchy'"
        [matTooltipPosition]="'below'"
        (clickEvent)="onViewHierarchy(row)"
      >
        <fa-icon
          class="pr-2"
          [icon]="['far', 'list-tree']"
          [size]="'lg'"
        ></fa-icon
        >View dependencies
      </itq-mat-button>
    }
  </ng-template>
</ng-template>

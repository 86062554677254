import { Type } from "@angular/core";
import { TypeSocialChannelsComponent } from "./type-social-channels/type-social-channels.component";
import { TypeTextNoteComponent } from "./type-text-note/type-text-note.component";
import { TypePaymentDetailsComponent } from "./type-payment-details/type-payment-details.component";
import { TypeGamerTagComponent } from "./type-gamer-tag/type-gamer-tag.component";
import { TypeRepoLinkComponent } from "./type-repo-link/type-repo-link.component";
import { TypeContactDetailsComponent } from "./type-contact-details/type-contact-details.component";
import { TypeDomainDetailsComponent } from "./type-domain-details/type-domain-details.component";
import { TypeCodeSnippetComponent } from "./type-code-snippet/type-code-snippet.component";
import { TypeFileHashComponent } from "./type-file-hash/type-file-hash.component";
import { TypeScreenshotComponent } from "./type-screenshot/type-screenshot.component";
import { TypeVideoLinkComponent } from "./type-video-link/type-video-link.component";
import { TypeBioLinkComponent } from "./type-bio-link/type-bio-link.component";
import { TypeAliasComponent } from "./type-alias/type-alias.component";
import { TypeForumProfileComponent } from "./type-forum-profile/type-forum-profile.component";
import { TypeStoreFrontComponent } from "./type-store-front/type-store-front.component";
import { NoteTypes } from "@portal/profiles/enums/profile.enum";
import { NoteType } from "@portal/profiles/models/note-type";

export class AddNotesFactory {
  static getComponent(
    item: NoteType
  ): Type<
    | TypeSocialChannelsComponent
    | TypeTextNoteComponent
    | TypePaymentDetailsComponent
    | TypeGamerTagComponent
    | TypeRepoLinkComponent
    | TypeContactDetailsComponent
    | TypeDomainDetailsComponent
    | TypeCodeSnippetComponent
    | TypeFileHashComponent
    | TypeScreenshotComponent
    | TypeVideoLinkComponent
    | TypeBioLinkComponent
    | TypeAliasComponent
    | TypeForumProfileComponent
    | TypeStoreFrontComponent
  > {
    if (item.name === NoteTypes.SocialChannels) {
      return TypeSocialChannelsComponent;
    } else if (item.name === NoteTypes.PaymentDetails) {
      return TypePaymentDetailsComponent;
    } else if (item.name === NoteTypes.Gamertag) {
      return TypeGamerTagComponent;
    } else if (item.name === NoteTypes.CodeRepoLink) {
      return TypeRepoLinkComponent;
    } else if (item.name === NoteTypes.ContactDetails) {
      return TypeContactDetailsComponent;
    } else if (item.name === NoteTypes.DomainDetails) {
      return TypeDomainDetailsComponent;
    } else if (item.name === NoteTypes.CodeSnippet) {
      return TypeCodeSnippetComponent;
    } else if (item.name === NoteTypes.FileHash) {
      return TypeFileHashComponent;
    } else if (item.name === NoteTypes.Screenshot) {
      return TypeScreenshotComponent;
    } else if (item.name === NoteTypes.VideoLink) {
      return TypeVideoLinkComponent;
    } else if (item.name === NoteTypes.BioLink) {
      return TypeBioLinkComponent;
    } else if (item.name === NoteTypes.Alias) {
      return TypeAliasComponent;
    } else if (item.name === NoteTypes.ProfileLink) {
      return TypeForumProfileComponent;
    } else if (item.name === NoteTypes.Storefront) {
      return TypeStoreFrontComponent;
    } else {
      return TypeTextNoteComponent;
    }
  }
}

<ng-container *ngIf="!showLoader">
  <itq-headline-simple
    [title]="'Setup endpoint'"
    [iconSize]="'2x'"
    [iconName]="'share-alt'"
    [description]="'Connect our data to your systems for additional insight'"
  ></itq-headline-simple>
  <div class="settings__body">
    <section>
      <h2 *ngIf="apiKey">Account Credentials</h2>
      <p *ngIf="apiKeyPrefix && !apiKey">
        You have a key already with prefix {{ apiKeyPrefix }} - Creating a new
        one will delete the old one
      </p>

      <itq-mat-button
        *ngIf="!apiKey"
        [type]="'button'"
        [materialStyle]="'flat'"
        [size]="Sizes.BLOCK"
        [style]="'primary'"
        [disabled]="apiKey || showConfirmationPanel ? true : false"
        (click)="$event.stopImmediatePropagation()"
        (mousedown)="onGenerateKey()"
      >
        Generate Credentials
      </itq-mat-button>

      <itq-form-row [class]="'fill copy__container'" *ngIf="apiKey">
        <div class="copy__wrapper">
          <div class="copy__values">
            <p><strong>Api Key: </strong>{{ apiKey }}</p>
            <p><strong>Api Prefix: </strong>{{ apiKeyPrefix }}</p>
          </div>
          <itq-mat-button
            [type]="'button'"
            [materialStyle]="'flat'"
            [style]="'primary'"
            [cdkCopyToClipboard]="apiKey"
            (click)="$event.stopImmediatePropagation()"
            (mousedown)="copy('key')"
          >
            <fa-icon [icon]="['far', 'copy']"></fa-icon>
          </itq-mat-button>
        </div>

        <span class="color-danger"
          >Please save this key securely as it cannot be recovered by
          Intorqa</span
        >
      </itq-form-row>
      <itq-form-row
        [class]="'fill confirmation__container'"
        *ngIf="showConfirmationPanel"
      >
        <span>This will delete the old key. Are you sure?</span>
        <div class="confirmation__actions">
          <itq-mat-button
            [type]="'button'"
            [materialStyle]="'flat'"
            [cdkCopyToClipboard]="apiKey"
            (click)="$event.stopImmediatePropagation()"
            (mousedown)="showConfirmationPanel = false"
          >
            NO
          </itq-mat-button>
          <itq-mat-button
            [type]="'button'"
            [materialStyle]="'flat'"
            [style]="'primary'"
            [cdkCopyToClipboard]="apiKey"
            (click)="$event.stopImmediatePropagation()"
            (mousedown)="apiKeyPrefix = undefined; onGenerateKey()"
          >
            YES
          </itq-mat-button>
        </div>
      </itq-form-row>
    </section>
    <section class="settings__container" *ngIf="apiKey || apiKeyPrefix">
      <h2 class="font-semibold">Settings</h2>
      <itq-form-row [class]="'fill settings__wrapper'">
        <p class="settings__copy_container">
          <span [innerHTML]="baseUrl"></span>
          <button
            type="button"
            class="btn btn-icon btn-primary"
            (click)="copy('url')"
            [cdkCopyToClipboard]="
              externalApiBase +
              '/key/' +
              (this.apiKey ? this.apiKey : '%yourKey%')
            "
          >
            <fa-icon [icon]="['far', 'copy']"></fa-icon>
          </button>
        </p>
        <p><strong>Http Method: </strong> GET</p>
        <p class="settings__copy_container curl">
          <span>
            <strong>Curl Command: </strong>
            <span class="curl__command" [innerHTML]="curlCommand"></span>
          </span>
          <button
            type="button"
            class="btn btn-icon btn-primary"
            (click)="copy('curl')"
            [cdkCopyToClipboard]="curlCommand"
          >
            <fa-icon [icon]="['far', 'copy']"></fa-icon>
          </button>
        </p>
        <p><strong>Query String Params: </strong></p>
        <p>page: number | default = 1</p>
        <p>pageSize: number | min = 1 | max = 100 | default = 100</p>
        <p>fromEpochSeconds: date | default = 0 (01-01-1970)</p>
        <p>toEpochSeconds: date | default = (now)</p>
      </itq-form-row>
    </section>
  </div>
</ng-container>

<itq-loader *ngIf="showLoader"></itq-loader>

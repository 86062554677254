import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreModule, IconType, ThumbnailComponent } from '@intorqa-ui/core';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import { HasEventsEnabledPipe } from '@portal/shared/pipes/events.pipe';
import { UserService } from '@portal/shared/services/user.service';

@Component({
  selector: 'itq-widget-type-thumbnail-list',
  templateUrl: './widget-type-thumbnail-list.component.html',
  styleUrls: ['./widget-type-thumbnail-list.component.scss'],
  standalone: true,
  imports: [CoreModule, ThumbnailComponent, HasEventsEnabledPipe],
})
export class WidgetTypeThumbnailListComponent implements OnInit {
  @Output() add = new EventEmitter<AnalysisTypes>();

  @Input() disabled: boolean;

  readonly AnalysisTypes = AnalysisTypes;
  readonly IconType = IconType;

  constructor(public readonly userService: UserService) {}

  ngOnInit() {}

  public onAdd(type: AnalysisTypes): void {
    this.add.emit(type);
  }
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  FAwesomeModule,
  IconType,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetService } from '@portal/boards/services/widget.service';
import { IAddConnection } from '@portal/profiles/interfaces/profile-connection.interface';
import { IAddNote } from '@portal/profiles/interfaces/profile-note.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { ProfileNote } from '@portal/profiles/models/profile-note';
import { AddConnectionsComponent } from '@portal/shared/components/add-connections/add-connections.component';
import { PostNotesWizardComponent } from '@portal/shared/components/post-notes-wizard/post-notes-wizard.component';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { IData } from '@portal/shared/interfaces/document.interface';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { SharedModule } from '@portal/shared/shared.module';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { IsDiscordNavigationPipe } from '../search-results/search-results.pipe';
import { TaggingStatus } from '@portal/shared/enums/tag.enum';
import { DocumentItemTileV2Component } from '../document-item-tile-v2/document-item-tile-v2.component';

@Component({
  selector: 'itq-timeline-feed-v2',
  templateUrl: './timeline-feed-v2.component.html',
  styleUrls: ['./timeline-feed-v2.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    IsDiscordNavigationPipe,
    DocumentItemTileV2Component,
  ],
})
export class TimelineFeedV2Component implements OnInit, AfterViewInit {
  @Input() widget: Widget;
  @Input() initialState: QueryFilters;
  @Input() segment: ISegment;
  @Input() data: IData;
  @Input() query: string;
  @Input() timelineStatus: TaggingStatus;
  @Input() count: number;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() allowDrilldown = true;
  @Input() showFooter = false;

  @Output() expandTimeline = new EventEmitter<Timeline>();
  @Output() scroll = new EventEmitter<QueryFilters>();
  @Output() drilldown = new EventEmitter<ISegment>();
  @Output() createProfile = new EventEmitter<ProfileDrilldown>();

  public tableColumns: TableColumn[] = [];

  readonly Sizes = Sizes;
  readonly TaggingStatus = TaggingStatus;
  readonly PillType = PillType;

  public form: FormGroup;

  @ViewChild('template')
  template: TemplateRef<unknown>;

  constructor(
    readonly customOverlayService: CustomOverlayService,
    private virtualScrollService: VirtualScrollService,
    readonly widgetService: WidgetService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initColumns();
  }

  initColumns(): void {
    this.tableColumns = [
      {
        name: undefined,
        dataKey: 'result',
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
  }

  public onDataBound(params: QueryFilters): void {
    this.scroll.emit(params);
  }

  public onDrilldown(segment: ISegment): void {
    this.drilldown.emit(segment);
  }

  public onCreateProfile(segment: ProfileDrilldown): void {
    this.createProfile.emit(segment);
  }

  public onAddConnection(params: IAddConnection): void {
    this.customOverlayService.open({
      data: params,
      type: CustomOverlayType['slide-right'],
      component: AddConnectionsComponent,
      disposeOnNavigation: true,
    });
  }

  public onExplore(): void {
    this.widgetService.explore$.next(this.widget);
  }

  public onAddNote(params: IAddNote): void {
    let customOverlay: CustomOverlayRef;
    customOverlay = this.customOverlayService.open({
      data: params,
      size: 'lg',
      type: CustomOverlayType['slide-right'],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });

    customOverlay.afterClosed.subscribe(
      (response: { refresh: boolean; profile: Profile; note: ProfileNote }) => {
        if (response?.profile && response.note) {
          const navigationItem = new ProfilesNavigationItem(
            `${WidgetActions.CREATE}_addnote_profiles`,
            response.profile,
            WidgetActions.CREATE,
            undefined,
            undefined,
            this.form,
            'plus',
            IconType.FONT_AWESOME,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            [response.note],
            0,
          );
          customOverlay = this.customOverlayService.open({
            data: {
              navigationItem,
            },
            closeBtnStyle: 'basic',
            type: CustomOverlayType['almost-full'],
            size: 'md',
            component: WidgetSettingsComponent,
            disposeOnNavigation: true,
          });
        }
      },
    );
  }

  public scrollToTop(): void {
    this.virtualScrollService.dataBoundObservable.next();
  }
}

import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CoreModule,
  DateQueryType,
  DynamicPlaceholderDirective,
  QueryFilters,
} from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { WidgetSettingsFactory } from '@portal/widget-settings/widget-settings.factory';
import moment from 'moment';

@Component({
  selector: 'itq-chart-wizard',
  templateUrl: './chart-wizard.component.html',
  styleUrls: ['./chart-wizard.component.scss'],
  standalone: true,
  imports: [CoreModule],
})
export class ChartWizardComponent implements OnInit {
  @Input() widget: Widget;
  @Input() action = WidgetActions.CREATE;
  @Input() initialState = new QueryFilters(
    100,
    1,
    {
      label: DateQueryType.LastMonth,
      start: moment().subtract(1, 'month').valueOf(),
      end: moment().valueOf(),
    },
    undefined,
    undefined,
    undefined,
  );

  private componentRef: ComponentRef<any>;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.createComponent();
  }

  private createComponent(): void {
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const dynamicComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        WidgetSettingsFactory.getWizardComponent(this.widget.type),
      );

    this.componentRef = viewContainerRef.createComponent(
      dynamicComponentFactory,
    );
    this.componentRef.instance.widget = this.widget;
    this.componentRef.instance.action = this.action;
    this.componentRef.instance.initialState = this.initialState;
  }
}

import { CastIconNamePipe } from './../../../../../../core/src/lib/components/icon/icon.pipe';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  AlertTypesIcons,
  AlertTypesLabel,
  Condition_Notification,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  FAwesomeModule,
  IconType,
  MillisToDate,
  PriorityColor,
  PriorityLabel,
  QueryFilters,
  Sizes,
} from '@intorqa-ui/core';
import { GetPeriodLabelPipe } from './notifications-tag-threshold.pipe';
import { Subscription } from 'rxjs';
import { ThresholdNotification } from '@portal/notifications/models/notifications';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { SharedModule } from '@portal/shared/shared.module';
import { WidgetActions,  } from '@portal/shared/enums/widget.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { QueryRule } from '@portal/shared/models/query-rule';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { TagService } from '@portal/shared/services/tag.service';
import { TIMELINE_CHART_TYPES } from '@portal/boards/const/timeline.const';
import { Tag } from '@portal/shared/models/tag';
import { WidgetService } from '@portal/boards/services/widget.service';
import { UserService } from '@portal/shared/services/user.service';
import { ChartType } from '@portal/shared/enums/chart.enum';

@Component({
  selector: 'itq-notifications-tag-threshold',
  templateUrl: './notifications-tag-threshold.component.html',
  styleUrls: ['./notifications-tag-threshold.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CommonModule,
    CoreModule,
    MatTooltipModule,
    SharedModule,
    MillisToDate,
    GetPeriodLabelPipe,
    CastIconNamePipe,
  ],
})
export class NotificationsTagThresholdComponent implements OnInit {
  @Input() notification: ThresholdNotification;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;

  readonly AlertTypesIcons = AlertTypesIcons;
  readonly Sizes = Sizes;
  readonly PriorityLabel = PriorityLabel;
  readonly PriorityColor = PriorityColor;
  readonly Condition_Notification = Condition_Notification;
  readonly AlertTypesLabel = AlertTypesLabel;

  private subscriptions = new Subscription();

  @HostBinding('class') class: string;

  constructor(
    readonly notificationService: NotificationsService,
    readonly customOverlayService: CustomOverlayService,
    readonly tagService: TagService,
    readonly widgetService: WidgetService,
    readonly userService: UserService,
  ) {}

  ngOnInit() {
    this.bindUnredSubscritpion();
    this.class = !this.notification.read ? 'unread' : '';
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  private bindUnredSubscritpion(): void {
    this.subscriptions.add(
      this.notificationService.markAsRead$.subscribe(() => {
        this.class = '';
      }),
    );
  }

  public onViewOtherMatches(): void {
    this.notificationService.loadOtherMatches$.next({
      raisedAlertId: this.notification.raisedAlertId,
      filters: undefined,
    });
  }

  public onToggleRead(): void {
    this.notificationService
      .toggleReadState(this.notification.raisedAlertId, !this.notification.read)
      .subscribe((response: ThresholdNotification) => {
        this.notification = response;
        this.class = !this.notification.read ? 'unread' : '';
        this.notificationService
          .getUnreadNotificationsCount(
            this.initialState,
            this.form?.get('priority')?.value,
            this.form?.get('alertType')?.value,
          )
          .subscribe();
      });
  }

  public onExploreTag(): void {
    const { tagId } = this.notification;
    this.tagService.getTagById(tagId).subscribe((tag: Tag) => {
      const timeline = this.widgetService.mergeTagIntoTimeline(tag);
      const navigationItem = new NavigationHistoryItem(
        `${timeline.widgetId}_${WidgetActions.EXPLORE}`,
        timeline,
        WidgetActions.EXPLORE,
        [
          new QueryRule(
            DTOQueryFieldType.tag,
            DTOQueryConditionOperator.in,
            timeline.dataSource,
          ),
        ],
        new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
        new FormGroup({}),
        'magnifying-glass',
        IconType.FONT_AWESOME,
        TIMELINE_CHART_TYPES.find(
          (item: IDisplayType) => item.type === ChartType.TIMELINE,
        ),
        undefined,
        undefined,
      );
      this.openCustomOverlay({ navigationItem });
    });
  }

  private openCustomOverlay(data: any): void {
    this.customOverlayService.open({
      data,
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }
}

<div class="field__container">
  <h1>Platform</h1>
  <div>{{ note.subType }}</div>
</div>
<div class="field__container">
  <h1>Social channel</h1>
  <div>{{ note.rawValue }}</div>
</div>
<div class="field__container" *ngIf="note.textNote">
  <h1>Text notes</h1>
  <div [innerHTML]="note.textNote | urlify"></div>
</div>

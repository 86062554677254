<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'boardToolbar'">
    <div class="flex justify-between items-center flex-1 mx-7">
      <div class="flex items-center">
        <itq-mat-button
          [materialStyle]="'stroked'"
          [type]="'button'"
          (clickEvent)="onRefresh()"
        >
          <fa-icon [icon]="['far', 'sync']" [size]="'lg'"></fa-icon>
          REFRESH
        </itq-mat-button>
        <itq-input
          class="mx-2"
          [width]="300"
          [icon]="'search'"
          [placeholder]="'Search this dashboard...'"
          formControlName="query"
          (search)="onSearch()"
          (clear)="onSearch()"
        ></itq-input>

        <itq-date-range
          class="date_range__control"
          [dates]="this.initialState?.where"
          (search)="onChangeDate($event)"
        ></itq-date-range>
      </div>
      <div class="flex">
        @if (
          userService.userPreferences.defaultEcosystemId | hasEventsEnabled
        ) {
          <itq-mat-button
            class="ml-2"
            [materialStyle]="'flat'"
            [style]="'primary'"
            [type]="'button'"
            (clickEvent)="onRecordEvent()"
          >
            <fa-icon [icon]="['far', 'plus']"></fa-icon>
            <span class="whitespace-nowrap">RECORD EVENT</span>
          </itq-mat-button>
        }
        <itq-mat-button
          class="ml-2"
          [materialStyle]="'flat'"
          [style]="'primary'"
          [type]="'button'"
          (clickEvent)="onAddWidget()"
        >
          <fa-icon [icon]="['far', 'plus']"></fa-icon>
          <span class="whitespace-nowrap">CREATE WIDGET</span>
        </itq-mat-button>
      </div>
    </div>
  </ng-container>
</ng-container>

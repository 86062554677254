import { map } from 'rxjs';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Align,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  FoamtreeService,
  IPresetQuery,
  IRiplResults,
  IconType,
  PillType,
  QueryFilters,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { TIMELINE_CHART_TYPES } from '@portal/boards/const/timeline.const';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { WidgetService } from '@portal/boards/services/widget.service';
import { Profile } from '@portal/profiles/models/profile';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import {
  ToolbarActions,
  ViewMode,
} from '@portal/shared/components/toolbar/toolbar.enum';
import { IToolbarAction } from '@portal/shared/components/toolbar/toolbar.interface';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import {
  IData,
  ISearchResults,
} from '@portal/shared/interfaces/document.interface';
import {
  IDisplayType,
  IWidgetData,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { TagService } from '@portal/shared/services/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { DiscordNavigationItem } from '@portal/widget-settings/models/discord-navigation-item.model';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { cloneDeep } from 'lodash';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ChartService } from '@portal/shared/services/chart.service';

@Component({
  selector: 'itq-profiles-tags',
  templateUrl: './profiles-tags.component.html',
  styleUrls: ['./profiles-tags.component.scss'],
})
export class ProfilesTagsComponent implements OnInit {
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() profile: Profile;
  @Input() showLinkTags = false;
  @Input() action: WidgetActions;

  public toolbarActions: Array<IToolbarAction>;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    {
      direction: 'desc',
      active: 'updatedDate',
    },
    undefined,
  );
  public selectedCluster: any;
  public timelineData: IData;
  public viewMode = ViewMode.TABLE;
  public queryModel = new Query();
  public widget: Timeline;
  public foamtreeData: IRiplResults;
  public widgetData: IWidgetData;
  public tagAnalysisWidget: TagAnalysis;
  public displayTypes: Array<IDisplayType>;
  private foamtreeRef: ElementRef;
  public expandedFilters = true;
  private collapseHistorySubscription: Subscription;
  private scrollSubscription: Subscription;
  private showAddLinkSubscription: Subscription;

  readonly ViewMode = ViewMode;
  readonly ChartType = ChartType;
  readonly PillType = PillType;
  readonly Align = Align;
  readonly Sizes = Sizes;

  constructor(
    private foamtreeService: FoamtreeService,
    private widgetService: WidgetService,
    private widgetSettingsService: WidgetSettingsService,
    private linkTagsService: LinkTagsService,
    readonly tagService: TagService,
    private virtualScrollService: VirtualScrollService,
    readonly userService: UserService,
    readonly chartService: ChartService,
  ) {}

  ngOnInit() {
    this.showAddLinkSubscription = this.linkTagsService.showLinkTag$.subscribe(
      (response: boolean) => {
        if (!response) {
          if (this.queryModel.hasRules()) {
            this.widgetSettingsService.loader$.next(true);
            this.getData().subscribe((response: IData | IWidgetData) => {
              this.getDataCallback(response);
              this.widgetSettingsService.loader$.next(false);
            });
          }
        }
      },
    );
    this.scrollSubscription = this.virtualScrollService.scroll$.subscribe(
      (response: { query: QueryFilters; direction: string }) => {
        this.initialState.page = response.query.page;
        this.widgetSettingsService.loader$.next(true);
        this.getData().subscribe((response: IData) => {
          this.getDataCallback(response);
          this.widgetSettingsService.loader$.next(false);
        });
      },
    );
    this.collapseHistorySubscription =
      this.widgetService.responsivePanels$.subscribe(() => {
        const responsivePanels = this.widgetService.responsivePanels;
        if (
          responsivePanels.foamtree &&
          responsivePanels.articleDetail &&
          (responsivePanels.filters || responsivePanels.navigationHistory)
        ) {
          this.widgetService.responsivePanels.filters = false;
          this.expandedFilters = false;
        }
      });

    this.widgetSettingsService.loader$.next(true);
    this.linkTagsService
      .getLinkedTags(this.profile.profileId, this.initialState)
      .subscribe(() => {
        this.widgetSettingsService.loader$.next(false);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.action?.previousValue === WidgetActions.CREATE &&
      changes?.action?.currentValue === WidgetActions.EDIT
    ) {
      if (this.queryModel.hasRules()) {
        this.widgetSettingsService.loader$.next(true);
        this.getData().subscribe((response: IData | IWidgetData) => {
          this.getDataCallback(response);
          this.widgetSettingsService.loader$.next(false);
        });
      }
    }

    if (
      changes?.navigationItem?.previousValue !==
      changes?.navigationItem?.currentValue
    ) {
      this.toolbarActions = [
        {
          action: ToolbarActions.REFRESH,
        },
      ];
      if (this.navigationItem?.linkTag?.tagId) {
        this.toolbarActions = [
          ...this.toolbarActions,
          {
            action: ToolbarActions.TOGGLE_VIEWS,
            data: [ViewMode.RIPL, ViewMode.TABLE],
          },
        ];

        this.displayTypes = TIMELINE_CHART_TYPES.filter(
          (item: IDisplayType) => {
            return item.id !== this.navigationItem.selectedDisplayType.id;
          },
        );
      }
      this.viewMode = ViewMode.TABLE;
      if (this.navigationItem.linkTag) {
        this.widget = new Timeline(
          undefined,
          undefined,
          AnalysisTypes.TIMELINE,
          this.profile.name,
          this.profile.description,
          ChartType.TIMELINE,
          this.userService.userPreferences.defaultEcosystemId,
          undefined,
          undefined,
          undefined,
          undefined,
          [
            this.navigationItem?.linkTag?.type === TagCategory['My Tags']
              ? this.navigationItem?.linkTag.tagId
              : `Field field:${this.navigationItem.linkTag.tagName}:${this.navigationItem.linkTag.type}`,
          ],
          undefined,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        );
      }
      this.initialState = changes?.navigationItem?.currentValue?.initialState;
      if (this.widget) {
        this.queryModel = new Query([
          new QueryRule(
            this.widget.dataSource[0].indexOf('Field field:') > -1
              ? DTOQueryFieldType.filter
              : DTOQueryFieldType.tag,
            DTOQueryConditionOperator.in,
            this.widget.dataSource,
          ),
        ]);

        if (
          this.navigationItem?.selectedDisplayType?.type !== ChartType.TIMELINE
        ) {
          this.tagAnalysisWidget = new TagAnalysis(
            this.widget.widgetId,
            this.widget.username,
            AnalysisTypes.TAG_ANALYSIS,
            this.widget.name,
            this.widget.description,
            undefined,
            undefined,
            undefined,
            undefined,
            this.navigationItem.selectedDisplayType?.options,
            this.widget.dataSource,
            [this.navigationItem.selectedDisplayType?.id as TagCategory],
            25,
            ChartType.TABLE,
            this.userService.userPreferences.defaultEcosystemId,
            this.widget.updatedDate,
          );
        }
        if (this.queryModel.hasRules()) {
          this.widgetSettingsService.loader$.next(true);
          this.getData().subscribe((response: IData | IWidgetData) => {
            this.getDataCallback(response);
            this.widgetSettingsService.loader$.next(false);
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
    this.collapseHistorySubscription.unsubscribe();
    this.showAddLinkSubscription.unsubscribe();
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onDataBound(this.queryModel, this.initialState);
  }

  public onDrilldownFoamtree(cluster: any): void {
    this.widgetSettingsService.loader$.next(true);
    this.selectedCluster = cluster;
    const documentIds = this.foamtreeService.getClusterDocumentIds(
      [cluster],
      this.foamtreeData,
    );
    this.foamtreeService
      .filterCluster({ documentIds })
      .subscribe((response: ISearchResults) => {
        this.timelineData = {
          result: response.items,
          count: response.totalHits,
        };
        this.widgetSettingsService.loader$.next(false);
      });
  }

  public onDataBound(query: Query, params: QueryFilters): void {
    this.articleDetail = undefined;
    this.queryModel = query;
    this.initialState = params;
    if (this.viewMode === ViewMode.RIPL) {
      this.widgetSettingsService.loader$.next(true);
      forkJoin([this.getData(), this.getFoamtreeData()]).subscribe(
        (response: Array<any>) => {
          this.getDataCallback(response[0]);
          this.widgetSettingsService.loader$.next(false);
        },
      );
    } else if (this.viewMode === ViewMode.TABLE) {
      this.widgetSettingsService.loader$.next(true);
      this.getData().subscribe((response: IData | IWidgetData) => {
        this.getDataCallback(response);
        this.widgetSettingsService.loader$.next(false);
      });
    }
  }

  public getData(): Observable<IData | IWidgetData> {
    if (this.navigationItem.selectedDisplayType.type === ChartType.TIMELINE) {
      return this.getTimelineData();
    } else {
      return this.getWidgetData(this.initialState);
    }
  }

  private getWidgetData(params: QueryFilters): Observable<IWidgetData> {
    const queryModel = this.queryModel.cloneDeep();
    this.navigationItem.rules?.forEach((rule: QueryRule) => {
      queryModel.addRule(rule);
    });
    return this.widgetService.getData(
      {
        widget: this.tagAnalysisWidget,
        filters: queryModel.modelToDTO(),
      },
      {
        where: params?.where,
      },
    );
  }

  private getDataCallback(response: IData | IWidgetData): void {
    this.updateNavigationItem();
    if (this.navigationItem.selectedDisplayType.type === ChartType.TIMELINE) {
      this.timelineData = {
        result:
          this.initialState.page > 1
            ? [...this.timelineData.result, ...(response as IData).result]
            : (response as IData).result,
        count: (response as IData).count,
      };
    } else {
      this.widgetData = response as IWidgetData;
    }
  }

  private getFoamtreeData(): Observable<void> {
    this.foamtreeData = undefined;
    const queryModel = this.queryModel.cloneDeep();
    this.navigationItem.rules?.forEach((rule: QueryRule) => {
      queryModel.addRule(rule);
    });
    const query = queryModel.modelToDTO();
    return this.foamtreeService
      .getData(
        this.initialState,
        query,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .pipe(
        map((response: IRiplResults) => {
          this.chartService.reloadChart$.next(this.widget.widgetId);
          this.foamtreeData = response;
        }),
      );
  }

  private updateNavigationItem(): void {
    const query = new Query();
    let rules = this.queryModel.getRules();
    if (this.navigationItem?.rules?.length > 0) {
      rules = this.queryModel.getRules().filter((rule: QueryRule) => {
        return rule.value !== this.navigationItem?.rules[0]?.value;
      });
    }
    rules.forEach((item: QueryRule) => {
      query.addRule(item);
    });
    this.onUpdateWidget({ prop: '_extras', value: query });
  }

  public onUpdateWidget(params: { prop: string; value: any }): void {
    this.widget[params.prop] = params.value;
    this.navigationItem.form = cloneDeep(this.form);
    this.navigationItem.initialState = cloneDeep(this.initialState);
    this.navigationItem.item[params.prop] = params.value;
  }

  private getTimelineData(): Observable<IData> {
    return this.tagService.execute(
      this.initialState,
      this.queryModel,
      this.userService.userPreferences.defaultEcosystemId,
    );
  }

  public onDrilldown(segment: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment);
        break;
      case SegmentScope.REPLIES:
        this.drilldownReplies(segment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(
        30,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      segment,
      undefined,
      segment.context,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownReplies(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitType === 'Comment'
        ? `${segment.value.emitType} by ${segment.value.emitActor} `
        : `Replies of ${segment.value.emitHeadline}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.id}:ReplyTo`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'file',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownDiscord(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      this.articleDetail,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onChangeDisplayType(params: IDisplayType): void {
    this.articleDetail = undefined;
    this.displayTypes?.push(cloneDeep(this.navigationItem.selectedDisplayType));
    this.navigationItem.selectedDisplayType = params;
    if (this.navigationItem.selectedDisplayType.type === ChartType.TIMELINE) {
      this.initialState.page = 1;
    } else {
      this.viewMode = ViewMode.TABLE;
      this.tagAnalysisWidget = new TagAnalysis(
        this.widget.widgetId,
        this.widget.username,
        AnalysisTypes.TAG_ANALYSIS,
        this.widget.name,
        this.widget.description,
        undefined,
        undefined,
        undefined,
        undefined,
        params.options,
        this.widget.dataSource,
        [params.id as TagCategory],
        25,
        ChartType.TABLE,
        this.userService.userPreferences.defaultEcosystemId,
        this.widget.updatedDate,
      );
    }
    this.widgetSettingsService.loader$.next(true);
    this.getData().subscribe((response: IData | IWidgetData) => {
      this.getDataCallback(response);
      this.widgetSettingsService.loader$.next(false);
    });
    this.displayTypes = this.displayTypes.filter((item: IDisplayType) => {
      return item.id !== this.navigationItem.selectedDisplayType.id;
    });
  }

  public onExportFoamtree(): void {
    this.foamtreeService.exportImage(this.widget.name, this.foamtreeRef);
  }

  public onToggleView(viewMode: ViewMode): void {
    this.expandedFilters = true;
    this.widgetService.responsivePanels.foamtree = viewMode === ViewMode.RIPL;
    this.widgetService.responsivePanels$.next();
    if (viewMode === ViewMode.RIPL) {
      if (viewMode === this.viewMode) {
        viewMode = ViewMode.TABLE;
      } else {
        this.widgetSettingsService.loader$.next(true);
        this.getFoamtreeData().subscribe(() => {
          this.widgetSettingsService.loader$.next(false);
        });
      }
    }
    this.viewMode = viewMode;
  }

  public onToggleSettings(): void {
    this.linkTagsService.showLinkTag$.next(true);
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CastIconNamePipe,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DateRangeComponent,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  FAwesomeModule,
  IconType,
  IPresetQuery,
  PillType,
  QueryFilters,
  TableColumn,
  TagCategory,
  Utils,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { EChartTypes } from '@portal/boards/const/widget.const';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { IAddConnection } from '@portal/profiles/interfaces/profile-connection.interface';
import { IAddNote } from '@portal/profiles/interfaces/profile-note.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { AddConnectionsComponent } from '@portal/shared/components/add-connections/add-connections.component';
import { ChartComponent } from '@portal/shared/components/chart/chart.component';
import { PostNotesWizardComponent } from '@portal/shared/components/post-notes-wizard/post-notes-wizard.component';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';

import { CommonModule } from '@angular/common';
import { MatFormField } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TOP_ACTORS_CHART_TYPES } from '@portal/boards/const/top-actors.const';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { TopActor } from '@portal/boards/models/widgets/top-actor';
import { DocumentItemTileV2Component } from '@portal/shared/components/document-item-tile-v2/document-item-tile-v2.component';
import { ISearchResults } from '@portal/shared/interfaces/document.interface';
import {
  IDataPoint,
  IDisplayType,
  IWidgetData,
  IWidgetType,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { ChartService } from '@portal/shared/services/chart.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { DiscordNavigationItem } from '@portal/widget-settings/models/discord-navigation-item.model';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { MapLabelPipe } from '@portal/widgets/components/chart-wizard/pipes/chart-wizard.pipe';
import { ECharts } from 'echarts';
import html2canvas from 'html2canvas';
import { cloneDeep } from 'lodash';
import { forkJoin, map, Observable, of, Subscription } from 'rxjs';
import { TableChartComponent } from '@portal/shared/components/table-chart/table-chart.component';

@Component({
  selector: 'itq-chart-explore-top-actors',
  templateUrl: './chart-explore-top-actors.component.html',
  styleUrls: ['./chart-explore-top-actors.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ChartComponent,
    CommonModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DateRangeComponent,
    CastIconNamePipe,
    MatFormField,
    DocumentItemTileV2Component,
    MapLabelPipe,
    TableChartComponent,
  ],
})
export class ChartExploreTopActorsComponent implements OnInit, AfterViewInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() segment: any;
  @Input() form: FormGroup;
  @Input() widget: TopActor;
  @Input() initialState: QueryFilters;

  @ViewChild('chart') chart: ComponentRef<ChartComponent>;
  @ViewChild('template')
  template: TemplateRef<unknown>;
  @ViewChild('chartContainer', { static: false }) chartContainer: ElementRef;
  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  public widgetType: IWidgetType;
  public chartTypesDataSource = TOP_ACTORS_CHART_TYPES;
  public get documentsData(): ISearchResults {
    return this._documentsData;
  }

  public set documentsData(value: ISearchResults) {
    if (this.initialState.page > 1) {
      this._documentsData.items = [...this.documentsData.items, ...value.items];
    } else {
      this._documentsData = value;
    }
  }
  public dataSource: IWidgetData;
  public chartInstance: ECharts;
  public docsTableColumns: Array<TableColumn>;
  private subscription = new Subscription();
  private _documentsData: ISearchResults;
  public count = 0;
  public tableColumns: Array<TableColumn>;

  readonly PillType = PillType;
  readonly ChartType = ChartType;

  constructor(
    private widgetService: WidgetService,
    public customOverlayRef: CustomOverlayRef,
    private customOverlayService: CustomOverlayService,
    private virtualScrollService: VirtualScrollService,
    readonly cdr: ChangeDetectorRef,
    readonly userService: UserService,
    readonly widgetSettingsService: WidgetSettingsService,
    readonly chartService: ChartService,
    readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.initialState = cloneDeep(this.navigationItem.initialState);
    this.addControls();
    this.bindRemoveSegmentSubscription();
  }

  ngAfterViewInit(): void {
    this.initColumns();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initTableColumns(): void {
    if (this.widget.chartType === ChartType.TABLE) {
      this.tableColumns = this.widget?.getTableColumns(this.countTemplate);
    }
  }

  private bindRemoveSegmentSubscription(): void {
    this.subscription.add(
      this.chartService.removeSegment$.subscribe(() => {
        this.segment = undefined;
        this.cdr.detectChanges();
        this.chartService.reloadChart$.next(this.navigationItem?.id);
      }),
    );
  }

  public onChangeChartType(params: IDisplayType): void {
    this.widget.options = params.options;
    this.widget.chartType = params.type;
    this.initTableColumns();
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(this.navigationItem?.id);
  }

  private addControls(): void {
    this.form.addControl('query', new FormControl(''));
    this.form.addControl(
      'chartType',
      new FormControl(this.widget.getDisplayType()?.id, [Validators.required]),
    );
  }

  public onClose(): void {
    this.customOverlayRef.close();
  }

  private initColumns(): void {
    this.docsTableColumns = [
      {
        name: undefined,
        dataKey: 'result',
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
  }

  private loadData(): void {
    this.widgetSettingsService.loader$.next(true);
    forkJoin([this.getData(), this.getDocuments()]).subscribe(() => {
      this.cdr.detectChanges();
      this.chartService.reloadChart$.next(this.navigationItem?.id);
      this.widgetSettingsService.loader$.next(false);
    });
  }

  public onClearDate(): void {
    this.initialState.where = undefined;
    this.segment = undefined;
    this.onDataBound(this.initialState);
  }

  public onDrilldownChart(segment: any): void {
    this.widgetSettingsService.loader$.next(true);
    this.segment = segment;
    this.cdr.detectChanges();
    this.chartService.reloadChart$.next(this.navigationItem?.id);
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });

    this.getDocuments().subscribe(() => {
      this.widgetSettingsService.loader$.next(false);
    });
  }

  private getDocuments(): Observable<any> {
    if (!this.segment) return of(undefined);
    const query = this.widget.getDocsQueryModel(
      this.initialState,
      this.segment,
    );

    return this.widgetService
      .getWidgetDocuments(
        {
          widget: this.widget,
          filters: query,
        },
        {
          pageSize: 30,
          page: this.initialState?.page || 1,
          where: this.initialState?.where,
          dataValue: this.segment?.data?.tagId,
        },
      )
      .pipe(
        map((response: Array<ISearchResults>) => {
          this.navigationItem.form = cloneDeep(this.form);
          this.navigationItem.initialState = cloneDeep(this.initialState);
          this.documentsData = response[0];
        }),
      );
  }

  private getData(): Observable<IWidgetData> {
    const queryModel = new Query();
    if (this.initialState?.query) {
      queryModel.addRule(
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [this.initialState.query],
        ),
      );
    }
    const query = queryModel.modelToDTO();
    const state = {
      where: this.initialState?.where,
    };
    return this.widgetService
      .getData(
        {
          widget: this.widget,
          filters: query,
        },
        state,
      )
      .pipe(
        map((response: IWidgetData) => {
          this.dataSource = response;
          this.initTableColumns();
          this.count = this.dataSource.totalHits;
          return response;
        }),
      );
  }

  public onResetFilters(): void {
    this.segment = cloneDeep(this.navigationItem.segment);
    this.widget = cloneDeep(this.navigationItem.item);
    this.initialState = cloneDeep(this.navigationItem.initialState);
    this.onSearch();
    this.chartService.reloadChart$.next(this.navigationItem?.id);
  }

  public onDataBound(params: QueryFilters): void {
    this.widgetSettingsService.loader$.next(true);
    this.initialState = params;
    this.loadData();
  }

  public onChangeDate(dates: IPresetQuery): void {
    this.initialState.where = dates;
    this.onSearch();
  }

  public onChangeSearch(): void {
    this.initialState.query = this.form.get('query').value;
    this.onSearch();
  }

  public onSearch(): void {
    this.widgetSettingsService.loader$.next(true);
    this.segment = undefined;
    this.loadData();
  }

  public onExport(): void {
    this.widgetSettingsService.loader$.next(true);
    setTimeout(() => {
      if (EChartTypes.includes(this.widget.chartType)) {
        Utils.exportImage(
          this.chartInstance.getDataURL({
            pixelRatio: 2,
            backgroundColor: 'transparent',
          }),
          this.widget.name,
        );
        this.snackBar.open('Chart has been exported successfully!', 'Close', {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        });
        this.widgetSettingsService.loader$.next(false);
      } else {
        html2canvas(this.chartContainer.nativeElement).then((canvas: any) => {
          Utils.exportImage(canvas.toDataURL('image/png'), this.widget.name);
          this.snackBar.open('Chart has been exported successfully!', 'Close', {
            horizontalPosition: 'right',
            duration: 5000,
            verticalPosition: 'top',
          });
          this.widgetSettingsService.loader$.next(false);
        });
      }
    }, 100);
  }

  public onUpdateRef(ref: ECharts): void {
    this.chartInstance = ref;
  }

  public onLoadCount(row: IDataPoint): void {
    this.widgetSettingsService.loader$.next(true);
    this.segment = {
      data: { tagId: row.tagId, name: row.category, value: row.count },
    };

    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();

      this.getDocuments().subscribe(() => {
        this.widgetSettingsService.loader$.next(false);
      });
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.scope === ProfileDrildownScope.TAG
        ? this.widget.name
        : profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? this.widget.name
          : profileDrilldown.value,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? profileDrilldown.value
          : `Field field:${profileDrilldown.value}:${
              profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onDrilldown(segment: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment);
        break;
      // case SegmentScope.REPLIES:
      //   this.drilldownReplies(segment);
      //   break;
      default:
        break;
    }
  }

  private drilldownContext(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(
        30,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      segment,
      undefined,
      segment.context,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  // private drilldownReplies(segment: ISegment): void {
  //   const tagId = this.widget.getTagId(this.segment.seriesIndex);
  //   this.tagService.getTagById(tagId).subscribe((response: Tag) => {
  //     const widget = this.widgetService.mergeTagIntoTimeline(response);
  //     widget.name =
  //       segment.value.emitType === 'Comment'
  //         ? `${segment.value.emitType} by ${segment.value.emitActor} `
  //         : `Replies of ${segment.value.emitHeadline}`;
  //     const value = `Field Filter:${segment.value.id}:ReplyTo`;
  //     const navigationItem = new NavigationHistoryItem(
  //       `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
  //       widget,
  //       WidgetActions.DRILLDOWN,
  //       [
  //         {
  //           field: DTOQueryFieldType.filter,
  //           operator: DTOQueryConditionOperator.in,
  //           value: [value],
  //         },
  //       ],
  //       new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
  //       new FormGroup({}),
  //       'file',
  //       IconType.FONT_AWESOME,
  //       {
  //         id: 'Timeline',
  //         type: ChartType.TIMELINE,
  //         svgIcon: 'board',
  //         tooltip: 'Timeline',
  //       },
  //       undefined,
  //       undefined,
  //     );
  //     this.widgetService.drilldownObservable.next(navigationItem);
  //   });
  // }

  private drilldownDiscord(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      Utils.generateUUID(),
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onAddConnection(params: IAddConnection): void {
    this.customOverlayService.open({
      data: params,
      type: CustomOverlayType['slide-right'],
      component: AddConnectionsComponent,
      disposeOnNavigation: true,
    });
  }

  public onAddNote(params: IAddNote): void {
    this.customOverlayService.open({
      data: params,
      size: 'lg',
      type: CustomOverlayType['slide-right'],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });
  }
}

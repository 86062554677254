import { DTOQuery } from '@intorqa-ui/core';
import { ITag } from '../interfaces/tag.interface';

export class Tag implements ITag {
  constructor(
    public tagId: string,
    public description: string,
    public createdDate: number,
    public updatedDate: number,
    public _extras: any,
    public name: string,
    public query: DTOQuery,
    public sharedTag: boolean,
    public username: string,
    public categoryId: string,
    public lastTaggingTime: number,
    public alertTypeId: string,
    public ecosystemId: string,
  ) {}
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DropdownService {
  dropdownObservable: Subject<void> = new Subject();

  constructor() {}

  toggleDropdown(): void {
    this.dropdownObservable.next();
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  CoreModule,
  IconComponent,
  IconType,
  QueryFilters,
} from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import { VirtualScrollService } from './../../services/virtual-scrolling.service';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'itq-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    MatListModule,
    FormsModule,
    IconComponent,
    CommonModule,
  ],
})
export class ListComponent implements OnInit, OnDestroy {
  private scrollTop = 0;
  public query: string;
  private subscription = new Subscription();

  readonly IconType = IconType;

  @Input() dataSource: Array<any>;
  @Input() template: TemplateRef<unknown>;
  @Input() dataFields: { name: string; value: string };
  @Input() initialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @Output() dataBound = new EventEmitter<void>();
  @Output() add = new EventEmitter<string>();

  @ViewChild('selectionsList') selectionsList: any;

  constructor(private virtualScrollService: VirtualScrollService) {}

  ngOnInit(): void {
    this.bindDataBoundObservable();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private bindDataBoundObservable(): void {
    this.subscription.add(
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        this.scrollTop = 0;
        this.selectionsList?._element?.nativeElement?.scrollTo(0, 0);
      }),
    );
  }

  private onDataBound(): void {
    this.dataBound.emit();
  }

  public onScroll(): void {
    if (this.initialState) {
      this.virtualScrollService
        .scrollDown(
          this.selectionsList._element.nativeElement,
          this.initialState.pageSize,
          this.dataSource.length,
          this.scrollTop,
          undefined,
          this.initialState.page,
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.onDataBound();
          }
        });
    }
  }
}

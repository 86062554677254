import { Injectable } from '@angular/core';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import { QueryFilters } from '@intorqa-ui/core';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { Observable, Subject, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { IConnectionType } from '../interfaces/connection-type.interface';
import {
  IConnectionsData,
  IProfileConnection,
  IProfileConnectionsResults,
} from '../interfaces/profile-connection.interface';
import { ConnectionType } from '../models/connection-type';
import { Profile } from '../models/profile';
import { ProfileConnection } from '../models/profile-connection';

@Injectable({
  providedIn: 'root',
})
export class ConnectionsService {
  public connectionTypes$ = new Subject<Array<ConnectionType>>();
  public reset$ = new Subject<void>();
  public connections$ = new Subject<IConnectionsData>();
  public showAddConnections$ = new Subject<WidgetActions | void>();
  public showConnectionsSettings$ = new Subject<boolean>();

  private _connections: IConnectionsData = {
    items: [],
    totalCount: 0,
  };
  private _connectionTypes: Array<ConnectionType> = [];

  public get connectionTypes(): Array<ConnectionType> {
    return this._connectionTypes;
  }

  public set connectionTypes(v: Array<ConnectionType>) {
    this._connectionTypes = v;
  }

  public get connections(): IConnectionsData {
    return this._connections;
  }

  public set connections(v: IConnectionsData) {
    this._connections = v;
  }

  constructor(private apiRequestService: ApiRequestService) {}

  public getConnectionTypes(
    profile: Profile,
    targetTypeId?: string,
  ): Observable<Array<ConnectionType>> {
    let pageQuery = `originTypeId=${profile.profileTypeId}`;
    if (targetTypeId) {
      pageQuery += `&targetTypeId=${targetTypeId}`;
    }
    return this.apiRequestService
      .getToObservable(
        `/profiles/connections?${pageQuery}`,
        new DTOTypeConverter<Array<IConnectionType>>(),
      )
      .pipe(
        map((response: Array<IConnectionType>) => {
          this.connectionTypes = response?.map(
            (item: IConnectionType) =>
              new ConnectionType(item.id, item.name, item.targetProfileTypeId),
          );
          this.connectionTypes$.next(this.connectionTypes);
          return this.connectionTypes;
        }),
      );
  }

  public resetConnections(): void {
    this.connections = {
      items: [],
      totalCount: 0,
    };
  }

  public addConnections(
    profile: Profile,
    connections: Array<ProfileConnection>,
  ): Observable<Array<ProfileConnection>> {
    const connectionsSaveDTO: Array<ProfileConnection> = [];
    connections.map((item: ProfileConnection) => {
      connectionsSaveDTO.push(
        new ProfileConnection(
          undefined,
          item.typeId,
          item.typeName,
          undefined,
          undefined,
          undefined,
          item.targetProfileId,
          undefined,
          undefined,
          item.description,
          undefined,
          undefined,
        ),
      );
    });
    return this.apiRequestService
      .postToObservable(
        `/profiles/${profile.profileId}/connections`,
        new DTOTypeConverter<Array<IProfileConnection>>(),
        connectionsSaveDTO,
      )
      .pipe(
        map((response: Array<IProfileConnection>) => {
          this.connections.items = [
            ...this.connections.items,
            ...response?.map(
              (item: IProfileConnection) =>
                new ProfileConnection(
                  item.id,
                  item.typeId,
                  item.typeName,
                  item.originProfileId,
                  item.originProfileName,
                  item.originProfileTypeName,
                  item.targetProfileId,
                  item.targetProfileName,
                  item.targetProfileTypeName,
                  item.description,
                  item.updatedDate,
                  item.updatedBy,
                ),
            ),
          ];
          this.connections.totalCount =
            this.connections.totalCount + connections.length;
          this.connections$.next(this.connections);
          return this.connections.items;
        }),
      );
  }

  public getConnections(
    id: string,
    params: QueryFilters,
  ): Observable<IConnectionsData> {
    let pageQuery = `page=${params.page}`;
    pageQuery += `&size=${params.pageSize}`;
    pageQuery += `&sortField=${params.sort.active}`;
    pageQuery += `&sortOrder=${params.sort.direction}`;
    return this.apiRequestService
      .postToObservable(
        `/profiles/${id}/connections/list?${pageQuery}`,
        new DTOTypeConverter<IProfileConnectionsResults>(),
        params?.query,
      )
      .pipe(
        map((response: IProfileConnectionsResults) => {
          this.connections = {
            items: response?.items?.map(
              (item: IProfileConnection) =>
                new ProfileConnection(
                  item.id,
                  item.typeId,
                  item.typeName,
                  item.originProfileId,
                  item.originProfileName,
                  item.originProfileTypeName,
                  item.targetProfileId,
                  item.targetProfileName,
                  item.targetProfileTypeName,
                  item.description,
                  item.updatedDate,
                  item.updatedBy,
                ),
            ),
            totalCount: response?.totalCount,
          };
          this.connections$.next(this.connections);
          return this.connections;
        }),
      );
  }

  public removeConnections(
    id: string,
    connections: Array<ProfileConnection>,
  ): Observable<boolean> {
    return this.apiRequestService
      .deleteToObserable(`/profiles/${id}/connections`, connections)
      .pipe(
        map((response: boolean) => {
          const connectionIds = connections.map(
            (item: ProfileConnection) => item.id,
          );
          this.connections.items = this.connections.items.filter(
            (item: ProfileConnection) => !connectionIds.includes(item.id),
          );
          this.connections.totalCount -= connections.length;
          this.connections$.next(this.connections);
          return response;
        }),
      );
  }

  public getConnectionTypeById(connectionTypeId: string): ConnectionType {
    return this.connectionTypes.find(
      (item: ConnectionType) => item.id === connectionTypeId,
    );
  }

  public getConnectionTypeByTargetTypeId(
    targetProfileTypeId: string,
  ): ConnectionType {
    return this.connectionTypes.find(
      (item: ConnectionType) =>
        item.targetProfileTypeId === targetProfileTypeId,
    );
  }

  public getConnectionById(connectionId: string): ProfileConnection {
    return this.connections.items.find(
      (item: ProfileConnection) => item.id === connectionId,
    );
  }

  public updateConnection(
    profileId: string,
    connection: ProfileConnection,
  ): Observable<IConnectionsData> {
    return this.apiRequestService
      .putToObservable(`/profiles/${profileId}/connections`, {
        connectionId: connection.id,
        typeId: connection.typeId,
        typeName: connection.typeName,
        targetProfileId: connection.targetProfileId,
        targetProfileName: connection.targetProfileName,
        targetProfileTypeName: connection.targetProfileTypeName,
        description: connection.description,
      })
      .pipe(
        map((response: IProfileConnection) => {
          this.connections.items = this.connections.items?.map(
            (item: ProfileConnection) => {
              if (connection.id === item.id) {
                return new ProfileConnection(
                  response.id,
                  response.typeId,
                  response.typeName,
                  response.originProfileId,
                  response.originProfileName,
                  response.originProfileTypeName,
                  response.targetProfileId,
                  response.targetProfileName,
                  response.targetProfileTypeName,
                  response.description,
                  response.updatedDate,
                  response.updatedBy,
                );
              } else {
                return item;
              }
            },
          );
          this.connections$.next(this.connections);
          return this.connections;
        }),
      );
  }

  public getUsers(profileId: string): Observable<Array<string>> {
    return this.apiRequestService.getToObservable(
      `/profiles/${profileId}/connections/users`,
      new DTOTypeConverter<Array<string>>(),
      undefined,
    );
  }
}

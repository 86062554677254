import { Component, Input, OnInit } from '@angular/core';
import { IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconType } from './icon.enum';
import { FAwesomeModule } from '../../modules/font-awesome.module';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../modules/material.module';
import { SvgComponent } from '../svg/svg.component';
import { CastIconNamePipe, CastIconSizePipe } from './icon.pipe';

@Component({
  selector: 'itq-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    SvgComponent,
    CommonModule,
    MaterialModule,
    CastIconNamePipe,
    CastIconSizePipe,
  ],
})
export class IconComponent implements OnInit {
  @Input() iconName: IconName | string;
  @Input() iconType: IconType = IconType.FONT_AWESOME;
  @Input() iconSize: SizeProp | string;

  constructor() {}

  ngOnInit(): void {}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CastIconNamePipe,
  CoreModule,
  FAwesomeModule,
  IconType,
  SvgComponent,
} from '@intorqa-ui/core';
import { Sizes } from '../../enums/shared.enum';

@Component({
  selector: 'itq-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  standalone: true,
  imports: [SvgComponent, CoreModule, FAwesomeModule, CastIconNamePipe],
})
export class ThumbnailComponent implements OnInit {
  @Input() icon: string;
  @Input() title: string;
  @Input() description: string;
  @Input() disabled: boolean;
  @Input() iconType: IconType;

  @Output() add = new EventEmitter<void>();

  readonly Sizes = Sizes;
  readonly IconType = IconType;

  constructor() {}

  ngOnInit() {}

  public onAdd(): void {
    this.add.emit();
  }
}

<div class="custom-overlay-container">
  <itq-mat-button
    [ngClass]="['btn--close', config.closeBtnClass || '']"
    [defaultColor]="'metadata-light'"
    [type]="'button'"
    [materialStyle]="config.closeBtnStyle"
    [rounded]="true"
    [matTooltip]="'Close'"
    (clickEvent)="onClose()"
  >
    <fa-icon [size]="'2x'" [icon]="['far', 'times']"></fa-icon>
  </itq-mat-button>
  <div class="custom-overlay-content">
    <ng-template dynamicPlaceholder></ng-template>
  </div>
</div>

export enum DTOQueryOperator {
  and = "and",
  or = "or",
}

export enum DTOQueryConditionOperator {
  in = "in",
  equals = "equals",
  "notin" = "notin",
  contains = "contains",
  "doesnotcontain" = "doesnotcontain",
}

export enum DTOQueryFieldType {
  tag = "tag",
  content = "content",
  filter = "filter",
  date = "date",
  query = "query",
}

export enum QueryMode {
  SIMPLE = "SIMPLE",
  ADVANCED = "ADVANCED",
}

export enum Operator {
  in = "In",
  equals = "Equals",
  "notin" = "Not in",
  contains = "Contains",
  "doesnotcontain" = "Does not contain",
}

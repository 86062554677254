<ng-container *ngIf="actions | findAction: ToolbarActions.REFRESH">
  <itq-mat-button
    [padding]="Sizes.SM"
    [materialStyle]="'basic'"
    [disabled]="count === 0"
    [matTooltip]="
      showTooltip && showTooltip && sharedService?.xlLaptop
        ? 'Refresh'
        : undefined
    "
    (clickEvent)="onRefresh()"
  >
    <fa-icon [icon]="['far', 'sync']"></fa-icon>
    <span class="text-wrapper">Refresh</span>
  </itq-mat-button>
</ng-container>
<ng-container
  *ngIf="(actions | findAction: ToolbarActions.RESET_FILTERS) && filtersApplied"
>
  <itq-mat-button
    [padding]="Sizes.SM"
    [materialStyle]="'basic'"
    [matTooltip]="
      showTooltip && sharedService?.xlLaptop ? 'Reset Filters' : undefined
    "
    (clickEvent)="onResetFilters()"
  >
    <mat-icon>filter_alt_off</mat-icon>
    <span class="text-wrapper">Clear Filters</span>
  </itq-mat-button>
</ng-container>

<ng-container
  *ngIf="actions | findAction: ToolbarActions.TOGGLE_VIEWS as toggleViews"
>
  <ng-container *ngIf="toggleViews?.data.includes(ViewMode.TABLE)">
    <itq-nav-button
      class="btn--display_types"
      [padding]="Sizes['X-SM']"
      [width]="170"
      [openDirection]="{ vertical: 'down', horizontal: 'right' }"
      [openPosition]="'inside'"
      [template]="displayTypesTemplate"
      [templateData]="widget"
      [disabled]="count === 0 || viewMode === ViewMode.RIPL"
      [padding]="Sizes.SM"
      [materialStyle]="'basic'"
      [matTooltipPosition]="'below'"
      [matTooltip]="'Change display type'"
    >
      @if (selectedDisplayType?.icon) {
        <fa-icon [size]="'lg'" [icon]="selectedDisplayType?.icon"></fa-icon>
      } @else {
        <itq-svg [icon]="selectedDisplayType?.svgIcon" class="w-5"></itq-svg>
      }
      {{ selectedDisplayType?.tooltip }}
    </itq-nav-button>
  </ng-container>
</ng-container>
<ng-container *ngIf="actions | findAction: ToolbarActions.API">
  <itq-mat-button
    [materialStyle]="'basic'"
    [padding]="Sizes.SM"
    [matTooltip]="showTooltip && sharedService?.xlLaptop ? 'API' : undefined"
    (clickEvent)="onShowApi()"
  >
    <fa-icon [icon]="['far', 'share-alt']"></fa-icon>
    <span class="text-wrapper">API</span>
  </itq-mat-button>
</ng-container>
<ng-container *ngIf="actions | findAction: ToolbarActions.EXPORT">
  <itq-mat-button
    [materialStyle]="'basic'"
    [padding]="Sizes.SM"
    [matTooltip]="showTooltip && sharedService?.xlLaptop ? 'Export' : undefined"
    (clickEvent)="onExport()"
  >
    <fa-icon [icon]="['far', 'download']"></fa-icon>
    <span class="text-wrapper">Export</span>
  </itq-mat-button>
</ng-container>
<ng-container
  *ngIf="actions | findAction: ToolbarActions.SEARCH"
  [formGroup]="form"
>
  <itq-input-toggle
    [expanded]="form?.controls?.searchTerm?.value ? true : false"
    formControlName="searchTerm"
    [placeholder]="'Add search term...'"
    [label]="'Search'"
    [matTooltip]="showTooltip && sharedService?.xlLaptop ? 'Search' : undefined"
    [autoFocus]="true"
    (search)="onSearchQuery()"
    (clear)="onSearchQuery()"
  ></itq-input-toggle>
</ng-container>

<ng-template #displayTypesTemplate>
  <div class="displayTypes__container">
    <itq-mat-button
      *ngFor="let item of displayTypes"
      [type]="'button'"
      [align]="Align.START"
      [padding]="Sizes['X-SM']"
      [materialStyle]="'basic'"
      [matTooltipPosition]="'left'"
      (clickEvent)="onChangeDisplay(item)"
    >
      <div class="display_type__icon">
        <fa-icon
          *ngIf="item.icon; else svgTemplate"
          class="mr-2"
          [icon]="item.icon"
          [size]="'lg'"
        ></fa-icon>
        <ng-template #svgTemplate>
          <itq-svg class="mr-3 w-6" [icon]="item.svgIcon"></itq-svg>
        </ng-template>
      </div>
      {{ item.tooltip }}
    </itq-mat-button>
  </div>
</ng-template>

export enum CurrencyDescription {
  "EUR" = "EUR-Euro",
  "USD" = "USD-US Dollar",
  "GBP" = "GBP-Great Britain Pound",
  "RUB" = "RUB-Ruble",
  "YEN" = "YEN-Yen",
  "IRR" = "IRR-Iranian Rial",
  "SAR" = "SAR-Saudi Rial",
  "CNY" = "CNY-Chinese Yuan",
  "VND" = "VND-Vietnamese Dong",
  "BRL" = "BRL-Brazilian Rial",
  "IDR" = "IDR-Indonesian Rupiah",
  "RUP" = "RUP-Indian Rupee",
  "BTC" = "Crypto Currency",
}

export enum CurrencyPrefixDescription {
  "EUR-Euro" = "EUR",
  "USD-US Dollar" = "USD",
  "GBP-Great Britain Pound" = "GBP",
  "RUB-Ruble" = "RUB",
  "YEN-Yen" = "YEN",
  "IRR-Iranian Rial" = "IRR",
  "SAR-Saudi Rial" = "SAR",
  "CNY-Chinese Yuan" = "CNY",
  "VND-Vietnamese Dong" = "VND",
  "BRL-Brazilian Rial" = "BRL",
  "IDR-Indonesian Rupiah" = "IDR",
  "RUP-Indian Rupee" = "RUP",
  "Crypto Currency" = "BTC",
}

export enum CurrencyPrefix {
  "EUR" = "EUR",
  "USD" = "USD",
  "GBP" = "GBP",
  "RUB" = "RUB",
  "YEN" = "YEN",
  "IRR" = "IRR",
  "SAR" = "SAR",
  "CNY" = "CNY",
  "VND" = "VND",
  "BRL" = "BRL",
  "IDR" = "IDR",
  "RUP" = "RUP",
  "BTC" = "BTC",
}

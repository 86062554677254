<div class="flex flex-col" [formGroup]="form">
  <h2 class="flex font-bold pt-7 pb-2 text-xl">Widget metrics</h2>
  <div class="flex mb-4 flex-col">
    <span class="mb-1 text-13 font-medium">TAG</span>
    <itq-dropdown
      formControlName="dataSource"
      [clearSelection]="false"
      [httpBinding]="true"
      [initialState]="initialState"
      [dataSource]="tagsDataSource"
      [dataFields]="{ name: 'name', value: 'id' }"
      [placeholder]="'Tag...'"
      (changeValue)="onChangeDataSources()"
      (dataBound)="onGetTags($event)"
    ></itq-dropdown>
    @if (
      form.controls['dataSource']?.touched &&
      form.controls['dataSource']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data source is required </mat-error>
    }
  </div>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATA SLICER</span>
    <itq-dropdown
      [clearSelection]="false"
      formControlName="dataPoints"
      [dataSource]="dataPointsDataSource"
      (changeValue)="onChangeDatePoints()"
      [dataFields]="{ name: 'name', value: 'name' }"
    ></itq-dropdown>
    @if (
      form.controls['dataPoints']?.touched &&
      form.controls['dataPoints']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data slicer is required </mat-error>
    }
  </div>
  <h2 class="flex font-bold pt-7 pb-2 text-xl">Filters</h2>

  <div class="flex flex-col mb-4">
    <span class="mb-1 text-13 font-medium">TOP</span>
    <itq-dropdown
      formControlName="top"
      [clearSelection]="false"
      [dataSource]="topDataSource"
      (changeValue)="onChangeTop()"
      [dataFields]="{ name: 'name', value: 'value' }"
    ></itq-dropdown>
  </div>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      [dates]="this.dates"
      [openDirection]="{
        vertical: 'up',
        horizontal: 'right'
      }"
      [openPosition]="'outside'"
      (search)="onChangeDate($event)"
    ></itq-date-range>
  </div>
</div>

@if (segment && allowClearSegment) {
  <div class="flex items-center pb-2">
    <span class="font-semibold mr-2">Segment:</span>
    <mat-chip-set>
      <mat-chip>
        <div class="flex items-center justify-between">
          <span class="font-medium">{{ segment?.data?.name }}</span>
          <button matChipRemove (click)="onRemoveSegment()">
            <fa-icon
              class="text-black flex items-center"
              [icon]="['far', 'circle-xmark']"
            ></fa-icon>
          </button>
        </div>
      </mat-chip>
    </mat-chip-set>
  </div>
}
<div class="w-full flex h-full" #chartContainer>
  @if (!data || data?.totalHits > 0) {
    <div
      class="w-full h-full"
      echarts
      [options]="options"
      [theme]="theme"
      [autoResize]="true"
      (chartClick)="onChartClick($event)"
      (chartInit)="onChartInit($event)"
    ></div>
  } @else {
    @if (data && data?.totalHits === 0) {
      <itq-panel-info
        class="w-full"
        [label]="noResultsTitle"
        [message]="noResultsMessage"
        [icon]="'exclamation-circle'"
      ></itq-panel-info>
    }
  }
</div>

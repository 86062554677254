import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomOverlayRef } from '@intorqa-ui/core';
import { ProfileEditAction } from '@portal/profiles/enums/profile.enum';
import { ProfileMetadataGroups } from '@portal/profiles/enums/profiles-metadata.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileMetadata } from '@portal/profiles/models/profile-metadata';
import { ProfileTypeMetadata } from '@portal/profiles/models/profile-type-metadata';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';

@Component({
  selector: 'itq-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  @Input() navigationItem: ProfilesNavigationItem;
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() action: ProfileEditAction;
  @Input() closeOnUpdate = true;
  @Input() editMetadataAction: ProfileMetadataGroups;

  readonly ProfileEditAction = ProfileEditAction;

  constructor(
    private profileService: ProfileService,
    private snackBar: MatSnackBar,
    private customOverlayRef: CustomOverlayRef,
    readonly widgetSettingsService: WidgetSettingsService,
  ) {}

  ngOnInit() {}

  public onCancel(): void {
    this.navigationItem.action = WidgetActions.EXPLORE;
  }

  public onUpdate(): void {
    const dirtyValues = this.getDirtyValues();
    if (Object.keys(dirtyValues).length > 0) {
      this.widgetSettingsService.loader$.next(true);
      this.profileService
        .update(this.profile.profileId, dirtyValues)
        .subscribe((response: Profile) => {
          this.profile = response;
          this.widgetSettingsService.loader$.next(false);
          this.snackBar.open(
            'Your vendor has been updated, and is available for immediate use!',
            'Close',
            {
              horizontalPosition: 'right',
              duration: 5000,
              verticalPosition: 'top',
            },
          );
          if (this.closeOnUpdate) {
            this.customOverlayRef.close({ refresh: true });
          } else {
            this.navigationItem.action = WidgetActions.EXPLORE;
          }
        });
    } else {
      this.navigationItem.action = WidgetActions.EXPLORE;
    }
  }

  private getDirtyValues(): { [key: string]: any } {
    let dirtyValues = {};

    const detailsGroup = this.form.get('details') as FormGroup;
    Object.keys(detailsGroup.controls).forEach((key) => {
      let currentControl = detailsGroup.controls[key];

      if (currentControl.dirty) {
        dirtyValues[key] = currentControl.value;
      }
    });
    let metadata = this.getMetadata();
    dirtyValues['metadata'] = [...(dirtyValues['metadata'] || []), ...metadata];

    return dirtyValues;
  }

  private getMetadata(): Array<ProfileMetadata> {
    const metadataGroup = this.form.get('metadata') as FormGroup;
    let result: Array<ProfileMetadata> = [];
    Object.keys(metadataGroup.controls).forEach((item: string) => {
      let currentControl = metadataGroup.controls[item];
      if (currentControl.dirty) {
        // if (currentControl.value) {
        const typeMetadata = this.profileService.typeMetadata.find(
          (typeMetadata: ProfileTypeMetadata) => {
            return item === typeMetadata.id;
          },
        );
        result.push(
          new ProfileMetadata(
            typeMetadata.id,
            typeMetadata.name,
            JSON.stringify(metadataGroup.controls[item].value),
            typeMetadata.component,
            typeMetadata.groupName,
          ),
        );
        // }
      }
    });
    return result;
  }
}

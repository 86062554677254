<div [ngClass]="{ 'user-menu': true, open: this.open }">
  <itq-mat-button
    class="btn--add"
    [type]="'button'"
    [matTooltip]="'Settings'"
    [matTooltipPosition]="'below'"
    [align]="Align.START"
    [materialStyle]="'basic'"
    (clickEvent)="$event.stopPropagation(); open = !open"
  >
    <fa-icon
      [icon]="['far', 'user-circle']"
      class="text-white"
      [size]="size"
    ></fa-icon>
  </itq-mat-button>
  <ul [ngClass]="['user-menu-content', 'text-right', direction]">
    <li class="user" *ngIf="userProfile">
      <span class="name block"
        >{{ userProfile.firstName }} {{ userProfile.lastName }}</span
      >
      <span class="email block">{{ userProfile.email }}</span>
      <span class="type label-secondary"
        >{{ role
        }}<span class="type-super-admin" *ngIf="isSuperAdmin">
          (Intorqa Admin)</span
        ></span
      >
    </li>
    <li>
      <button type="button" class="btn btn-link" (click)="accountManagement()">
        My account
      </button>
    </li>
    <li>
      <button class="btn btn-link" type="button" (click)="logOut()">
        Sign out
      </button>
    </li>
  </ul>
</div>

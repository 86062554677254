import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Category, QueryFilters, TagCategory } from '@intorqa-ui/core';
import { QueryType } from '@portal/shared/enums/timeline-query.enum';
import { Query } from '@portal/shared/models/query-model';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';

@Component({
  selector: 'itq-timeline-query',
  templateUrl: './timeline-query.component.html',
  styleUrls: ['./timeline-query.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimelineQueryComponent),
    },
  ],
})
export class TimelineQueryComponent implements OnInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() widget: Timeline;
  @Input() queryType: QueryType;
  @Input() query: Query;
  @Input() dataSource: Array<Category>;
  @Input() required = true;
  @Input() initialState: QueryFilters;

  @Output() dataBound = new EventEmitter<Query>();
  @Output() clearFilters = new EventEmitter<Query>();

  readonly QueryType = QueryType;
  readonly TagCategory = TagCategory;

  private touched = false;
  private value: Query;

  constructor() {}

  onChange = () => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  writeValue(value: Query): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  ngOnInit(): void {}

  public onDataBound(query: Query): void {
    this.touched = true;
    this.onTouched(true);
    this.dataBound.emit(query);
  }

  public onClearFilters(query: Query): void {
    this.touched = true;
    this.onTouched(true);
    this.clearFilters.emit(query);
  }
}

<ng-container [formGroup]="form">
  <div
    class="connections__toolbar"
    *ngIf="navigationItem.action !== WidgetActions.CREATE"
  >
    <itq-pill [type]="PillType.CUSTOM" [class]="'secondary rounded-sm'">{{
      dataSource?.totalCount | number
    }}</itq-pill>
    <div
      class="content__switch"
      *ngIf="navigationItem.action === WidgetActions.EXPLORE"
    >
      <itq-mat-button
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        (clickEvent)="onExit()"
      >
        <fa-icon [icon]="['far', 'eye']"></fa-icon>
        <span class="text-wrapper">View content</span>
      </itq-mat-button>
      <itq-mat-button
        class="ml-x-sm"
        [style]="'primary'"
        [type]="'button'"
        [materialStyle]="'flat'"
        [padding]="Sizes.SM"
      >
        <fa-icon [icon]="['far', 'cog']"></fa-icon>
        <span class="text-wrapper">Settings</span>
      </itq-mat-button>
    </div>
    <div class="toolbar__wrapper">
      <itq-button-toggle
        class="collape__btn"
        [materialStyle]="'basic'"
        [padding]="Sizes.SM"
        [activeTooltip]="'Collapse filters'"
        [inactiveTooltip]="'Expand filters'"
        (clickEvent)="isFilterable = !isFilterable"
      >
        <fa-icon class="mr-1" [icon]="['far', 'filter-list']"></fa-icon>
        <span class="text-wrapper">Filters</span>
      </itq-button-toggle>
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onAddConnection()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="text-wrapper">Add connection</span>
      </itq-mat-button>
    </div>
  </div>
  <div class="connections__body">
    <itq-virtual-table
      *ngIf="
        navigationItem.action !== WidgetActions.CREATE
          ? true
          : action
            ? false
            : true
      "
      [ngStyle]="{
        flex: navigationItem.action !== WidgetActions.CREATE ? 1 : undefined
      }"
      [showFilters]="isFilterable"
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [dataSource]="dataSource?.items"
      [totalCount]="dataSource?.totalCount"
      [trackBy]="'id'"
      [footerTemplate]="
        navigationItem.action === WidgetActions.CREATE
          ? footerTemplate
          : undefined
      "
      [emptyMessage]="'You haven\'t added any connections yet!'"
      [emptyMessageDescription]="'To add a connection click the button below.'"
      (dataBound)="onDataBound($event)"
      (sort)="onDataBound(this.initialState)"
    ></itq-virtual-table>
    <ng-template #footerTemplate>
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="onAddConnection()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        <span class="text-wrapper">Add connection</span>
      </itq-mat-button>
    </ng-template>
    <ng-template #actionsButtonTemplate let-row let-index="index">
      <itq-nav-button
        [openDirection]="{ vertical: 'down', horizontal: 'left' }"
        [openPosition]="'inside'"
        [materialStyle]="'basic'"
        [padding]="Sizes.NONE"
        [size]="Sizes.BLOCK"
        [align]="Align.CENTER"
        [width]="72"
        [template]="actionsTemplate"
      >
        <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
      </itq-nav-button>
      <ng-template #actionsTemplate>
        <itq-mat-button
          [materialStyle]="'basic'"
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          (clickEvent)="onEditConnection(row)"
        >
          <fa-icon
            [icon]="['far', 'pencil']"
            class="mr-2"
            [size]="'lg'"
          ></fa-icon>
          Edit
        </itq-mat-button>
        <itq-mat-button
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          [materialStyle]="'basic'"
          (clickEvent)="onDeleteConnection(row)"
        >
          <fa-icon
            [icon]="['far', 'trash-alt']"
            class="mr-2"
            [size]="'lg'"
          ></fa-icon>
          Delete
        </itq-mat-button>
      </ng-template>
    </ng-template>
    <ng-template #dateTemplate let-row>
      <span [matTooltip]="row.updatedBy">
        {{ row.updatedDate | date: 'yyyy MMM dd' }}
      </span>
    </ng-template>
    <ng-template #ownerTemplate let-row>
      <itq-avatar [tooltip]="row.updatedBy">
        {{ row.updatedBy | getUsernameInitials }}
      </itq-avatar>
    </ng-template>
    <ng-container>
      <itq-add-connections
        *ngIf="action"
        [ngStyle]="{
          width:
            navigationItem.action !== WidgetActions.CREATE ? '380px' : undefined
        }"
        [ngClass]="{ 'ml-4': navigationItem.action === WidgetActions.EXPLORE }"
        [action]="action"
        [form]="form"
        [navigationItem]="navigationItem"
        [showTitle]="showTitle"
        [profile]="profile"
        [connection]="connection"
      ></itq-add-connections>
    </ng-container>
  </div>
</ng-container>

<div
  class="flex flex-col bg-white rounded-md border overflow-hidden border-border h-full hover:border hover:border-primary"
>
  <itq-timeline-widget-header
    [count]="data?.count"
    [widget]="widget"
    [board]="board"
    [timelineStatus]="timelineStatus"
    [initialState]="initialState"
    (delete)="onDeleteWidget()"
    (explore)="onExplore()"
  ></itq-timeline-widget-header>
  <div
    class="flex flex-1 overflow-hidden w-full border-t border-border"
    #chartContainer
  >
    <itq-timeline-widget-body
      class="flex w-full h-full"
      [timelineStatus]="timelineStatus"
      [widget]="widget"
      [data]="data"
      [noContentTitle]="noContentTitle"
      [noContentMessage]="noContentMessage"
      [segment]="segment"
      [query]="query"
      [initialState]="initialState"
      (databound)="onDataBound($event)"
      (drilldown)="onDrilldown($event)"
    ></itq-timeline-widget-body>
  </div>
</div>

@if (showLoader) {
  <itq-loader></itq-loader>
}

import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDropdownItem } from '@intorqa-ui/core';
import {
  MetadataFields,
  PricingModel,
} from '@portal/profiles/enums/profiles-metadata.enum';
import { ProfileMetadata } from '@portal/profiles/models/profile-metadata';
import { ProfileTypeMetadata } from '@portal/profiles/models/profile-type-metadata';
import { GetMetadataFieldPipe } from '@portal/profiles/pipes/vendors.pipe';

@Pipe({
  name: 'formatMetadataValues',
})
export class FormatMetadataValuesPipe implements PipeTransform {
  constructor() {}

  transform(data: Array<string>): Array<IDropdownItem> {
    return data.map((item: string) => ({ name: item, value: item }));
  }
}

@Pipe({
  name: 'getMetadataComponentDisabled',
})
export class GetMetadataComponentDisabledPipe implements PipeTransform {
  constructor(private getMetadataField: GetMetadataFieldPipe) {}

  transform(
    form: FormGroup,
    metadata: Array<ProfileMetadata>,
    profileMetadata: ProfileTypeMetadata,
  ): boolean {
    if (
      profileMetadata.name === MetadataFields.PRICING_CURRENCY ||
      profileMetadata.name === MetadataFields.PRICE_PER_MONTH
    ) {
      const metadataForm = form.get('metadata');
      const pricingModel = this.getMetadataField.transform(
        metadata,
        MetadataFields.PRICING_MODEL,
      );
      const pricingModelFormControl = metadataForm.get(pricingModel?.id);
      return pricingModelFormControl.value === PricingModel.FREE;
    }
    return false;
  }
}

<div class="flex w-full items-center justify-between flex-col">
  <div
    class="flex items-center bg-white rounded-md border border-border w-full p-7"
  >
    <div class="flex items-center flex-1">
      <fa-icon
        class="pr-2"
        [icon]="['far', 'calendar-plus']"
        [size]="'3x'"
      ></fa-icon>
      <span class="font-bold text-28">Events</span>
      @if (dataSource?.totalCount) {
        <itq-pill
          class="pl-4"
          [class]="
            'rounded-2xl px-4 py-2  shadow-none font-semibold bg-primary-over'
          "
          [type]="PillType.CUSTOM"
          >{{ dataSource.totalCount | number }}</itq-pill
        >
      }
    </div>
    <div class="flex flex-1">
      This area contains all the events that have been created for this
      ecosystem.<br />
      Here you can search for the event you need, create new events or delete
      events that are no longer required.
    </div>
  </div>
</div>
<div class="flex justify-between my-7 w-full items-center">
  <itq-mat-button
    [materialStyle]="'stroked'"
    [style]="initialState?.columnFilters?.length > 0 ? 'primary' : 'basic'"
    [type]="'button'"
    (clickEvent)="showFilters = !showFilters"
  >
    <fa-icon [icon]="['far', 'filter-list']"></fa-icon>
    FILTERS
    @if (initialState?.columnFilters?.length > 0) {
      <span class="ml-1"> ({{ initialState?.columnFilters?.length }})</span>
    }
  </itq-mat-button>
  <itq-mat-button
    [materialStyle]="'flat'"
    [style]="'primary'"
    [type]="'button'"
    (clickEvent)="onCreateEvent()"
  >
    <fa-icon [icon]="['far', 'plus']"></fa-icon>
    CREATE EVENT
  </itq-mat-button>
</div>
@if (dataSource) {
  <div class="flex overflow-hidden flex-1">
    <itq-virtual-table
      [tableColumns]="tableColumns"
      [initialState]="initialState"
      [showFilters]="showFilters"
      [dataSource]="dataSource?.items | formatEvents"
      [totalCount]="dataSource?.totalCount"
      [trackBy]="'id'"
      [emptyMessage]="'No events found!'"
      [emptyMessageDescription]="'Please update search term.'"
      (dataBound)="onDataBound()"
      (sort)="onDataBound()"
    ></itq-virtual-table>
  </div>
}

<ng-template #actionsButtonTemplate let-row let-index="index">
  <div class="flex justify-center">
    <itq-nav-button
      [openDirection]="{ vertical: 'down', horizontal: 'left' }"
      [openPosition]="'inside'"
      [materialStyle]="'stroked'"
      [width]="180"
      [template]="actionsTemplate"
      [matTooltip]="'More actions'"
      [matTooltipPosition]="'below'"
    >
      <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
    </itq-nav-button>
  </div>
  <ng-template #actionsTemplate>
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [align]="Align.START"
      [padding]="Sizes.NONE"
      [materialStyle]="'basic'"
      (clickEvent)="onEdit(row)"
    >
      <fa-icon class="pr-2" [icon]="['far', 'pencil']" [size]="'lg'"></fa-icon>
      Edit
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [align]="Align.START"
      [materialStyle]="'basic'"
      [padding]="Sizes.NONE"
      (clickEvent)="onDelete(row)"
    >
      <fa-icon
        class="pr-2"
        [icon]="['far', 'trash-alt']"
        [size]="'lg'"
      ></fa-icon>
      Delete
    </itq-mat-button>
  </ng-template>
</ng-template>

<ng-template #updatedByTemplate let-row>
  <itq-avatar [tooltip]="row.createdBy">
    {{ row.updatedBy | getUsernameInitials }}
  </itq-avatar>
</ng-template>

<ng-template #teamTemplate let-row>
  @if (row.team === 'Security Team') {
    <itq-pill
      [class]="
        'rounded-2xl px-4 py-2 shadow-none font-semibold bg-danger text-white'
      "
      [type]="PillType.CUSTOM"
      >{{ row.team }}</itq-pill
    >
  } @else {
    <itq-pill
      [class]="
        'rounded-2xl px-4 py-2 shadow-none font-semibold bg-link text-white'
      "
      [type]="PillType.CUSTOM"
      >{{ row.team }}</itq-pill
    >
  }
</ng-template>

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import {
  CastIconNamePipe,
  CastIconSizePipe,
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DateQueryType,
  Ecosystem,
  FAwesomeModule,
  HelpComponent,
  IconType,
  QueryFilters,
  SharedService,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { BoardService } from '@portal/boards/services/board.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { UserService } from '@portal/shared/services/user.service';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { NavigationHistoryItem } from './../../../widget-settings/models/navigation-history-item.model';
import { AnalysisTypes, WidgetActions } from './../../enums/widget.enum';

@Component({
  selector: 'itq-default-navigation',
  templateUrl: './default-navigation.component.html',
  styleUrls: ['./default-navigation.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    MatTooltipModule,
    FAwesomeModule,
    CommonModule,
    RouterModule,
    SvgComponent,
    CastIconNamePipe,
    CastIconSizePipe,
    FormsModule,
  ],
})
export class DefaultNavigationComponent implements OnInit {
  readonly Sizes = Sizes;
  readonly IconType = IconType;

  public unreadStatus: boolean;
  private subscriptions = new Subscription();
  public ecosystemDataSource: Array<Ecosystem> = [];
  public ecosystemId: string;
  private interval: NodeJS.Timeout;

  @Output() loadNotifications = new EventEmitter<MouseEvent>();
  @Output() closeNotifications = new EventEmitter<void>();

  constructor(
    public sharedService: SharedService,
    private customOverlayService: CustomOverlayService,
    readonly notificationsService: NotificationsService,
    readonly keycloakService: KeycloakService,
    readonly ecosystemsService: EcosystemsService,
    readonly userService: UserService,
    readonly boardService: BoardService,
    readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.getUnreadStatus();
    this.bindUnreadCountSubscription();
    this.ecosystemDataSource = this.ecosystemsService.ecosystems;
    this.ecosystemId = this.userService.userPreferences.defaultEcosystemId;
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.subscriptions.unsubscribe();
  }

  private bindUnreadCountSubscription(): void {
    this.interval = setInterval(() => {
      this.getUnreadStatus();
    }, 30000);
  }

  private getUnreadStatus(): void {
    this.notificationsService.getUnread().subscribe((response: boolean) => {
      this.unreadStatus = response;
    });
  }

  public onLoadHelp(): void {
    this.customOverlayService.open({
      closeBtnStyle: 'basic',
      closeBtnClass: 'help_close__btn',
      type: CustomOverlayType['almost-full'],
      size: 'lg',
      component: HelpComponent,
      disposeOnNavigation: true,
    });
  }

  public isMobileDevice(): boolean {
    return this.sharedService.mobile || this.sharedService.tablet;
  }

  public onLoadNotifications(event: MouseEvent): void {
    this.loadNotifications.emit(event);
  }

  public onCreateSearch(): void {
    const widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      'Search',
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new NavigationHistoryItem(
      `${widget.widgetId}_search_${WidgetActions.DRILLDOWN}`,
      widget,
      WidgetActions.DRILLDOWN,
      undefined,
      new QueryFilters(
        30,
        1,
        this.boardService.board?.filter?.date || {
          label: DateQueryType.LastMonth,
        },
        undefined,
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'search',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      this.boardService.board?.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  removeRouteParam() {
    // Get the current route
    let currentRoute = this.router.url.split('/');
    let lastSegment = currentRoute[currentRoute.length - 1];

    // Check if the last segment is a UUID
    let uuidRegex =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    if (uuidRegex.test(lastSegment)) {
      currentRoute.pop(); // Remove the last segment (id)

      // Navigate to the same route without the id
      this.router.navigate(currentRoute, { replaceUrl: true });
    }
  }

  public onChangeEcosystem(): void {
    this.userService.setDefaultEcosystem(this.ecosystemId).subscribe(() => {
      this.removeRouteParam();
      this.ecosystemsService.changeEcosystem$.next();
    });
  }
}

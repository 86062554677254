import { Component, Input, OnInit } from "@angular/core";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: "itq-modal-header",
  templateUrl: "./modal-header.component.html",
  styleUrls: ["./modal-header.component.scss"],
})
export class ModalHeaderComponent implements OnInit {
  @Input() icon: IconProp;
  @Input() size: SizeProp;

  constructor() {}

  ngOnInit(): void {}
}

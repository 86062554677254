<div class="flex flex-col h-full" [formGroup]="form">
  <h2 class="flex font-bold pt-7 pb-2 text-xl">Data source</h2>
  <div class="flex flex-col overflow-hidden">
    <span class="mb-1 text-13 font-medium">TAG</span>
    <itq-chart-wizard-tags-list
      [formControlName]="'dataSource'"
      [dataSource]="tagsDataSource"
      [dataFields]="{ name: 'name', value: 'id' }"
      [initialState]="initialState"
      (changeValue)="onChangeDataSources($event)"
      (search)="onDataBoundGetTags($event)"
    ></itq-chart-wizard-tags-list>
    @if (
      form.controls['dataSource']?.touched &&
      form.controls['dataSource']?.hasError('required')
    ) {
      <mat-error class="text-13 pt-1"> Data slicer is required </mat-error>
    }
  </div>
  <h2 class="flex font-bold pt-7 pb-2 text-xl">Filters</h2>
  <div class="flex flex-col">
    <span class="mb-1 text-13 font-medium">DATE RANGE</span>
    <itq-date-range
      class="w-full"
      [dates]="this.dates"
      [openDirection]="{
        vertical: 'up',
        horizontal: 'right'
      }"
      [openPosition]="'outside'"
      (search)="onChangeDate($event)"
    ></itq-date-range>
  </div>
</div>

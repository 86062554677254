import {
  Component,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CoreModule,
  DynamicPlaceholderDirective,
  QueryFilters,
} from '@intorqa-ui/core';
import { Widget } from '@portal/boards/models/widgets/widget';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { WidgetSettingsFactory } from '@portal/widget-settings/widget-settings.factory';

@Component({
  selector: 'itq-chart-explore',
  templateUrl: './chart-explore.component.html',
  styleUrls: ['./chart-explore.component.scss'],
  standalone: true,
  imports: [CoreModule],
})
export class ChartExploreComponent implements OnInit {
  @Input() widget: Widget;
  @Input() form: FormGroup;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() segment: any;
  @Input() initialState: QueryFilters;

  private componentRef: ComponentRef<any>;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  constructor() {}

  ngOnInit() {
    this.createComponent();
  }

  private createComponent(): void {
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const dynamicComponent = WidgetSettingsFactory.getExploreComponent(
      this.widget.type,
    );
    this.componentRef = viewContainerRef.createComponent(dynamicComponent);
    this.componentRef.instance.widget = this.widget;
    this.componentRef.instance.form = this.form;
    this.componentRef.instance.navigationItem = this.navigationItem;
    this.componentRef.instance.segment = this.segment;
    this.componentRef.instance.initialState = this.initialState;
  }
}

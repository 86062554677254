import { ChartOrientation, ChartType } from '@portal/shared/enums/chart.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

export const TOP_CHANNELS_CHART_TYPES: Array<IDisplayType> = [
  {
    id: 'Table',
    type: ChartType.TABLE,
    icon: ['far', 'table'],
    tooltip: 'Table',
  },
  {
    id: 'VerticalBar',
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.VERTICAL,
    },
    svgIcon: 'vertical-bar',
    tooltip: 'Vertical Bar',
  },
  {
    id: 'HorizontalBar',
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.HORIZONTAL,
    },
    svgIcon: 'horizontal-bar',
    tooltip: 'Horizontal Bar',
  },
];

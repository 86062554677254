import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  Category,
  CoreModule,
  DateRangeComponent,
  IPresetQuery,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { TopOptions } from '@portal/boards/const/widget.const';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetService } from '@portal/boards/services/widget.service';
import { ICustomTag } from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { Tag } from '@portal/shared/models/tag';
import { CategoryService } from '@portal/shared/services/category.service';
import { TagService } from '@portal/shared/services/tag.service';
import { UserService } from '@portal/shared/services/user.service';

@Component({
  selector: 'itq-tag-analysis-metrics',
  templateUrl: './tag-analysis-metrics.component.html',
  styleUrls: ['./tag-analysis-metrics.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    MatFormFieldModule,
    DateRangeComponent,
  ],
})
export class TagAnalysisMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.tagAnalysisWidget = widget as TagAnalysis;
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public dataPointsDataSource: Array<Category> = [];
  public tagsDataSource: Array<ICustomTag>;
  public topDataSource = TopOptions;
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  public tagAnalysisWidget: TagAnalysis;

  constructor(
    private categoryService: CategoryService,
    public widgetService: WidgetService,
    readonly tagService: TagService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.addControls();
    this.onGetTags(this.initialState);
    this.getTagById();
    if (this.tagAnalysisWidget.widgetId) {
      this.getCategories();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataSource');
    this.form.removeControl('dataPoints');
    this.form.removeControl('top');
  }

  public getTagById(): void {
    if (this.tagAnalysisWidget?.widgetId) {
      this.tagService
        .getTagById(this.tagAnalysisWidget.dataSource[0])
        .subscribe((tag: Tag) => {
          this.form.patchValue({
            dataSource: {
              name: tag.name,
              id: tag.tagId,
            },
          });
        });
    }
  }

  public onGetTags(params: QueryFilters): void {
    this.initialState = params;
    this.categoryService
      .getTags(
        params.query,
        params,
        new Query().modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        params.page > 1
          ? this.tagsDataSource[this.tagsDataSource.length - 1].name
          : undefined,
      )
      .then((response: { items: Array<ICustomTag>; totalCount: number }) => {
        this.tagsDataSource =
          params.page > 1
            ? [...this.tagsDataSource, ...response.items]
            : response.items;
      });
  }

  private getCategories(): void {
    this.tagService
      .getCategories(this.userService.userPreferences.defaultEcosystemId)
      .subscribe((response: Array<Category>) => {
        this.dataPointsDataSource = response?.filter(
          (item: Category) => item.name !== TagCategory['My Tags'],
        );
        if (this.tagAnalysisWidget.dataPoints?.length > 0) {
          this.form.patchValue({
            dataPoints: this.tagAnalysisWidget.dataPoints[0],
          });
          this.form.get('dataPoints').enable();
        }
      });
  }

  private addControls(): void {
    this.form.addControl(
      'dataSource',
      new FormControl(undefined, [Validators.required]),
    );
    this.form.addControl(
      'dataPoints',
      new FormControl(
        {
          value: undefined,
          disabled: this.tagAnalysisWidget?.dataPoints?.length === 0,
        },
        [Validators.required],
      ),
    );
    this.form.addControl(
      'top',
      new FormControl(this.tagAnalysisWidget.top, [Validators.required]),
    );
  }

  public onChangeTop(): void {
    this.tagAnalysisWidget.top = this.form.controls.top.value;
    this.widgetService.updateWidget$.next({
      widget: this.tagAnalysisWidget,
      dates: this.dates,
      reloadData: true,
    });
  }

  public onChangeDataSources(): void {
    this.getCategories();
    this.form.get('dataPoints').enable();
    this.form.get('dataPoints').setValue([]);
    this.tagAnalysisWidget.dataPoints = [];
    this.tagAnalysisWidget.dataSource = [
      this.form.controls.dataSource.value.id,
    ];
    this.updateFormStatus();
    this.widgetService.updateWidget$.next({
      widget: this.tagAnalysisWidget,
      dates: this.dates,
      reloadData: true,
    });
  }

  private updateFormStatus(): void {
    if (
      this.form.get('dataPoints').value &&
      this.form.get('dataSource').value
    ) {
      this.form.get('name').enable();
      this.form.get('description').enable();
    }
  }

  public onChangeDatePoints(): void {
    this.tagAnalysisWidget.dataPoints = [this.form.controls.dataPoints.value];
    this.updateFormStatus();
    this.widgetService.updateWidget$.next({
      widget: this.tagAnalysisWidget,
      dates: this.dates,
      reloadData: true,
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.dates = params;
    this.initialState.where = this.dates;
    this.widgetService.updateWidget$.next({
      widget: this.tagAnalysisWidget,
      dates: this.dates,
      reloadData: true,
    });
  }
}

@if (data) {
  <p>This tag cannot be deleted as it is being used by:</p>
  <div class="dependency__container" *ngIf="data?.tags?.length > 0">
    <span><strong>The following tags: </strong></span>
    <mat-chip-listbox>
      <mat-chip-option *ngFor="let item of data?.tags">
        {{ item.name }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <div class="dependency__container" *ngIf="data?.boards?.length > 0">
    <span class="dependency__label"
      ><strong>The following boards: </strong></span
    >
    <mat-chip-listbox>
      <mat-chip-option *ngFor="let item of data?.boards">
        {{ item.name }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <div class="dependency__container" *ngIf="data?.widgets?.length > 0">
    <span><strong>The following widgets: </strong></span>
    <mat-chip-listbox>
      <mat-chip-option *ngFor="let item of data?.widgets">
        {{ item.name }}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
}

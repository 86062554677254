import { VirtualScrollService } from './../../../services/virtual-scrolling.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { QueryFilters } from '../../../models/query-filters';
import { IDropdownItem } from '../../dropdown/dropdown.interface';
import { PillType } from '../../pill/pill.enum';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'itq-multiple-dropdown-results',
  templateUrl: './multiple-dropdown-results.component.html',
  styleUrls: ['./multiple-dropdown-results.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultipleDropdownResultsComponent,
    },
  ],
})
export class MultipleDropdownResultsComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() width: number;
  @Input() direction = 'down';
  @Input() dataSource: Array<any>;
  @Input() selections: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() query: string;
  @Input() initialState: QueryFilters;
  @Input() emptyDataSourceMessage = 'No results found!';
  @Input() httpBinding = false;

  @Output() changeValue = new EventEmitter<IDropdownItem>();
  @Output() dataBound = new EventEmitter<QueryFilters>();
  @Output() scroll = new EventEmitter<QueryFilters>();

  @ViewChild('inputControl') inputControl: ElementRef;
  @ViewChild('listSearch', { read: ElementRef }) listSearch: ElementRef;

  private keyupSubscription: Subscription;
  public results: Array<any>;
  private scrollTop = 0;
  private dataBoundSubscription: Subscription;
  public showLoader: boolean;
  public disabled = false;
  private touched = false;

  readonly PillType = PillType;

  constructor(private virtualScrollService: VirtualScrollService) {}

  onChange = (items: any) => {};

  onTouched = () => {};

  writeValue(items: any): void {
    this.selections = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnInit(): void {
    this.dataBoundSubscription =
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        this.scrollTop = 0;
        this.initialState.page = 1;
        this.listSearch?.nativeElement?.scrollTo(0, 0);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataSource?.currentValue) {
      this.results = cloneDeep(this.dataSource);
      this.showLoader = false;
    }
  }
  ngOnDestroy(): void {
    this.dataBoundSubscription.unsubscribe();
    this.keyupSubscription?.unsubscribe();
  }

  public onChangeValue(item: IDropdownItem): void {
    this.changeValue.emit(item);
  }

  public onFilter(filter: boolean): void {
    this.initialState.query = this.query;
    this.results = cloneDeep(this.dataSource);
    if (filter) {
      if (this.httpBinding) {
        this.showLoader = true;
        this.onDataBound();
      } else {
        const value = this.query?.toLowerCase().trim();
        this.results = this.results.filter((elem: any) => {
          return elem[this.dataFields.name]
            .toLowerCase()
            .trim()
            .includes(value);
        });
      }
    }
  }

  public onDataBound(): void {
    this.dataBound.emit(this.initialState);
  }

  public trackByFn(index: number, item: any): any {
    return index;
  }

  public isChecked(item: any): boolean {
    return this.selections.includes(item[this.dataFields.value]) ? true : false;
  }

  public onScroll(): void {
    if (this.initialState && this.httpBinding) {
      this.virtualScrollService
        .scrollDown(
          this.listSearch.nativeElement,
          this.initialState.pageSize,
          this.dataSource.length,
          this.scrollTop,
          this.showLoader,
          this.initialState.page,
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.onDataBound();
          }
        });
    }
  }
}

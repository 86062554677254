import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { IError } from "./error.interface";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  public errorObservable: Subject<IError> = new Subject();
  public error: IError;

  constructor() {}
}

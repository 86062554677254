<textarea
  #textarea
  [name]="name"
  [disabled]="disabled"
  class="w-full p-2"
  [rows]="rows"
  [(ngModel)]="query"
  [placeholder]="placeholder"
  (blur)="onBlur()"
  (keyup)="onKeyUp()"
></textarea>

export enum MetadataFields {
  FIRST_SIGHTED = "First Seen",
  CHEAT_REPUTATION_SCORE = "Cheat Reputation Score",
  PRIORITY = "Priority",
  CHEAT_STATUS = "Cheat Status",
  CHEAT_FEATURE = "Cheat Features",
  PRICING_MODEL = "Pricing Model",
  PRICING_CURRENCY = "Pricing currency",
  PRICE_PER_MONTH = "Price Per Month",
  PRODUCT_FOCUS = "Product Focus",
  PAYMENTS_SUPPORTED = "Payments Supported",
  PLATFORMS_AND_TOOLS = "Platforms and Tools",
  RECENT_POSTS = "Recent posts",
}

export enum MetadataIcons {
  "Vendor Details" = "database",
  "Actor Details" = "user",
  "Geo Data" = "map-location",
  "Supporting Platforms" = "life-ring",
  "Pricing" = "dollar-sign",
  "Details" = "gamepad",
}

export enum PricingModel {
  FREE = "Free",
  SUBSCRIPTION = "Subscription",
  ONE_OFF_PAYMENT = "One-off Payment",
}

export enum ProfileMetadataGroups {
  INFO = "Info",
  CHEAT_DETAILS = "Details",
  PRICING = "Pricing",
}

<li class="pl-4">
  <div [ngClass]="{ noresults: item?.count === 0 }">
    <mat-checkbox
      (click)="$event.stopImmediatePropagation()"
      (change)="onToggle(item)"
      [checked]="checked"
      [color]="'primary'"
    >
      <span [ngClass]="{ noresults: item?.count === 0 }">
        <span> {{ item?.name }} </span
        ><strong *ngIf="item.count">({{ item?.count }})</strong>
      </span>
    </mat-checkbox>
  </div>
</li>

<itq-mat-button
  #button
  [size]="Sizes.BLOCK"
  [type]="'button'"
  [padding]="padding"
  [disabled]="disabled"
  [ngClass]="{ invalid }"
  [style]="color"
  [materialStyle]="materialStyle"
  (click)="$event.stopImmediatePropagation()"
  (clickEvent)="onClick()"
>
  <div class="flex justify-between items-center w-full">
    <div class="wrapper">
      <div class="wrapper__container" #buttonWrapper>
        @if (selections?.length > 0) {
          <div class="dropdown-container__selections">
            <ng-container *ngFor="let item of selections; let i = index">
              @if (i < maxNumberSelections) {
                <itq-pill
                  [class]="'secondary'"
                  [type]="PillType.BUTTON"
                  [stopPropagation]="true"
                  (select)="onChangeValue(item)"
                >
                  <span>
                    {{ item[this.dataFields.name] || item }}
                  </span>
                  <fa-icon
                    [icon]="['far', 'circle-xmark']"
                    [size]="'sm'"
                  ></fa-icon>
                </itq-pill>
              }
            </ng-container>
            @if (selections?.length > maxNumberSelections) {
              <fa-icon
                class="'more__icon"
                [icon]="['far', 'circle-ellipsis']"
                [size]="'lg'"
                (mouseover)="onToggle($event, true)"
                (mouseout)="onToggle($event, false)"
              ></fa-icon>
            }
          </div>
        } @else {
          <span class="font-normal">{{ placeholder }} </span>
        }
      </div>
      <fa-icon
        [icon]="open ? ['far', 'chevron-up'] : ['far', 'chevron-down']"
      ></fa-icon>
    </div>
    @if (showLoader) {
      <itq-loader [type]="'controls'"></itq-loader>
    }
  </div>
</itq-mat-button>
<ng-template #portalContent>
  <div
    [ngClass]="{
      navigation__container: true,
      block: open,
      invisible: !open
    }"
  >
    <itq-multiple-dropdown-results
      [ngStyle]="{ 'width.px': dropdownWidth }"
      [ngClass]="{
        down: direction === 'down',
        up: direction === 'up'
      }"
      [width]="width"
      [direction]="direction"
      [query]="query"
      [dataSource]="dataSource"
      [initialState]="initialState"
      [selections]="selections"
      [emptyDataSourceMessage]="emptyDataSourceMessage"
      [dataFields]="dataFields"
      [httpBinding]="httpBinding"
      [pageSize]="pageSize"
      (dataBound)="onDataBound($event)"
      (changeValue)="onChangeValue($event)"
      (scroll)="onDataBound($event)"
    >
    </itq-multiple-dropdown-results>
  </div>
</ng-template>

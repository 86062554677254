import { ChartUtils } from '@intorqa-ui/core';
import { ProfilePathSvg } from '@portal/profiles/enums/profile.enum';
import { EChartsOption } from 'echarts';
import { Chart } from './chart';

export class NetworkChart extends Chart {
  constructor() {
    super();
  }

  public getOptions(
    data: any,
    settings?: { [key: string]: any },
  ): EChartsOption {
    let options: EChartsOption;
    data = data[0].data[0];
    options = {
      animationDurationUpdate: 1500,
      tooltip: {},
      animationEasingUpdate: 'cubicOut',
      textStyle: ChartUtils.getTextStyle(),
      series: this.getSeries(data),
    };

    return options;
  }

  private getSeries(data: any): { [key: string]: any } {
    return [
      {
        name: 'Connections',
        type: 'graph',
        layout: 'none',
        data: data.nodes.map((node) => {
          return {
            ...node,
            symbol: `path://${ProfilePathSvg[node.profileType]}` || 'circle', // Use the SVG path for the node type, or fallback to 'circle'
          };
        }),
        categories: data.categories,
        edges: data.links,
        animation: false,
        force: {
          edgeLength: 5,
          repulsion: 20,
          gravity: 0.2,
        },
        edgeLabel: {
          show: true,
          position: 'middle',
          formatter: (value: any) => {
            return value.data.connectionType;
          },
          fontSize: 12,
        },
        roam: true,
        label: {
          show: true,
          position: 'right',
          formatter: '{b}',
        },
        scaleLimit: {
          min: 0.4,
          max: 2,
        },
      },
    ];
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Sections, TagCategory } from '@intorqa-ui/core';
import { ITagMetadata } from '@portal/shared/interfaces/tag.interface';

@Pipe({
  name: 'filterByCategory',
})
export class FilterByCategoryPipe implements PipeTransform {
  constructor() {}

  transform(
    data: Array<ITagMetadata>,
    category: TagCategory,
    section: Sections,
  ): Array<ITagMetadata> {
    return data?.filter((item: ITagMetadata) => {
      if (!item.categoryName) {
        return item.section === section;
      } else {
        return item.categoryName === category;
      }
    });
  }
}

@Pipe({
  name: 'getIncludedSelections',
})
export class GetIncludedSelectionsPipe implements PipeTransform {
  constructor() {}

  transform(data: Array<ITagMetadata>): Array<ITagMetadata> {
    return data.filter((item: ITagMetadata) => item.included);
  }
}

@Pipe({
  name: 'getExcludedSelections',
})
export class GetExcludedSelectionsPipe implements PipeTransform {
  constructor() {}

  transform(data: Array<ITagMetadata>): Array<ITagMetadata> {
    return data.filter((item: ITagMetadata) => item.excluded);
  }
}

import { TemplatePortal } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

interface DropdownPosition {
  left: number;
  top: number;
}
interface DropdownRequest {
  action: string;
  position?: DropdownPosition;
}
@Injectable({
  providedIn: "root",
})
export class PortalBridgeService {
  private activePortal = new Subject<TemplatePortal>();
  public tooltip$ = new Subject<{
    show: boolean;
    top: number;
    left: number;
    content: string;
  }>();
  private _dropdownObeservable = new Subject<DropdownRequest>();
  readonly _portal = this.activePortal.asObservable();

  set portal(value: TemplatePortal) {
    this.activePortal.next(value);
  }

  get dropdownObeservable(): Subject<DropdownRequest> {
    return this._dropdownObeservable;
  }

  constructor() {}

  toggle(action: string, position?: DropdownPosition): void {
    this.dropdownObeservable.next({
      action,
      position,
    });
  }

  toggleTooltip(params: {
    show: boolean;
    top: number;
    left: number;
    content: string;
  }): void {
    this.tooltip$.next(params);
  }
}

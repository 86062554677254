import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Sizes } from '../../enums/shared.enum';
import { PillType } from '../pill/pill.enum';
import { IPill } from '../pill/pill.interface';
import { PortalBridgeService } from '../../services/portal-bridge.service';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'itq-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
})
export class TagsListComponent implements OnInit {
  @Input() disabled = false;
  @Input() label: string;
  @Input() action: boolean;
  @Input() maxSelections: number;
  @Input() size: Sizes;
  @Input() dataSource: Array<IPill>;

  @Output() clickEvent = new EventEmitter<{
    item: IPill;
    action: boolean;
  }>();

  public PillType = PillType;
  @ViewChild('portalContent') portalContent: TemplateRef<unknown>;

  constructor(
    private portalBridgeService: PortalBridgeService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {}

  onClick(item: IPill): void {
    this.clickEvent.emit({
      item,
      action: this.action,
    });
  }

  onToggle(event: any, show: boolean): void {
    this.loadPortal();
    let htmlString = "<div class='tooltip_container'>";
    for (let i = this.maxSelections; i < this.dataSource.length; i++) {
      htmlString += `<div>${this.dataSource[i].name}</div>`;
    }
    htmlString += '</div>';
    const target = event.currentTarget.getBoundingClientRect();
    this.portalBridgeService.toggleTooltip({
      show: show,
      top: target.top,
      left: target.left,
      content: htmlString,
    });
  }

  loadPortal(): void {
    const portal = new TemplatePortal(
      this.portalContent,
      this.viewContainerRef,
    );
    this.portalBridgeService.portal = portal;
  }
}

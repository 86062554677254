<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'metadata'">
    <mat-accordion class="default" [multi]="false">
      <ng-container *ngFor="let group of metadata; let i = index">
        <mat-expansion-panel
          [hideToggle]="false"
          [expanded]="
            group.name === editAction
              ? true
              : !editAction && i === 0
              ? true
              : false
          "
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="group__name_title"
                ><fa-icon
                  class="mr-2"
                  [icon]="['far', MetadataIcons[group.name]]"
                ></fa-icon
                >{{ group.name }}</span
              ></mat-panel-title
            >
          </mat-expansion-panel-header>
          <div class="controls__container">
            <ng-container #dynamicComponentContainer></ng-container>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </ng-container>
</ng-container>

import { Pipe, PipeTransform } from '@angular/core';
import { DateQueryType } from '@intorqa-ui/core';
import { Moment } from 'moment';
import { IPreset } from '../../../../interfaces/date-range.interface';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'getSelected',
})
export class GetSelectedPipe implements PipeTransform {
  transform(item: DateQueryType, dateRange?: IPreset): boolean {
    if (!dateRange) {
      return item === DateQueryType.Custom ? true : false;
    }
    return item === dateRange.label ? true : false;
  }
}

@Pipe({
  name: 'formatMomentDates',
  standalone: true,
})
export class FormatMomentDatesPipe implements PipeTransform {
  constructor(readonly datePipe: DatePipe) {}

  transform(date: Moment): string {
    return this.datePipe.transform(date.toDate(), 'dd-MM-yyyy');
  }
}

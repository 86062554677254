<div class="item-detail-container" #itemdetailcontainer>
  @if (showParent) {
    <div class="document__item_tile">
      <itq-document-item-detail
        *ngIf="replyParent"
        [segment]="{ scope: segment.scope, value: replyParent }"
        [viewParent]="false"
        [profile]="profile"
        [form]="form"
        [navigationItem]="navigationItem"
        (drilldown)="
          allowDrilldown
            ? onDrilldown($event.scope, $event.value, replyParent)
            : undefined
        "
      ></itq-document-item-detail>
      <div class="document__item_separator"></div>
    </div>
  }

  <div class="document-item-detail-container">
    <div *ngIf="allowDrilldown" class="actions__container">
      <itq-mat-button
        *ngIf="segment.value.emitSource === 'Discord'"
        [type]="'button'"
        [padding]="Sizes.NONE"
        [materialStyle]="'basic'"
        matTooltip="View context"
        [matTooltipPosition]="'below'"
        (clickEvent)="onViewContext()"
      >
        <fa-icon [icon]="['far', 'list-timeline']"></fa-icon>
      </itq-mat-button>
      <ng-container *ngIf="keycloakService.isUserInRole('saas-profiles')">
        <itq-mat-button
          [type]="'button'"
          [padding]="Sizes.NONE"
          [materialStyle]="'basic'"
          matTooltip="Add note"
          [matTooltipPosition]="'below'"
          (clickEvent)="onAddNote()"
        >
          <fa-icon [icon]="['far', 'note']"></fa-icon>
        </itq-mat-button>
      </ng-container>
      <itq-mat-button
        *ngIf="profile"
        [type]="'button'"
        [padding]="Sizes.NONE"
        [materialStyle]="'basic'"
        matTooltip="Add connection"
        [matTooltipPosition]="'below'"
        (clickEvent)="onAddConnection()"
      >
        <fa-icon [icon]="['far', 'code-merge']"></fa-icon>
      </itq-mat-button>
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.NONE"
        [materialStyle]="'basic'"
        matTooltip="Share to slack"
        [matTooltipPosition]="'below'"
        (clickEvent)="shareSlack($event)"
      >
        <fa-icon [icon]="['fab', 'slack']"></fa-icon>
      </itq-mat-button>
      <itq-mat-button
        [type]="'button'"
        [padding]="Sizes.NONE"
        [materialStyle]="'basic'"
        (click)="onShare($event, segment.value)"
        matTooltip="Share by email"
        [matTooltipPosition]="'below'"
      >
        <fa-icon [icon]="['far', 'envelope']"></fa-icon>
      </itq-mat-button>
      <itq-pill
        *ngIf="repliesCount"
        [class]="' rounded-md'"
        (select)="
          onDrilldown(SegmentScope.REPLIES, undefined, this.segment.value)
        "
      >
        {{
          repliesCount > 1 ? repliesCount + ' replies' : repliesCount + ' reply'
        }}
      </itq-pill>
    </div>
    <div
      *ngIf="viewParent && segment.value.emitReplyToId"
      class="document__item_separator-small"
    >
      <itq-mat-button
        [padding]="Sizes.SM"
        [materialStyle]="'flat'"
        [matTooltip]="'View parent post'"
        [matTooltipPosition]="'above'"
        [style]="'primary'"
        (clickEvent)="onShowReply()"
      >
        <fa-icon
          [icon]="['far', showParent ? 'chevrons-down' : 'chevrons-up']"
        ></fa-icon>
      </itq-mat-button>
    </div>
    <div
      [ngClass]="{
        replyParent: replyParent,
        'document-item-detail-header': true
      }"
    >
      <itq-headline-simple
        [iconName]="segment.value.emitSource.toLowerCase()"
        iconSize="full"
        [iconType]="IconType.SVG"
        [title]="
          (segment.value.emitSource | highlightSearch: query) +
          ' ' +
          (segment.value.emitType | highlightSearch: query)
        "
      ></itq-headline-simple>
    </div>
    <div class="container__when">
      <ng-container *ngIf="allowDrilldown; else channelTemplate">
        <span
          *ngIf="segment.value.emitChannel"
          class="subtitle-value label-secondary"
          (click)="
            onDrilldown(SegmentScope.CHANNEL, undefined, this.segment.value)
          "
          [innerHTML]="segment.value.emitChannel | highlightSearch: query"
        >
        </span>
      </ng-container>
      <ng-template #channelTemplate>
        <span *ngIf="segment.value.emitChannel">
          <span
            [innerHTML]="segment.value.emitChannel | highlightSearch: query"
          ></span>
        </span>
      </ng-template>
      <span class="dash" *ngIf="segment.value.emitChannel">-</span>
      <div class="when_date">
        {{ segment.value.emitDocumentDate | date: dateFormats.short }}
      </div>
      <div class="when_ago" *ngIf="segment.value.emitDocumentDate">
        ({{ segment.value.emitDocumentDate | millisToDate | timeAgo }})
      </div>
    </div>
    <div class="container__content">
      <div class="custom-fields__container">
        <dl class="content-keyValue">
          <div class="title__container">
            <div class="title__wrapper">
              <ng-container *ngIf="segment.value.emitHeadline">
                <dt>Title</dt>
                <dd
                  [innerHTML]="
                    translatedTitle && translatedTitle !== ''
                      ? (translatedTitle | highlightSearch: query)
                      : (segment.value.emitHeadline | highlightSearch: query)
                  "
                ></dd>
              </ng-container>
              <ng-container *ngIf="!segment.value.emitHeadline">
                <dt>Title</dt>
                <dd>
                  <span
                    [innerHTML]="
                      segment.value.emitType | highlightSearch: query
                    "
                  ></span>
                  <span *ngIf="segment.value.emitActor">
                    by
                    <ng-container *ngIf="allowDrilldown; else actorTemplate">
                      <span
                        *ngIf="segment.value.emitActor"
                        class="subtitle-value field__actor label-secondary"
                        (click)="
                          onDrilldown(
                            SegmentScope.ACTOR,
                            undefined,
                            this.segment.value
                          )
                        "
                        [innerHTML]="
                          segment.value.emitActor | highlightSearch: query
                        "
                      >
                      </span>
                    </ng-container>
                    <ng-template #actorTemplate>
                      <span
                        [innerHTML]="
                          segment.value.emitActor | highlightSearch: query
                        "
                      ></span>
                    </ng-template>
                  </span>
                </dd>
              </ng-container>
            </div>
            <itq-mat-button
              *ngIf="showParent"
              [type]="'button'"
              [materialStyle]="'basic'"
              matTooltip="View full document"
              [matTooltipPosition]="'below'"
              (clickEvent)="expanded = !expanded"
            >
              <fa-icon
                [icon]="['far', expanded ? 'chevrons-up' : 'chevrons-down']"
              ></fa-icon>
            </itq-mat-button>
          </div>
          <ng-container *ngIf="segment.value.emitActor && expanded">
            <dt>Posted by</dt>
            <dd>
              <ng-container *ngIf="allowDrilldown; else actorTemplate">
                <span
                  *ngIf="segment.value.emitActor"
                  class="subtitle-value field__actor label-secondary"
                  (click)="
                    onDrilldown(
                      SegmentScope.ACTOR,
                      undefined,
                      this.segment.value
                    )
                  "
                  [innerHTML]="segment.value.emitActor | highlightSearch: query"
                >
                </span>
              </ng-container>
              <ng-template #actorTemplate>
                <div>
                  <span
                    [innerHTML]="
                      segment.value.emitActor | highlightSearch: query
                    "
                  ></span>
                </div>
              </ng-template>
              <ng-container
                *ngIf="
                  segment.value?.emitReplyToId && replyParent && viewParent
                "
              >
                <span>&nbsp;in reply to &nbsp;</span>
                <span
                  class="label-secondary"
                  (click)="
                    $event.stopImmediatePropagation();
                    onReplayDrilldown({
                      scope: SegmentScope.ACTOR,
                      value: this.replyParent
                    })
                  "
                  >{{ replyParent.emitActor }}</span
                ></ng-container
              >
            </dd>
          </ng-container>
          <ng-container *ngIf="segment.value.emitURL && expanded">
            <dt>Link</dt>
            <dd>
              <a
                class="label-secondary"
                rel="noreferrer"
                [attr.href]="segment.value.emitURL"
                target="_blank"
                [innerHTML]="segment.value.emitURL | highlightSearch: query"
              ></a>
            </dd>
          </ng-container>
        </dl>
      </div>
      <mat-accordion *ngIf="expanded" class="basic" [multi]="true">
        <mat-expansion-panel
          *ngIf="segment.value.emitBodyText"
          [hideToggle]="false"
          [expanded]="true"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Content </mat-panel-title>
            <mat-panel-description>
              <button
                type="button"
                class="btn btn-link-secondary btn--translate"
                (click)="$event.stopImmediatePropagation(); onTranslate()"
              >
                Translate to English
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div
            [innerHTML]="
              translatedContent && translatedContent !== ''
                ? (translatedContent | highlightSearch: query)
                : (segment.value.emitBodyText
                  | striphtml
                  | detecturl
                  | stripImg
                  | highlightSearch: query)
            "
          ></div>
        </mat-expansion-panel>
        <ng-container *ngIf="segment.value?.emitExtensionFields?.length > 0">
          <ng-container
            *ngIf="
              segment.value?.emitSource !== 'Discord';
              else discordTemplate
            "
          >
            <mat-expansion-panel
              [hideToggle]="false"
              [expanded]="true"
              *ngIf="extractedData?.length > 0"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>Extracted data</mat-panel-title>
              </mat-expansion-panel-header>
              <dl class="content-keyValue">
                <ng-container
                  *ngFor="let extension of segment.value.emitExtensionFields"
                >
                  <ng-container
                    *ngIf="
                      segment.value.emitSource === 'Telegram';
                      else otherSourcesTemplate
                    "
                  >
                    <ng-container
                      *ngIf="extension | getExtensionFieldName as fieldName"
                    >
                      <dt
                        *ngIf="
                          fieldName[0] !== ExtensionDataFieldsText['topic'] &&
                          fieldName[0] !== ExtensionDataFieldsText['urls']
                        "
                        [innerHTML]="fieldName | highlightSearch: query"
                      ></dt>
                      <dd>
                        <div
                          *ngFor="
                            let item of extension | getExtensionFieldValues
                          "
                        >
                          <ng-container
                            *ngIf="isArray(item[1]); else stringTemplate"
                          >
                            <ng-container *ngIf="item[1]?.length > 0">
                              <span class="mb-1 label">{{
                                ExtensionDataFieldsText[item[0]]
                              }}</span
                              >: <span>{{ item[1] }}</span>
                            </ng-container>
                          </ng-container>
                          <ng-template #stringTemplate>
                            <ng-container *ngIf="item[1] && item[1] !== ''">
                              <span class="mb-1 label">{{
                                ExtensionDataFieldsText[item[0]]
                              }}</span
                              >: <span [innerHTML]="item[1]"></span>
                            </ng-container>
                          </ng-template>
                        </div>
                      </dd>
                    </ng-container>
                  </ng-container>
                  <ng-template #otherSourcesTemplate>
                    <ng-container
                      *ngIf="
                        extension.name !== 'Links' &&
                        extension.name !== 'Link' &&
                        extension.name !== 'Number of Post Comments'
                      "
                    >
                      <dt
                        [innerHTML]="extension.name | highlightSearch: query"
                      ></dt>
                      <ng-container
                        *ngIf="extension.value; else nonValueTemplate"
                      >
                        <dd
                          [innerHTML]="extension.value | highlightSearch: query"
                        ></dd>
                      </ng-container>
                      <ng-template #nonValueTemplate>
                        <ng-container
                          *ngIf="
                            isArray(extension.selectors);
                            else listTemplate
                          "
                        >
                          <dd
                            [innerHTML]="
                              extension.value | highlightSearch: query
                            "
                          ></dd>
                        </ng-container>
                        <ng-template #listTemplate>
                          <ul class="extension__container">
                            <li *ngFor="let selector of extension.selectors">
                              <ng-container
                                *ngIf="
                                  selector.scrapedData;
                                  else selectorTemplate
                                "
                              >
                                <ng-container
                                  *ngFor="let item of selector.scrapedData"
                                >
                                  <itq-pill
                                    *ngIf="item.textContent.trim() !== ''"
                                    [class]="'secondary rounded-md'"
                                    [innerHTML]="
                                      item.textContent | highlightSearch: query
                                    "
                                  ></itq-pill>
                                </ng-container>
                              </ng-container>
                              <ng-template #selectorTemplate>
                                <itq-pill
                                  *ngIf="selector.trim() !== ''"
                                  [class]="'secondary rounded-md'"
                                  [innerHTML]="
                                    selector | highlightSearch: query
                                  "
                                ></itq-pill>
                              </ng-template>
                            </li>
                          </ul>
                        </ng-template>
                      </ng-template>
                    </ng-container>
                  </ng-template>
                </ng-container>
              </dl>
            </mat-expansion-panel>
          </ng-container>

          <ng-template #discordTemplate>
            <ng-container
              *ngFor="let extension of segment.value.emitExtensionFields"
            >
              <ng-container *ngIf="extension.name !== 'contentLinks'">
                <mat-expansion-panel [hideToggle]="false" [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>{{
                      ExtensionDataFieldsText[extension.name]
                        | highlightSearch: query
                    }}</mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="extension__data_container">
                    <div
                      class="extension__data_item"
                      *ngFor="
                        let item of extension.data | customSortExtensionFields
                      "
                    >
                      <ng-container *ngFor="let dataItem of item | keyvalue">
                        <ng-container
                          *ngIf="
                            dataItem.key !== ExtensionDataFields.AVATAR_URL &&
                            dataItem.value
                          "
                        >
                          <span class="font-normal">{{
                            ExtensionDataFieldsText[dataItem.key]
                          }}</span
                          >:&nbsp;
                          <ng-container
                            *ngIf="
                              dataItem.key !== ExtensionDataFields.NAME;
                              else drilldownTemplate
                            "
                          >
                            <span>{{
                              dataItem.key === ExtensionDataFields.ISBOT
                                ? 'True'
                                : dataItem.value
                            }}</span>
                          </ng-container>
                          <ng-template #drilldownTemplate>
                            <span
                              *ngIf="allowDrilldown; else labelTemplate"
                              class="label-secondary"
                              (click)="
                                onDrilldown(
                                  SegmentScope.DISCORD,
                                  extension,
                                  this.segment.value
                                )
                              "
                            >
                              {{ dataItem.value }}
                            </span>
                            <ng-template #labelTemplate>
                              <span>
                                {{ dataItem.value }}
                              </span>
                            </ng-template>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </mat-expansion-panel>
              </ng-container>
            </ng-container>
          </ng-template>
        </ng-container>

        <mat-expansion-panel
          [hideToggle]="false"
          [expanded]="true"
          *ngIf="contentLinks?.length > 0"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <fa-icon
                [matTooltip]="
                  'Links that have been automatically extracted from the text of this post'
                "
                [matTooltipPosition]="'above'"
                [icon]="['far', 'info-circle']"
              ></fa-icon>
              Extracted Links
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul>
            <li *ngFor="let link of contentLinks">
              <a
                [href]="link.href"
                target="_blank"
                [innerHTML]="link.href | highlightSearch: query"
              ></a>
            </li>
          </ul>
        </mat-expansion-panel>
        <ng-container *ngFor="let media of segment.value.emitMedia">
          <ng-container
            *ngIf="media?.media_source?.toLowerCase() === 'youtube'"
          >
            <mat-expansion-panel [hideToggle]="false" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>Video</mat-panel-title>
              </mat-expansion-panel-header>
              <iframe
                [src]="media.media_link | safe"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </mat-expansion-panel>
          </ng-container>
        </ng-container>
      </mat-accordion>
    </div>
  </div>
</div>
<itq-loader *ngIf="showLoader"></itq-loader>

<!-- Default template for key-value -->
<ng-template #isNotList let-extension>
  <dd [innerHTML]="extension.value | highlightSearch: query"></dd>
</ng-template>

<!-- List template for key-value -->
<ng-template #isList let-extension>
  <ul class="extension__container">
    <li *ngFor="let selector of extension.selectors">
      <ng-container *ngIf="selector.scrapedData; else selectorTemplate">
        <ng-container *ngFor="let item of selector.scrapedData">
          <itq-pill
            *ngIf="item.textContent.trim() !== ''"
            [class]="'secondary rounded-md'"
            [innerHTML]="item.textContent | highlightSearch: query"
          ></itq-pill>
        </ng-container>
      </ng-container>
      <ng-template #selectorTemplate>
        <itq-pill
          *ngIf="selector.trim() !== ''"
          [class]="'secondary rounded-md'"
          [innerHTML]="selector | highlightSearch: query"
        ></itq-pill>
      </ng-template>
    </li>
  </ul>
</ng-template>

import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IDropdownItem } from "@intorqa-ui/core";

import { Utils } from "@intorqa-ui/core";
import { NotesService } from "@portal/profiles/services/notes.service";
import { ProfileNote } from "@portal/profiles/models/profile-note";
import { TextNote } from "@portal/profiles/models/text-note";
import { VideoLinkNote } from "@portal/profiles/models/video-link";
import { Profile } from '@portal/profiles/models/profile';

@Component({
  selector: "itq-type-video-link",
  templateUrl: "./type-video-link.component.html",
  styleUrls: ["./type-video-link.component.scss"],
})
export class TypeVideoLinkComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: VideoLinkNote;

  public editorModules = {
    toolbar: [["video"]],
  };
  public textNoteModules = {
    toolbar: [
      ["bold", "italic", "underline"], // Basic formatting options
      [{ header: [1, 2, 3, 4, 5, 6] }], // Header formatting option
      [{ list: "ordered" }, { list: "bullet" }], // List formatting options
      ["link", "image", "video"], // Insert link, image, and video options
      ["clean"], // Remove formatting option
    ],
  };
  public subTypesDataSource: Array<IDropdownItem> = [];

  constructor(private notesService: NotesService) {}

  ngOnInit() {
    const type = this.notesService.getTypeById(this.note.typeId);
    this.subTypesDataSource = type.subTypes.map((item: string) => ({
      name: item,
      value: item,
    }));
    this.createForm();
  }

  ngOnDestroy(): void {
    const formGroup = this.form.get("addNote") as FormGroup;
    formGroup?.removeControl("description");
  }

  private createForm(): void {
    const formGroup = this.form.get("addNote") as FormGroup;
    formGroup.addControl(
      "provider",
      new FormControl(this.note?.subType, [Validators.required])
    );
    formGroup.addControl(
      "value",
      new FormControl(this.note?.value, [Validators.required])
    );
    formGroup.addControl("description", new FormControl(this.note.textNote));
  }

  public onContentChanged(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id
    );
    this.note.textNote = this.form.get("addNote.description").value;
    this.note.rawTextNote = Utils.removeHtmlTags(this.note.textNote);

    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: TextNote) => {
            return item.id === this.note.id ? this.note : item;
          }
        );
      }
    }
  }

  public onValueChanged(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id
    );
    this.note.value = this.form.get("addNote.value").value;
    this.note.rawValue = Utils.removeHtmlTags(this.note.value);
    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: ProfileNote) => {
            return item.id === this.note.id ? this.note : item;
          }
        );
      }
    }
  }

  public onChangeSubType(): void {
    const note = this.notesService.notes?.items?.find(
      (item: ProfileNote) => item.id === this.note.id
    );
    this.note.subType = this.form.get("addNote.provider").value;
    if (this.note.isValid()) {
      if (note) {
        this.notesService.notes.items = this.notesService.notes?.items?.map(
          (item: ProfileNote) => {
            return item.id === this.note.id ? this.note : item;
          }
        );
      }
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { TransactionNotificationType } from '@intorqa-ui/core';

@Pipe({
  name: 'getTransactionalAction',
  standalone: true,
})
export class GetActionPipe implements PipeTransform {
  transform(action: TransactionNotificationType): IconName {
    switch (action) {
      case TransactionNotificationType.TAG_CREATED:
      case TransactionNotificationType.PROFILE_CREATED:
        return 'expand' as IconName;
      case TransactionNotificationType.TAG_EDITED:
      case TransactionNotificationType.PROFILE_UPDATED:
      case TransactionNotificationType.PROFILE_NOTE_CREATED:
      case TransactionNotificationType.PROFILE_NOTE_UPDATED:
      case TransactionNotificationType.PROFILE_NOTE_DELETED:
        return 'pencil' as IconName;
      default:
        return '' as IconName;
    }
  }
}

import { TemplateRef } from '@angular/core';
import {
  DTOQuery,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DateQueryType,
  QueryFilters,
  TableColumn,
  TagCategory,
  TagCategoryId,
} from '@intorqa-ui/core';
import { TAG_TRENDS_CHART_TYPES } from '@portal/boards/const/time-series.const';
import { FilterTypes } from '@portal/shared/const/tag.const';
import {
  BarMode,
  ChartOption,
  ChartOrientation,
  ChartType,
  LineMode,
} from '@portal/shared/enums/chart.enum';
import {
  AnalysisTypes,
  DateInterval,
  WidgetActions,
} from '@portal/shared/enums/widget.enum';
import {
  IDataPoint,
  IDisplayType,
  ISerie,
  ITimeSeries,
} from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Widget } from './widget';

export class TimeSeries extends Widget implements ITimeSeries {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public options: { [key: string]: any },
    public dataSource: Array<string>,
    public dataValues: Array<string>,
    public dataType: TagCategory,
    public chartType: ChartType,
    public interval: DateInterval,
    public ecosystemId: string,
    public updatedDate: number,
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public getDisplayType(): IDisplayType {
    if (this.chartType === ChartType.BAR) {
      if (this.options.mode === BarMode.GROUP) {
        return TAG_TRENDS_CHART_TYPES.find((displayType: IDisplayType) => {
          return (
            displayType.type === this.chartType &&
            displayType.options?.orientation === ChartOrientation.VERTICAL &&
            displayType.options?.mode === BarMode.GROUP
          );
        });
      } else {
        return TAG_TRENDS_CHART_TYPES.find((displayType: IDisplayType) => {
          return (
            displayType.type === this.chartType &&
            displayType.options?.orientation === ChartOrientation.VERTICAL &&
            displayType.options?.mode === BarMode.STACK
          );
        });
      }
    } else if (this.chartType === ChartType.LINE) {
      if (this.options.mode === LineMode.LINE) {
        return TAG_TRENDS_CHART_TYPES.find((displayType: IDisplayType) => {
          return (
            displayType.type === this.chartType &&
            displayType.options?.mode === LineMode.LINE
          );
        });
      } else {
        return TAG_TRENDS_CHART_TYPES.find((displayType: IDisplayType) => {
          return (
            displayType.type === this.chartType &&
            displayType.options?.mode === LineMode.AREA
          );
        });
      }
    }
  }

  public hasMetrics(): boolean {
    return this.dataValues?.length > 0 &&
      this.dataType?.length > 0 &&
      this.chartType
      ? true
      : false;
  }

  public resetMetrics(): void {
    this.dataValues = undefined;
    this.dataSource = undefined;
    this.dataType = undefined;
  }

  public getDocsQueryModel(initialState: QueryFilters, segment: any): DTOQuery {
    const queryModel = new Query();
    if (initialState?.query) {
      queryModel.addRule(
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [initialState.query],
        ),
      );
    }

    queryModel.addRule(
      new QueryRule(this.getDataPointField(), DTOQueryConditionOperator.in, [
        this.getDataPointValue(segment),
      ]),
    );

    return queryModel.modelToDTO();
  }

  public generateGetDocsState(
    params: { [key: string]: any },
    segment: any,
  ): any {
    const startDate = moment.utc(segment.data.unformattedName);
    const endDate = cloneDeep(startDate);
    endDate.add(1, this.interval.toLowerCase());
    const datePreset = {
      label: DateQueryType.Custom,
      start: startDate.startOf('day').valueOf(),
      end: endDate.startOf('day').valueOf(),
    };
    return {
      pageSize: 30,
      page: params.initialState?.page || 1,
      dataValue: params.dataValue,
      where: datePreset,
    };
  }

  public getDataPointField(): DTOQueryFieldType {
    if (
      this.dataType === TagCategory.Channel ||
      this.dataType === TagCategory.Actor ||
      this.dataType === TagCategory.Source ||
      this.dataType === TagCategory['Post Type'] ||
      this.dataType === TagCategory.Community
    ) {
      return DTOQueryFieldType.filter;
    }
    return DTOQueryFieldType.tag;
  }

  public canLoadDocuments(action: WidgetActions, segment: any): boolean {
    return action === WidgetActions.EXPLORE && segment;
  }

  public transformData(series: Array<ISerie>): Array<ISerie> {
    const keys = new Set<string>();
    series = series.filter((item: ISerie) => {
      return item.data.length > 0;
    });
    series.forEach((serie: ISerie) => {
      serie.data.forEach((item: IDataPoint) => {
        keys.add(item.category);
      });
    });
    const keysArray = Array.from(keys).sort();
    const fullSeries = series.map((serie: ISerie) => {
      const serieData = keysArray.map((key: string) => {
        const keyDataPoint = serie.data.find((dataPoint: IDataPoint) => {
          return dataPoint.category === key;
        });
        return keyDataPoint
          ? {
              category: moment(keyDataPoint.category).format('DD/MM/YYYY'),
              count: keyDataPoint.count,
              tagId: keyDataPoint.tagId,
              value: keyDataPoint.category,
            }
          : {
              category: moment(key).format('DD/MM/YYYY'),
              count: 0,
              value: key,
            };
      });
      serie.data = serieData;
      return serie;
    });

    return fullSeries;
  }

  public getMinWidth(): number {
    return 25;
  }

  public getMinHeight(): number {
    return 5;
  }

  public getDataPointValue(segment: any): string {
    if (
      this.dataType === TagCategory.Channel ||
      this.dataType === TagCategory.Actor ||
      this.dataType === TagCategory.Source ||
      this.dataType === TagCategory['Post Type']
    ) {
      return `Field Filter:${segment.data.tagId}:${
        TagCategoryId[this.dataType]
      }`;
    }
    return segment?.data?.tagId;
  }
  public getTableColumns(template: TemplateRef<unknown>): Array<TableColumn> {
    return [];
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return TAG_TRENDS_CHART_TYPES;
  }

  public getRuleValue(): Array<string> {
    return [];
  }

  public getTagId(index: number): string {
    return this.dataValues[index];
  }

  public getTagIds(): { included: Array<string>; excluded: Array<string> } {
    let result = { included: [], excluded: [] };
    this.dataValues?.forEach((item: string) => {
      if (item.indexOf(':') === -1) {
        result.included.push(item);
      }
    });
    return result;
  }

  public getFieldFilterTagIds(): {
    included: Array<string>;
    excluded: Array<string>;
  } {
    let result = { included: [], excluded: [] };
    this.dataValues?.forEach((item: string) => {
      if (item.indexOf(':') > -1) {
        const splittedValue = item.split(':');
        if (FilterTypes.includes(splittedValue[2])) {
          result.included.push(item);
        }
      }
    });
    return result;
  }

  public getOption(option: ChartOption): any {
    let result: any;
    if (this.options) {
      Object.keys(this.options)?.forEach((key: ChartOption) => {
        if (key === option) {
          result = this.options[key];
        }
      });
    }
    return result;
  }

  public cloneDeep(): TimeSeries {
    return new TimeSeries(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.width,
      this.height,
      this.x,
      this.y,
      this.options,
      this.dataSource,
      this.dataValues,
      this.dataType,
      this.chartType,
      this.interval,
      this.ecosystemId,
      this.updatedDate,
    );
  }
}

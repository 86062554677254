import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';

@Component({
  selector: 'itq-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextAreaComponent),
    },
  ],
})
export class TextAreaComponent implements OnInit {
  @Input() rows: number;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() disabled: boolean;

  @Output() blur = new EventEmitter<void>();

  @ViewChild('textarea') textarea: ElementRef;

  public touched = false;
  public dirty = false;
  public query: string;

  constructor() {}

  ngOnInit() {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = (text: string | number) => {};

  onTouched = (value: boolean) => {
    this.touched = value;
  };

  onDirty = (value: boolean) => {
    this.dirty = value;
  };

  writeValue(value: string): void {
    this.query = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public onBlur(): void {
    this.blur.emit();
  }

  public onKeyUp(): void {
    this.onChange(this.query);
  }
}

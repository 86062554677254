export class Utils {
  static calculateCoordinates(graph_data: any): any {
    // Calculate the number of remaining nodes
    const remainingNodesCount = graph_data.nodes.length - 1;

    // Calculate the angle between each node
    const angleStep = (2 * Math.PI) / remainingNodesCount;

    // Set coordinates for remaining nodes
    for (let i = 1; i < graph_data.nodes.length; i++) {
      const radius = 200; // Adjust the radius as needed
      const angle = i * angleStep;
      const x = Math.cos(angle) * radius;
      const y = Math.sin(angle) * radius;

      graph_data.nodes[i].x = x;
      graph_data.nodes[i].y = y;
    }
    return graph_data;
  }

  static exportImage = (dataUrl: string, name: string) => {
    const img = new Image();
    img.src = dataUrl;
    const element = document.createElement('a');
    element.setAttribute('href', img.src);
    element.setAttribute('download', name + '.png');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  static generateUUID(): string {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c: string) => {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
    return uuid;
  }

  static arraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }

    return true;
  }

  static numFormatter(num: number): string {
    if (num > 999 && num < 1000000) {
      return num / 1000 + 'K'; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return num / 1000000 + 'M'; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num.toString(); // if value < 1000, nothing to do
    }
  }

  static unique(value: any, index: number, self): any {
    return self.indexOf(value) === index;
  }

  static empty(self): any {
    return self !== '';
  }

  static checkDifferences<T>(
    array1: Array<T> = [],
    array2: Array<T> = [],
  ): Array<T> {
    let difference: Array<T> = [];
    if (array1.length >= array2.length) {
      difference = array1.filter((x: T) => !array2.includes(x));
    } else {
      difference = array2.filter((x: T) => !array1.includes(x));
    }
    return difference;
  }

  static getTextWidthFromElement(
    text: string,
    fontFamily: string,
    fontSize: string,
  ): number {
    const spanElement = document.createElement('span');
    spanElement.style.fontFamily = fontFamily;
    spanElement.style.fontSize = fontSize;
    spanElement.style.visibility = 'hidden';
    spanElement.style.position = 'absolute';
    spanElement.textContent = text;
    document.body.appendChild(spanElement);

    const textWidth = spanElement.offsetWidth;

    document.body.removeChild(spanElement);

    return textWidth;
  }

  static remToPx(valueInRem: number) {
    const fontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize,
    );
    return valueInRem * fontSize;
  }

  static removeHtmlTags(text: string): string {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(text, 'text/html');
    return parsedHtml.body.innerText;
  }

  static removeHtmlExceptBrTags(text: string): string {
    const parser = new DOMParser();
    let parsedHtml = parser.parseFromString(text, 'text/html');

    // Replace <br> with a placeholder
    parsedHtml.body.innerHTML = parsedHtml.body.innerHTML.replace(
      /<br>/g,
      '||BR||',
    );

    // Replace <p> and </p> with a space
    parsedHtml.body.innerHTML = parsedHtml.body.innerHTML.replace(
      /<\/?p>/g,
      ' ',
    );

    // Get the text, which removes HTML tags
    let textWithoutHtml = parsedHtml.body.textContent || '';

    // Replace the placeholder with <br>
    textWithoutHtml = textWithoutHtml.replace(/\|\|BR\|\|/g, ' ');

    return textWithoutHtml;
  }
}

import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentType } from '@intorqa-ui/core';
import { Subscription } from 'rxjs';
import {
  ProfileMetadataGroups,
  MetadataIcons,
  MetadataFields,
} from '@portal/profiles/enums/profiles-metadata.enum';
import { GroupMetadata } from '@portal/profiles/models/group-metadata';
import { ProfileTypeMetadata } from '@portal/profiles/models/profile-type-metadata';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { Profile } from '@portal/profiles/models/profile';
import { GroupMetadataPipe } from '../../../profiles-explore/profiles-explore.pipe';
import { MetadataContainerComponent } from './metadata-container/metadata-container/metadata-container.component';
import { Cheat_Reputation_Map } from '@portal/profiles/const/vendors.const';

@Component({
  selector: 'itq-profiles-wizard-metadata',
  templateUrl: './profiles-wizard-metadata.component.html',
  styleUrls: ['./profiles-wizard-metadata.component.scss'],
})
export class ProfilesWizardMetadataComponent implements OnInit {
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() editAction: ProfileMetadataGroups;
  @Input() dataSource: Array<ProfileTypeMetadata>;

  @Output() updateRecord = new EventEmitter<Profile>();

  private metadataSubscription: Subscription;
  public metadata: Array<GroupMetadata>;

  readonly MetadataIcons = MetadataIcons;
  readonly ProfileMetadataGroups = ProfileMetadataGroups;

  @ViewChildren('dynamicComponentContainer', { read: ViewContainerRef })
  dynamicComponentContainers: QueryList<ViewContainerRef>;

  constructor(
    private profileService: ProfileService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private groupMetadataPipe: GroupMetadataPipe,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.metadataSubscription = this.profileService.metadata$.subscribe(
      (response: Array<ProfileTypeMetadata>) => {
        this.metadata = this.groupMetadataPipe.transform(response);
        if (!this.form.get('metadata')) {
          this.form.controls.metadata = new FormGroup({});
        }
        this.changeDetectorRef.detectChanges();
        this.createComponents();
      },
    );
    this.profileService.getMetadata(this.profile.profileTypeId).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.profile?.currentValue?.profileId !==
      changes?.profile?.previousValue?.profileId
    ) {
      this.profileService.getMetadata(this.profile.profileTypeId).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.metadataSubscription.unsubscribe();
  }

  private createForm(): void {
    this.form.addControl('metadata', new FormGroup({}));
  }

  public createComponents(): void {
    this.dynamicComponentContainers.forEach(
      (container: ViewContainerRef, index: number) => {
        container?.clear();
        this.metadata[index].metadata.forEach((item: ProfileTypeMetadata) => {
          if (
            item.name !== MetadataFields.PRIORITY &&
            item.name !== MetadataFields.CHEAT_STATUS
          ) {
            const componentFactory =
              this.componentFactoryResolver.resolveComponentFactory(
                MetadataContainerComponent,
              );
            const componentRef = container.createComponent(componentFactory);

            componentRef.instance.formControlName = `${item.id}`;
            componentRef.instance.form = this.form;
            componentRef.instance.profileMetadata = item;
            componentRef.instance.profile = this.profile;
            if (
              item.component === ComponentType.DROPDOWN ||
              item.component === ComponentType.MULTIPLE_DROPDOWN
            ) {
              if (item.name === MetadataFields.CHEAT_REPUTATION_SCORE) {
                componentRef.instance.dataSource = item.values?.map(
                  (value: string) => {
                    return {
                      name: `${value} - ${
                        Cheat_Reputation_Map.find(
                          (reputation: { name: string; value: number }) =>
                            reputation.value === parseInt(value),
                        ).name
                      }`,
                      value,
                    };
                  },
                );
              } else {
                componentRef.instance.dataSource = item.values?.map(
                  (value: string) => ({
                    name: value,
                    value,
                  }),
                );
              }
            }
          }
        });
      },
    );
  }
}

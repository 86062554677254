import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  EventBusService,
  FAwesomeModule,
  IconType,
  QueryFilters,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { WidgetDisplayTypesComponent } from '@portal/boards/components/widget-display-types/widget-display-types.component';
import { Board } from '@portal/boards/models/board';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { Widget } from '@portal/boards/models/widgets/widget';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { TimelineFeedComponent } from '@portal/shared/components/timeline-feed/timeline-feed.component';
import { ChartType } from '@portal/shared/enums/chart.enum';
import {
  EventBusScope,
  EventBusUrls,
} from '@portal/shared/enums/event-bus.enum';
import { TaggingStatus } from '@portal/shared/enums/tag.enum';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { IData } from '@portal/shared/interfaces/document.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { TagService } from '@portal/shared/services/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { INavigationHistoryItem } from '@portal/widget-settings/interfaces/navigation-history-item.interface';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { TimelineNavigationItem } from '@portal/widget-settings/models/timeline-navigation-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { TimelineWidgetBodyComponent } from './components/timeline-widget-body/timeline-widget-body.component';
import { TimelineWidgetFooterComponent } from './components/timeline-widget-footer/timeline-widget-footer.component';
import { TimelineWidgetHeaderComponent } from './components/timeline-widget-header/timeline-widget-header.component';
import { DiscordNavigationItem } from '@portal/widget-settings/models/discord-navigation-item.model';

@Component({
  selector: 'itq-timeline-widget',
  templateUrl: './timeline-widget.component.html',
  styleUrls: ['./timeline-widget.component.scss'],
  standalone: true,
  imports: [
    FAwesomeModule,
    CoreModule,
    MatTooltip,
    CommonModule,
    TimelineFeedComponent,
    TimelineWidgetHeaderComponent,
    TimelineWidgetBodyComponent,
    TimelineWidgetFooterComponent,
    WidgetDisplayTypesComponent,
  ],
  providers: [VirtualScrollService],
})
export class TimelineWidgetComponent implements OnInit {
  @Input() segment: any;
  @Input() widget: Timeline;
  @Input() action: WidgetActions;
  @Input() board: Board;
  @Input() selectedNavigationHistory: INavigationHistoryItem;
  @Input() query: string;

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  public chartInstance: echarts.ECharts;
  public data: IData;
  public showLoader = false;
  public noContentTitle = 'No results found';
  public noContentMessage = 'Please update your filters and try again';
  public timelineStatus: TaggingStatus;
  private subscriptions = new Subscription();
  public initialState = new QueryFilters(
    100,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  constructor(
    private widgetService: WidgetService,
    private tagService: TagService,
    private eventBusService: EventBusService,
    private customOverlayService: CustomOverlayService,
    readonly userService: UserService,
    readonly activatedRoute: ActivatedRoute,
    readonly boardService: BoardService,
  ) {}

  ngOnInit(): void {
    this.bindQueryParamsSubscription();
    this.bindRemoveWidgetSubscription();
    this.bindReloadWidgetSubscription();
    this.bindExploreWidgetSubscription();
    this.registerEventBusEvents();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unRegisterEventBusEvents();
  }

  private bindExploreWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.explore$.subscribe((response: Widget) => {
        if (response.widgetId === this.widget.widgetId) {
          this.onExplore();
        }
      }),
    );
  }

  private unRegisterEventBusEvents(): void {
    this.unRegisterTaggingEventBusEvent();
    this.unRegisterEventBusDeleteWidgetEvent();
    this.unRegisterEventBusUpdateWidgetEvent();
  }

  private registerEventBusEvents(): void {
    this.registerEventBusDeleteWidgetEvent();
    this.registerEventBusUpdateWidgetEvent();
  }

  private bindReloadWidgetSubscription(): void {
    this.subscriptions.add(
      this.widgetService.reload$.subscribe(
        (params: { widget: Widget; initialState: QueryFilters }) => {
          if (
            !params.widget ||
            params.widget.widgetId === this.widget.widgetId
          ) {
            this.initialState = params.initialState;
            this.onDataBound(this.initialState);
          }
        },
      ),
    );
  }

  private bindQueryParamsSubscription(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params?.widgetid && this.widget.widgetId === params.widgetid) {
          this.onExplore();
        }
      }),
    );
  }

  private bindRemoveWidgetSubscription(): void {
    this.subscriptions.add(
      this.boardService.removeTimelines$.subscribe((widget: Timeline) => {
        if (widget.widgetId === this.widget.widgetId) {
          this.onDeleteWidget();
        }
      }),
    );
  }

  public onUpdateRef(ref: echarts.ECharts): void {
    this.chartInstance = ref;
  }

  public onDeleteWidget(): void {
    this.unRegisterTaggingEventBusEvent();
    this.boardService
      .addWidgetsToBoard(this.board.id, {
        delete: [this.widget.widgetId],
      })
      .subscribe();
  }

  private loadExploreView(
    segment?: ISegment,
    initialState?: QueryFilters,
  ): void {
    const widget = cloneDeep(this.widget);
    const rules = [
      new QueryRule(
        DTOQueryFieldType.tag,
        DTOQueryConditionOperator.in,
        widget.dataSource,
      ),
    ];
    if (this.initialState?.query) {
      rules.push(
        new QueryRule(DTOQueryFieldType.content, DTOQueryConditionOperator.in, [
          this.initialState?.query,
        ]),
      );
    }
    const navigationItem = new TimelineNavigationItem(
      `${this.widget.widgetId}_${WidgetActions.EXPLORE}`,
      widget,
      WidgetActions.EXPLORE,
      rules,
      initialState,
      new FormGroup({}),
      'magnifying-glass',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      this.board.id,
      segment?.value?.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
        articleDetail: segment,
      },
      closeBtnStyle: 'basic',
      cssClass: 'w-[90%]',
      type: CustomOverlayType['almost-full'],
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onExplore(): void {
    const initialState = this.initialState.cloneDeep();
    initialState.resetPagination();
    this.loadExploreView(undefined, initialState);
  }

  public onDrilldown(segment?: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.ARTICLE_DETAIL:
        this.loadExploreView(segment, this.initialState.cloneDeep());
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment);
        break;
      default:
        break;
    }
  }

  private drilldownContext(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(
        30,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      segment,
      undefined,
      segment.context,
    );

    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      cssClass: 'w-[90%]',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownDiscord(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      cssClass: 'w-[90%]',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      this.board.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      cssClass: 'w-[90%]',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      this.board.id,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      cssClass: 'w-[90%]',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onDataBound(params: QueryFilters): void {
    this.showLoader = true;
    const queryModel = new Query();
    queryModel.addRule(
      new QueryRule(
        DTOQueryFieldType.tag,
        DTOQueryConditionOperator.in,
        this.widget.dataSource,
      ),
    );
    const initialState = new QueryFilters(
      30,
      params?.page,
      params?.where,
      params.query,
      undefined,
      undefined,
    );
    if (params?.query) {
      queryModel.query.rules.push({
        field: DTOQueryFieldType.content,
        operator: DTOQueryConditionOperator.contains,
        value: params?.query,
      });
    }
    this.tagService
      .execute(
        initialState,
        queryModel,
        this.userService.userPreferences.defaultEcosystemId,
      )
      .subscribe((response: IData) => {
        if (initialState.page > 1) {
          this.data.result = [...this.data.result, ...response.result];
        } else {
          this.data = response;
        }
        this.onCheckTagging();
        this.showLoader = false;
      });
  }

  private onCheckTagging(): void {
    this.timelineStatus =
      this.widget.lastTaggingTime > this.widget.updatedDate
        ? TaggingStatus.COMPLETED
        : TaggingStatus.IN_PROGRESS;
    if (this.timelineStatus === TaggingStatus.IN_PROGRESS) {
      this.noContentTitle = 'No results yet';
      this.noContentMessage =
        'Your tag is being built, please wait to see content.';

      this.registerEventBusTaggingEvent();
    }
    if (
      this.data?.count === 0 &&
      this.timelineStatus === TaggingStatus.COMPLETED
    ) {
      this.noContentTitle = 'No results found';
      this.noContentMessage = 'Please update your filters and try again';
    }
  }

  private registerEventBusDeleteWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.widget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private unRegisterEventBusDeleteWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.DELETE_WIDGET}.${this.widget.widgetId}`,
      this.deleteWidgetCallback(),
    );
  }

  private onDelete(): void {}

  private deleteWidgetCallback(): (
    err: Error,
    msg: {
      body: { result: boolean };
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: { result: boolean };
        address: string;
        type: string;
      },
    ) => {
      this.widgetService.delete$.next(this.widget);
    };
  }

  private registerEventBusUpdateWidgetEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.widget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private unRegisterEventBusUpdateWidgetEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.SASS}.${EventBusScope.UPDATE_WIDGET}.${this.widget.widgetId}`,
      this.updateWidgetCallback(),
    );
  }

  private updateWidgetCallback(): (
    err: Error,
    msg: {
      body: Timeline;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: Timeline;
        address: string;
        type: string;
      },
    ) => {
      this.widget = WidgetFactory.createWidget(msg.body) as Timeline;
      this.initialState.resetPagination();
      this.onDataBound(this.initialState);
    };
  }

  private registerEventBusTaggingEvent(): void {
    this.eventBusService.registerEvent(
      `${EventBusUrls.CORE_REACTOR}.${EventBusScope.TAGGING}.${this.widget.dataSource[0]}`,
      this.handleTaggingMessages(),
    );
  }

  private unRegisterTaggingEventBusEvent(): void {
    this.eventBusService.unRegisterEvent(
      `${EventBusUrls.CORE_REACTOR}.${EventBusScope.TAGGING}.${this.widget.dataSource[0]}`,
      this.handleTaggingMessages(),
    );
  }

  private handleTaggingMessages(): (
    err: Error,
    msg: {
      body: string;
      address: string;
      type: string;
    },
  ) => void {
    return (
      err: Error,
      msg: {
        body: string;
        address: string;
        type: string;
      },
    ) => {
      if (msg.body === this.widget.dataSource[0]) {
        this.onDataBound(this.initialState);
        this.timelineStatus = TaggingStatus.COMPLETED;
        this.unRegisterTaggingEventBusEvent();
      }
    };
  }
}

/**
 * Carrot Search FoamTree HTML5 (commercial variant)
 * v3.5.0, bugfix/3.5.x/e3b91c8e, build FOAMTREE-SOFTWARE5-DIST-3, Jan 18, 2021
 * 
 * Carrot Search confidential.
 * Copyright 2002-2021, Carrot Search s.c, All Rights Reserved.
 */
/* eslint-disable */
(function () {
  var ea = function () {
    var n = window.navigator.userAgent;
    try {
      window.localStorage.setItem("ftap5caavc", "ftap5caavc");
      window.localStorage.removeItem("ftap5caavc");
      var q = !0;
    } catch (m) {
      q = !1;
    }
    return {
      Ve: function () {
        return /webkit/i.test(n);
      },
      Ph: function () {
        return /Mac/.test(n);
      },
      Te: function () {
        return /iPad|iPod|iPhone/.test(n);
      },
      Oh: function () {
        return /Android/.test(n);
      },
      Kh: function () {
        return "ontouchstart" in window || !!window.DocumentTouch && document instanceof window.DocumentTouch;
      },
      Jh: function () {
        return q;
      },
      Ih: function () {
        var m = document.createElement("canvas");
        return !(!m.getContext || !m.getContext("2d"));
      },
      pd: function (m, f) {
        return [].forEach && ea.Ih() ? m && m() : f && f();
      }
    };
  }();
  var ka = function () {
    function n() {
      return window.performance && (window.performance.now || window.performance.mozNow || window.performance.msNow || window.performance.oNow || window.performance.webkitNow) || Date.now;
    }
    var q = n();
    return {
      create: function () {
        return {
          now: function () {
            var m = n();
            return function () {
              return m.call(window.performance);
            };
          }()
        };
      },
      now: function () {
        return q.call(window.performance);
      }
    };
  }();
  function na() {
    function n() {
      if (!c) throw "AF0";
      var k = ka.now();
      0 !== p && (m.vd = k - p);
      p = k;
      d = d.filter(function (g) {
        return null !== g;
      });
      m.frames++;
      for (var b = 0; b < d.length; b++) {
        var a = d[b];
        null !== a && (!0 === a.he.call(a.context) ? d[b] = null : P.Cc(a.repeat) && (a.repeat = a.repeat - 1, 0 >= a.repeat && (d[b] = null)));
      }
      d = d.filter(function (g) {
        return null !== g;
      });
      c = !1;
      q();
      k = ka.now() - k;
      0 !== k && (m.ud = k);
      m.totalTime += k;
      m.xe = 1E3 * m.frames / m.totalTime;
      0 === d.length ? p = 0 : p = ka.now();
    }
    function q() {
      0 < d.length && !c && (c = !0, f(n));
    }
    var m = this.Yf = {
      frames: 0,
      totalTime: 0,
      ud: 0,
      vd: 0,
      xe: 0
    };
    pa = m;
    var f = function () {
        return ea.Te() ? function (k) {
          window.setTimeout(k, 0);
        } : window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function () {
          var k = ka.create();
          return function (b) {
            var a = 0;
            window.setTimeout(function () {
              var g = k.now();
              b();
              a = k.now() - g;
            }, 16 > a ? 16 - a : 0);
          };
        }();
      }(),
      d = [],
      c = !1,
      p = 0;
    this.repeat = function (k, b, a) {
      this.cancel(k);
      d.push({
        he: k,
        context: a,
        repeat: b
      });
      q();
    };
    this.once = function (k, b) {
      this.repeat(k, 1, b);
    };
    this.cancel = function (k) {
      for (var b = 0; b < d.length; b++) {
        var a = d[b];
        null !== a && a.he === k && (d[b] = null);
      }
    };
    this.i = function () {
      d = [];
    };
  }
  var pa;
  var ra = ea.pd(function () {
    function n() {
      this.buffer = [];
      this.ma = 0;
      this.current = P.extend({}, p);
    }
    function q(k) {
      return function () {
        var b,
          a = this.buffer,
          g = this.ma;
        a[g++] = "call";
        a[g++] = k;
        a[g++] = arguments.length;
        for (b = 0; b < arguments.length; b++) a[g++] = arguments[b];
        this.ma = g;
      };
    }
    function m(k) {
      return function () {
        return d[k].apply(d, arguments);
      };
    }
    var f = document.createElement("canvas");
    f.width = 1;
    f.height = 1;
    var d = f.getContext("2d");
    f = ["font"];
    var c = "fillStyle globalAlpha globalCompositeOperation lineCap lineDashOffset lineJoin lineWidth miterLimit shadowBlur shadowColor shadowOffsetX shadowOffsetY strokeStyle textAlign textBaseline".split(" "),
      p = {};
    c.concat(f).forEach(function (k) {
      p[k] = d[k];
    });
    n.prototype.clear = function () {
      this.ma = 0;
    };
    n.prototype.Ha = function () {
      return 0 === this.ma;
    };
    n.prototype.Oa = function (k) {
      function b(g, e, u) {
        for (var t = 0, l = g.ma, h = g.buffer; t < u;) h[l++] = e[t++];
        g.ma = l;
      }
      function a(g, e, u, t) {
        for (var l = 0; l < u;) switch (e[l++]) {
          case "set":
            g[e[l++]] = e[l++];
            break;
          case "setGlobalAlpha":
            g[e[l++]] = e[l++] * t;
            break;
          case "call":
            var h = e[l++];
            switch (e[l++]) {
              case 0:
                g[h]();
                break;
              case 1:
                g[h](e[l++]);
                break;
              case 2:
                g[h](e[l++], e[l++]);
                break;
              case 3:
                g[h](e[l++], e[l++], e[l++]);
                break;
              case 4:
                g[h](e[l++], e[l++], e[l++], e[l++]);
                break;
              case 5:
                g[h](e[l++], e[l++], e[l++], e[l++], e[l++]);
                break;
              case 6:
                g[h](e[l++], e[l++], e[l++], e[l++], e[l++], e[l++]);
                break;
              case 7:
                g[h](e[l++], e[l++], e[l++], e[l++], e[l++], e[l++], e[l++]);
                break;
              case 8:
                g[h](e[l++], e[l++], e[l++], e[l++], e[l++], e[l++], e[l++], e[l++]);
                break;
              case 9:
                g[h](e[l++], e[l++], e[l++], e[l++], e[l++], e[l++], e[l++], e[l++], e[l++]);
                break;
              default:
                throw "CB0";
            }
        }
      }
      k instanceof ra ? b(k, this.buffer, this.ma) : a(k, this.buffer, this.ma, P.I(k.globalAlpha, 1));
    };
    n.prototype.replay = n.prototype.Oa;
    n.prototype.i = function () {
      return new n();
    };
    n.prototype.scratch = n.prototype.i;
    "arc arcTo beginPath bezierCurveTo clearRect clip closePath drawImage fill fillRect fillText lineTo moveTo putImageData quadraticCurveTo rect rotate scale setLineDash setTransform stroke strokeRect strokeText transform translate".split(" ").forEach(function (k) {
      n.prototype[k] = q(k);
    });
    ["measureText", "createLinearGradient", "createRadialGradient", "createPattern", "getLineDash"].forEach(function (k) {
      n.prototype[k] = m(k);
    });
    ["save", "restore"].forEach(function (k) {
      var b = m(k),
        a = q(k);
      n.prototype[k] = function (g, e) {
        return function () {
          g.apply(this, arguments);
          e.apply(this, arguments);
        };
      }(a, b);
    });
    f.forEach(function (k) {
      Object.defineProperty(n.prototype, k, {
        set: function (b) {
          d[k] = b;
          this.current[k] = b;
          var a = this.buffer;
          a[this.ma++] = "set";
          a[this.ma++] = k;
          a[this.ma++] = b;
        },
        get: function () {
          return this.current[k];
        }
      });
    });
    c.forEach(function (k) {
      Object.defineProperty(n.prototype, k, {
        set: function (b) {
          this.current[k] = b;
          var a = this.buffer;
          a[this.ma++] = "globalAlpha" === k ? "setGlobalAlpha" : "set";
          a[this.ma++] = k;
          a[this.ma++] = b;
        },
        get: function () {
          return this.current[k];
        }
      });
    });
    n.prototype.roundRect = function (k, b, a, g, e) {
      this.beginPath();
      this.moveTo(k + e, b);
      this.lineTo(k + a - e, b);
      this.quadraticCurveTo(k + a, b, k + a, b + e);
      this.lineTo(k + a, b + g - e);
      this.quadraticCurveTo(k + a, b + g, k + a - e, b + g);
      this.lineTo(k + e, b + g);
      this.quadraticCurveTo(k, b + g, k, b + g - e);
      this.lineTo(k, b + e);
      this.quadraticCurveTo(k, b, k + e, b);
      this.closePath();
    };
    n.prototype.fillPolygonWithText = function (k, b, a, g, e) {
      e || (e = {});
      var u = {
          ib: P.I(e.maxFontSize, ta.ya.ib),
          Jc: P.I(e.minFontSize, ta.ya.Jc),
          lineHeight: P.I(e.lineHeight, ta.ya.lineHeight),
          eb: P.I(e.horizontalPadding, ta.ya.eb),
          Va: P.I(e.verticalPadding, ta.ya.Va),
          jb: P.I(e.maxTotalTextHeight, ta.ya.jb),
          fontFamily: P.I(e.fontFamily, ta.ya.fontFamily),
          fontStyle: P.I(e.fontStyle, ta.ya.fontStyle),
          fontVariant: P.I(e.fontVariant, ta.ya.fontVariant),
          fontWeight: P.I(e.fontWeight, ta.ya.fontWeight),
          verticalAlign: P.I(e.verticalAlign, ta.ya.verticalAlign)
        },
        t = e.cache;
      if (t && P.has(e, "area")) {
        t.Tc || (t.Tc = new ra());
        var l = e.area,
          h = P.I(e.cacheInvalidationThreshold, .05);
        k = ta.ge(u, this, g, k, wa.F(k, {}), {
          x: b,
          y: a
        }, e.allowForcedSplit || !1, e.allowEllipsis || !1, t, l, h, e.invalidateCache);
      } else k = ta.ue(u, this, g, k, wa.F(k, {}), {
        x: b,
        y: a
      }, e.allowForcedSplit || !1, e.allowEllipsis || !1);
      return k.ka ? {
        fit: !0,
        lineCount: k.dc,
        fontSize: k.fontSize,
        box: {
          x: k.box.x,
          y: k.box.y,
          w: k.box.w,
          h: k.box.o
        },
        ellipsis: k.Wb
      } : {
        fit: !1
      };
    };
    return n;
  });
  var xa = ea.pd(function () {
    function n(a) {
      this.S = a;
      this.canvas = a.canvas;
      this.i = [];
      this.Bb = [void 0];
      this.yc = ["#SIZE#px sans-serif"];
      this.wd = [0];
      this.xd = [1];
      this.Ud = [0];
      this.Vd = [0];
      this.Wd = [0];
      this.Bd = [10];
      this.Zb = [10];
      this.Jb = [this.Bb, this.yc, this.Zb, this.wd, this.xd, this.Ud, this.Bd, this.Vd, this.Wd];
      this.da = [1, 0, 0, 1, 0, 0];
    }
    function q(a) {
      var g = a.S,
        e = a.Jb[0].length - 1;
      a.Bb[e] && (g.setLineDash(a.Bb[e]), g.lineDashOffset = a.wd[e]);
      g.miterLimit = a.Bd[e];
      g.lineWidth = a.xd[e];
      g.shadowBlur = a.Ud[e];
      g.shadowOffsetX = a.Vd[e];
      g.shadowOffsetY = a.Wd[e];
      g.font = a.yc[e].replace("#SIZE#", a.Zb[e].toString());
    }
    function m(a) {
      return function () {
        return this.S[a].apply(this.S, arguments);
      };
    }
    function f(a) {
      return function (g, e) {
        var u = this.da;
        return this.S[a].call(this.S, c(g, e, u), p(g, e, u));
      };
    }
    function d(a) {
      return function (g, e, u, t) {
        var l = this.da;
        return this.S[a].call(this.S, c(g, e, l), p(g, e, l), u * l[0], t * l[3]);
      };
    }
    function c(a, g, e) {
      return a * e[0] + g * e[2] + e[4];
    }
    function p(a, g, e) {
      return a * e[1] + g * e[3] + e[5];
    }
    function k(a, g) {
      for (var e = 0; e < a.length; e++) a[e] *= g[0];
      return a;
    }
    n.prototype.save = function () {
      this.i.push(this.da.slice(0));
      for (var a = 0; a < this.Jb.length; a++) {
        var g = this.Jb[a];
        g.push(g[g.length - 1]);
      }
      this.S.save();
    };
    n.prototype.restore = function () {
      this.da = this.i.pop();
      for (var a = 0; a < this.Jb.length; a++) this.Jb[a].pop();
      this.S.restore();
      q(this);
    };
    n.prototype.scale = function (a, g) {
      var e = this.da;
      e[0] *= a;
      e[1] *= a;
      e[2] *= g;
      e[3] *= g;
      a = this.da;
      g = this.Jb;
      e = g[0].length - 1;
      var u = this.Bb[e];
      u && k(u, a);
      for (u = 2; u < g.length; u++) {
        var t = g[u];
        t[e] *= a[0];
      }
      q(this);
    };
    n.prototype.translate = function (a, g) {
      var e = this.da;
      e[4] += e[0] * a + e[2] * g;
      e[5] += e[1] * a + e[3] * g;
    };
    ["moveTo", "lineTo"].forEach(function (a) {
      n.prototype[a] = f(a);
    });
    ["clearRect", "fillRect", "strokeRect", "rect"].forEach(function (a) {
      n.prototype[a] = d(a);
    });
    "fill stroke beginPath closePath clip createImageData createPattern getImageData putImageData getLineDash setLineDash".split(" ").forEach(function (a) {
      n.prototype[a] = m(a);
    });
    [{
      p: "lineDashOffset",
      a: function (a) {
        return a.wd;
      }
    }, {
      p: "lineWidth",
      a: function (a) {
        return a.xd;
      }
    }, {
      p: "miterLimit",
      a: function (a) {
        return a.Bd;
      }
    }, {
      p: "shadowBlur",
      a: function (a) {
        return a.Ud;
      }
    }, {
      p: "shadowOffsetX",
      a: function (a) {
        return a.Vd;
      }
    }, {
      p: "shadowOffsetY",
      a: function (a) {
        return a.Wd;
      }
    }].forEach(function (a) {
      Object.defineProperty(n.prototype, a.p, {
        set: function (g) {
          var e = a.a(this);
          g *= this.da[0];
          e[e.length - 1] = g;
          this.S[a.p] = g;
        }
      });
    });
    var b = /(\d+(?:\.\d+)?)px/;
    Object.defineProperty(n.prototype, "font", {
      set: function (a) {
        var g = b.exec(a);
        if (1 < g.length) {
          var e = this.Zb.length - 1;
          this.Zb[e] = parseFloat(g[1]);
          this.yc[e] = a.replace(b, "#SIZE#px");
          a = this.S;
          e = this.yc[e].replace("#SIZE#", (this.Zb[e] * this.da[0]).toString());
          a.font = e;
        }
      }
    });
    "fillStyle globalAlpha globalCompositeOperation lineCap lineJoin shadowColor strokeStyle textAlign textBaseline".split(" ").forEach(function (a) {
      Object.defineProperty(n.prototype, a, {
        set: function (g) {
          this.S[a] = g;
        }
      });
    });
    n.prototype.arc = function (a, g, e, u, t, l) {
      var h = this.da;
      this.S.arc(c(a, g, h), p(a, g, h), e * h[0], u, t, l);
    };
    n.prototype.arcTo = function (a, g, e, u, t) {
      var l = this.da;
      this.S.arc(c(a, g, l), p(a, g, l), c(e, u, l), p(e, u, l), t * l[0]);
    };
    n.prototype.bezierCurveTo = function (a, g, e, u, t, l) {
      var h = this.da;
      this.S.bezierCurveTo(c(a, g, h), p(a, g, h), c(e, u, h), p(e, u, h), c(t, l, h), p(t, l, h));
    };
    n.prototype.drawImage = function (a, g, e, u, t, l, h, r, y) {
      function w(E, D, H, O) {
        z.push(c(E, D, v));
        z.push(p(E, D, v));
        H = P.V(H) ? a.width : H;
        O = P.V(O) ? a.height : O;
        z.push(H * v[0]);
        z.push(O * v[3]);
      }
      var v = this.da,
        z = [a];
      P.V(l) ? w(g, e, u, t) : w(l, h, r, y);
      this.S.drawImage.apply(this.S, z);
    };
    n.prototype.quadraticCurveTo = function (a, g, e, u) {
      var t = this.da;
      this.S.quadraticCurveTo(c(a, g, t), p(a, g, t), c(e, u, t), p(e, u, t));
    };
    n.prototype.fillText = function (a, g, e, u) {
      var t = this.da;
      this.S.fillText(a, c(g, e, t), p(g, e, t), P.Cc(u) ? u * t[0] : 1E20);
    };
    n.prototype.setLineDash = function (a) {
      a = k(a.slice(0), this.da);
      this.Bb[this.Bb.length - 1] = a;
      this.S.setLineDash(a);
    };
    return n;
  });
  var Aa = function () {
    var n = !ea.Ve() || ea.Te() || ea.Oh() ? 1 : 7;
    return {
      estimate: function () {
        function q(h) {
          h.beginPath();
          ya.Xd(h, k);
        }
        var m = document.createElement("canvas");
        m.width = 800;
        m.height = 600;
        var f = m.getContext("2d"),
          d = m.width;
        m = m.height;
        var c,
          p = 0,
          k = [{
            x: 0,
            y: 100
          }];
        for (c = 1; 6 >= c; c++) p = 2 * c * Math.PI / 6, k.push({
          x: 100 * Math.sin(p),
          y: 100 * Math.cos(p)
        });
        c = {
          polygonPlainFill: [q, function (h) {
            h.fillStyle = "rgb(255, 0, 0)";
            h.fill();
          }],
          polygonPlainStroke: [q, function (h) {
            h.strokeStyle = "rgb(128, 0, 0)";
            h.lineWidth = 2;
            h.closePath();
            h.stroke();
          }],
          polygonGradientFill: [q, function (h) {
            var r = h.createRadialGradient(0, 0, 10, 0, 0, 60);
            r.addColorStop(0, "rgb(255, 0, 0)");
            r.addColorStop(1, "rgb(255, 255, 0)");
            h.fillStyle = r;
            h.fill();
          }],
          polygonGradientStroke: [q, function (h) {
            var r = h.createLinearGradient(-100, -100, 100, 100);
            r.addColorStop(0, "rgb(224, 0, 0)");
            r.addColorStop(1, "rgb(32, 0, 0)");
            h.strokeStyle = r;
            h.lineWidth = 2;
            h.closePath();
            h.stroke();
          }],
          polygonExposureShadow: [q, function (h) {
            h.shadowBlur = 50;
            h.shadowColor = "rgba(0, 0, 0, 1)";
            h.fillStyle = "rgba(0, 0, 0, 1)";
            h.globalCompositeOperation = "source-over";
            h.fill();
            h.shadowBlur = 0;
            h.shadowColor = "transparent";
            h.globalCompositeOperation = "destination-out";
            h.fill();
          }],
          labelPlainFill: [function (h) {
            h.fillStyle = "#000";
            h.font = "24px sans-serif";
            h.textAlign = "center";
          }, function (h) {
            h.fillText("Some text", 0, -16);
            h.fillText("for testing purposes", 0, 16);
          }]
        };
        p = 100 / Object.keys(c).length;
        var b = ka.now(),
          a = {},
          g;
        for (g in c) {
          var e = c[g],
            u = ka.now(),
            t = 0;
          do {
            f.save();
            f.translate(Math.random() * d, Math.random() * m);
            var l = 3 * Math.random() + .5;
            f.scale(l, l);
            for (l = 0; l < e.length; l++) e[l](f);
            f.restore();
            t++;
            l = ka.now();
          } while (l - u < p);
          a[g] = n * (l - u) / t;
        }
        a.total = ka.now() - b;
        return a;
      }
    };
  }();
  var ya = {
    Xd: function (n, q) {
      var m = q[0];
      n.moveTo(m.x, m.y);
      for (var f = q.length - 1; 0 < f; f--) m = q[f], n.lineTo(m.x, m.y);
    },
    Xi: function (n, q, m, f) {
      var d,
        c = [],
        p = 0,
        k = q.length;
      for (d = 0; d < k; d++) {
        var b = q[d];
        var a = q[(d + 1) % k];
        b = wa.i(b, a);
        b = Math.sqrt(b);
        c.push(b);
        p += b;
      }
      m = f * (m + .5 * f * p / k);
      p = {};
      var g = {},
        e = {},
        u = 0;
      for (d = 0; d < k; d++) {
        b = q[d];
        a = q[(d + 1) % k];
        f = q[(d + 2) % k];
        var t = c[(d + 1) % k];
        t = Math.min(.5, m / t);
        wa.ga(1 - t, a, f, g);
        wa.ga(t, a, f, e);
        u++;
        0 == d && (wa.ga(Math.min(.5, m / c[0]), b, a, p), u++, n.moveTo(p.x, p.y));
        n.quadraticCurveTo(a.x, a.y, g.x, g.y);
        n.lineTo(e.x, e.y);
      }
      return !0;
    }
  };
  function Ba(n) {
    function q() {
      return "embedded" === d.getAttribute("data-foamtree");
    }
    function m(l) {
      e[l] && (e[l].style.opacity = t * u[l]);
    }
    function f(l) {
      l.width = Math.round(p * l.B);
      l.height = Math.round(k * l.B);
    }
    var d,
      c,
      p,
      k,
      b,
      a,
      g = [],
      e = {},
      u = {},
      t = 0;
    this.M = function (l) {
      d = l;
      0 !== d.clientWidth && 0 !== d.clientHeight || Ca.i("element has zero dimensions: " + d.clientWidth + " x " + d.clientHeight + ".");
      d.innerHTML = "";
      p = d.clientWidth;
      k = d.clientHeight;
      b = 0 !== p ? p : void 0;
      a = 0 !== k ? k : void 0;
      q() && Ca.i("visualization already embedded in the element.");
      d.setAttribute("data-foamtree", "embedded");
      c = document.createElement("div");
      c.style.width = "100%";
      c.style.height = "100%";
      c.style.position = "relative";
      d.appendChild(c);
      n.j.D("stage:initialized", this, c, p, k);
    };
    this.$a = function () {
      q() && (d.removeAttribute("data-foamtree"), g = [], e = {}, d.removeChild(c), n.j.D("stage:disposed", this, c));
    };
    this.u = function () {
      p = d.clientWidth;
      k = d.clientHeight;
      if (0 !== p && 0 !== k && (p !== b || k !== a)) {
        for (var l = g.length - 1; 0 <= l; l--) f(g[l]);
        n.j.D("stage:resized", b, a, p, k);
        b = p;
        a = k;
      }
    };
    this.Li = function (l, h) {
      l.B = h;
      f(l);
    };
    this.fc = function (l, h, r) {
      var y = document.createElement("canvas");
      y.setAttribute("style", "position: absolute; top: 0; bottom: 0; left: 0; right: 0; width: 100%; height: 100%; -webkit-touch-callout: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;");
      y.B = h;
      f(y);
      g.push(y);
      e[l] = y;
      u[l] = 1;
      m(l);
      r || c.appendChild(y);
      n.j.D("stage:newLayer", l, y);
      return y;
    };
    this.bc = function (l, h) {
      P.V(h) || (u[l] = h, m(l));
      return u[l];
    };
    this.i = function (l) {
      P.V(l) || (t = l, P.Aa(e, function (h, r) {
        m(r);
      }));
      return t;
    };
  }
  ;
  function Da(n) {
    function q(v, z, E) {
      w = !0;
      u.x = 0;
      u.y = 0;
      t.x = 0;
      t.y = 0;
      d = g;
      c.x = e.x;
      c.y = e.y;
      z();
      p *= v;
      E ? k = p / d : k = v;
      k = Math.max(.25 / d, k);
      return !0;
    }
    function m(v, z) {
      z.x = v.x / g + e.x;
      z.y = v.y / g + e.y;
      return z;
    }
    function f(v, z, E, D, H, O, G, B, M) {
      var Q = (v - E) * (O - B) - (z - D) * (H - G);
      if (1E-5 > Math.abs(Q)) return !1;
      M.x = ((v * D - z * E) * (H - G) - (v - E) * (H * B - O * G)) / Q;
      M.y = ((v * D - z * E) * (O - B) - (z - D) * (H * B - O * G)) / Q;
      return !0;
    }
    var d = 1,
      c = {
        x: 0,
        y: 0
      },
      p = 1,
      k = 1,
      b = 1,
      a = {
        x: 0,
        y: 0
      },
      g = 1,
      e = {
        x: 0,
        y: 0
      },
      u = {
        x: 0,
        y: 0
      },
      t = {
        x: 0,
        y: 0
      },
      l,
      h,
      r = {
        x: 0,
        y: 0,
        w: 0,
        o: 0
      },
      y = {
        x: 0,
        y: 0,
        w: 0,
        o: 0,
        scale: 1
      },
      w = !0;
    n.j.subscribe("stage:initialized", function (v, z, E, D) {
      l = E;
      h = D;
      r.x = 0;
      r.y = 0;
      r.w = E;
      r.o = D;
      y.x = 0;
      y.y = 0;
      y.w = E;
      y.o = D;
      y.scale = 1;
    });
    n.j.subscribe("stage:resized", function (v, z, E, D) {
      function H(M) {
        M.x *= G;
        M.y *= B;
      }
      function O(M) {
        H(M);
        M.w *= G;
        M.o *= B;
      }
      l = E;
      h = D;
      var G = E / v,
        B = D / z;
      H(c);
      H(e);
      H(a);
      H(u);
      H(t);
      O(r);
      O(y);
    });
    this.Pb = function (v, z) {
      return q(z, function () {
        m(v, a);
      }, !0);
    };
    this.ga = function (v, z) {
      if (1 === Math.round(1E4 * z) / 1E4) {
        z = r.x - e.x;
        var E = r.y - e.y;
        q(1, function () {}, !0);
        return this.i(-z, -E);
      }
      return q(z, function () {
        for (var D = !1; !D;) {
          D = Math.random();
          var H = Math.random(),
            O = Math.random(),
            G = Math.random();
          D = f(v.x + D * v.w, v.y + H * v.o, r.x + D * r.w, r.y + H * r.o, v.x + O * v.w, v.y + G * v.o, r.x + O * r.w, r.y + G * r.o, a);
        }
      }, !0);
    };
    this.kc = function (v, z) {
      var E = v.w / v.o;
      var D = l / h;
      if (E < D) {
        var H = v.o * D;
        var O = v.o;
        E = v.x - .5 * (H - v.w);
        D = v.y;
      } else E > D ? (H = v.w, O = v.w * h / l, E = v.x, D = v.y - .5 * (O - v.o)) : (E = v.x, D = v.y, H = v.w, O = v.o);
      E -= H * z;
      D -= O * z;
      H *= 1 + 2 * z;
      if (f(E, D, e.x, e.y, E + H, D, e.x + l / g, e.y, a)) return q(l / g / H, P.qa, !1);
      w = !1;
      return this.i(g * (e.x - E), g * (e.y - D));
    };
    this.i = function (v, z) {
      v = Math.round(1E4 * v) / 1E4;
      z = Math.round(1E4 * z) / 1E4;
      t.x += v / g;
      t.y += z / g;
      return 0 !== v || 0 !== z;
    };
    this.reset = function (v) {
      v && this.content(0, 0, l, h);
      return this.ga({
        x: r.x + e.x,
        y: r.y + e.y,
        w: r.w / g,
        o: r.o / g
      }, b / p);
    };
    this.Hb = function (v) {
      b = Math.min(1, Math.round(1E4 * (v || p)) / 1E4);
    };
    this.u = function () {
      return e.x < r.x ? (r.x - e.x) * g : e.x + l / g > r.x + r.w ? -(e.x + l / g - r.x - r.w) * g : 0;
    };
    this.H = function () {
      return e.y < r.y ? (r.y - e.y) * g : e.y + h / g > r.y + r.o ? -(e.y + h / g - r.y - r.o) * g : 0;
    };
    this.update = function (v) {
      var z = Math.abs(Math.log(k));
      6 > z ? z = 2 : (z /= 4, z += 3 * z * (1 < k ? v : 1 - v));
      z = 1 < k ? Math.pow(v, z) : 1 - Math.pow(1 - v, z);
      z = (w ? z : 1) * (k - 1) + 1;
      g = d * z;
      e.x = a.x - (a.x - c.x) / z;
      e.y = a.y - (a.y - c.y) / z;
      e.x -= u.x * (1 - v) + t.x * v;
      e.y -= u.y * (1 - v) + t.y * v;
      1 === v && (u.x = t.x, u.y = t.y);
      y.x = e.x;
      y.y = e.y;
      y.w = l / g;
      y.o = h / g;
      y.scale = g;
    };
    this.T = function (v) {
      v.x = y.x;
      v.y = y.y;
      v.scale = y.scale;
      return v;
    };
    this.absolute = function (v, z) {
      return m(v, z || {});
    };
    this.Xc = function (v, z) {
      z = z || {};
      z.x = (v.x - e.x) * g;
      z.y = (v.y - e.y) * g;
      return z;
    };
    this.sc = function (v) {
      return this.scale() < b / v;
    };
    this.Cd = function () {
      return P.rd(g, 1);
    };
    this.scale = function () {
      return Math.round(1E4 * g) / 1E4;
    };
    this.content = function (v, z, E, D) {
      r.x = v;
      r.y = z;
      r.w = E;
      r.o = D;
    };
    this.uc = function (v, z) {
      var E;
      for (E = v.length - 1; 0 <= E; E--) {
        var D = v[E];
        D.save();
        D.scale(g, g);
        D.translate(-e.x, -e.y);
      }
      z(y);
      for (E = v.length - 1; 0 <= E; E--) D = v[E], D.restore();
    };
  }
  ;
  var Ea = new function () {
    function n(m) {
      if ("hsl" == m.model || "hsla" == m.model) return m;
      var f = m.r /= 255,
        d = m.g /= 255,
        c = m.b /= 255,
        p = Math.max(f, d, c),
        k = Math.min(f, d, c),
        b = (p + k) / 2;
      if (p == k) var a = k = 0;else {
        var g = p - k;
        k = .5 < b ? g / (2 - p - k) : g / (p + k);
        switch (p) {
          case f:
            a = (d - c) / g + (d < c ? 6 : 0);
            break;
          case d:
            a = (c - f) / g + 2;
            break;
          case c:
            a = (f - d) / g + 4;
        }
        a /= 6;
      }
      m.h = 360 * a;
      m.s = 100 * k;
      m.l = 100 * b;
      m.model = "hsl";
      return m;
    }
    var q = {
      h: 0,
      s: 0,
      l: 0,
      a: 1,
      model: "hsla"
    };
    this.u = function (m) {
      return P.Dc(m) ? n(Ea.ga(m)) : P.yb(m) ? n(m) : q;
    };
    this.ga = function (m) {
      var f;
      return (f = /rgba\(\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*\)/.exec(m)) && 5 == f.length ? {
        r: parseFloat(f[1]),
        g: parseFloat(f[2]),
        b: parseFloat(f[3]),
        a: parseFloat(f[4]),
        model: "rgba"
      } : (f = /hsla\(\s*([^,\s]+)\s*,\s*([^,%\s]+)%\s*,\s*([^,\s%]+)%\s*,\s*([^,\s]+)\s*\)/.exec(m)) && 5 == f.length ? {
        h: parseFloat(f[1]),
        s: parseFloat(f[2]),
        l: parseFloat(f[3]),
        a: parseFloat(f[4]),
        model: "hsla"
      } : (f = /rgb\(\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*\)/.exec(m)) && 4 == f.length ? {
        r: parseFloat(f[1]),
        g: parseFloat(f[2]),
        b: parseFloat(f[3]),
        a: 1,
        model: "rgb"
      } : (f = /hsl\(\s*([^,\s]+)\s*,\s*([^,\s%]+)%\s*,\s*([^,\s%]+)%\s*\)/.exec(m)) && 4 == f.length ? {
        h: parseFloat(f[1]),
        s: parseFloat(f[2]),
        l: parseFloat(f[3]),
        a: 1,
        model: "hsl"
      } : (f = /#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})/.exec(m)) && 4 == f.length ? {
        r: parseInt(f[1], 16),
        g: parseInt(f[2], 16),
        b: parseInt(f[3], 16),
        a: 1,
        model: "rgb"
      } : (f = /#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])/.exec(m)) && 4 == f.length ? {
        r: 17 * parseInt(f[1], 16),
        g: 17 * parseInt(f[2], 16),
        b: 17 * parseInt(f[3], 16),
        a: 1,
        model: "rgb"
      } : q;
    };
    this.T = function (m) {
      function f(a, g, e) {
        0 > e && (e += 1);
        1 < e && --e;
        return e < 1 / 6 ? a + 6 * (g - a) * e : .5 > e ? g : e < 2 / 3 ? a + (g - a) * (2 / 3 - e) * 6 : a;
      }
      function d(a, g, e) {
        return Math.sqrt(a * a * .241 + g * g * .691 + e * e * .068) / 255;
      }
      if ("rgb" == m.model || "rgba" == m.model) return d(m.r, m.g, m.b);
      var c = m.l / 100;
      var p = m.s / 100;
      var k = m.h / 360;
      if (0 == m.yj) c = m = k = c;else {
        p = .5 > c ? c * (1 + p) : c + p - c * p;
        var b = 2 * c - p;
        c = f(b, p, k + 1 / 3);
        m = f(b, p, k);
        k = f(b, p, k - 1 / 3);
      }
      return d(255 * c, 255 * m, 255 * k);
    };
    this.wa = function (m) {
      if (P.Dc(m)) return m;
      if (P.yb(m)) switch (m.model) {
        case "hsla":
          return Ea.sa(m);
        case "hsl":
          return Ea.H(m);
        case "rgba":
          return Ea.ua(m);
        case "rgb":
          return Ea.ta(m);
        default:
          return "#000";
      } else return "#000";
    };
    this.ua = function (m) {
      return "rgba(" + (.5 + m.r | 0) + "," + (.5 + m.g | 0) + "," + (.5 + m.b | 0) + "," + m.a + ")";
    };
    this.ta = function (m) {
      return "rgba(" + (.5 + m.r | 0) + "," + (.5 + m.g | 0) + "," + (.5 + m.b | 0) + ")";
    };
    this.sa = function (m) {
      return "hsla(" + (.5 + m.h | 0) + "," + (.5 + m.s | 0) + "%," + (.5 + m.l | 0) + "%," + m.a + ")";
    };
    this.H = function (m) {
      return "hsl(" + (.5 + m.h | 0) + "," + (.5 + m.s | 0) + "%," + (.5 + m.l | 0) + "%)";
    };
    this.i = function (m, f, d) {
      return "hsl(" + (.5 + m | 0) + "," + (.5 + f | 0) + "%," + (.5 + d | 0) + "%)";
    };
  }();
  function Fa() {
    var n = !1,
      q,
      m = [],
      f = this,
      d = new function () {
        this.then = function (c) {
          c && (n ? c.apply(f, q) : m.push(c));
          return this;
        };
        this.Jg = function (c) {
          f = c;
          return {
            then: this.then
          };
        };
      }();
    this.resolve = function () {
      q = arguments;
      for (var c = 0; c < m.length; c++) m[c].apply(f, q);
      n = !0;
      return this;
    };
    this.promise = function () {
      return d;
    };
  }
  function Ga(n) {
    var q = new Fa(),
      m = n.length;
    if (0 < n.length) for (var f = n.length - 1; 0 <= f; f--) n[f].then(function () {
      0 === --m && q.resolve();
    });else q.resolve();
    return q.promise();
  }
  function Ha(n) {
    var q = 0;
    this.i = function () {
      q++;
    };
    this.u = function () {
      q--;
      0 === q && n();
    };
    this.clear = function () {
      q = 0;
    };
    this.initial = function () {
      return 0 === q;
    };
  }
  ;
  var Ia = {
    re: function (n, q, m, f) {
      f = f || {};
      try {
        var d = n.getBoundingClientRect();
      } catch (p) {
        if (!Ia.Mh) {
          Ia.Mh = !0;
          window.console.log("getBoundingClientRect() failed.");
          window.console.log("Element", n);
          d = window.console;
          for (var c = d.log; null !== n.parentElement;) n = n.parentElement;
          c.call(d, "Attached to DOM", n === document.body.parentElement);
        }
        d = {
          left: 0,
          top: 0
        };
      }
      f.x = q - d.left;
      f.y = m - d.top;
      return f;
    }
  };
  function Ja() {
    var n = document,
      q = {};
    this.addEventListener = function (m, f, d) {
      var c = q[m];
      c || (c = [], q[m] = c);
      c.push(f);
      n.addEventListener(m, f, d);
    };
    this.i = function () {
      P.Aa(q, function (m, f) {
        for (var d = m.length - 1; 0 <= d; d--) n.removeEventListener(f, m[d]);
      });
    };
  }
  ;
  function Ka(n) {
    function q(G) {
      return function (B) {
        m(B) && G.apply(this, arguments);
      };
    }
    function m(G) {
      for (G = G.target; G;) {
        if (G === n) return !0;
        G = G.parentElement;
      }
      return !1;
    }
    function f(G, B, M) {
      M = M || {};
      d(G, M);
      for (var Q = 0; Q < B.length; Q++) B[Q].call(G.target, M);
      d(G, M);
      (void 0 === M.Fb && M.di || "prevent" === M.Fb) && G.preventDefault();
      return M;
    }
    function d(G, B) {
      Ia.re(n, G.clientX, G.clientY, B);
      B.altKey = G.altKey;
      B.metaKey = G.metaKey;
      B.ctrlKey = G.ctrlKey;
      B.shiftKey = G.shiftKey;
      B.mb = 3 === G.which;
      return B;
    }
    var c = new Ja(),
      p = [],
      k = [],
      b = [],
      a = [],
      g = [],
      e = [],
      u = [],
      t = [],
      l = [],
      h = [],
      r = [];
    this.i = function (G) {
      p.push(G);
    };
    this.u = function (G) {
      g.push(G);
    };
    this.sa = function (G) {
      k.push(G);
    };
    this.wa = function (G) {
      b.push(G);
    };
    this.La = function (G) {
      a.push(G);
    };
    this.ua = function (G) {
      r.push(G);
    };
    this.ta = function (G) {
      e.push(G);
    };
    this.Ka = function (G) {
      u.push(G);
    };
    this.ga = function (G) {
      t.push(G);
    };
    this.H = function (G) {
      l.push(G);
    };
    this.T = function (G) {
      h.push(G);
    };
    this.$a = function () {
      c.i();
    };
    var y,
      w,
      v,
      z,
      E = {
        x: 0,
        y: 0
      },
      D = {
        x: 0,
        y: 0
      },
      H = !1,
      O = !1;
    c.addEventListener("mousedown", q(function (G) {
      if (G.target !== n) {
        var B = f(G, b);
        D.x = B.x;
        D.y = B.y;
        E.x = B.x;
        E.y = B.y;
        H = !0;
        f(G, t);
        w = !1;
        y = window.setTimeout(function () {
          100 > wa.i(E, B) && (window.clearTimeout(z), f(G, k), w = !0);
        }, 400);
      }
    }));
    c.addEventListener("mouseup", function (G) {
      var B = f(G, a);
      H && (O && f(G, h), window.clearTimeout(y), w || O || !m(G) || (B = {
        x: B.x,
        y: B.y
      }, v && 100 > wa.i(B, v) ? f(G, g) : f(G, p), v = B, z = window.setTimeout(function () {
        v = null;
      }, 350)), O = H = !1);
    });
    c.addEventListener("mousemove", function (G) {
      var B = d(G, {});
      m(G) && f(G, e, {
        type: "move"
      });
      E.x = B.x;
      E.y = B.y;
      H && !O && 100 < wa.i(D, E) && (O = !0);
      O && f(G, l, B);
    });
    c.addEventListener("mouseout", q(function (G) {
      f(G, u, {
        type: "out"
      });
    }));
    c.addEventListener("wheel", q(function () {
      return function (G) {
        if ("deltaY" in G) var B = G.deltaY;else B = 0, "detail" in G && (B = G.detail), "wheelDelta" in G && (B = -G.wheelDelta / 120), "wheelDeltaY" in G && (B = -G.wheelDeltaY / 120), "axis" in G && G.axis === G.HORIZONTAL_AXIS && (B = 0), B *= 10;
        B && G.deltaMode && (B = 1 === G.deltaMode ? 67 * B : 800 * B);
        f(G, r, {
          hd: -B / 200,
          di: !0
        });
      };
    }()), {
      passive: !1
    });
    c.addEventListener("contextmenu", q(function (G) {
      G.preventDefault();
    }));
  }
  ;
  var La = function () {
    function n(d) {
      return function (c) {
        return Math.pow(c, d);
      };
    }
    function q(d) {
      return function (c) {
        return 1 - Math.pow(1 - c, d);
      };
    }
    function m(d) {
      return function (c) {
        return 1 > (c *= 2) ? .5 * Math.pow(c, d) : 1 - .5 * Math.abs(Math.pow(2 - c, d));
      };
    }
    function f(d) {
      return function (c) {
        for (var p = 0; p < d.length; p++) c = (0, d[p])(c);
        return c;
      };
    }
    return {
      ia: function (d) {
        switch (d) {
          case "linear":
            return La.Cb;
          case "bounce":
            return La.xg;
          case "squareIn":
            return La.Vf;
          case "squareOut":
            return La.Ib;
          case "squareInOut":
            return La.Wf;
          case "cubicIn":
            return La.Ag;
          case "cubicOut":
            return La.ie;
          case "cubicInOut":
            return La.Bg;
          case "quadIn":
            return La.wi;
          case "quadOut":
            return La.yi;
          case "quadInOut":
            return La.xi;
          default:
            return La.Cb;
        }
      },
      Cb: function (d) {
        return d;
      },
      xg: f([m(2), function (d) {
        return 0 === d ? 0 : 1 === d ? 1 : d * (d * (d * (d * (25.9425 * d - 85.88) + 105.78) - 58.69) + 13.8475);
      }]),
      Vf: n(2),
      Ib: q(2),
      Wf: m(2),
      Ag: n(3),
      ie: q(3),
      Bg: m(3),
      wi: n(2),
      yi: q(2),
      xi: m(2),
      nj: f
    };
  }();
  var P = {
    V: function (n) {
      return void 0 === n;
    },
    Ue: function (n) {
      return null === n;
    },
    Cc: function (n) {
      return "[object Number]" === Object.prototype.toString.call(n);
    },
    Dc: function (n) {
      return "[object String]" === Object.prototype.toString.call(n);
    },
    Se: function (n) {
      return "function" === typeof n;
    },
    yb: function (n) {
      return n === Object(n);
    },
    rd: function (n, q) {
      return 1E-6 > n - q && -1E-6 < n - q;
    },
    Qe: function (n) {
      return P.V(n) || P.Ue(n) || P.Dc(n) && !/\S/.test(n);
    },
    has: function (n, q) {
      return n && n.hasOwnProperty(q);
    },
    cb: function (n, q) {
      if (n) for (var m = q.length - 1; 0 <= m; m--) if (n.hasOwnProperty(q[m])) return !0;
      return !1;
    },
    extend: function (n) {
      P.Fg(Array.prototype.slice.call(arguments, 1), function (q) {
        if (q) for (var m in q) q.hasOwnProperty(m) && (n[m] = q[m]);
      });
      return n;
    },
    wj: function (n, q) {
      return n.map(function (m) {
        return m[q];
      }, []);
    },
    Fg: function (n, q, m) {
      null != n && (n.forEach ? n.forEach(q, m) : P.Aa(n, q, m));
    },
    Aa: function (n, q, m) {
      for (var f in n) if (n.hasOwnProperty(f) && !1 === q.call(m, n[f], f, n)) break;
    },
    I: function () {
      for (var n = 0; n < arguments.length; n++) {
        var q = arguments[n];
        if (!(P.V(q) || P.Cc(q) && isNaN(q) || P.Dc(q) && P.Qe(q))) return q;
      }
    },
    Lf: function (n, q) {
      q = n.indexOf(q);
      0 <= q && n.splice(q, 1);
    },
    Cg: function (n, q, m) {
      var f;
      return function () {
        var d = this,
          c = arguments,
          p = m && !f;
        clearTimeout(f);
        f = setTimeout(function () {
          f = null;
          m || n.apply(d, c);
        }, q);
        p && n.apply(d, c);
      };
    },
    defer: function (n) {
      setTimeout(n, 1);
    },
    uj: function (n) {
      return n;
    },
    qa: function () {}
  };
  var Ma = {
    Lh: function (n, q, m) {
      return ea.Jh() ? function () {
        var f = q + ":" + JSON.stringify(arguments),
          d = window.localStorage.getItem(f);
        d && (d = JSON.parse(d));
        if (d && Date.now() - d.t < m) return d.v;
        d = n.apply(this, arguments);
        window.localStorage.setItem(f, JSON.stringify({
          v: d,
          t: Date.now()
        }));
        return d;
      } : n;
    }
  };
  var Na = {
    A: function (n, q) {
      function m() {
        var f = [];
        if (Array.isArray(n)) for (var d = 0; d < n.length; d++) {
          var c = n[d];
          c && f.push(c.apply(q, arguments));
        } else n && f.push(n.apply(q, arguments));
        return f;
      }
      m.empty = function () {
        return 0 === n.length && !P.Se(n);
      };
      return m;
    }
  };
  function Oa() {
    var n = {};
    this.subscribe = function (q, m) {
      var f = n[q];
      f || (f = [], n[q] = f);
      f.push(m);
    };
    this.D = function (q, m) {
      var f = n[q];
      if (f) for (var d = Array.prototype.slice.call(arguments, 1), c = 0; c < f.length; c++) f[c].apply(this, d);
    };
  }
  ;
  function Pa(n) {
    function q(a, g, e) {
      var u = this,
        t,
        l = 0;
      this.id = p++;
      this.name = e ? e : "{unnamed on " + a + "}";
      this.target = function () {
        return a;
      };
      this.zb = function () {
        return -1 != b.indexOf(u);
      };
      this.start = function () {
        if (!u.zb()) {
          if (-1 == b.indexOf(u)) {
            var h = k.now();
            !0 === u.df(h) && (b = b.slice(), b.push(u));
          }
          0 < b.length && n.repeat(f);
        }
        return this;
      };
      this.stop = function () {
        for (c(u); t < g.length; t++) {
          var h = g[t];
          h.Ya && h.hb.call();
        }
        return this;
      };
      this.df = function (h) {
        l++;
        if (0 !== g.length) {
          if (P.V(t)) {
            t = 0;
            var r = g[t];
            r.before && r.before.call(r, h, l, u);
          } else r = g[t];
          for (; t < g.length;) {
            if (r.hb && r.hb.call(r, h, l, u)) return !0;
            r.after && r.after.call(r, h, l, u);
            P.V(t) && (t = -1);
            ++t < g.length && (r = g[t], r.before && r.before.call(r, h, l, u));
          }
        }
        return !1;
      };
    }
    function m(a) {
      return P.V(a) ? b.slice() : b.filter(function (g) {
        return g.target() === a;
      });
    }
    function f() {
      d();
      0 == b.length && n.cancel(f);
    }
    function d() {
      var a = k.now();
      b.forEach(function (g) {
        !0 !== g.df(a) && c(g);
      });
    }
    function c(a) {
      b = b.filter(function (g) {
        return g !== a;
      });
    }
    var p = 0,
      k = ka.create(),
      b = [];
    this.i = function () {
      for (var a = b.length - 1; 0 <= a; a--) b[a].stop();
      b = [];
    };
    this.K = function () {
      function a() {}
      function g(h) {
        function r(D) {
          return P.Se(D) ? D.call(void 0) : D;
        }
        var y = h.target,
          w = h.duration,
          v = h.ba,
          z,
          E;
        this.before = function () {
          z = {};
          for (var D in h.P) y.hasOwnProperty(D) && (z[D] = {
            start: P.V(h.P[D].start) ? y[D] : r(h.P[D].start),
            end: P.V(h.P[D].end) ? y[D] : r(h.P[D].end),
            easing: P.V(h.P[D].easing) ? La.Cb : h.P[D].easing
          });
          E = k.now();
        };
        this.hb = function () {
          var D = k.now() - E;
          D = 0 === w ? 1 : Math.min(w, D) / w;
          for (var H in z) {
            var O = z[H];
            y[H] = O.start + (O.end - O.start) * O.easing(D);
          }
          v && v.call(y, D);
          return 1 > D;
        };
      }
      function e(h, r, y) {
        this.Ya = y;
        this.hb = function () {
          h.call(r);
          return !1;
        };
      }
      function u(h) {
        var r;
        this.before = function (y, w) {
          r = w + h;
        };
        this.hb = function (y, w) {
          return w < r;
        };
      }
      function t(h) {
        var r;
        this.before = function (y) {
          r = y + h;
        };
        this.hb = function (y) {
          return y < r;
        };
      }
      function l(h) {
        this.before = function () {
          h.forEach(function (r) {
            r.start();
          });
        };
        this.hb = function () {
          for (var r = 0; r < h.length; r++) if (h[r].zb()) return !0;
          return !1;
        };
      }
      a.A = function (h, r) {
        return new function () {
          function y(v, z, E, D) {
            return z ? (P.V(E) && (E = h), v.Ob(new e(z, E, D))) : v;
          }
          var w = [];
          this.Ob = function (v) {
            w.push(v);
            return this;
          };
          this.wait = function (v) {
            return this.Ob(new t(v));
          };
          this.$d = function (v) {
            return this.Ob(new u(v || 1));
          };
          this.call = function (v, z) {
            return y(this, v, z, !1);
          };
          this.Ya = function (v, z) {
            return y(this, v, z, !0);
          };
          this.fa = function (v) {
            P.V(v.target) && (v.target = h);
            return this.Ob(new g(v));
          };
          this.Ra = function (v) {
            return this.Ob(new l(v));
          };
          this.done = function () {
            return new q(h, w, r);
          };
          this.start = function () {
            return this.done().start();
          };
          this.i = function () {
            var v = new Fa();
            this.$d().call(v.resolve).done();
            return v.promise();
          };
          this.Ua = function () {
            var v = this.i();
            this.start();
            return v;
          };
        }();
      };
      a.lc = function (h) {
        m(h).forEach(function (r) {
          r.stop();
        });
        return a.A(h, void 0);
      };
      return a;
    }();
  }
  ;
  var Qa = function () {
    var n = {
      qe: function (q, m) {
        if (q.m) {
          q = q.m;
          for (var f = 0; f < q.length; f++) m(q[f], f);
        }
      },
      vc: function (q, m) {
        if (q.m) {
          q = q.m;
          for (var f = 0; f < q.length; f++) if (!1 === n.vc(q[f], m) || !1 === m(q[f], f)) return !1;
        }
      }
    };
    n.L = n.vc;
    n.wc = function (q, m) {
      if (q.m) {
        q = q.m;
        for (var f = 0; f < q.length; f++) if (!1 === m(q[f], f) || !1 === n.wc(q[f], m)) return !1;
      }
    };
    n.za = function (q, m) {
      if (q.m) for (var f = q.m, d = 0; d < f.length; d++) if (!1 === n.za(f[d], m)) return !1;
      return m(q);
    };
    n.oj = n.za;
    n.jd = function (q, m) {
      !1 !== m(q) && n.wc(q, m);
    };
    n.xc = function (q, m) {
      var f = [];
      n.wc(q, function (d) {
        f.push(d);
      });
      return m ? f.filter(m) : f;
    };
    n.pe = function (q, m) {
      for (q = q.parent; q && !1 !== m(q);) q = q.parent;
    };
    n.Nh = function (q, m) {
      for (q = q.parent; q && q !== m;) q = q.parent;
      return !!q;
    };
    return n;
  }();
  var wa = new function () {
    function n(m, f) {
      var d = m.x - f.x;
      m = m.y - f.y;
      return d * d + m * m;
    }
    function q(m, f, d) {
      for (var c = 0; c < m.length; c++) {
        var p = wa.T(m[c], m[c + 1] || m[0], f, d, !0);
        if (p) return p;
      }
    }
    this.T = function (m, f, d, c, p) {
      var k = m.x;
      m = m.y;
      var b = f.x - k;
      f = f.y - m;
      var a = d.x,
        g = d.y;
      d = c.x - a;
      var e = c.y - g;
      c = b * e - d * f;
      if (!(1E-12 >= c && -1E-12 <= c) && (a -= k, g -= m, d = (a * e - d * g) / c, c = (a * f - b * g) / c, 0 <= c && (p || 1 >= c) && 0 <= d && 1 >= d)) return {
        x: k + b * d,
        y: m + f * d
      };
    };
    this.Nb = function (m, f, d, c) {
      var p = m.x;
      m = m.y;
      var k = f.x - p;
      f = f.y - m;
      var b = d.x;
      d = d.y;
      var a = c.x - b;
      c = c.y - d;
      var g = k * c - a * f;
      if (!(1E-12 >= g && -1E-12 <= g) && (c = ((b - p) * c - a * (d - m)) / g, 0 <= c && 1 >= c)) return {
        x: p + k * c,
        y: m + f * c
      };
    };
    this.wa = function (m, f, d) {
      var c = wa.u(f, {}),
        p = wa.u(d, {}),
        k = p.x - c.x,
        b = p.y - c.y,
        a = [];
      for (p = 0; p < d.length; p++) {
        var g = d[p];
        a.push({
          x: g.x - k,
          y: g.y - b
        });
      }
      d = [];
      g = [];
      for (p = 0; p < m.length; p++) {
        var e = m[p],
          u = q(f, c, e);
        u ? (d.push(u), g.push(q(a, c, e))) : (d.push(null), g.push(null));
      }
      for (p = 0; p < m.length; p++) if (u = d[p], e = g[p], u && e) {
        f = m[p];
        a = c;
        var t = u.x - c.x;
        u = u.y - c.y;
        u = Math.sqrt(t * t + u * u);
        if (1E-12 < u) {
          t = f.x - c.x;
          var l = f.y - c.y;
          u = Math.sqrt(t * t + l * l) / u;
          f.x = a.x + u * (e.x - a.x);
          f.y = a.y + u * (e.y - a.y);
        } else f.x = a.x, f.y = a.y;
      }
      for (p = 0; p < m.length; p++) g = m[p], g.x += k, g.y += b;
    };
    this.F = function (m, f) {
      if (0 !== m.length) {
        var d, c;
        var p = d = m[0].x;
        var k = c = m[0].y;
        for (var b = m.length; 0 < --b;) p = Math.min(p, m[b].x), d = Math.max(d, m[b].x), k = Math.min(k, m[b].y), c = Math.max(c, m[b].y);
        f.x = p;
        f.y = k;
        f.w = d - p;
        f.o = c - k;
        return f;
      }
    };
    this.H = function (m) {
      return [{
        x: m.x,
        y: m.y
      }, {
        x: m.x + m.w,
        y: m.y
      }, {
        x: m.x + m.w,
        y: m.y + m.o
      }, {
        x: m.x,
        y: m.y + m.o
      }];
    };
    this.u = function (m, f) {
      for (var d = 0, c = 0, p = m.length, k = m[0], b = 0, a = 1; a < p - 1; a++) {
        var g = m[a],
          e = m[a + 1],
          u = k.y + g.y + e.y,
          t = (g.x - k.x) * (e.y - k.y) - (e.x - k.x) * (g.y - k.y);
        d += t * (k.x + g.x + e.x);
        c += t * u;
        b += t;
      }
      f.x = d / (3 * b);
      f.y = c / (3 * b);
      f.ha = b / 2;
      return f;
    };
    this.Ka = function (m, f) {
      this.u(m, f);
      f.r = Math.sqrt(f.ha / Math.PI);
    };
    this.sa = function (m, f) {
      for (var d = 0; d < m.length; d++) {
        var c = m[d],
          p = m[d + 1] || m[0];
        if (0 > (f.y - c.y) * (p.x - c.x) - (f.x - c.x) * (p.y - c.y)) return !1;
      }
      return !0;
    };
    this.Yc = function (m, f, d) {
      var c = m.x,
        p = f.x;
      m.x > f.x && (c = f.x, p = m.x);
      p > d.x + d.w && (p = d.x + d.w);
      c < d.x && (c = d.x);
      if (c > p) return !1;
      var k = m.y,
        b = f.y,
        a = f.x - m.x;
      1E-7 < Math.abs(a) && (b = (f.y - m.y) / a, m = m.y - b * m.x, k = b * c + m, b = b * p + m);
      k > b && (c = b, b = k, k = c);
      b > d.y + d.o && (b = d.y + d.o);
      k < d.y && (k = d.y);
      return k <= b;
    };
    this.La = function (m, f, d, c, p) {
      var k;
      function b(l, h, r) {
        if (f.x === e.x && f.y === e.y) return r;
        var y = q(m, f, e),
          w = Math.sqrt(n(y, f) / (l * l + h * h));
        return w < a ? (a = w, k = y.x, g = y.y, 0 !== h ? Math.abs(g - f.y) / Math.abs(h) : Math.abs(k - f.x) / Math.abs(l)) : r;
      }
      c = P.I(c, .5);
      p = P.I(p, .5);
      d = P.I(d, 1);
      var a = Number.MAX_VALUE;
      var g = k = 0;
      var e = {
          x: 0,
          y: 0
        },
        u = c * d;
      d = (1 - c) * d;
      c = 1 - p;
      e.x = f.x - u;
      e.y = f.y - p;
      var t = b(u, p, t);
      e.x = f.x + d;
      e.y = f.y - p;
      t = b(d, p, t);
      e.x = f.x - u;
      e.y = f.y + c;
      t = b(u, c, t);
      e.x = f.x + d;
      e.y = f.y + c;
      return t = b(d, c, t);
    };
    this.qb = function (m, f) {
      function d(a, g, e) {
        var u = g.x,
          t = e.x;
        g = g.y;
        e = e.y;
        var l = t - u,
          h = e - g;
        return Math.abs(h * a.x - l * a.y - u * e + t * g) / Math.sqrt(l * l + h * h);
      }
      for (var c = m.length, p = d(f, m[c - 1], m[0]), k = 0; k < c - 1; k++) {
        var b = d(f, m[k], m[k + 1]);
        b < p && (p = b);
      }
      return p;
    };
    this.ua = function (m, f, d) {
      var c;
      d = {
        x: f.x + Math.cos(d),
        y: f.y - Math.sin(d)
      };
      var p = [],
        k = [],
        b = m.length;
      for (c = 0; c < b; c++) {
        var a = wa.Nb(m[c], m[(c + 1) % b], f, d);
        if (a && (p.push(a), 2 == k.push(c))) break;
      }
      if (2 == p.length) {
        a = p[0];
        p = p[1];
        var g = k[0];
        k = k[1];
        var e = [p, a];
        for (c = g + 1; c <= k; c++) e.push(m[c]);
        for (c = [a, p]; k != g;) k = (k + 1) % b, c.push(m[k]);
        m = [e, c];
        b = d.x - f.x;
        c = p.x - a.x;
        0 === b && (b = d.y - f.y, c = p.y - a.y);
        (0 > b ? -1 : 0 < b ? 1 : 0) !== (0 > c ? -1 : 0 < c ? 1 : 0) && m.reverse();
        return m;
      }
    };
    this.ga = function (m, f, d, c) {
      c.x = m * (f.x - d.x) + d.x;
      c.y = m * (f.y - d.y) + d.y;
      return c;
    };
    this.i = n;
    this.ta = function (m, f, d) {
      if (P.Cc(f)) var c = 2 * Math.PI * f / 360;else switch (c = wa.F(m, {}), f) {
        case "random":
          c = Math.random() * Math.PI * 2;
          break;
        case "top":
          c = Math.atan2(-c.o, 0);
          break;
        case "bottom":
          c = Math.atan2(c.o, 0);
          break;
        case "left":
          c = Math.atan2(0, -c.w);
          break;
        case "right":
          c = Math.atan2(0, c.w);
          break;
        case "topleft":
          c = Math.atan2(-c.o, -c.w);
          break;
        case "topright":
          c = Math.atan2(-c.o, c.w);
          break;
        case "bottomleft":
          c = Math.atan2(c.o, -c.w);
          break;
        default:
          c = Math.atan2(c.o, c.w);
      }
      f = wa.u(m, {});
      m = q(m, f, {
        x: f.x + Math.cos(c),
        y: f.y + Math.sin(c)
      });
      return wa.ga(d, m, f, {});
    };
    return this;
  }();
  var Ra = new function () {
    function n(c, p) {
      this.face = c;
      this.Uc = p;
      this.hc = this.Oc = null;
    }
    function q(c, p, k) {
      this.la = [c, p, k];
      this.J = Array(3);
      var b = p.y - c.y,
        a = k.z - c.z,
        g = p.x - c.x;
      p = p.z - c.z;
      var e = k.x - c.x;
      c = k.y - c.y;
      this.Ia = {
        x: b * a - p * c,
        y: p * e - g * a,
        z: g * c - b * e
      };
      this.Za = [];
      this.dd = this.visible = !1;
    }
    this.i = function (c) {
      function p(aa, A, U) {
        var fa = aa.la[0],
          ba = aa.Ia,
          ja = ba.x,
          N = ba.y;
        ba = ba.z;
        var S = Array(a);
        A = A.Za;
        var ca = A.length;
        for (b = 0; b < ca; b++) {
          var I = A[b].Uc;
          S[I.index] = !0;
          0 > ja * (I.x - fa.x) + N * (I.y - fa.y) + ba * (I.z - fa.z) && n.add(aa, I);
        }
        A = U.Za;
        ca = A.length;
        for (b = 0; b < ca; b++) I = A[b].Uc, !0 !== S[I.index] && 0 > ja * (I.x - fa.x) + N * (I.y - fa.y) + ba * (I.z - fa.z) && n.add(aa, I);
      }
      var k,
        b,
        a = c.length;
      for (k = 0; k < a; k++) c[k].index = k, c[k].Rb = null;
      var g = [],
        e;
      if (0 < (e = function () {
        function aa(T, R, W, ha) {
          var x = R.x - T.x;
          var C = R.y - T.y;
          var F = R.z - T.z;
          var L = W.x - T.x,
            K = W.y - T.y,
            J = W.z - T.z;
          var V = C * J - F * K;
          F = F * L - x * J;
          x = x * K - C * L;
          return V * ha.x + F * ha.y + x * ha.z > V * T.x + F * T.y + x * T.z ? new q(T, R, W) : new q(W, R, T);
        }
        function A(T, R, W, ha) {
          function x(C, F, L) {
            C = C.la;
            F = C[0] == F ? 0 : C[1] == F ? 1 : 2;
            return C[(F + 1) % 3] != L ? (F + 2) % 3 : F;
          }
          R.J[x(R, W, ha)] = T;
          T.J[x(T, ha, W)] = R;
        }
        if (4 > a) return 0;
        var U = c[0],
          fa = c[1],
          ba = c[2],
          ja = c[3],
          N = aa(U, fa, ba, ja),
          S = aa(U, ba, ja, fa),
          ca = aa(U, fa, ja, ba),
          I = aa(fa, ba, ja, U);
        A(N, S, ba, U);
        A(N, ca, U, fa);
        A(N, I, fa, ba);
        A(S, ca, ja, U);
        A(S, I, ba, ja);
        A(ca, I, ja, fa);
        g.push(N, S, ca, I);
        for (U = 4; U < a; U++) for (fa = c[U], ba = 0; 4 > ba; ba++) ja = g[ba], N = ja.la[0], S = ja.Ia, 0 > S.x * (fa.x - N.x) + S.y * (fa.y - N.y) + S.z * (fa.z - N.z) && n.add(ja, fa);
        return 4;
      }())) {
        for (; e < a;) {
          var u = c[e];
          if (u.Rb) {
            for (k = u.Rb; null !== k;) k.face.visible = !0, k = k.hc;
            k = 0;
            a: for (; k < g.length; k++) {
              var t = g[k];
              if (!1 === t.visible) {
                var l = t.J;
                for (b = 0; 3 > b; b++) if (!0 === l[b].visible) {
                  var h = t;
                  var r = b;
                  break a;
                }
              }
            }
            t = [];
            l = [];
            var y = h,
              w = r;
            do if (t.push(y), l.push(w), w = (w + 1) % 3, !1 === y.J[w].visible) {
              do for (k = y.la[w], y = y.J[w], b = 0; 3 > b; b++) y.la[b] == k && (w = b); while (!1 === y.J[w].visible && (y !== h || w !== r));
            } while (y !== h || w !== r);
            var v = null,
              z = null;
            for (k = 0; k < t.length; k++) {
              y = t[k];
              w = l[k];
              var E = y.J[w],
                D = y.la[(w + 1) % 3],
                H = y.la[w],
                O = D.y - u.y,
                G = H.z - u.z,
                B = D.x - u.x,
                M = D.z - u.z,
                Q = H.x - u.x,
                X = H.y - u.y;
              if (0 < d.length) {
                var Y = d.pop();
                Y.la[0] = u;
                Y.la[1] = D;
                Y.la[2] = H;
                Y.Ia.x = O * G - M * X;
                Y.Ia.y = M * Q - B * G;
                Y.Ia.z = B * X - O * Q;
                Y.Za.length = 0;
                Y.visible = !1;
                Y.dd = !0;
              } else Y = {
                la: [u, D, H],
                J: Array(3),
                Ia: {
                  x: O * G - M * X,
                  y: M * Q - B * G,
                  z: B * X - O * Q
                },
                Za: [],
                visible: !1
              };
              g.push(Y);
              y.J[w] = Y;
              Y.J[1] = y;
              null !== z && (z.J[0] = Y, Y.J[2] = z);
              z = Y;
              null === v && (v = Y);
              p(Y, y, E);
            }
            z.J[0] = v;
            v.J[2] = z;
            k = [];
            for (b = 0; b < g.length; b++) if (t = g[b], !0 === t.visible) {
              l = t.Za;
              y = l.length;
              for (u = 0; u < y; u++) w = l[u], v = w.Oc, z = w.hc, null !== v && (v.hc = z), null !== z && (z.Oc = v), null === v && (w.Uc.Rb = z), m.push(w);
              t.dd && d.push(t);
            } else k.push(t);
            g = k;
          }
          e++;
        }
        for (k = 0; k < g.length; k++) t = g[k], t.dd && d.push(t);
      }
      return {
        se: g
      };
    };
    n.add = function (c, p) {
      if (0 < m.length) {
        var k = m.pop();
        k.face = c;
        k.Uc = p;
        k.hc = null;
        k.Oc = null;
      } else k = new n(c, p);
      c.Za.push(k);
      c = p.Rb;
      null !== c && (c.Oc = k);
      k.hc = c;
      p.Rb = k;
    };
    for (var m = Array(2E3), f = 0; f < m.length; f++) m[f] = new n(null, null);
    var d = Array(1E3);
    for (f = 0; f < d.length; f++) d[f] = {
      la: Array(3),
      J: Array(3),
      Ia: {
        x: 0,
        y: 0,
        z: 0
      },
      Za: [],
      visible: !1
    };
  }();
  var Sa = new function () {
    function n(q, m, f, d, c, p, k, b) {
      var a = (q - f) * (p - b) - (m - d) * (c - k);
      if (!(1E-12 > Math.abs(a))) return {
        x: ((q * d - m * f) * (c - k) - (q - f) * (c * b - p * k)) / a,
        y: ((q * d - m * f) * (p - b) - (m - d) * (c * b - p * k)) / a
      };
    }
    this.i = function (q, m) {
      for (var f = q[0], d = f.x, c = f.y, p = f.x, k = f.y, b = q.length - 1; 0 < b; b--) f = q[b], d = Math.min(d, f.x), c = Math.min(c, f.y), p = Math.max(p, f.x), k = Math.max(k, f.y);
      if (!(p - d < 3 * m || k - c < 3 * m)) {
        a: {
          f = !0;
          void 0 == f && (f = !1);
          d = [];
          c = q.length;
          for (p = 0; p <= c; p++) {
            k = q[p % c];
            b = q[(p + 1) % c];
            var a = q[(p + 2) % c];
            var g = b.x - k.x;
            var e = b.y - k.y;
            var u = Math.sqrt(g * g + e * e);
            var t = m * g / u,
              l = m * e / u;
            g = a.x - b.x;
            e = a.y - b.y;
            u = Math.sqrt(g * g + e * e);
            g = m * g / u;
            e = m * e / u;
            if (k = n(k.x - l, k.y + t, b.x - l, b.y + t, b.x - e, b.y + g, a.x - e, a.y + g)) if (d.push(k), a = d.length, f && 3 <= a && (k = d[a - 3], b = d[a - 2], a = d[a - 1], 0 > (b.x - k.x) * (a.y - k.y) - (a.x - k.x) * (b.y - k.y))) {
              f = void 0;
              break a;
            }
          }
          d.shift();
          f = 3 > d.length ? void 0 : d;
        }
        if (!f) a: {
          d = q.slice(0);
          for (f = 0; f < q.length; f++) {
            p = q[f % q.length];
            b = q[(f + 1) % q.length];
            a = b.x - p.x;
            c = b.y - p.y;
            k = Math.sqrt(a * a + c * c);
            a = m * a / k;
            k = m * c / k;
            c = p.x - k;
            p = p.y + a;
            k = b.x - k;
            a = b.y + a;
            if (0 != d.length) {
              l = c - k;
              e = p - a;
              t = [];
              g = u = !0;
              for (b = 0; b < d.length; b++) {
                var h = l * (p - d[b].y) - (c - d[b].x) * e;
                1E-12 >= h && -1E-12 <= h && (h = 0);
                t.push(h);
                0 < h && (u = !1);
                0 > h && (g = !1);
              }
              if (u) d = [];else if (!g) {
                l = [];
                for (b = 0; b < d.length; b++) e = (b + 1) % d.length, u = t[b], g = t[e], 0 <= u && l.push(d[b]), (0 < u && 0 > g || 0 > u && 0 < g) && l.push(n(d[b].x, d[b].y, d[e].x, d[e].y, c, p, k, a));
                d = l;
              }
            }
            if (3 > d.length) {
              f = void 0;
              break a;
            }
          }
          f = d;
        }
        return f;
      }
    };
    return this;
  }();
  var Ta = new function () {
    function n(q) {
      for (var m = q[0].x, f = q[0].y, d = m, c = f, p = 1; p < q.length; p++) {
        var k = q[p];
        m = Math.min(m, k.x);
        f = Math.min(f, k.y);
        d = Math.max(d, k.x);
        c = Math.max(c, k.y);
      }
      q = d - m;
      c -= f;
      return [{
        x: m + 2 * q,
        y: f + 2 * c,
        w: 0
      }, {
        x: m + 2 * q,
        y: f - 2 * c,
        w: 0
      }, {
        x: m - 2 * q,
        y: f + 2 * c,
        w: 0
      }];
    }
    this.i = function (q, m) {
      function f(l) {
        var h = [l[0]],
          r = l[0][0],
          y = l[0][1],
          w = l.length,
          v = 1;
        a: for (; v < w; v++) for (var z = 1; z < w; z++) {
          var E = l[z];
          if (null !== E) {
            if (E[1] === r) if (h.unshift(E), r = E[0], l[z] = null, h.length === w) break a;else continue;
            if (E[0] === y && (h.push(E), y = E[1], l[z] = null, h.length === w)) break a;
          }
        }
        h[0][0] != h[w - 1][1] && h.push([h[w - 1][1], h[0][0]]);
        return h;
      }
      function d(l, h, r, y) {
        var w = [],
          v = [],
          z = r.length,
          E,
          D = h.length,
          H = 0,
          O = -1,
          G = -1,
          B,
          M = y;
        for (y = 0; y < z; y++) {
          var Q = (M + 1) % z,
            X = r[M][0],
            Y = r[Q][0];
          if (1E-12 < wa.i(X.ea, Y.ea)) if (X.kb && Y.kb) {
            var aa = [],
              A = [];
            for (E = 0; E < D; E++) {
              var U = (H + 1) % D;
              if (B = wa.T(h[H], h[U], X.ea, Y.ea, !1)) if (A.push(H), 2 === aa.push(B)) break;
              H = U;
            }
            if (2 === aa.length) {
              E = aa[1];
              B = wa.i(X.ea, aa[0]);
              E = wa.i(X.ea, E);
              X = B < E ? 0 : 1;
              B = B < E ? 1 : 0;
              E = A[X];
              -1 === O && (O = E);
              if (-1 !== G) for (; E != G;) G = (G + 1) % D, w.push(h[G]), v.push(null);
              w.push(aa[X], aa[B]);
              v.push(r[M][2], null);
              G = A[B];
            }
          } else if (X.kb && !Y.kb) for (E = 0; E < D; E++) {
            U = (H + 1) % D;
            if (B = wa.T(h[H], h[U], X.ea, Y.ea, !1)) {
              if (-1 !== G) for (aa = G; H != aa;) aa = (aa + 1) % D, w.push(h[aa]), v.push(null);
              w.push(B);
              v.push(r[M][2]);
              -1 === O && (O = H);
              break;
            }
            H = U;
          } else if (!X.kb && Y.kb) for (E = 0; E < D; E++) {
            U = (H + 1) % D;
            if (B = wa.T(h[H], h[U], X.ea, Y.ea, !1)) {
              w.push(X.ea, B);
              v.push(r[M][2], null);
              G = H;
              break;
            }
            H = U;
          } else w.push(X.ea), v.push(r[M][2]);
          M = Q;
        }
        if (0 == w.length) v = w = null;else if (-1 !== G) for (; O != G;) G = (G + 1) % D, w.push(h[G]), v.push(null);
        l.C = w;
        l.J = v;
      }
      if (1 === q.length) q[0].C = m.slice(0), q[0].J = [];else {
        var c;
        var p = n(m);
        var k = [];
        for (c = 0; c < p.length; c++) {
          var b = p[c];
          k.push({
            x: b.x,
            y: b.y,
            z: b.x * b.x + b.y * b.y - b.w
          });
        }
        for (c = 0; c < q.length; c++) b = q[c], b.C = null, k.push({
          x: b.x,
          y: b.y,
          z: b.x * b.x + b.y * b.y - b.w
        });
        var a = Ra.i(k).se;
        (function () {
          for (c = 0; c < a.length; c++) {
            var l = a[c],
              h = l.la,
              r = h[0],
              y = h[1],
              w = h[2];
            h = r.x;
            var v = r.y;
            r = r.z;
            var z = y.x,
              E = y.y;
            y = y.z;
            var D = w.x,
              H = w.y;
            w = w.z;
            var O = h * (E - H) + z * (H - v) + D * (v - E);
            l.ea = {
              x: -(v * (y - w) + E * (w - r) + H * (r - y)) / O / 2,
              y: -(r * (z - D) + y * (D - h) + w * (h - z)) / O / 2
            };
          }
        })();
        (function (l) {
          for (c = 0; c < a.length; c++) {
            var h = a[c];
            h.kb = !wa.sa(l, h.ea);
          }
        })(m);
        k = function (l, h) {
          var r = Array(h.length),
            y;
          for (y = 0; y < r.length; y++) r[y] = [];
          for (y = 0; y < l.length; y++) {
            var w = l[y];
            if (!(0 > w.Ia.z)) for (var v = w.J, z = 0; z < v.length; z++) {
              var E = v[z];
              if (!(0 > E.Ia.z)) {
                var D = w.la,
                  H = D[(z + 1) % 3].index;
                D = D[z].index;
                2 < H && r[H - 3].push([w, E, 2 < D ? h[D - 3] : null]);
              }
            }
          }
          return r;
        }(a, q);
        for (c = 0; c < q.length; c++) if (b = k[c], 0 !== b.length) {
          var g = q[c];
          b = f(b);
          var e = b.length,
            u = -1;
          for (p = 0; p < e; p++) b[p][0].kb && (u = p);
          if (0 <= u) d(g, m, b, u);else {
            u = [];
            var t = [];
            for (p = 0; p < e; p++) 1E-12 < wa.i(b[p][0].ea, b[(p + 1) % e][0].ea) && (u.push(b[p][0].ea), t.push(b[p][2]));
            g.C = u;
            g.J = t;
          }
          g.C && 3 > g.C.length && (g.C = null, g.J = null);
        }
      }
    };
    this.u = function (q, m) {
      var f,
        d = !1,
        c = q.length;
      for (f = 0; f < c; f++) {
        var p = q[f];
        null === p.C && (d = !0);
        p.ae = p.w;
      }
      if (d) {
        d = n(m);
        var k = [];
        f = q.length;
        for (p = 0; p < d.length; p++) {
          var b = d[p];
          k.push({
            x: b.x,
            y: b.y,
            z: b.x * b.x + b.y * b.y
          });
        }
        for (p = 0; p < f; p++) b = q[p], k.push({
          x: b.x,
          y: b.y,
          z: b.x * b.x + b.y * b.y
        });
        b = Ra.i(k).se;
        d = Array(f);
        for (p = 0; p < f; p++) d[p] = {};
        k = b.length;
        for (p = 0; p < k; p++) {
          var a = b[p];
          if (0 < a.Ia.z) {
            var g = a.la,
              e = g.length;
            for (a = 0; a < e - 1; a++) {
              var u = g[a].index - 3,
                t = g[a + 1].index - 3;
              0 <= u && 0 <= t && (d[u][t] = !0, d[t][u] = !0);
            }
            a = g[0].index - 3;
            0 <= t && 0 <= a && (d[t][a] = !0, d[a][t] = !0);
          }
        }
        for (p = 0; p < f; p++) {
          a = d[p];
          b = q[p];
          t = Number.MAX_VALUE;
          k = null;
          for (var l in a) a = q[l], g = wa.i(b, a), t > g && (t = g, k = a);
          b.vj = k;
          b.bf = Math.sqrt(t);
        }
        for (f = 0; f < c; f++) p = q[f], l = Math.min(Math.sqrt(p.w), .95 * p.bf), p.w = l * l;
        this.i(q, m);
        for (f = 0; f < c; f++) p = q[f], p.ae !== p.w && 0 < p.mc && (m = Math.min(p.mc, p.ae - p.w), p.w += m, p.mc -= m);
      }
    };
  }();
  var Ua = new function () {
    this.H = function (n) {
      n = n.m;
      for (var q = 0, m = n.length, f = 0; f < m; f++) {
        var d = n[f];
        if (d.C) {
          var c = d.x,
            p = d.y;
          wa.u(d.C, d);
          c -= d.x;
          d = p - d.y;
          d = (0 < c ? c : -c) + (0 < d ? d : -d);
          q < d && (q = d);
        }
      }
      return q;
    };
    this.i = function (n, q) {
      var m = n.m;
      switch (q) {
        case "random":
          return n.m[Math.floor(m.length * Math.random())];
        case "topleft":
          n = m[0];
          var f = n.x + n.y;
          for (q = 1; q < m.length; q++) {
            var d = m[q];
            var c = d.x + d.y;
            c < f && (f = c, n = d);
          }
          return n;
        case "bottomright":
          n = m[0];
          f = n.x + n.y;
          for (q = 1; q < m.length; q++) d = m[q], c = d.x + d.y, c > f && (f = c, n = d);
          return n;
        default:
          d = m[0];
          f = c = wa.i(n, d);
          for (q = m.length - 1; 1 <= q; q--) {
            var p = m[q];
            c = wa.i(n, p);
            c < f && (f = c, d = p);
          }
          return d;
      }
    };
    this.u = function (n, q, m) {
      var f = n.m;
      if (f[0].J) {
        var d = f.length;
        for (n = 0; n < d; n++) f[n].Vc = !1, f[n].ac = 0;
        d = [];
        var c;
        var p = c = 0;
        d[c++] = q || f[0];
        for (q = q.ac = 0; p < c;) if (f = d[p++], !f.Vc && f.J) {
          m(f, q++, f.ac);
          f.Vc = !0;
          var k = f.J,
            b = k.length;
          for (n = 0; n < b; n++) {
            var a = k[n];
            a && !0 !== a.Vc && (0 === a.ac && (a.ac = f.ac + 1), d[c++] = a);
          }
        }
      } else for (n = 0; n < f.length; n++) m(f[n], n, 1);
    };
  }();
  var ta = function () {
    function n(l, h, r, y, w, v, z, E) {
      var D = P.extend({}, k, l);
      1 > l.lineHeight && (l.lineHeight = 1);
      l = D.fontFamily;
      var H = D.fontStyle + " " + D.fontVariant + " " + D.fontWeight,
        O = D.ib,
        G = D.Jc,
        B = H + " " + l;
      D.we = B;
      var M = {
        ka: !1,
        dc: 0,
        fontSize: 0
      };
      h.save();
      h.font = H + " 100px " + l;
      h.textBaseline = "middle";
      h.textAlign = "center";
      q(h, D);
      r = r.trim();
      u.text = r;
      d(y, w, v, t);
      if (/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(r)) f(u), m(h, u, B), c(D, u, t, G, O, !0, M);else if (m(h, u, B), c(D, u, t, G, O, !1, M), !M.ka && (z && (f(u), m(h, u, B)), E || z)) E && (M.Wb = !0), c(D, u, t, G, G, !0, M);
      if (M.ka) {
        var Q = "",
          X = 0,
          Y = Number.MAX_VALUE,
          aa = Number.MIN_VALUE;
        p(D, u, M.dc, M.fontSize, t, M.Wb, function (A, U) {
          0 < Q.length && " " === U && (Q += " ");
          Q += A;
        }, function (A, U, fa, ba, ja) {
          "\u00ad" === ba && (Q += "\u2010");
          h.save();
          h.translate(v.x, U);
          A = M.fontSize / 100;
          h.scale(A, A);
          h.fillText(Q, 0, 0);
          h.restore();
          Q = fa;
          X < ja && (X = ja);
          Y > U && (Y = U);
          aa < U && (aa = U);
        });
        M.box = {
          x: v.x - X / 2,
          y: Y - M.fontSize / 2,
          w: X,
          o: aa - Y + M.fontSize
        };
        h.restore();
      } else h.clear && h.clear();
      return M;
    }
    function q(l, h) {
      h = h.we;
      var r = b[h];
      void 0 === r && (r = {}, b[h] = r);
      r[" "] = l.measureText(" ").width;
      r["\u2026"] = l.measureText("\u2026").width;
    }
    function m(l, h, r) {
      var y,
        w = h.text.split(/(\n|[ \f\r\t\v\u2028\u2029]+|\u00ad+|\u200b+)/),
        v = [],
        z = [],
        E = w.length >>> 1;
      for (y = 0; y < E; y++) v.push(w[2 * y]), z.push(w[2 * y + 1]);
      2 * y < w.length && (v.push(w[2 * y]), z.push(void 0));
      r = b[r];
      for (y = 0; y < v.length; y++) w = v[y], E = r[w], void 0 === E && (E = l.measureText(w).width, r[w] = E);
      h.Wc = v;
      h.Sf = z;
    }
    function f(l) {
      for (var h = l.text.split(/\s+/), r = [], y = {
          ".": !0,
          ",": !0,
          ";": !0,
          "?": !0,
          "!": !0,
          ":": !0,
          "\u3002": !0
        }, w = 0; w < h.length; w++) {
        var v = h[w];
        if (3 < v.length) {
          var z = "";
          z += v.charAt(0);
          z += v.charAt(1);
          for (var E = 2; E < v.length - 2; E++) {
            var D = v.charAt(E);
            y[D] || (z += "\u200b");
            z += D;
          }
          z += "\u200b";
          z += v.charAt(v.length - 2);
          z += v.charAt(v.length - 1);
          r.push(z);
        } else r.push(v);
      }
      l.text = r.join(" ");
    }
    function d(l, h, r, y) {
      for (var w, v, z = 0; z < l.length; z++) l[z].y === h.y && (void 0 === w ? w = z : v = z);
      void 0 === v && (v = w);
      w !== v && l[v].x < l[w].x && (z = w, w = v, v = z);
      y.C = l;
      y.F = h;
      y.fd = r;
      y.$e = w;
      y.af = v;
    }
    function c(l, h, r, y, w, v, z) {
      var E = l.lineHeight,
        D = Math.max(l.Va, .001),
        H = l.jb,
        O = h.Wc,
        G = r.fd,
        B = r.F,
        M = void 0,
        Q = void 0;
      switch (l.verticalAlign) {
        case "top":
          G = B.y + B.o - G.y;
          break;
        case "bottom":
          G = G.y - B.y;
          break;
        default:
          G = 2 * Math.min(G.y - B.y, B.y + B.o - G.y);
      }
      H = Math.min(G, H * r.F.o);
      if (0 >= H) z.ka = !1;else {
        G = y;
        w = Math.min(w, H);
        B = Math.min(1, H / Math.max(20, h.Wc.length));
        do {
          var X = (G + w) / 2,
            Y = Math.min(O.length, Math.floor((H + X * (E - 1 - 2 * D)) / (X * E))),
            aa = void 0;
          if (0 < Y) {
            var A = 1,
              U = Y;
            do {
              var fa = Math.floor((A + U) / 2);
              if (p(l, h, fa, X, r, v && X === y && fa === Y, null, null)) {
                if (U = M = aa = fa, A === U) break;
              } else if (A = fa + 1, A > U) break;
            } while (1);
          }
          void 0 !== aa ? G = Q = X : w = X;
        } while (w - G > B);
        void 0 === Q ? (z.ka = !1, z.fontSize = 0) : (z.ka = !0, z.fontSize = Q, z.dc = M, z.Wb = v && X === G);
        return z;
      }
    }
    function p(l, h, r, y, w, v, z, E) {
      var D = l.eb,
        H = y * (l.lineHeight - 1),
        O = Math.max(l.Va, .001),
        G = b[l.we],
        B = h.Wc;
      h = h.Sf;
      var M = w.C,
        Q = w.fd,
        X = w.$e,
        Y = w.af;
      switch (l.verticalAlign) {
        case "top":
          w = Q.y + y / 2 + y * O;
          var aa = 1;
          break;
        case "bottom":
          w = Q.y - (y * r + H * (r - 1)) + y / 2 - y * O;
          aa = -1;
          break;
        default:
          w = Q.y - (y * (r - 1) / 2 + H * (r - 1) / 2), aa = 1;
      }
      l = w;
      for (O = 0; O < r; O++) a[2 * O] = w - y / 2, a[2 * O + 1] = w + y / 2, w += aa * y, w += aa * H;
      for (; g.length < a.length;) g.push(Array(2));
      O = a;
      w = 2 * r;
      aa = g;
      var A = M.length,
        U = X;
      X = (X - 1 + A) % A;
      var fa = Y;
      Y = (Y + 1) % A;
      for (var ba = 0; ba < w;) {
        for (var ja = O[ba], N = M[X]; N.y < ja;) U = X, X = (X - 1 + A) % A, N = M[X];
        for (var S = M[Y]; S.y < ja;) fa = Y, Y = (Y + 1) % A, S = M[Y];
        var ca = M[U],
          I = M[fa];
        S = I.x + (S.x - I.x) * (ja - I.y) / (S.y - I.y);
        aa[ba][0] = ca.x + (N.x - ca.x) * (ja - ca.y) / (N.y - ca.y);
        aa[ba][1] = S;
        ba++;
      }
      for (O = 0; O < r; O++) M = 2 * O, w = Q.x, aa = w - g[M][0], A = g[M][1] - w, aa = aa < A ? aa : A, A = w - g[M + 1][0], M = g[M + 1][1] - w, M = A < M ? A : M, e[O] = 2 * (aa < M ? aa : M) - D * y;
      U = G[" "] * y / 100;
      aa = G["\u2026"] * y / 100;
      D = 0;
      X = e[D];
      Q = 0;
      M = void 0;
      for (O = 0; O < B.length; O++) {
        w = B[O];
        fa = h[O];
        A = G[w] * y / 100;
        if (Q + A < X && B.length - O >= r - D && "\n" != M) Q += A, " " === fa && (Q += U), z && z(w, M);else {
          if (A > X && (D !== r - 1 || !v)) return !1;
          if (D + 1 >= r) {
            if (v) {
              r = X - Q - aa;
              if (r > aa || A > aa) r = Math.floor(w.length * r / A), 0 < r && z && z(w.substring(0, r), M);
              z && z("\u2026", void 0);
              E && E(D, l, w, M, Q);
              return !0;
            }
            return !1;
          }
          D++;
          E && E(D, l, w, M, Q);
          l += y;
          l += H;
          X = e[D];
          Q = A;
          " " === fa && (Q += U);
          if (A > X && (D !== r || !v)) return !1;
        }
        M = fa;
      }
      E && E(D, l, void 0, void 0, Q);
      return !0;
    }
    var k = {
        ib: 72,
        Jc: 0,
        lineHeight: 1.05,
        eb: 1,
        Va: .5,
        jb: .9,
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontVariant: "normal",
        verticalAlign: "center"
      },
      b = {},
      a = [],
      g = [],
      e = [],
      u = {
        text: "",
        Wc: void 0,
        Sf: void 0
      },
      t = {
        C: void 0,
        F: void 0,
        fd: void 0,
        $e: 0,
        af: 0
      };
    return {
      ue: n,
      ge: function (l, h, r, y, w, v, z, E, D, H, O, G) {
        var B = 0,
          M = 0;
        r = r.toString().trim();
        if (!G && D.result && r === D.Zf && Math.abs(H - D.be) / H <= O) {
          var Q = D.result;
          Q.ka && (B = v.x - D.gg, M = v.y - D.hg, O = D.Tc, h.save(), h.translate(B, M), O.Oa(h), h.restore());
        }
        Q || (O = D.Tc, O.clear(), Q = n(l, O, r, y, w, v, z, E), Q.ka && O.Oa(h), D.be = H, D.gg = v.x, D.hg = v.y, D.result = Q, D.Zf = r);
        return Q.ka ? {
          ka: !0,
          dc: Q.dc,
          fontSize: Q.fontSize,
          box: {
            x: Q.box.x + B,
            y: Q.box.y + M,
            w: Q.box.w,
            o: Q.box.o
          },
          Wb: Q.Wb
        } : {
          ka: !1
        };
      },
      ci: function () {
        return {
          be: 0,
          gg: 0,
          hg: 0,
          result: void 0,
          Tc: new ra(),
          Zf: void 0
        };
      },
      ya: k
    };
  }();
  var Va = new function () {
    function n(f, d) {
      return function (c, p, k, b) {
        function a(r, y, w, v, z) {
          if (0 != r.length) {
            var E = r.shift(),
              D = m(E);
            if (d(v, z)) {
              var H = y;
              var O = D / v;
              do {
                D = E.shift();
                var G = D.nc;
                var B = G / O;
                G = D;
                var M = w,
                  Q = O;
                G.x = H + B / 2;
                G.y = M + Q / 2;
                k && g(D, H, w, B, O);
                H += B;
              } while (0 < E.length);
              return a(r, y, w + O, v, z - O);
            }
            H = w;
            B = D / z;
            do D = E.shift(), G = D.nc, O = G / B, G = D, M = H, Q = O, G.x = y + B / 2, G.y = M + Q / 2, k && g(D, y, H, B, O), H += O; while (0 < E.length);
            return a(r, y + B, w, v - B, z);
          }
        }
        function g(r, y, w, v, z) {
          r.C = [{
            x: y,
            y: w
          }, {
            x: y + v,
            y: w
          }, {
            x: y + v,
            y: w + z
          }, {
            x: y,
            y: w + z
          }];
        }
        var e = p.x,
          u = p.y,
          t = p.w;
        p = p.o;
        if (0 != c.length) if (1 == c.length) c[0].x = e + t / 2, c[0].y = u + p / 2, c[0].qd = 0, k && g(c[0], e, u, t, p);else {
          c = c.slice(0);
          for (var l = 0, h = 0; h < c.length; h++) l += c[h].weight;
          l = t * p / l;
          for (h = 0; h < c.length; h++) c[h].nc = c[h].weight * l;
          b = f(c, t, p, [[c.shift()]], b);
          a(b, e, u, t, p);
        }
      };
    }
    function q(f, d, c, p) {
      function k(e) {
        return Math.max(Math.pow(g * e / a, c), Math.pow(a / (g * e), p));
      }
      var b = m(f),
        a = b * b,
        g = d * d;
      d = k(f[0].nc);
      for (b = 1; b < f.length; b++) d = Math.max(d, k(f[b].nc));
      return d;
    }
    function m(f) {
      for (var d = 0, c = 0; c < f.length; c++) d += f[c].nc;
      return d;
    }
    this.u = n(function (f, d, c, p, k) {
      k = Math.pow(2, k);
      for (var b = 1 / k, a = d < c; 0 < f.length;) {
        var g = p[p.length - 1],
          e = f.shift(),
          u = a ? d : c,
          t = a ? k : b,
          l = a ? b : k,
          h = q(g, u, t, l);
        g.push(e);
        u = q(g, u, t, l);
        h < u && (g.pop(), p.push([e]), a ? c -= m(g) / d : d -= m(g) / c, a = d < c);
      }
      return p;
    }, function (f, d) {
      return f < d;
    });
    this.i = n(function (f, d, c, p, k) {
      function b(t) {
        if (1 < p.length) {
          for (var l = p[p.length - 1], h = p[p.length - 2].slice(0), r = 0; r < l.length; r++) h.push(l[r]);
          q(h, d, a, g) < t && p.splice(-2, 2, h);
        }
      }
      for (var a = Math.pow(2, k), g = 1 / a; 0 < f.length;) {
        c = p[p.length - 1];
        k = q(c, d, a, g);
        if (0 == f.length) return;
        var e = f.shift();
        c.push(e);
        var u = q(c, d, a, g);
        k < u && (c.pop(), b(k), p.push([e]));
      }
      b(q(p[p.length - 1], d, a, g));
      return p;
    }, function () {
      return !0;
    });
  }();
  function Wa(n) {
    var q = {},
      m = n.Fd,
      f;
    n.j.subscribe("model:loaded", function (d) {
      f = d;
    });
    this.M = function () {
      n.j.D("api:initialized", this);
    };
    this.qc = function (d, c, p, k) {
      this.$c(q, c);
      this.ad(q, c);
      this.Zc(q, c, !1);
      k && k(q);
      d(m, q, p);
    };
    this.ed = function (d, c, p, k, b, a, g) {
      if (d) {
        for (d = c.length - 1; 0 <= d; d--) {
          var e = c[d],
            u = P.extend({
              group: e.group
            }, b);
          u[p] = k(e);
          a(u);
        }
        0 < c.length && g(P.extend({
          groups: Qa.xc(f, k).map(function (t) {
            return t.group;
          })
        }, b));
      }
    };
    this.ad = function (d, c) {
      d.selected = c.selected;
      d.hovered = c.wb;
      d.open = c.open;
      d.openness = c.Eb;
      d.exposed = c.U;
      d.exposure = c.ja;
      d.transitionProgress = c.ra;
      d.revealed = !c.aa.Ha();
      d.browseable = c.Ja ? c.R : void 0;
      d.visible = c.Y;
      d.labelDrawn = c.oa && c.oa.ka;
      return d;
    };
    this.$c = function (d, c) {
      var p = c.parent;
      d.group = c.group;
      d.parent = p && p.group;
      d.weightNormalized = c.eg;
      d.level = c.level - 1;
      d.siblingCount = p && p.m.length;
      d.hasChildren = !c.empty();
      d.index = c.index;
      d.indexByWeight = c.qd;
      d.description = c.description;
      d.attribution = c.attribution;
      return d;
    };
    this.Zc = function (d, c, p) {
      d.polygonCenterX = c.O.x;
      d.polygonCenterY = c.O.y;
      d.polygonArea = c.O.ha;
      d.boxLeft = c.F.x;
      d.boxTop = c.F.y;
      d.boxWidth = c.F.w;
      d.boxHeight = c.F.o;
      if (c.oa && c.oa.ka) {
        var k = c.oa.box;
        d.labelBoxLeft = k.x;
        d.labelBoxTop = k.y;
        d.labelBoxWidth = k.w;
        d.labelBoxHeight = k.o;
        d.labelFontSize = c.oa.fontSize;
      }
      p && c.$ && (d.polygon = c.$.map(function (b) {
        return {
          x: b.x,
          y: b.y
        };
      }), d.neighbors = c.J && c.J.map(function (b) {
        return b && b.group;
      }));
      return d;
    };
  }
  ;
  var Ca = new function () {
    var n = window.console;
    this.i = function (q) {
      throw "FoamTree: " + q;
    };
    this.info = function (q) {
      n.info("FoamTree: " + q);
    };
    this.warn = function (q) {
      n.warn("FoamTree: " + q);
    };
  }();
  function Xa(n) {
    function q(h, r) {
      h.m = [];
      h.Ea = !0;
      var y = d(r),
        w = 0;
      if (("flattened" === n.nb || "always" === n.Dg && h.group && h.group.description) && 0 < r.length && 0 < h.level) {
        var v = r.reduce(function (D, H) {
            return D + P.I(H.weight, 1);
          }, 0),
          z = m(h.group, !1);
        z.description = !0;
        z.weight = v * n.Ub;
        z.index = w++;
        z.parent = h;
        z.level = h.level + 1;
        z.id = z.id + "_d";
        h.m.push(z);
      }
      for (v = 0; v < r.length; v++) {
        var E = r[v];
        z = P.I(E.weight, 1);
        if (0 >= z) if (n.$i) z = .9 * y;else continue;
        E = m(E, !0);
        E.weight = z;
        E.index = w;
        E.parent = h;
        E.level = h.level + 1;
        h.m.push(E);
        w++;
      }
    }
    function m(h, r) {
      var y = new Ya();
      f(h);
      y.id = h.__id;
      y.group = h;
      r && (e[h.__id] = y);
      return y;
    }
    function f(h) {
      P.has(h, "__id") || (Object.defineProperty(h, "__id", {
        enumerable: !1,
        configurable: !1,
        writable: !1,
        value: g
      }), g++);
    }
    function d(h) {
      for (var r = Number.MAX_VALUE, y = 0; y < h.length; y++) {
        var w = h[y].weight;
        0 < w && r > w && (r = w);
      }
      r === Number.MAX_VALUE && (r = 1);
      return r;
    }
    function c(h) {
      if (!h.empty()) {
        h = h.m;
        var r = 0,
          y;
        for (y = h.length - 1; 0 <= y; y--) {
          var w = h[y].weight;
          r < w && (r = w);
        }
        for (y = h.length - 1; 0 <= y; y--) w = h[y], w.eg = w.weight / r;
      }
    }
    function p(h) {
      if (!h.empty()) {
        h = h.m.slice(0).sort(function (y, w) {
          return y.weight < w.weight ? 1 : y.weight > w.weight ? -1 : y.index - w.index;
        });
        for (var r = 0; r < h.length; r++) h[r].qd = r;
      }
    }
    function k() {
      for (var h = a.m.reduce(function (w, v) {
          return w + v.weight;
        }, 0), r = 0; r < a.m.length; r++) {
        var y = a.m[r];
        y.attribution && (y.weight = Math.max(0, n.wg) * h);
      }
    }
    var b = this,
      a = new Ya(),
      g,
      e,
      u,
      t,
      l;
    this.M = function () {
      return a;
    };
    this.T = function (h) {
      var r = h.group.groups,
        y = n.Vh;
      return !h.m && !h.description && r && 0 < r.length && l + r.length <= y ? (l += r.length, q(h, r), c(h), p(h), !0) : !1;
    };
    this.load = function (h) {
      function r(w) {
        var v = w.groups;
        if (v) for (var z = 0; z < v.length; z++) {
          var E = v[z];
          f(E);
          var D = E.__id;
          e[D] = null;
          t[D] = w;
          D = E.id;
          P.V(D) || (u[D] = E);
          r(E);
        }
      }
      function y(w, v) {
        if (!w) return v;
        v = Math.max(v, w.__id || 0);
        if ((w = w.groups) && 0 < w.length) for (var z = w.length - 1; 0 <= z; z--) v = y(w[z], v);
        return v;
      }
      a.group = h;
      a.xa = !1;
      a.R = !1;
      a.Ja = !1;
      a.open = !0;
      a.Eb = 1;
      g = y(h, 0) + 1;
      e = {};
      u = {};
      t = {};
      l = 0;
      h && (f(h), e[h.__id] = a, P.V(h.id) || (u[h.id] = h), r(h));
      q(a, h && h.groups || []);
      (function (w) {
        if (!w.empty() && (n.de || n.sb)) {
          var v = m({
            attribution: !0
          });
          v.index = w.m.length;
          n.sb || (v.group.label = n.de);
          v.parent = w;
          v.level = w.level + 1;
          v.attribution = !0;
          w.m.push(v);
        }
      })(a);
      c(a);
      k();
      p(a);
    };
    this.update = function (h) {
      h.forEach(function (r) {
        Qa.za(r, function (y) {
          if (!y.empty()) {
            y = y.m;
            for (var w = d(y.map(function (E) {
                return E.group;
              })), v = 0; v < y.length; v++) {
              var z = y[v];
              z.weight = 0 < z.group.weight ? z.group.weight : .9 * w;
            }
          }
        });
        c(r);
        r === a && k();
        p(r);
      });
    };
    this.u = function (h) {
      return function () {
        if (P.V(h) || P.Ue(h)) return [];
        if (Array.isArray(h)) return h.map(b.i, b);
        if (P.yb(h)) {
          if (P.has(h, "__id")) return [b.i(h)];
          if (P.has(h, "all")) {
            var r = [];
            Qa.L(a, function (y) {
              r.push(y);
            });
            return r;
          }
          if (P.has(h, "groups")) return b.u(h.groups);
        }
        return [b.i(h)];
      }().filter(function (r) {
        return void 0 !== r;
      });
    };
    this.i = function (h) {
      if (P.yb(h) && P.has(h, "__id")) {
        if (h = h.__id, P.has(e, h)) {
          if (null === e[h]) {
            for (var r = t[h], y = []; r;) {
              r = r.__id;
              y.push(r);
              if (e[r]) break;
              r = t[r];
            }
            for (r = y.length - 1; 0 <= r; r--) this.T(e[y[r]]);
          }
          return e[h];
        }
      } else if (P.has(u, h)) return this.i(u[h]);
    };
    this.H = function (h, r, y) {
      return {
        m: b.u(h),
        Ca: P.I(h && h[r], !0),
        Ba: P.I(h && h.keepPrevious, y)
      };
    };
  }
  function Za(n, q, m) {
    var f = {};
    q.Ba && Qa.L(n, function (k) {
      m(k) && (f[k.id] = k);
    });
    n = q.m;
    q = q.Ca;
    for (var d = n.length - 1; 0 <= d; d--) {
      var c = n[d];
      f[c.id] = q ? c : void 0;
    }
    var p = [];
    P.Aa(f, function (k) {
      void 0 !== k && p.push(k);
    });
    return p;
  }
  ;
  function $a(n) {
    function q(B, M) {
      B = B.ja;
      M.opacity = 1;
      M.Da = 1;
      M.va = 0 > B ? 1 - t.Gh / 100 * B : 1;
      M.saturation = 0 > B ? 1 - t.Hh / 100 * B : 1;
      M.ca = 0 > B ? 1 + .5 * B : 1;
    }
    function m(B) {
      B = B.ja;
      return Math.max(.001, 0 === B ? 1 : 1 + B * (t.Qa - 1));
    }
    function f(B, M) {
      for (var Q = B.reduce(function (A, U) {
          A[U.id] = U;
          return A;
        }, {}), X = B.length - 1; 0 <= X; X--) Qa.L(B[X], function (A) {
        Q[A.id] = void 0;
      });
      var Y = [];
      P.Aa(Q, function (A) {
        A && Qa.pe(A, function (U) {
          U.open || Y.push(U);
        });
      });
      var aa = [];
      P.Aa(Q, function (A) {
        A && A.open && aa.push(A);
      });
      B = [];
      0 !== Y.length && B.push(v.Db({
        m: Y,
        Ca: !0,
        Ba: !0
      }, M, !0));
      return Ga(B);
    }
    function d(B, M, Q, X) {
      var Y = k();
      if (0 === B.length && !Y) return new Fa().resolve().promise();
      var aa = B.reduce(function (ba, ja) {
          ba[ja.id] = !0;
          return ba;
        }, {}),
        A = [];
      B = [];
      if (z.reduce(function (ba, ja) {
        return ba || aa[ja.id] && (!ja.U || 1 !== ja.ja) || !aa[ja.id] && !ja.parent.U && (ja.U || -1 !== ja.ja);
      }, !1)) {
        var U = [],
          fa = {};
        z.forEach(function (ba) {
          aa[ba.id] && (ba.U || A.push(ba), ba.U = !0, Qa.za(ba, function (ja) {
            U.push(a(ja, 1));
            fa[ja.id] = !0;
          }));
        });
        0 < U.length ? (Qa.L(l, function (ba) {
          aa[ba.id] || (ba.U && A.push(ba), ba.U = !1);
          fa[ba.id] || U.push(a(ba, -1));
        }), B.push(w.K.A({}).Ra(U).call(g).Ua()), c(aa), B.push(p(Y)), Q && (y.kc(E, t.$b, t.Pa, La.ia(t.Yb)), y.Hb())) : (B.push(b(Q)), M && Qa.L(l, function (ba) {
          ba.U && A.push(ba);
        }));
      }
      return Ga(B).then(function () {
        r.ed(M, A, "exposed", function (ba) {
          return ba.U;
        }, {
          indirect: X
        }, n.options.lf, n.options.kf);
      });
    }
    function c(B) {
      z.reduce(e(!0, void 0, function (M) {
        return M.U || B[M.id];
      }), u(E));
      E.x -= E.w * (t.Qa - 1) / 2;
      E.y -= E.o * (t.Qa - 1) / 2;
      E.w *= t.Qa;
      E.o *= t.Qa;
    }
    function p(B) {
      if (B || !y.Cd()) return w.K.A(h).fa({
        duration: .7 * t.Pa,
        P: {
          x: {
            end: E.x + E.w / 2,
            easing: La.ia(t.Yb)
          },
          y: {
            end: E.y + E.o / 2,
            easing: La.ia(t.Yb)
          }
        },
        ba: function () {
          n.j.D("foamtree:dirty", !0);
        }
      }).Ua();
      h.x = E.x + E.w / 2;
      h.y = E.y + E.o / 2;
      return new Fa().resolve().promise();
    }
    function k() {
      return !!z && z.reduce(function (B, M) {
        return B || 0 !== M.ja;
      }, !1);
    }
    function b(B) {
      var M = [],
        Q = [];
      Qa.L(l, function (X) {
        0 !== X.ja && Q.push(a(X, 0, function () {
          this.U = !1;
        }));
      });
      M.push(w.K.A({}).Ra(Q).Ua());
      y.content(0, 0, D, H);
      B && (M.push(y.reset(t.Pa, La.ia(t.Yb))), y.Hb());
      return Ga(M);
    }
    function a(B, M, Q) {
      var X = w.K.A(B);
      0 === B.ja && 0 !== M && X.call(function () {
        this.oc(O);
        this.rb(q);
      });
      X.fa({
        duration: t.Pa,
        P: {
          ja: {
            end: M,
            easing: La.ia(t.Yb)
          }
        },
        ba: function () {
          l.N = !0;
          l.Ga = !0;
          n.j.D("foamtree:dirty", !0);
        }
      });
      0 === M && X.call(function () {
        this.yd();
        this.ec();
        this.Qc(O);
        this.Pc(q);
      });
      return X.call(Q).done();
    }
    function g() {
      var B = l.m.reduce(e(!1, O.transformPoint, void 0), u({})).box,
        M = t.$b,
        Q = Math.min(B.x, E.x - E.w * M),
        X = Math.min(B.y, E.y - E.o * M);
      y.content(Q, X, Math.max(B.x + B.w, E.x + E.w * (1 + M)) - Q, Math.max(B.y + B.o, E.y + E.o * (1 + M)) - X);
    }
    function e(B, M, Q) {
      var X = {};
      return function (Y, aa) {
        if (!Q || Q(aa)) {
          for (var A = B ? aa.$ || aa.C : aa.C, U, fa = A.length - 1; 0 <= fa; fa--) U = void 0 !== M ? M(aa, A[fa], X) : A[fa], Y.Kc = Math.min(Y.Kc, U.x), Y.zd = Math.max(Y.zd, U.x), Y.Lc = Math.min(Y.Lc, U.y), Y.Ad = Math.max(Y.Ad, U.y);
          Y.box.x = Y.Kc;
          Y.box.y = Y.Lc;
          Y.box.w = Y.zd - Y.Kc;
          Y.box.o = Y.Ad - Y.Lc;
        }
        return Y;
      };
    }
    function u(B) {
      return {
        Kc: Number.MAX_VALUE,
        zd: Number.MIN_VALUE,
        Lc: Number.MAX_VALUE,
        Ad: Number.MIN_VALUE,
        box: B
      };
    }
    var t = n.options,
      l,
      h,
      r,
      y,
      w,
      v,
      z,
      E,
      D,
      H,
      O = {
        Ye: function (B, M) {
          M.scale = m(B);
          return !1;
        },
        Kb: function (B, M) {
          B = m(B);
          var Q = h.x,
            X = h.y;
          M.translate(Q, X);
          M.scale(B, B);
          M.translate(-Q, -X);
        },
        Lb: function (B, M, Q) {
          B = m(B);
          var X = h.x,
            Y = h.y;
          Q.x = (M.x - X) / B + X;
          Q.y = (M.y - Y) / B + Y;
        },
        transformPoint: function (B, M, Q) {
          B = m(B);
          var X = h.x,
            Y = h.y;
          Q.x = (M.x - X) * B + X;
          Q.y = (M.y - Y) * B + Y;
          return Q;
        }
      };
    n.j.subscribe("stage:initialized", function (B, M, Q, X) {
      h = {
        x: Q / 2,
        y: X / 2
      };
      D = Q;
      H = X;
      E = {
        x: 0,
        y: 0,
        w: D,
        o: H
      };
    });
    n.j.subscribe("stage:resized", function (B, M, Q, X) {
      h.x *= Q / B;
      h.y *= X / M;
      D = Q;
      H = X;
    });
    n.j.subscribe("api:initialized", function (B) {
      r = B;
    });
    n.j.subscribe("zoom:initialized", function (B) {
      y = B;
    });
    n.j.subscribe("model:loaded", function (B, M) {
      l = B;
      z = M;
    });
    n.j.subscribe("model:childrenAttached", function (B) {
      z = B;
    });
    n.j.subscribe("timeline:initialized", function (B) {
      w = B;
    });
    n.j.subscribe("openclose:initialized", function (B) {
      v = B;
    });
    var G = ["groupExposureScale", "groupUnexposureScale", "groupExposureZoomMargin"];
    n.j.subscribe("options:changed", function (B) {
      P.cb(B, G) && k() && (c({}), y.gj(E, t.$b), y.Hb());
    });
    this.M = function () {
      n.j.D("expose:initialized", this);
    };
    this.Xb = function (B, M, Q, X) {
      var Y = B.m.reduce(function (U, fa) {
          for (; fa = fa.parent;) U[fa.id] = !0;
          return U;
        }, {}),
        aa = Za(l, B, function (U) {
          return U.U && !U.open && !Y[U.id];
        }),
        A = new Fa();
      f(aa, M).then(function () {
        d(aa.filter(function (U) {
          return U.C && U.$;
        }), M, Q, X).then(A.resolve);
      });
      return A.promise();
    };
  }
  ;
  function ab(n) {
    function q(b) {
      function a(D, H) {
        var O = Math.min(1, Math.max(0, D.ra));
        H.opacity = O;
        H.va = 1;
        H.saturation = O;
        H.Da = O;
        H.ca = D.Ab;
      }
      var g = n.options,
        e = g.Vi,
        u = g.Wi,
        t = g.Si,
        l = g.Ti,
        h = g.Ui,
        r = g.Rd,
        y = e + u + t + l + h,
        w = 0 < y ? r / y : 0,
        v = [];
      k.i(g.Pf, g.Of, g.Qf, g.Rf, g.Nf);
      if (0 === w && b.m && b.R) {
        r = b.m;
        for (y = 0; y < r.length; y++) {
          var z = r[y];
          z.ra = 1;
          z.Ab = 1;
          z.rb(a);
          z.ec();
          z.Pc(a);
        }
        b.N = !0;
        n.j.D("foamtree:dirty", 0 < w);
        return new Fa().resolve().promise();
      }
      if (b.m && b.R) {
        Ua.u(b, Ua.i(b, n.options.Td), function (D, H, O) {
          D.oc(k);
          D.rb(a);
          O = "groups" === n.options.Sd ? O : H;
          H = f.K.A(D).wait(O * w * e).fa({
            duration: w * u,
            P: {
              ra: {
                end: 1,
                easing: La.ia(g.Ri)
              }
            },
            ba: function () {
              this.N = !0;
              n.j.D("foamtree:dirty", 0 < w);
            }
          }).done();
          O = f.K.A(D).wait(p ? w * (t + O * l) : 0).fa({
            duration: p ? w * h : 0,
            P: {
              Ab: {
                end: 1,
                easing: La.Cb
              }
            },
            ba: function () {
              this.N = !0;
              n.j.D("foamtree:dirty", 0 < w);
            }
          }).done();
          D = f.K.A(D).Ra([H, O]).$d().Ya(function () {
            this.yd();
            this.ec();
            this.Qc(k);
            this.Pc(a);
          }).done();
          v.push(D);
        });
        c.i();
        var E = new Fa();
        f.K.A({}).Ra(v).call(function () {
          c.u();
          E.resolve();
        }).start();
        return E.promise();
      }
      return new Fa().resolve().promise();
    }
    var m,
      f,
      d = [],
      c = new Ha(P.qa);
    n.j.subscribe("stage:initialized", function () {});
    n.j.subscribe("stage:resized", function () {});
    n.j.subscribe("stage:newLayer", function (b, a) {
      d.push(a);
    });
    n.j.subscribe("model:loaded", function (b) {
      m = b;
      c.clear();
    });
    n.j.subscribe("zoom:initialized", function () {});
    n.j.subscribe("timeline:initialized", function (b) {
      f = b;
    });
    var p = !1;
    n.j.subscribe("render:renderers:resolved", function (b) {
      p = b.labelPlainFill || !1;
    });
    var k = new function () {
      var b = 0,
        a = 0,
        g = 0,
        e = 0,
        u = 0,
        t = 0;
      this.i = function (l, h, r, y, w) {
        b = 1 + h;
        a = 1 - b;
        g = r;
        e = y;
        u = w;
        t = l;
      };
      this.Ye = function (l, h) {
        h.scale = b + a * l.ra;
        return 0 !== u || 0 !== g || 0 !== e;
      };
      this.Kb = function (l, h) {
        var r = b + a * l.ra,
          y = l.parent,
          w = t * l.x + (1 - t) * y.x,
          v = t * l.y + (1 - t) * y.y;
        h.translate(w, v);
        h.scale(r, r);
        l = 1 - l.ra;
        h.rotate(u * Math.PI * l);
        h.translate(-w, -v);
        h.translate(y.F.w * g * l, y.F.o * e * l);
      };
      this.Lb = function (l, h, r) {
        var y = b + a * l.ra,
          w = t * l.x + (1 - t) * l.parent.x,
          v = t * l.y + (1 - t) * l.parent.y,
          z = 1 - l.ra;
        l = l.parent;
        r.x = (h.x - w) / y + w - l.F.w * g * z;
        r.y = (h.y - v) / y + v - l.F.o * e * z;
      };
      this.transformPoint = function (l, h, r) {
        var y = b + a * l.ra,
          w = t * l.x + (1 - t) * l.parent.x,
          v = t * l.y + (1 - t) * l.parent.y,
          z = 1 - l.ra;
        l = l.parent;
        r.x = (h.x - w) * y + w - l.F.w * g * z;
        r.y = (h.y - v) * y + v - l.F.o * e * z;
      };
    }();
    this.M = function () {};
    this.u = function () {
      function b(O, G) {
        var B = Math.min(1, Math.max(0, O.ra));
        G.opacity = B;
        G.va = 1;
        G.saturation = B;
        G.Da = B;
        G.ca = O.Ab;
      }
      function a(O, G) {
        var B = Math.min(1, Math.max(0, O.Kd));
        G.opacity = B;
        G.Da = B;
        G.va = 1;
        G.saturation = 1;
        G.ca = O.Ab;
      }
      var g = n.options,
        e = g.Jd,
        u = g.mi,
        t = g.ni,
        l = g.oi,
        h = g.ii,
        r = g.ji,
        y = g.ki,
        w = g.ei,
        v = g.fi,
        z = g.gi,
        E = h + r + y + w + v + z + u + t + l,
        D = 0 < E ? e / E : 0,
        H = [];
      c.initial() ? k.i(g.ti, g.ri, g.ui, g.vi, g.pi) : k.i(g.Pf, g.Of, g.Qf, g.Rf, g.Nf);
      Ua.u(m, Ua.i(m, n.options.si), function (O, G, B) {
        var M = "groups" === n.options.li ? B : G;
        H.push(f.K.A(O).call(function () {
          this.rb(b);
        }).wait(p ? D * (h + M * r) : 0).fa({
          duration: p ? D * y : 0,
          P: {
            Ab: {
              end: 0,
              easing: La.Cb
            }
          },
          ba: function () {
            this.N = !0;
            n.j.D("foamtree:dirty", !0);
          }
        }).done());
        Qa.L(O, function (Q) {
          H.push(f.K.A(Q).call(function () {
            this.oc(k);
            this.rb(a);
          }).wait(D * (w + v * M)).fa({
            duration: D * z,
            P: {
              Kd: {
                end: 0,
                easing: La.Cb
              }
            },
            ba: function () {
              this.N = !0;
              n.j.D("foamtree:dirty", !0);
            }
          }).Ya(function () {
            this.selected = !1;
            this.Qc(k);
          }).done());
        });
        H.push(f.K.A(O).call(function () {
          this.oc(k);
        }).wait(D * (u + t * M)).fa({
          duration: D * l,
          P: {
            ra: {
              end: 0,
              easing: La.ia(g.hi)
            }
          },
          ba: function () {
            this.N = !0;
            n.j.D("foamtree:dirty", !0);
          }
        }).Ya(function () {
          this.selected = !1;
          this.Qc(k);
        }).done());
      });
      return f.K.A({}).Ra(H).Ua();
    };
    this.i = function (b) {
      return q(b);
    };
  }
  ;
  function bb(n) {
    function q(b, a) {
      var g = [];
      Qa.L(p, function (u) {
        if (u.m) {
          var t = P.has(b, u.id);
          u.open !== t && (t || u.U || Qa.L(u, function (l) {
            if (l.U) return g.push(u), !1;
          }));
        }
      });
      if (0 === g.length) return new Fa().resolve().promise();
      var e;
      for (e = g.length - 1; 0 <= e; e--) g[e].open = !1;
      a = c.Xb({
        m: g,
        Ca: !0,
        Ba: !0
      }, a, !0, !0);
      for (e = g.length - 1; 0 <= e; e--) g[e].open = !0;
      return a;
    }
    function m(b, a, g) {
      function e(h, r) {
        h.rb(u);
        var y = d.K.A(h).fa({
          duration: n.options.Nc,
          P: {
            Eb: {
              end: r ? 1 : 0,
              easing: La.ie
            }
          },
          ba: function () {
            this.N = !0;
            n.j.D("foamtree:dirty", !0);
          }
        }).call(function () {
          this.open = r;
          h.Wa = !1;
        }).Ya(function () {
          this.ec();
          this.Pc(u);
          delete f[this.id];
        }).done();
        return f[h.id] = y;
      }
      function u(h, r) {
        r.opacity = 1 - h.Eb;
        r.va = 1;
        r.saturation = 1;
        r.ca = 1;
        r.Da = 1;
      }
      var t = [],
        l = [];
      Qa.L(p, function (h) {
        if (h.R && h.X) {
          var r = P.has(b, h.id),
            y = f[h.id];
          if (y && y.zb()) y.stop();else if (h.open === r) return;
          h.Wa = r;
          r || (h.open = r, h.Ed = !1);
          l.push(h);
          t.push(e(h, r));
        }
      });
      return 0 < t.length ? (n.j.D("openclose:changing"), d.K.A({}).Ra(t).Ua().then(function () {
        k.ed(a, l, "open", function (h) {
          return h.open;
        }, {
          indirect: g
        }, n.options.uf, n.options.tf);
      })) : new Fa().resolve().promise();
    }
    var f, d, c, p, k;
    n.j.subscribe("api:initialized", function (b) {
      k = b;
    });
    n.j.subscribe("model:loaded", function (b) {
      p = b;
      f = {};
    });
    n.j.subscribe("timeline:initialized", function (b) {
      d = b;
    });
    n.j.subscribe("expose:initialized", function (b) {
      c = b;
    });
    this.M = function () {
      n.j.D("openclose:initialized", this);
    };
    this.Db = function (b, a, g) {
      if ("flattened" == n.options.nb) return new Fa().resolve().promise();
      b = Za(p, b, function (l) {
        return l.open || l.Wa;
      });
      for (var e = new Fa(), u = 0; u < b.length; u++) b[u].Wa = !0;
      0 < b.length && n.j.D("foamtree:attachChildren", b);
      var t = b.reduce(function (l, h) {
        l[h.id] = !0;
        return l;
      }, {});
      q(t, a).then(function () {
        m(t, a, g).then(e.resolve);
      });
      return e.promise();
    };
  }
  ;
  function cb(n) {
    function q(d, c) {
      d = Za(m, d, function (a) {
        return a.selected;
      });
      Qa.L(m, function (a) {
        !0 === a.selected && (a.selected = !a.selected, a.N = !a.N, a.Ta = !a.Ta);
      });
      var p;
      for (p = d.length - 1; 0 <= p; p--) {
        var k = d[p];
        k.selected = !k.selected;
        k.N = !k.N;
        k.Ta = !k.Ta;
      }
      var b = [];
      Qa.L(m, function (a) {
        a.N && b.push(a);
      });
      0 < b.length && n.j.D("foamtree:dirty", !1);
      f.ed(c, b, "selected", function (a) {
        return a.selected;
      }, {}, n.options.wf, n.options.vf);
    }
    var m, f;
    n.j.subscribe("api:initialized", function (d) {
      f = d;
    });
    n.j.subscribe("model:loaded", function (d) {
      m = d;
    });
    this.M = function () {
      n.j.D("select:initialized", this);
    };
    this.select = function (d, c) {
      return q(d, c);
    };
  }
  ;
  function db(n) {
    function q(N) {
      return function (S) {
        N.call(this, {
          x: S.x,
          y: S.y,
          scale: S.scale,
          hd: S.delta,
          ctrlKey: S.ctrlKey,
          metaKey: S.metaKey,
          altKey: S.altKey,
          shiftKey: S.shiftKey,
          mb: S.secondary,
          touches: S.touches
        });
      };
    }
    function m() {
      function N(S) {
        return function (ca) {
          ca.x *= Y / u.clientWidth;
          ca.y *= aa / u.clientHeight;
          return S(ca);
        };
      }
      "external" !== e.Pe && ("hammerjs" === e.Pe && P.has(window, "Hammer") && (ba.M(u), ba.A("tap", N(g.i), !0), ba.A("doubletap", N(g.u), !0), ba.A("hold", N(g.sa), !0), ba.A("touch", N(g.ua), !0), ba.A("release", N(g.wa), !1), ba.A("dragstart", N(g.ga), !0), ba.A("drag", N(g.H), !0), ba.A("dragend", N(g.T), !0), ba.A("transformstart", N(g.qb), !0), ba.A("transform", N(g.transform), !0), ba.A("transformend", N(g.La), !0)), Q = new Ka(u), X = new Ja(), Q.i(N(g.i)), Q.u(N(g.u)), Q.sa(N(g.sa)), Q.wa(N(g.ua)), Q.La(N(g.wa)), Q.ga(N(g.ga)), Q.H(N(g.H)), Q.T(N(g.T)), Q.ta(N(g.ta)), Q.Ka(N(g.ta)), Q.ua(N(g.Ka)), X.addEventListener("keyup", function (S) {
        var ca = !1,
          I = void 0,
          T = e.Af({
            keyCode: S.keyCode,
            preventDefault: function () {
              ca = !0;
            },
            preventOriginalEventDefault: function () {
              I = "prevent";
            },
            allowOriginalEventDefault: function () {
              I = "allow";
            }
          });
        "prevent" === I && S.preventDefault();
        (ca = ca || 0 <= T.indexOf(!1)) || 27 === S.keyCode && n.j.D("interaction:reset");
      }));
    }
    function f() {
      t.sc(2) ? n.j.D("interaction:reset") : t.normalize(e.pb, La.ia(e.Mb));
    }
    function d(N) {
      return function () {
        v.empty() || N.apply(this, arguments);
      };
    }
    function c(N, S, ca) {
      var I = {},
        T = {};
      return function (R) {
        switch (N) {
          case "click":
            var W = e.ef;
            break;
          case "doubleclick":
            W = e.ff;
            break;
          case "hold":
            W = e.mf;
            break;
          case "hover":
            W = e.nf;
            break;
          case "mousemove":
            W = e.qf;
            break;
          case "mousewheel":
            W = e.sf;
            break;
          case "mousedown":
            W = e.pf;
            break;
          case "mouseup":
            W = e.rf;
            break;
          case "dragstart":
            W = e.jf;
            break;
          case "drag":
            W = e.gf;
            break;
          case "dragend":
            W = e.hf;
            break;
          case "transformstart":
            W = e.zf;
            break;
          case "transform":
            W = e.xf;
            break;
          case "transformend":
            W = e.yf;
        }
        var ha = !1,
          x = !W.empty(),
          C = t.absolute(R, I),
          F = (S || x) && k(C),
          L = (S || x) && b(C);
        x && (x = F ? F.group : null, C = F ? F.Lb(C, T) : C, R.Fb = void 0, W = W({
          type: N,
          group: x,
          topmostClosedGroup: x,
          bottommostOpenGroup: L ? L.group : null,
          x: R.x,
          y: R.y,
          xAbsolute: C.x,
          yAbsolute: C.y,
          scale: P.I(R.scale, 1),
          secondary: R.mb,
          touches: P.I(R.touches, 1),
          delta: P.I(R.hd, 0),
          ctrlKey: R.ctrlKey,
          metaKey: R.metaKey,
          altKey: R.altKey,
          shiftKey: R.shiftKey,
          preventDefault: function () {
            ha = !0;
          },
          preventOriginalEventDefault: function () {
            R.Fb = "prevent";
          },
          allowOriginalEventDefault: function () {
            R.Fb = "allow";
          }
        }), ha = ha || 0 <= W.indexOf(!1));
        ha || ca && ca({
          gd: F,
          yg: L
        }, R);
      };
    }
    function p(N) {
      function S(ha, x) {
        var C = x.m;
        if (C) {
          for (var F = -Number.MAX_VALUE, L, K = 0; K < C.length; K++) {
            var J = C[K];
            !J.description && J.Y && ja(J, ha) && J.scale > F && (L = J, F = J.scale);
          }
          var V;
          L && (V = S(ha, L));
          return V || L || x;
        }
        return x;
      }
      for (var ca = A.length, I = A[0].scale, T = A[0].scale, R = 0; R < ca; R++) {
        var W = A[R];
        W = W.scale;
        W < I && (I = W);
        W > T && (T = W);
      }
      if (I !== T) for (R = 0; R < ca; R++) if (W = A[R], W.scale === T && W.Y && ja(W, N)) return S(N, W);
      return S(N, v);
    }
    function k(N, S) {
      if ("flattened" === e.nb) N = p(N);else {
        S = S || 0;
        for (var ca = A.length, I = void 0, T = 0; T < ca; T++) {
          var R = A[T];
          R.scale > S && !1 === R.open && R.Y && ja(R, N) && (I = R, S = R.scale);
        }
        N = I;
      }
      N && N.description && (N = N.parent);
      return N;
    }
    function b(N) {
      var S = void 0,
        ca = 0;
      Qa.vc(v, function (I) {
        !0 === I.open && I.Y && I.scale > ca && ja(I, N) && (S = I, ca = I.scale);
      });
      return S;
    }
    var a = ea.Ph(),
      g = this,
      e = n.options,
      u,
      t,
      l,
      h,
      r,
      y,
      w,
      v,
      z = !1,
      E,
      D,
      H,
      O,
      G,
      B,
      M,
      Q,
      X,
      Y,
      aa;
    n.j.subscribe("stage:initialized", function (N, S, ca, I) {
      u = S;
      Y = ca;
      aa = I;
      m();
    });
    n.j.subscribe("stage:resized", function (N, S, ca, I) {
      Y = ca;
      aa = I;
    });
    n.j.subscribe("stage:disposed", function () {
      Q.$a();
      ba.$a();
      X.i();
    });
    n.j.subscribe("expose:initialized", function (N) {
      h = N;
    });
    n.j.subscribe("zoom:initialized", function (N) {
      t = N;
    });
    n.j.subscribe("openclose:initialized", function (N) {
      r = N;
    });
    n.j.subscribe("select:initialized", function (N) {
      y = N;
    });
    n.j.subscribe("titlebar:initialized", function (N) {
      w = N;
    });
    n.j.subscribe("timeline:initialized", function (N) {
      l = N;
    });
    var A;
    n.j.subscribe("model:loaded", function (N, S) {
      v = N;
      A = S;
    });
    n.j.subscribe("model:childrenAttached", function (N) {
      A = N;
    });
    this.M = function () {};
    this.ua = d(c("mousedown", !1, function () {
      t.$h();
    }));
    this.wa = d(c("mouseup", !1, void 0));
    this.i = d(c("click", !0, function (N, S) {
      S.mb || S.shiftKey || !(N = N.gd) || (N.attribution && e.pc ? S.ctrlKey ? e.pc && (document.location.href = e.pc) : (S = La.ia(e.Mb), N.ee ? (t.reset(e.pb, S), N.ee = !1) : (t.dg(N, e.$b, e.pb, S), N.ee = !0)) : y.select({
        m: [N],
        Ca: !N.selected,
        Ba: S.metaKey || S.ctrlKey
      }, !0));
    }));
    this.u = d(c("doubleclick", !0, function (N, S) {
      var ca = N.gd;
      ca && ca.attribution && e.pc || (S.mb || S.shiftKey ? ca && (ca.parent.U && (ca = ca.parent), N = {
        m: ca.parent !== v ? [ca.parent] : [],
        Ca: !0,
        Ba: !1
      }, y.select(N, !0), h.Xb(N, !0, !0, !1)) : ca && (N = {
        m: [ca],
        Ca: !0,
        Ba: !1
      }, ca.Wa = !0, n.j.D("foamtree:attachChildren", [ca]), h.Xb(N, !0, !0, !1)), ca && l.K.A({}).wait(e.Pa / 2).call(function () {
        r.Db({
          m: Qa.xc(v, function (I) {
            return I.Ed && !Qa.Nh(ca, I);
          }),
          Ca: !1,
          Ba: !0
        }, !0, !0);
        ca.Ed = !0;
        r.Db({
          m: [ca],
          Ca: !(S.mb || S.shiftKey),
          Ba: !0
        }, !0, !0);
      }).start());
    }));
    this.sa = d(c("hold", !0, function (N, S) {
      (N = (S = !(S.metaKey || S.ctrlKey || S.shiftKey) && !S.mb) ? N.gd : N.yg) && N !== v && r.Db({
        m: [N],
        Ca: S,
        Ba: !0
      }, !0, !1);
    }));
    this.ga = d(c("dragstart", !1, function (N, S) {
      E = S.x;
      D = S.y;
      H = Date.now();
      z = !0;
    }));
    this.H = d(c("drag", !1, function (N, S) {
      if (z) {
        N = Date.now();
        B = Math.min(1, N - H);
        H = N;
        N = S.x - E;
        var ca = S.y - D;
        t.Yh(N, ca);
        O = N;
        G = ca;
        E = S.x;
        D = S.y;
      }
    }));
    this.T = d(c("dragend", !1, function () {
      if (z) {
        z = !1;
        var N = Math.sqrt(O * O + G * G) / B;
        4 <= N ? t.Zh(N, O, G) : t.cf();
      }
    }));
    this.qb = d(c("transformstart", !1, function (N, S) {
      M = 1;
      E = S.x;
      D = S.y;
    }));
    var U = 1,
      fa = !1;
    this.transform = d(c("transform", !1, function (N, S) {
      N = S.scale - .01;
      t.rg(S, N / M, S.x - E, S.y - D);
      M = N;
      E = S.x;
      D = S.y;
      U = M;
      fa = fa || 2 < S.touches;
    }));
    this.La = d(c("transformend", !1, function () {
      fa && .8 > U ? n.j.D("interaction:reset") : f();
      fa = !1;
    }));
    this.Ka = d(c("mousewheel", !1, function () {
      var N = P.Cg(function () {
        f();
      }, 300);
      return function (S, ca) {
        S = e.mj;
        1 !== S && (S = Math.pow(S, ca.hd), a ? (t.sg(ca, S), N()) : t.Pb(ca, S, e.pb, La.ia(e.Mb)).then(f));
      };
    }()));
    this.ta = d(function () {
      var N = void 0,
        S = {},
        ca = !1,
        I,
        T = c("hover", !1, function () {
          N && (N.wb = !1, 0 < N.level && (N.N = !0));
          I && (I.wb = !0, 0 < I.level && (I.N = !0));
          w.update(I);
          n.j.D("foamtree:dirty", !1);
        }),
        R = c("mousemove", !1, void 0);
      return function (W) {
        if ("out" === W.type) I = void 0, ca = I !== N;else if (t.absolute(W, S), N && !N.open && ja(N, S)) {
          var ha = k(S, N.scale);
          ha && ha !== N ? (ca = !0, I = ha) : ca = !1;
        } else I = k(S), ca = I !== N;
        ca && (T(W), N = I, ca = !1);
        N && R(W);
      };
    }());
    this.Nb = {
      click: q(this.i),
      doubleclick: q(this.u),
      hold: q(this.sa),
      mouseup: q(this.wa),
      mousedown: q(this.ua),
      dragstart: q(this.ga),
      drag: q(this.H),
      dragend: q(this.T),
      transformstart: q(this.qb),
      transform: q(this.transform),
      transformend: q(this.La),
      hover: q(this.ta),
      mousewheel: q(this.Ka)
    };
    var ba = function () {
        function N(I, T) {
          return function (R) {
            R = R.gesture;
            var W = R.center;
            W = Ia.re(u, W.pageX, W.pageY, {});
            W.scale = R.scale;
            W.mb = 1 < R.touches.length;
            W.touches = R.touches.length;
            I.call(u, W);
            (void 0 === W.Fb && T || "prevent" === W.Fb) && R.preventDefault();
          };
        }
        var S,
          ca = {};
        return {
          M: function (I) {
            S = window.Hammer(I, {
              doubletap_interval: 350,
              hold_timeout: 400,
              doubletap_distance: 10
            });
          },
          A: function (I, T, R) {
            ca[I] = T;
            S.on(I, N(T, R));
          },
          $a: function () {
            S && P.Aa(ca, function (I, T) {
              S.off(T, I);
            });
          }
        };
      }(),
      ja = function () {
        var N = {};
        return function (S, ca) {
          S.Lb(ca, N);
          return S.$ && wa.sa(S.$, N);
        };
      }();
  }
  ;
  function eb(n) {
    function q(d, c, p, k) {
      var b,
        a = 0,
        g = [];
      for (b = 0; b < c.length; b++) {
        var e = Math.sqrt(wa.i(c[b], c[(b + 1) % c.length]));
        g.push(e);
        a += e;
      }
      for (b = 0; b < g.length; b++) g[b] /= a;
      d[0].x = p.x;
      d[0].y = p.y;
      var u = e = a = 0;
      for (b = 1; b < d.length; b++) {
        var t = d[b],
          l = .95 * Math.pow(b / d.length, k);
        for (a += .3819; e < a;) e += g[u], u = (u + 1) % g.length;
        var h = (u - 1 + g.length) % g.length,
          r = 1 - (e - a) / g[h],
          y = c[h].x;
        h = c[h].y;
        var w = c[u].x,
          v = c[u].y;
        y = (y - p.x) * l + p.x;
        h = (h - p.y) * l + p.y;
        w = (w - p.x) * l + p.x;
        v = (v - p.y) * l + p.y;
        t.x = y * (1 - r) + w * r;
        t.y = h * (1 - r) + v * r;
      }
    }
    var m = {
      random: {
        xb: function (d, c) {
          for (var p = 0; p < d.length; p++) {
            var k = d[p];
            k.x = c.x + Math.random() * c.w;
            k.y = c.y + Math.random() * c.o;
          }
        },
        Qb: "box"
      },
      ordered: {
        xb: function (d, c) {
          d = d.slice(0);
          f.cc && d.sort(fb);
          Va.i(d, c, !1, f.Od);
        },
        Qb: "box"
      },
      squarified: {
        xb: function (d, c) {
          d = d.slice(0);
          f.cc && d.sort(fb);
          Va.u(d, c, !1, f.Od);
        },
        Qb: "box"
      },
      fisheye: {
        xb: function (d, c, p) {
          d = d.slice(0);
          f.cc && d.sort(fb);
          q(d, c, p, .25);
        },
        Qb: "polygon"
      },
      blackhole: {
        xb: function (d, c, p) {
          d = d.slice(0);
          f.cc && d.sort(fb).reverse();
          q(d, c, p, 1);
        },
        Qb: "polygon"
      }
    };
    m.order = m.ordered;
    m.treemap = m.squarified;
    var f = n.options;
    this.i = function (d, c, p) {
      if (0 < d.length) {
        p = m[p.relaxationInitializer || p.initializer || f.Mi || "random"];
        if ("box" === p.Qb) {
          var k = wa.F(c, {});
          p.xb(d, k);
          wa.wa(d, wa.H(k), c);
        } else p.xb(d, c, wa.u(c, {}));
        for (p = d.length - 1; 0 <= p; p--) {
          k = d[p];
          if (k.description) {
            var b = wa.ta(c, f.tc, f.Eg);
            k.x = b.x;
            k.y = b.y;
          }
          k.attribution && (b = wa.ta(c, f.ce, f.tg), k.x = b.x, k.y = b.y);
          P.yb(k.group.initialPosition) && (b = k.group.initialPosition, b = wa.ta(c, b.position || "bottomright", b.distanceFromCenter || 1), k.x = b.x, k.y = b.y);
        }
      }
    };
  }
  ;
  function gb(n) {
    var q,
      m = n.options,
      f = new hb(n, this),
      d = new ib(n, this),
      c = {
        relaxed: f,
        ordered: d,
        squarified: d
      },
      p = c[n.options.Gc] || f;
    this.lg = 5E-5;
    n.j.subscribe("model:loaded", function (k) {
      q = k;
    });
    n.j.subscribe("options:changed", function (k) {
      k.layout && P.has(c, m.Gc) && (p = c[m.Gc]);
    });
    this.step = function (k, b, a, g) {
      return p.step(k, b, a, g);
    };
    this.complete = function (k) {
      p.complete(k);
    };
    this.Re = function (k) {
      return k === q ? !0 : 2 * Math.sqrt(k.O.ha / (Math.PI * k.m.length)) >= Math.max(m.Ee, 5E-5);
    };
    this.kd = function (k, b) {
      var a = Math.pow(m.Ma, k.level),
        g = m.ab * a;
      a *= m.md;
      k = k.m;
      for (var e = k.length - 1; 0 <= e; e--) {
        var u = k[e];
        p.fe(u, a);
        var t = u;
        t.$ = 0 < g ? Sa.i(t.C, g) : t.C;
        t.$ && (wa.F(t.$, t.F), wa.Ka(t.$, t.O));
        u.m && b.push(u);
      }
    };
    this.ic = function (k) {
      p.ic(k);
    };
    this.Gb = function (k) {
      p.Gb(k);
    };
  }
  ;
  function hb(n, q) {
    function m(l) {
      if (l.m) {
        l = l.m;
        for (var h = 0; h < l.length; h++) {
          var r = l[h];
          r.mc = r.jc * e.sh;
        }
      }
    }
    function f(l, h) {
      q.Re(l) && (l.G || (l.G = Sa.i(l.C, e.md * Math.pow(e.Ma, l.level - 1)), l.G && l.m[0] && l.m[0].description && "stab" == e.Vb && k(l)), l.G && (a.Gb(l), u.i(d(l), l.G, l.group), l.R = !0, h(l)), m(l));
    }
    function d(l) {
      return "stab" === e.Vb && 0 < l.m.length && l.m[0].description ? l.m.slice(1) : l.m;
    }
    function c(l) {
      var h = d(l);
      Ta.i(h, l.G);
      Ta.u(h, l.G);
      return Ua.H(l) * Math.sqrt(g.O.ha / l.O.ha);
    }
    function p(l) {
      return l < e.Kf || 1E-4 > l;
    }
    function k(l) {
      var h = e.Ub / (1 + e.Ub),
        r = wa.F(l.G, {}),
        y = {
          x: r.x,
          y: 0
        },
        w = r.y,
        v = r.o,
        z = e.le * Math.pow(e.Ma, l.level - 1),
        E = v * e.ke,
        D = e.tc;
      "bottom" == D || 0 <= D && 180 > D ? (D = Math.PI, w += v, v = -1) : (D = 0, v = 1);
      var H = l.G,
        O = D,
        G = 0,
        B = 1,
        M = wa.u(H, {}),
        Q = M.ha;
      h *= Q;
      for (var X = 0; G < B && 20 > X++;) {
        var Y = (G + B) / 2;
        y.y = r.y + r.o * Y;
        var aa = wa.ua(H, y, O);
        wa.u(aa[0], M);
        var A = M.ha - h;
        if (.01 >= Math.abs(A) / Q) break;else 0 < (0 == O ? 1 : -1) * A ? B = Y : G = Y;
      }
      wa.F(aa[0], r);
      if (r.o < z || r.o > E) y.y = r.o < z ? w + v * Math.min(z, E) : w + v * E, aa = wa.ua(l.G, y, D);
      l.m[0].C = aa[0];
      l.G = aa[1];
    }
    function b(l) {
      l !== g && 2 * Math.sqrt(l.O.ha / (Math.PI * l.m.length)) < Math.max(.85 * e.Ee, q.lg) && (l.R = !1, l.xa = !1, l.Ja = !0, l.G = null);
    }
    var a = this,
      g,
      e = n.options,
      u = new eb(n),
      t = 0;
    n.j.subscribe("model:loaded", function (l) {
      g = l;
      t = 0;
    });
    this.step = function (l, h, r, y) {
      function w(H) {
        H.R && H.xa ? b(H) : H.Ja && H.C && f(H, function () {
          var G = d(H);
          Ta.i(G, H.G);
          Ta.u(G, H.G);
          l(H);
        });
        if (!H.G || !H.R) return 0;
        if (H.parent && H.parent.Z || H.Ea) {
          var O = c(H);
          y && y(H);
          H.Ea = !p(O) && !r;
          H.Z = !0;
        } else O = 0;
        q.kd(H, E);
        return O;
      }
      function v(H, O, G) {
        t < H && (t = H);
        var B = e.Kf;
        e.Dd(O ? 1 : 1 - (H - B) / (t - B || 1), O, G);
        O && (t = 0);
      }
      for (var z = 0, E = [g]; 0 < E.length;) z = Math.max(z, w(E.shift()));
      var D = p(z);
      h && v(z, D, r);
      return D;
    };
    this.complete = function (l) {
      for (var h = [g]; 0 < h.length;) {
        var r = h.shift();
        !r.R && r.Ja && r.C && f(r, l);
        if (r.G) {
          if (r.parent && r.parent.Z || r.Ea) {
            for (var y = 1E-4 > r.O.ha, w = 0; !(p(c(r)) || y && 32 < w++););
            r.Z = !0;
            r.Ea = !1;
          }
          q.kd(r, h);
        }
      }
    };
    this.ic = function (l) {
      Qa.L(l, m);
    };
    this.fe = function (l, h) {
      if (l.R) {
        var r = l.G;
        r && (l.Id = r);
        l.G = Sa.i(l.C, h);
        l.G && l.m[0] && l.m[0].description && "stab" == e.Vb && k(l);
        r && !l.G && (l.Z = !0);
        l.G && l.Id && wa.wa(d(l), l.Id, l.G);
      }
    };
    this.Gb = function (l) {
      for (var h = d(l), r = l.ha, y, w = y = 0; w < h.length; w++) y += h[w].weight;
      l.Cj = y;
      for (l = 0; l < h.length; l++) w = h[l], w.Xf = w.w, w.jc = r / Math.PI * (0 < y ? w.weight / y : 1 / h.length);
    };
  }
  ;
  function ib(n, q) {
    function m(a, g) {
      if (q.Re(a)) {
        if (!a.G || a.parent && a.parent.Z) {
          var e = k.md * Math.pow(k.Ma, a.level - 1);
          a.G = wa.H(d(wa.F(a.C, {}), e));
        }
        a.G && (a.R = !0, g(a));
      } else a.R = !1, Qa.za(a, function (u) {
        u.G = null;
      });
    }
    function f(a) {
      function g(t) {
        function l() {
          r.C = wa.H(y);
          r.x = y.x + y.w / 2;
          r.y = y.y + y.o / 2;
        }
        var h = k.Ub / (1 + k.Ub),
          r = t.m[0],
          y = wa.F(t.G, {}),
          w = y.o;
        h = Math.min(Math.max(w * h, k.le * Math.pow(k.Ma, t.level - 1)), w * k.ke);
        var v = k.tc;
        "bottom" == v || 0 <= v && 180 > v ? (y.o = w - h, t.G = wa.H(y), y.y += w - h, y.o = h, l()) : (y.o = h, l(), y.y += h, y.o = w - h, t.G = wa.H(y));
      }
      if ("stab" == k.Vb && 0 < a.m.length && a.m[0].description) {
        var e = a.m.slice(1);
        g(a);
      } else e = a.m;
      k.cc && e.sort(fb);
      "floating" == k.Vb && c(e, k.tc, function (t) {
        return t.description;
      });
      c(e, k.ce, function (t) {
        return t.attribution;
      });
      var u = wa.F(a.G, {});
      (b[k.Gc] || Va.i)(e, u, !0, k.Od);
      a.Ea = !1;
      a.Z = !0;
      a.N = !0;
      a.Ga = !0;
    }
    function d(a, g) {
      var e = 2 * g;
      a.x += g;
      a.y += g;
      a.w -= e;
      a.o -= e;
      return a;
    }
    function c(a, g, e) {
      for (var u = 0; u < a.length; u++) {
        var t = a[u];
        if (e(t)) {
          a.splice(u, 1);
          "topleft" == g || 135 <= g && 315 > g ? a.unshift(t) : a.push(t);
          break;
        }
      }
    }
    var p,
      k = n.options,
      b = {
        squarified: Va.u,
        ordered: Va.i
      };
    n.j.subscribe("model:loaded", function (a) {
      p = a;
    });
    this.step = function (a, g, e) {
      this.complete(a);
      g && k.Dd(1, !0, e);
      return !0;
    };
    this.complete = function (a) {
      for (var g = [p]; 0 < g.length;) {
        var e = g.shift();
        (!e.R || e.parent && e.parent.Z) && e.Ja && e.C && m(e, a);
        e.G && ((e.parent && e.parent.Z || e.Ea) && f(e), q.kd(e, g));
      }
    };
    this.Gb = this.ic = this.fe = P.qa;
  }
  ;
  function jb(n, q) {
    function m(x, C) {
      var F = x.O.r,
        L = F / 15,
        K = .5 * F / 15;
      F /= 5;
      var J = x.O.x;
      x = x.O.y;
      C.fillRect(J - K, x - K, L, L);
      C.fillRect(J - K - F, x - K, L, L);
      C.fillRect(J - K + F, x - K, L, L);
    }
    function f(x, C, F, L) {
      null === x && F.clearRect(0, 0, O, G);
      var K,
        J = Array(T.length);
      for (K = T.length - 1; 0 <= K; K--) J[K] = T[K].na(F, L);
      for (K = T.length - 1; 0 <= K; K--) J[K] && T[K].before(F, L);
      X.uc([F, H], function (V) {
        var Z;
        if (null !== x) {
          F.save();
          F.globalCompositeOperation = "destination-out";
          F.fillStyle = F.strokeStyle = "rgba(255, 255, 255, 1)";
          for (Z = x.length - 1; 0 <= Z; Z--) {
            var ia = x[Z],
              la = ia.C;
            la && (F.save(), F.beginPath(), ia.Kb(F), ya.Xd(F, la), F.fill(), ia = A.ab * Math.pow(A.Ma, ia.level - 1), 0 < ia && (F.lineWidth = ia / 2, F.stroke()), F.restore());
          }
          F.restore();
        }
        V = V.scale;
        if (0 !== C.length) {
          Z = {};
          for (la = T.length - 1; 0 <= la; la--) T[la].pg(Z);
          for (ia = I.length - 1; 0 <= ia; ia--) if (la = I[ia], Z[la.id]) {
            var qa = la.Nd;
            for (la = 0; la < C.length; la++) {
              var oa = C[la];
              !oa.parent || oa.parent.xa && oa.parent.R ? qa(oa, V) : oa.aa.clear();
            }
          }
        }
        for (Z = T.length - 1; 0 <= Z; Z--) ia = T[Z], J[Z] && ia.Qd(C, F, V);
      });
      for (K = T.length - 1; 0 <= K; K--) J[K] && T[K].after(F);
      A.bd && (F.canvas.style.opacity = .99, setTimeout(function () {
        F.canvas.style.opacity = 1;
      }, 1));
    }
    function d(x) {
      h === y ? x < .9 * u && (h = r, v = z, b()) : x >= u && (h = y, v = E, b());
    }
    function c() {
      function x(K, J, V) {
        K.ub = Math.floor(1E3 * K.scale) - V * J;
        0 < K.opacity && !K.open && J++;
        var Z = K.m;
        if (Z) for (var ia = Z.length - 1; 0 <= ia; ia--) K.W && x(Z[ia], J, V);
      }
      var C = null,
        F = null,
        L = null;
      X.uc([], function (K) {
        d(K.scale);
        var J = !1;
        Qa.L(U, function (da) {
          da.W && (J = da.yd() || J, da.ec(), da.Na = M.i(da) || da.Na);
        });
        J && (U.N = !0);
        var V = "onSurfaceDirty" === A.Pg;
        Qa.jd(U, function (da) {
          da.parent && da.parent.Z && (da.aa.clear(), da.Na = !0, V || (da.rc = !0, da.Sb.clear()));
          V && (da.rc = !0, da.Sb.clear());
        });
        var Z = K.scale * K.scale;
        Qa.jd(U, function (da) {
          if (da.R) {
            for (var ma = da.m, sa = 0; sa < ma.length; sa++) if (5 < ma[sa].O.ha * Z) {
              da.X = !0;
              return;
            }
            da.X = !1;
          }
        });
        e(K);
        L = [];
        Qa.wc(U, function (da) {
          if (da.parent.X && da.Y && da.W) {
            L.push(da);
            for (var ma = da.parent; ma !== U && (ma.open || 0 === ma.opacity);) ma = ma.parent;
            ma !== U && .02 > Math.abs(ma.scale - da.scale) && (da.scale = Math.min(da.scale, ma.scale));
          }
        });
        x(U, 0, "flattened" === A.nb ? -1 : 1);
        L.sort(function (da, ma) {
          return da.ub - ma.ub;
        });
        if (p()) C = L, F = null;else {
          var ia = {},
            la = {},
            qa = "none" != A.od && A.ab < A.bb / 2,
            oa = A.ab < A.Bc / 2 + A.nd * A.Ge.a;
          Qa.L(U, function (da) {
            if (da.W && !da.description && (da.Z || da.N || da.Ic && da.parent.X && da.Na)) {
              var ma,
                sa,
                ua = [da],
                va = da.J || da.parent.m;
              if (qa) for (ma = 0; ma < va.length; ma++) (sa = va[ma]) && ua.push(sa);else if (oa) if (!da.selected && da.Ta) {
                sa = !0;
                for (ma = 0; ma < va.length; ma++) va[ma] ? ua.push(va[ma]) : sa = !1;
                !sa && 1 < da.level && ua.push(da.parent);
              } else for (ma = 0; ma < va.length; ma++) (sa = va[ma]) && sa.selected && ua.push(sa);
              var za;
              for (ma = da.parent; ma != U;) ma.selected && (za = ma), ma = ma.parent;
              za && ua.push(za);
              for (ma = 0; ma < ua.length; ma++) {
                za = ua[ma];
                for (da = za.parent; da && da !== U;) 0 < da.opacity && (za = da), da = da.parent;
                la[za.id] = !0;
                Qa.za(za, function (pb) {
                  ia[pb.id] = !0;
                });
              }
            }
          });
          C = L.filter(function (da) {
            return ia[da.id];
          });
          F = C.filter(function (da) {
            return la[da.id];
          });
        }
      });
      (function () {
        var K = !1;
        A.Jf && Qa.L(U, function (J) {
          if (J.W && 0 !== J.pa.a && 1 !== J.pa.a) return K = !0, !1;
        });
        K ? (Qa.vc(U, function (J) {
          if (J.W && (J.opacity !== J.Mc || J.Ga)) {
            var V = J.m;
            if (V) {
              for (var Z = 0, ia = V.length - 1; 0 <= ia; ia--) Z = Math.max(Z, V[ia].Hc);
              J.Hc = Z + J.opacity * J.pa.a;
            } else J.Hc = J.opacity * J.pa.a;
          }
        }), Qa.L(U, function (J) {
          if (J.W && (J.opacity !== J.Mc || J.Ga)) {
            for (var V = J.Hc, Z = J; (Z = Z.parent) && Z !== U;) V += Z.opacity * Z.pa.a * A.Hf;
            J.cd = 0 < V ? 1 - Math.pow(1 - J.pa.a, 1 / V) : 0;
            J.Mc = J.opacity;
          }
        })) : Qa.L(U, function (J) {
          J.W && (J.cd = 1, J.Mc = -1);
        });
      })();
      return {
        cg: C,
        bg: F,
        Y: L
      };
    }
    function p() {
      var x = U.Z || U.N || "none" == A.Ne;
      if (!x && !U.empty()) {
        var C = U.m[0].scale;
        Qa.L(U, function (F) {
          if (F.W && F.Y && F.scale !== C) return x = !0, !1;
        });
      }
      !x && 0 < A.Ae && 1 != A.Qa && Qa.L(U, function (F) {
        if (F.W && 0 < F.ja) return x = !0, !1;
      });
      "accurate" == A.Ne && (x = (x = x || 0 === A.ab) || "none" != A.od && A.ab < A.bb / 2, !x && A.ab < A.Bc / 2 + A.nd * A.Ge.a && Qa.L(U, function (F) {
        if (F.W && (F.selected && !F.Ta || !F.selected && F.Ta)) return x = !0, !1;
      }));
      return x;
    }
    function k() {
      if (A.B !== A.ob) return !0;
      var x = "polygonPlainFill polygonPlainStroke polygonGradientFill polygonGradientStroke labelPlainFill contentDecoration".split(" ");
      Qa.L(U, function (L) {
        if (L.W && L.U) return x.push("polygonExposureShadow"), !1;
      });
      for (var C = x.length - 1; 0 <= C; C--) {
        var F = x[C];
        if (!!ba[F] !== !!N[F]) return !0;
      }
      return !1;
    }
    function b() {
      function x(J, V, Z, ia, la) {
        function qa(da, ma, sa, ua, va) {
          da[ua] && (ma -= sa * t[ua], da[ua] = !1, va && (ma += sa * t[va], da[va] = !0));
          return ma;
        }
        J = P.extend({}, J);
        switch (Z) {
          case "never":
            J.labelPlainFill = !1;
            break;
          case "always":
          case "auto":
            J.labelPlainFill = !0;
        }
        if (A.Ac) switch (ia) {
          case "never":
            J.contentDecoration = !1;
            break;
          case "always":
          case "auto":
            J.contentDecoration = !0;
        } else J.contentDecoration = !1;
        var oa = 0;
        P.Aa(J, function (da, ma) {
          da && (oa += V * t["contentDecoration" === ma ? "labelPlainFill" : ma]);
        });
        J.polygonExposureShadow = C;
        oa += 2 * t.polygonExposureShadow;
        if (oa <= la || (oa = qa(J, oa, 2, "polygonExposureShadow")) <= la || (oa = qa(J, oa, V, "polygonGradientFill", "polygonPlainFill")) <= la || (oa = qa(J, oa, V, "polygonGradientStroke")) <= la || (oa = qa(J, oa, V, "polygonPlainStroke")) <= la || "auto" === ia && (oa = qa(J, oa, V, "contentDecoration")) <= la) return J;
        "auto" === Z && (oa = qa(J, oa, V, "labelPlainFill"));
        return J;
      }
      var C = h === r,
        F = 0,
        L = 0;
      Qa.qe(U, function (J) {
        var V = 1;
        Qa.L(J, function () {
          V++;
        });
        F += V;
        L = Math.max(L, V);
      });
      var K = {};
      switch (A.Yg) {
        case "plain":
          K.polygonPlainFill = !0;
          break;
        case "gradient":
          K.polygonPlainFill = !C, K.polygonGradientFill = C;
      }
      switch (A.od) {
        case "plain":
          K.polygonPlainStroke = !0;
          break;
        case "gradient":
          K.polygonPlainStroke = !C, K.polygonGradientStroke = C;
      }
      ba = x(K, F, A.kj, A.ij, A.jj);
      N = x(K, 2 * L, "always", "always", A.Ig);
      ja = x(K, F, "always", "always", A.Hg);
    }
    function a(x) {
      return function (C, F) {
        return C === h ? !0 === ba[x] : !0 === (F ? N : ja)[x];
      };
    }
    function g(x, C) {
      return function (F, L) {
        return x(F, L) && C(F, L);
      };
    }
    function e(x) {
      U.Y = !0;
      Qa.jd(U, function (C) {
        if (C.W && C.X && C.xa && C.R && (U.N || C.Z || C.Yd)) {
          C.Yd = !1;
          var F = C.m,
            L = {
              x: 0,
              y: 0,
              w: 0,
              o: 0
            },
            K = !!C.G;
          if (1 < O / x.w) {
            var J;
            for (J = F.length - 1; 0 <= J; J--) F[J].Y = !1;
            if (C.Y && K) for (J = F.length - 1; 0 <= J; J--) if (C = F[J], 1 !== C.scale && (C.Lb(x, L), L.w = x.w / C.scale, L.o = x.o / C.scale), !1 === C.Y && C.C) {
              K = C.C;
              var V = K.length;
              if (wa.sa(C.C, 1 === C.scale ? x : L)) C.Y = !0;else for (var Z = 0; Z < V; Z++) if (wa.Yc(K[Z], K[(Z + 1) % V], 1 === C.scale ? x : L)) {
                C.Y = !0;
                C.J && (C = C.J[Z]) && (F[C.index].Y = !0);
                break;
              }
            }
          } else for (J = 0; J < F.length; J++) F[J].Y = K;
        }
      });
    }
    var u = ea.Ve() ? 50 : 1E4,
      t,
      l,
      h,
      r,
      y,
      w,
      v,
      z,
      E,
      D,
      H,
      O,
      G,
      B,
      M = new kb(n),
      Q = new lb(n),
      X,
      Y,
      aa,
      A = n.options,
      U,
      fa,
      ba,
      ja,
      N;
    n.j.subscribe("stage:initialized", function (x, C, F, L) {
      B = x;
      O = F;
      G = L;
      l = B.fc("wireframe", A.ob, !1);
      r = l.getContext("2d");
      y = new xa(r);
      w = B.fc("hifi", A.B, !1);
      z = w.getContext("2d");
      E = new xa(z);
      h = r;
      v = z;
      r.B = A.ob;
      y.B = A.ob;
      z.B = A.B;
      E.B = A.B;
      D = B.fc("tmp", Math.max(A.B, A.ob), !0);
      H = D.getContext("2d");
      H.B = 1;
      [r, z, H].forEach(function (K) {
        K.scale(K.B, K.B);
      });
    });
    n.j.subscribe("stage:resized", function (x, C, F, L) {
      O = F;
      G = L;
      [r, z, H].forEach(function (K) {
        K.scale(K.B, K.B);
      });
    });
    n.j.subscribe("model:loaded", function (x) {
      function C(F) {
        var L = 0;
        if (!F.empty()) {
          for (var K = F.m, J = K.length - 1; 0 <= J; J--) L = Math.max(L, C(K[J]));
          L += 1;
        }
        return F.Uf = L;
      }
      U = x;
      fa = !0;
      C(U);
      b();
      n.j.D("render:renderers:resolved", ba, N, ja);
    });
    var S = "groupFillType groupStrokeType wireframeDrawMaxDuration wireframeLabelDrawing wireframeContentDecorationDrawing finalCompleteDrawMaxDuration finalIncrementalDrawMaxDuration groupContentDecorator".split(" "),
      ca = ["groupLabelLightColor", "groupLabelDarkColor", "groupLabelColorThreshold", "groupUnexposureLabelColorThreshold"];
    n.j.subscribe("options:changed", function (x) {
      function C(L, K, J, V) {
        B.Li(L, J);
        K.B = J;
        V && K.scale(J, J);
      }
      x.dataObject || (P.cb(x, S) && b(), P.cb(x, ca) && Qa.L(U, function (L) {
        L.ld = -1;
      }));
      var F = P.has(x, "pixelRatio");
      x = P.has(x, "wireframePixelRatio");
      if (F || x) F && C(w, v, A.B, !0), x && C(l, h, A.ob, !0), C(D, H, Math.max(A.B, A.ob), !1);
    });
    n.j.subscribe("zoom:initialized", function (x) {
      X = x;
    });
    n.j.subscribe("timeline:initialized", function (x) {
      Y = x;
    });
    n.j.subscribe("api:initialized", function (x) {
      aa = x;
    });
    var I = [{
        id: "offsetPolygon",
        Nd: function (x) {
          if ((x.selected || 0 < x.opacity && !1 === x.open || !x.X) && x.aa.Ha()) {
            var C = x.aa;
            C.clear();
            if (x.$) {
              var F = x.$,
                L = A.Kg;
              0 < L ? ya.Xi(C, F, x.parent.O.r / 32, Math.min(1, L * Math.pow(1 - A.Lg * L, x.Uf))) : ya.Xd(C, F);
            }
            x.Gd = !0;
          }
        }
      }, {
        id: "label",
        Nd: function (x) {
          x.Na && x.Ic && M.u(x);
        }
      }, {
        id: "custom",
        Nd: function (x, C) {
          if (x.$ && (0 < x.opacity && (!1 === x.open || !0 === x.selected) || !x.X) && x.rc && n.options.Ac) {
            var F = {};
            aa.$c(F, x);
            aa.ad(F, x);
            aa.Zc(F, x, !0);
            F.context = x.Sb;
            F.polygonContext = x.aa;
            F.labelContext = x.Ec;
            F.shapeDirty = x.Gd;
            F.viewportScale = C;
            C = {
              groupLabelDrawn: !0,
              groupPolygonDrawn: !0
            };
            n.options.Og(n.Fd, F, C);
            x.We = C.groupLabelDrawn;
            x.Hd = C.groupPolygonDrawn;
            x.Gd = !1;
            x.rc = !1;
          }
        }
      }].reverse(),
      T = [new function (x) {
        var C = Array(x.length);
        this.Qd = function (F, L, K) {
          if (0 !== F.length) {
            var J,
              V = [],
              Z = F[0].ub;
            for (J = 0; J < F.length; J++) {
              var ia = F[J];
              ia.ub !== Z && (V.push(J), Z = ia.ub);
            }
            V.push(J);
            for (var la = Z = 0; la < V.length; la++) {
              for (var qa = V[la], oa = x.length - 1; 0 <= oa; oa--) if (C[oa]) {
                var da = x[oa];
                L.save();
                for (J = Z; J < qa; J++) ia = F[J], L.save(), ia.Kb(L), da.lb.call(da, ia, L, K), L.restore();
                da.Xa.call(da, L, K);
                L.restore();
              }
              Z = qa;
            }
          }
        };
        this.na = function (F, L) {
          for (var K = !1, J = x.length - 1; 0 <= J; J--) C[J] = x[J].na(F, L), K |= C[J];
          return K;
        };
        this.before = function (F, L) {
          for (var K = x.length - 1; 0 <= K; K--) if (C[K]) {
            var J = x[K];
            J.before.call(J, F, L);
          }
        };
        this.after = function (F) {
          for (var L = x.length - 1; 0 <= L; L--) if (C[L]) {
            var K = x[L];
            K.after.call(K, F);
          }
        };
        this.pg = function (F) {
          for (var L = x.length - 1; 0 <= L; L--) {
            var K = x[L];
            if (C[L]) for (var J = K.Sa.length - 1; 0 <= J; J--) F[K.Sa[J]] = !0;
          }
        };
      }([{
        Sa: ["offsetPolygon"],
        na: a("polygonExposureShadow"),
        before: function (x) {
          H.save();
          H.scale(x.B, x.B);
        },
        after: function () {
          H.restore();
        },
        tb: function () {},
        Xa: function (x) {
          this.Tf && (this.Tf = !1, x.save(), x.setTransform(1, 0, 0, 1, 0, 0), x.drawImage(D, 0, 0, x.canvas.width, x.canvas.height, 0, 0, x.canvas.width, x.canvas.height), x.restore(), H.save(), H.setTransform(1, 0, 0, 1, 0, 0), H.clearRect(0, 0, D.width, D.height), H.restore());
        },
        lb: function (x, C, F) {
          if (!(x.open && x.X || x.aa.Ha())) {
            var L = A.Ae * x.opacity * x.ja * ("flattened" === A.nb ? 1 - x.parent.ja : (1 - x.Eb) * x.parent.Eb) * (1.1 <= A.Qa ? 1 : (A.Qa - 1) / .1);
            0 < L && (H.save(), H.beginPath(), x.Kb(H), x.aa.Oa(H), H.shadowBlur = F * C.B * L, H.shadowColor = A.Qg, H.fillStyle = "rgba(0, 0, 0, 1)", H.globalCompositeOperation = "source-over", H.globalAlpha = x.opacity, H.fill(), H.shadowBlur = 0, H.shadowColor = "transparent", H.globalCompositeOperation = "destination-out", H.fill(), H.restore(), this.Tf = !0);
          }
        }
      }, {
        Sa: ["offsetPolygon"],
        na: function () {
          return !0;
        },
        before: function () {
          function x(K) {
            var J = K.pa,
              V = K.wb,
              Z = K.selected,
              ia = (J.h + (V ? A.bh : 0) + (Z ? A.th : 0)) % 360,
              la = C(J.l * K.va + (V ? A.dh : 0) + (Z ? A.uh : 0));
            J = C(J.s * K.saturation + (V ? A.eh : 0) + (Z ? A.vh : 0));
            K = K.ze;
            K.h = ia;
            K.s = J;
            K.l = la;
            return K;
          }
          function C(K) {
            return 100 < K ? 100 : 0 > K ? 0 : K;
          }
          var F = [{
              type: "fill",
              na: a("polygonPlainFill"),
              Sc: function (K, J) {
                J.fillStyle = Ea.H(x(K));
              }
            }, {
              type: "fill",
              na: a("polygonGradientFill"),
              Sc: function (K, J) {
                var V = K.O.r,
                  Z = x(K);
                V = J.createRadialGradient(K.x, K.y, 0, K.x, K.y, V * A.Ug);
                var ia = Z.l,
                  la = A.Sg;
                V.addColorStop(0, Ea.i((Z.h + A.Rg) % 360, C(Z.s + A.Tg), C(ia + la)));
                ia = Z.l;
                la = A.Wg;
                V.addColorStop(1, Ea.i((Z.h + A.Vg) % 360, C(Z.s + A.Xg), C(ia + la)));
                K.aa.Oa(J);
                J.fillStyle = V;
              }
            }, {
              type: "stroke",
              na: g(a("polygonPlainStroke"), function () {
                return 0 < A.bb;
              }),
              Sc: function (K, J) {
                var V = K.pa,
                  Z = K.wb,
                  ia = K.selected,
                  la = (V.h + A.Ke + (Z ? A.Be : 0) + (ia ? A.He : 0)) % 360,
                  qa = C(V.s * K.saturation + A.Me + (Z ? A.De : 0) + (ia ? A.Je : 0));
                V = C(V.l * K.va + A.Le + (Z ? A.Ce : 0) + (ia ? A.Ie : 0));
                J.strokeStyle = Ea.i(la, qa, V);
                J.lineWidth = A.bb * Math.pow(A.Ma, K.level - 1);
              }
            }, {
              type: "stroke",
              na: g(a("polygonGradientStroke"), function () {
                return 0 < A.bb;
              }),
              Sc: function (K, J) {
                var V = K.O.r * A.Bh,
                  Z = K.pa,
                  ia = Math.PI * A.xh / 180;
                V = J.createLinearGradient(K.x + V * Math.cos(ia), K.y + V * Math.sin(ia), K.x + V * Math.cos(ia + Math.PI), K.y + V * Math.sin(ia + Math.PI));
                var la = K.wb,
                  qa = K.selected;
                ia = (Z.h + A.Ke + (la ? A.Be : 0) + (qa ? A.He : 0)) % 360;
                var oa = C(Z.s * K.saturation + A.Me + (la ? A.De : 0) + (qa ? A.Je : 0));
                Z = C(Z.l * K.va + A.Le + (la ? A.Ce : 0) + (qa ? A.Ie : 0));
                la = A.zh;
                V.addColorStop(0, Ea.i((ia + A.yh) % 360, C(oa + A.Ah), C(Z + la)));
                la = A.Dh;
                V.addColorStop(1, Ea.i((ia + A.Ch) % 360, C(oa + A.Eh), C(Z + la)));
                J.strokeStyle = V;
                J.lineWidth = A.bb * Math.pow(A.Ma, K.level - 1);
              }
            }],
            L = Array(F.length);
          return function (K, J) {
            for (var V = F.length - 1; 0 <= V; V--) L[V] = F[V].na(K, J);
            this.aj = F;
            this.zg = L;
          };
        }(),
        after: function () {},
        tb: function () {},
        Xa: function () {},
        lb: function (x, C) {
          if (!(!x.Hd || (0 === x.opacity || x.open) && x.X || x.aa.Ha() || !A.me && x.description)) {
            var F = this.aj,
              L = this.zg;
            C.beginPath();
            x.aa.Oa(C);
            for (var K = !1, J = !1, V = F.length - 1; 0 <= V; V--) {
              var Z = F[V];
              if (L[V]) switch (Z.Sc(x, C), Z.type) {
                case "fill":
                  K = !0;
                  break;
                case "stroke":
                  J = !0;
              }
            }
            F = (x.X ? x.opacity : 1) * x.pa.a;
            L = !x.empty();
            V = A.Jf ? x.cd : 1;
            K && (x = L && x.X && x.R && x.m[0].W ? 1 - x.m.reduce(function (ia, la) {
              return ia + la.ra * la.Kd;
            }, 0) / x.m.length * (1 - A.Hf) : 1, C.globalAlpha = F * x * V, mb(C));
            J && (C.globalAlpha = F * (L ? A.ai : 1) * V, C.closePath(), nb(C), C.stroke());
          }
        }
      }, {
        Sa: ["offsetPolygon"],
        na: function () {
          return 0 < A.Bc;
        },
        before: function () {},
        after: function () {},
        tb: function () {},
        Xa: function () {},
        lb: function (x, C, F) {
          if (x.Hd && x.selected && !x.aa.Ha()) {
            C.globalAlpha = x.Da;
            C.beginPath();
            var L = Math.pow(A.Ma, x.level - 1);
            C.lineWidth = A.Bc * L;
            C.strokeStyle = A.wh;
            var K = A.nd;
            0 < K && (C.shadowBlur = K * L * F * C.B, C.shadowColor = A.Fe);
            x.aa.Oa(C);
            C.closePath();
            C.stroke();
          }
        }
      }, {
        Sa: [],
        na: function () {
          return A.Fa;
        },
        before: function () {},
        after: function () {},
        tb: function () {},
        Xa: function () {},
        lb: function (x, C) {
          function F(L, K, J) {
            var V = wa.La(x.$, x.O, L / K),
              Z = A.ug;
            V = Math.min(Math.min(.9 * V, Z * x.F.o) / K, Z * x.F.w / L);
            C.save();
            C.translate(x.x, x.y);
            C.globalAlpha = x.opacity * x.ca;
            C.scale(V, V);
            C.translate(-L / 2, -K / 2);
            J(C);
            C.restore();
          }
          x.attribution && !x.aa.Ha() && A.Fa && 0 < A.Fa.width && 0 < A.Fa.height && F(A.Fa.width, A.Fa.height, function (L) {
            L.drawImage(A.Fa, 0, 0);
          });
        }
      }, {
        Sa: [],
        na: function (x, C) {
          return function (F, L) {
            return x(F, L) || C(F, L);
          };
        }(a("labelPlainFill"), g(a("contentDecoration"), function () {
          return A.Ac;
        })),
        before: function () {},
        after: function () {},
        tb: function () {},
        Xa: function () {},
        lb: function (x, C, F) {
          (0 < x.opacity && 0 < x.ca && !x.open || !x.X) && !x.aa.Ha() && (x.Fc = x.oa && x.oa.ka && A.B * x.oa.fontSize * x.scale * F >= A.qh, "auto" === x.sd ? !A.me && x.description ? x.gb = x.parent.gb : (F = x.ze, C = F.h + (F.s << 9) + (F.l << 16), x.ld !== C && (F = Ea.T(F), x.gb = F > (0 > x.ja ? A.Fh : A.fh) ? A.gh : A.ph, x.ld = C)) : x.gb = x.sd);
        }
      }, {
        Sa: ["custom"],
        na: g(a("contentDecoration"), function () {
          return A.Ac;
        }),
        before: function () {},
        after: function () {},
        tb: function () {},
        Xa: function () {},
        lb: function (x, C) {
          !(0 < x.opacity && 0 < x.ca && !x.open || !x.X) || x.Sb.Ha() || x.aa.Ha() || (x.Fc || void 0 === x.oa ? (C.globalAlpha = x.ca * (x.X ? x.opacity : 1) * (x.empty() ? 1 : A.If), C.fillStyle = x.gb, C.strokeStyle = x.gb, x.Sb.Oa(C)) : m(x, C));
        }
      }, {
        Sa: ["label"],
        na: a("labelPlainFill"),
        before: function () {},
        after: function () {},
        tb: function () {},
        Xa: function () {},
        lb: function (x, C, F) {
          x.We && x.Ic && (0 < x.opacity && 0 < x.ca && !x.open || !x.X) && !x.aa.Ha() && x.oa && (C.fillStyle = x.gb, C.globalAlpha = x.ca * (x.X ? x.opacity : 1) * (x.empty() ? 1 : A.If), x.Fc ? ob(x, C, F) : m(x, C));
        }
      }].reverse())];
    this.M = function () {
      t = Ma.Lh(function () {
        return Aa.estimate();
      }, "CarrotSearchFoamTree", 12096E5)(qb());
      Q.M();
    };
    this.clear = function () {
      h.clearRect(0, 0, O, G);
      v.clearRect(0, 0, O, G);
    };
    var R = !1,
      W = void 0;
    this.u = function (x) {
      R ? W = x : x();
    };
    this.Qd = function () {
      function x() {
        window.clearTimeout(C);
        R = !0;
        C = setTimeout(function () {
          R = !1;
          if (k()) {
            var L = !p();
            f(null, F.Y, v, L);
            P.defer(function () {
              ha.Yi();
              W && (W(), W = void 0);
            });
          } else W && (W(), W = void 0);
        }, Math.max(A.lj, 3 * q.Yf.vd, 3 * q.Yf.ud));
      }
      var C, F;
      return function (L) {
        rb(Q);
        F = c();
        var K = null !== F.bg,
          J = 0 < B.bc("hifi"),
          V = J && (K || !L);
        L = K || fa || !L;
        fa = !1;
        J && !V && ha.Zi();
        f(F.bg, F.cg, V ? v : h, L);
        Qa.za(U, function (Z) {
          Z.Z = !1;
          Z.N = !1;
          Z.Ta = !1;
        });
        V || x();
        A.Df(K);
      };
    }();
    this.i = function (x) {
      x = x || {};
      rb(Q);
      U.N = !0;
      var C = c(),
        F = A.B;
      try {
        var L = P.I(x.pixelRatio, A.B);
        A.B = L;
        var K = B.fc("export", L, !0),
          J = K.getContext("2d");
        h === y && (J = new xa(J));
        J.scale(L, L);
        var V = P.has(x, "backgroundColor");
        V && (J.save(), J.fillStyle = x.backgroundColor, J.fillRect(0, 0, O, G), J.restore());
        f(V ? [] : null, C.cg, J, !0);
      } finally {
        A.B = F;
      }
      return K.toDataURL(P.I(x.format, "image/png"), P.I(x.quality, .8));
    };
    var ha = function () {
      function x(L, K, J, V, Z, ia) {
        function la(ma, sa, ua, va) {
          return Y.K.A({
            opacity: B.bc(ma)
          }).fa({
            duration: ua,
            P: {
              opacity: {
                end: sa,
                easing: va
              }
            },
            ba: function () {
              B.bc(ma, this.opacity);
            }
          }).done();
        }
        var qa = P.rd(B.bc(L), K),
          oa = P.rd(B.bc(V), Z);
        if (!qa || !oa) {
          for (var da = F.length - 1; 0 <= da; da--) F[da].stop();
          F = [];
          qa || F.push(la(L, K, J, La.Ib));
          oa || F.push(la(V, Z, ia, La.Vf));
          return Y.K.A({}).Ra(F).start();
        }
      }
      var C,
        F = [];
      return {
        Zi: function () {
          A.bd ? 1 !== l.style.opacity && (l.style.visibility = "visible", w.style.visibility = "hidden", l.style.opacity = 1, w.style.opacity = 0) : C && C.zb() || (C = x("wireframe", 1, A.ve, "hifi", 0, A.ve));
        },
        Yi: function () {
          A.bd ? (w.style.visibility = "visible", l.style.visibility = "hidden", l.style.opacity = 0, w.style.opacity = 1) : x("hifi", 1, A.fg, "wireframe", 0, A.fg);
        }
      };
    }();
    rb = function (x) {
      x.apply();
    };
    mb = function (x) {
      x.fill();
    };
    nb = function (x) {
      x.stroke();
    };
    return this;
  }
  var mb, nb, rb;
  function kb(n) {
    function q(b) {
      "undefined" !== typeof b.groupLabelFontFamily && (f.fontFamily = b.groupLabelFontFamily);
      "undefined" !== typeof b.groupLabelFontStyle && (f.fontStyle = b.groupLabelFontStyle);
      "undefined" !== typeof b.groupLabelFontVariant && (f.fontVariant = b.groupLabelFontVariant);
      "undefined" !== typeof b.groupLabelFontWeight && (f.fontWeight = b.groupLabelFontWeight);
      "undefined" !== typeof b.groupLabelLineHeight && (f.lineHeight = b.groupLabelLineHeight);
      "undefined" !== typeof b.groupLabelHorizontalPadding && (f.eb = b.groupLabelHorizontalPadding);
      "undefined" !== typeof b.groupLabelVerticalPadding && (f.Va = b.groupLabelVerticalPadding);
      "undefined" !== typeof b.groupLabelMaxTotalHeight && (f.jb = b.groupLabelMaxTotalHeight);
      "undefined" !== typeof b.groupLabelMaxFontSize && (f.ib = b.groupLabelMaxFontSize);
    }
    var m = n.options,
      f = {},
      d = {},
      c,
      p = {
        groupLabel: ""
      },
      k = {};
    n.j.subscribe("api:initialized", function (b) {
      c = b;
    });
    n.j.subscribe("options:changed", q);
    q(n.Fd);
    this.i = function (b) {
      if (!b.$) return !1;
      var a = b.group.label;
      m.ih && (p.labelText = a, c.qc(m.hh, b, p), a = p.labelText);
      b.Xe = a;
      return b.td !== a;
    };
    this.u = function (b) {
      var a = b.Xe;
      b.td = a;
      b.Ec.clear();
      b.oa = void 0;
      if (b.$ && !P.Qe(a) && ("flattened" !== m.nb || b.empty() || !b.R || !b.m[0].W)) {
        var g = ta,
          e = g.ge;
        if (m.oh) {
          k.fontFamily = f.fontFamily;
          k.fontStyle = f.fontStyle;
          k.fontVariant = f.fontVariant;
          k.fontWeight = f.fontWeight;
          k.lineHeight = f.lineHeight;
          k.horizontalPadding = f.eb;
          k.verticalPadding = f.Va;
          k.maxTotalTextHeight = f.jb;
          k.maxFontSize = f.ib;
          c.qc(m.nh, b, k);
          d.fontFamily = k.fontFamily;
          d.fontStyle = k.fontStyle;
          d.fontVariant = k.fontVariant;
          d.fontWeight = k.fontWeight;
          d.lineHeight = k.lineHeight;
          d.eb = k.horizontalPadding;
          d.Va = k.verticalPadding;
          d.jb = k.maxTotalTextHeight;
          d.ib = k.maxFontSize;
          var u = d;
        } else u = f;
        b.oa = e.call(g, u, b.Ec, a, b.$, b.F, b.O, !1, !1, b.Qh, b.O.ha, m.rh, b.Na);
      }
      b.Na = !1;
    };
    ob = this.H = function (b, a) {
      b.Ec.Oa(a);
    };
  }
  var ob;
  function lb(n) {
    function q(a, g) {
      var e = a.m,
        u = e.length,
        t,
        l = k.O.r;
      for (t = 0; t < u; t++) {
        var h = e[t];
        h.vb = (180 * (Math.atan2(h.x - a.x, h.y - a.y) + g) / Math.PI + 180) / 360;
        h.zc = Math.min(1, Math.sqrt(wa.i(h, a)) / l);
      }
    }
    function m(a, g) {
      a = a.m;
      var e = a.length;
      if (1 === e || 2 === e && a[0].description) a[0].vb = .5;else {
        var u = 0,
          t = Number.MAX_VALUE,
          l = Math.sin(g),
          h = Math.cos(g);
        for (g = 0; g < e; g++) {
          var r = a[g];
          var y = r.x * l + r.y * h;
          u < y && (u = y);
          t > y && (t = y);
          r.vb = y;
          r.zc = 1;
        }
        for (g = 0; g < e; g++) r = a[g], r.vb = (r.vb - t) / (u - t);
      }
    }
    function f(a, g, e, u) {
      g = g[u];
      return g + (e[u] - g) * a;
    }
    var d = {
        radial: q,
        linear: m
      },
      c = n.options,
      p,
      k,
      b = {
        groupColor: null,
        labelColor: null
      };
    n.j.subscribe("model:loaded", function (a) {
      k = a;
    });
    n.j.subscribe("api:initialized", function (a) {
      p = a;
    });
    this.M = function () {};
    this.apply = function () {
      function a(z) {
        if (z.R && z.xa) {
          var E = z.m,
            D;
          if (z.Z || z.Ga || y) {
            0 === z.level ? u(z, c.Ai * Math.PI / 180) : t(z, c.Ei * Math.PI / 180);
            for (D = E.length - 1; 0 <= D; D--) {
              var H = E[D];
              H.Ga = !0;
              var O = H.vb,
                G = H.ye;
              if (0 === z.level) {
                var B = f(O, l, h, "h");
                var M = (v + (1 - v) * H.zc) * f(O, l, h, "s");
                var Q = (1 + (0 > H.ja ? w * (H.ja + 1) : w) * (1 - H.zc)) * f(O, l, h, "l");
                var X = f(O, l, h, "a");
              } else Q = z.pa, B = Q.h, M = Q.s, Q = g(Q.l, O, c.Fi, c.Gi), X = z.ye.a;
              G.h = B;
              G.s = M;
              G.l = Q;
              G.a = X;
              B = H.pa;
              H.attribution ? (B.h = 0, B.s = 0, B.l = "light" == c.vg ? 90 : 10, B.a = 1) : (B.h = G.h, B.s = G.s, B.l = G.l, B.a = G.a);
              y && (b.groupColor = B, b.labelColor = "auto", p.qc(r, H, b, function (Y) {
                Y.ratio = O;
              }), H.pa = Ea.u(b.groupColor), H.pa.a = P.has(b.groupColor, "a") ? b.groupColor.a : 1, "auto" !== b.labelColor && (H.sd = Ea.wa(b.labelColor)));
            }
            z.Ga = !1;
          }
          for (D = E.length - 1; 0 <= D; D--) a(E[D]);
        }
      }
      function g(z, E, D, H) {
        var O = e(z + D * H);
        z = e(z - D * (1 - H));
        return O + E * (z - O);
      }
      function e(z) {
        return 0 > z ? 0 : 100 < z ? 100 : z;
      }
      var u = d[c.zi] || q,
        t = m,
        l = c.Ji,
        h = c.Ci,
        r = c.Mg,
        y = c.Ng,
        w = c.Di,
        v = c.Hi;
      a(k);
    };
    return this;
  }
  ;
  function Ya() {
    this.mc = this.ae = this.jc = this.Xf = this.w = this.eg = this.weight = this.y = this.x = this.id = 0;
    this.C = this.parent = this.m = null;
    this.F = {
      x: 0,
      y: 0,
      w: 0,
      o: 0
    };
    this.J = null;
    this.td = this.Xe = void 0;
    this.Vc = !1;
    this.zc = this.vb = 0;
    this.ye = {
      h: 0,
      s: 0,
      l: 0,
      a: 0,
      model: "hsla"
    };
    this.pa = {
      h: 0,
      s: 0,
      l: 0,
      a: 0,
      model: "hsla"
    };
    this.ze = {
      h: 0,
      s: 0,
      l: 0,
      model: "hsl"
    };
    this.ld = -1;
    this.sd = "auto";
    this.gb = "#000";
    this.Uf = this.level = this.qd = this.index = 0;
    this.attribution = !1;
    this.ha = this.bf = 0;
    this.Y = !1;
    this.$ = null;
    this.O = {
      x: 0,
      y: 0,
      ha: 0,
      r: 0
    };
    this.Id = this.G = null;
    this.Ic = this.W = this.Ta = this.rc = this.Yd = this.Gd = this.Na = this.Ga = this.N = this.Z = this.Ea = this.xa = this.R = this.Ja = !1;
    this.saturation = this.va = this.Da = this.ca = this.opacity = this.scale = 1;
    this.ra = 0;
    this.Kd = 1;
    this.Eb = this.ja = this.Ab = 0;
    this.description = this.selected = this.wb = this.Ed = this.open = this.U = !1;
    this.ub = 0;
    this.We = this.Hd = this.X = !0;
    this.oa = void 0;
    this.Fc = !1;
    this.Ec = new ra();
    this.aa = new ra();
    this.Sb = new ra();
    this.Qh = ta.ci();
    this.Hc = 0;
    this.cd = 1;
    this.Mc = -1;
    this.empty = function () {
      return !this.m || 0 === this.m.length;
    };
    var n = [];
    this.oc = function (d) {
      n.push(d);
    };
    this.Qc = function (d) {
      P.Lf(n, d);
    };
    var q = {
      scale: 1
    };
    this.yd = function () {
      var d = !1;
      this.scale = 1;
      for (var c = 0; c < n.length; c++) d = n[c].Ye(this, q) || d, this.scale *= q.scale;
      return d;
    };
    this.Kb = function (d) {
      for (var c = 0; c < n.length; c++) n[c].Kb(this, d);
    };
    this.transformPoint = function (d, c) {
      c.x = d.x;
      c.y = d.y;
      for (d = 0; d < n.length; d++) n[d].transformPoint(this, c, c);
      return c;
    };
    this.Lb = function (d, c) {
      c.x = d.x;
      c.y = d.y;
      for (d = 0; d < n.length; d++) n[d].Lb(this, c, c);
      return c;
    };
    var m = [];
    this.rb = function (d) {
      m.push(d);
    };
    this.Pc = function (d) {
      P.Lf(m, d);
    };
    var f = {
      opacity: 1,
      saturation: 1,
      va: 1,
      ca: 1,
      Da: 1
    };
    this.ec = function () {
      if (0 !== m.length) {
        this.Da = this.ca = this.va = this.saturation = this.opacity = 1;
        for (var d = m.length - 1; 0 <= d; d--) (0, m[d])(this, f), this.opacity *= f.opacity, this.va *= f.va, this.saturation *= f.saturation, this.ca *= f.ca, this.Da *= f.Da;
      }
    };
  }
  function fb(n, q) {
    return q.weight > n.weight ? 1 : q.weight < n.weight ? -1 : n.index - q.index;
  }
  ;
  function sb(n) {
    var q = this,
      m,
      f,
      d,
      c,
      p = n.options,
      k,
      b;
    n.j.subscribe("stage:initialized", function (a, g, e, u) {
      d = e;
      c = u;
      m = a.fc("titlebar", p.B, !1);
      f = m.getContext("2d");
      f.B = p.B;
      f.scale(f.B, f.B);
      n.j.D("titlebar:initialized", q);
    });
    n.j.subscribe("stage:resized", function (a, g, e, u) {
      d = e;
      c = u;
      f.scale(f.B, f.B);
    });
    n.j.subscribe("zoom:initialized", function (a) {
      b = a;
    });
    n.j.subscribe("api:initialized", function (a) {
      k = a;
    });
    n.j.subscribe("model:loaded", function () {
      f.clearRect(0, 0, d, c);
    });
    this.update = function (a) {
      f.clearRect(0, 0, d, c);
      if (a) {
        !a.empty() && a.m[0].description && (a = a.m[0]);
        var g = p.fj,
          e = p.ej,
          u = Math.min(c / 2, p.Zd + 2 * g),
          t = u - 2 * g,
          l = d - 2 * e;
        if (!(0 >= t || 0 >= l)) {
          var h = a.Fc ? a.oa.fontSize * a.scale * b.scale() : 0,
            r,
            y = {
              titleBarText: a.td,
              titleBarTextColor: p.ag,
              titleBarBackgroundColor: p.$f,
              titleBarMaxFontSize: p.Zd,
              titleBarShown: h < p.Wh
            };
          k.qc(p.bj, a, y, function (w) {
            w.titleBarWidth = l;
            w.titleBarHeight = t;
            w.labelFontSize = h;
            w.viewportScale = b.scale();
          });
          (r = y.titleBarText) && 0 !== r.length && y.titleBarShown && (a = b.Xc(a.transformPoint(a, {}), {}).y > c / 2, g = {
            x: e,
            y: a ? g : c - u + g,
            w: l,
            o: t
          }, e = wa.H(g), f.fillStyle = p.$f, f.fillRect(0, a ? 0 : c - u, d, u), f.fillStyle = p.ag, ta.ue({
            fontFamily: p.cj || p.jh,
            fontStyle: p.zj || p.kh,
            fontWeight: p.Bj || p.mh,
            fontVariant: p.Aj || p.lh,
            ib: p.Zd,
            Jc: p.dj,
            eb: 0,
            Va: 0,
            jb: 1
          }, f, r, e, g, {
            x: g.x + g.w / 2,
            y: g.y + g.o / 2
          }, !0, !0).ka || f.clearRect(0, 0, d, c));
        }
      }
    };
  }
  ;
  function tb(n) {
    function q(w, v, z) {
      y = !0;
      g && g.stop();
      u && u.stop();
      return p(b.reset(w), v, z).then(function () {
        y = !1;
      });
    }
    function m(w) {
      b.update(w);
      h.N = !0;
      n.j.D("foamtree:dirty", !0);
    }
    function f(w, v) {
      return b.i((0 !== b.u() ? .35 : 1) * w, (0 !== b.H() ? .35 : 1) * v);
    }
    function d() {
      if (1 === a.ratio) {
        var w = Math.round(1E4 * b.u()) / 1E4;
        0 !== w && (e.Ld = w, g = r.K.lc(e).fa({
          duration: 500,
          P: {
            x: {
              start: w,
              end: 0,
              easing: La.Ib
            }
          },
          ba: function () {
            b.i(e.x - e.Ld, 0);
            m(1);
            e.Ld = e.x;
          }
        }).start());
      }
    }
    function c() {
      if (1 === a.ratio) {
        var w = Math.round(1E4 * b.H()) / 1E4;
        0 !== w && (t.Md = w, u = r.K.lc(t).fa({
          duration: 500,
          P: {
            y: {
              start: w,
              end: 0,
              easing: La.Ib
            }
          },
          ba: function () {
            b.i(0, t.y - t.Md);
            m(1);
            t.Md = t.y;
          }
        }).start());
      }
    }
    function p(w, v, z) {
      return w ? r.K.lc(a).fa({
        duration: void 0 === v ? 700 : v,
        P: {
          ratio: {
            start: 0,
            end: 1,
            easing: z || La.Wf
          }
        },
        ba: function () {
          m(a.ratio);
        }
      }).Ua() : new Fa().resolve().promise();
    }
    function k(w) {
      return function () {
        return y ? new Fa().resolve().promise() : w.apply(this, arguments);
      };
    }
    var b = new Da(n),
      a = {
        ratio: 1
      },
      g,
      e = {
        ne: 0,
        x: 0,
        Ld: 0
      },
      u,
      t = {
        oe: 0,
        y: 0,
        Md: 0
      },
      l = this,
      h,
      r,
      y = !1;
    n.j.subscribe("model:loaded", function (w) {
      h = w;
      b.reset(!1);
      b.update(1);
    });
    n.j.subscribe("timeline:initialized", function (w) {
      r = w;
    });
    this.M = function () {
      n.j.D("zoom:initialized", this);
    };
    this.reset = function (w, v) {
      b.Hb(1);
      return q(!0, w, v);
    };
    this.normalize = k(function (w, v) {
      b.sc(1) ? q(!1, w, v) : l.cf();
    });
    this.cf = function () {
      d();
      c();
    };
    this.dg = k(function (w, v, z, E) {
      return l.kc(w.F, v, z, E);
    });
    this.Pb = k(function (w, v, z, E) {
      return p(b.Pb(w, v), z, E);
    });
    this.kc = k(function (w, v, z, E) {
      return p(b.kc(w, v), z, E);
    });
    this.gj = k(function (w, v) {
      b.kc(w, v) && m(1);
    });
    this.Yh = k(function (w, v) {
      1 === a.ratio && f(w, v) && m(1);
    });
    this.sg = k(function (w, v) {
      b.Pb(w, v) && m(1);
    });
    this.rg = k(function (w, v, z, E) {
      w = 0 | b.Pb(w, v);
      (w |= f(z, E)) && m(1);
    });
    this.Zh = k(function (w, v, z) {
      1 === a.ratio && (g = r.K.lc(e).fa({
        duration: w / .03,
        P: {
          ne: {
            start: v,
            end: 0,
            easing: La.Ib
          }
        },
        ba: function () {
          b.i(e.ne, 0) && m(1);
          d();
        }
      }).start(), u = r.K.lc(t).fa({
        duration: w / .03,
        P: {
          oe: {
            start: z,
            end: 0,
            easing: La.Ib
          }
        },
        ba: function () {
          f(0, t.oe) && m(1);
          c();
        }
      }).start());
    });
    this.$h = function () {
      g && 0 === b.u() && g.stop();
      u && 0 === b.H() && u.stop();
    };
    this.uc = function (w, v) {
      b.uc(w, v);
    };
    this.Hb = function (w) {
      return b.Hb(w);
    };
    this.sc = function (w) {
      return b.sc(w);
    };
    this.Cd = function () {
      return b.Cd();
    };
    this.absolute = function (w, v) {
      return b.absolute(w, v);
    };
    this.Xc = function (w, v) {
      return b.Xc(w, v);
    };
    this.scale = function () {
      return b.scale();
    };
    this.i = function (w) {
      return b.T(w);
    };
    this.content = function (w, v, z, E) {
      b.content(w, v, z, E);
    };
  }
  ;
  function ub(n, q, m) {
    function f(I) {
      var T = [];
      Qa.L(u, function (R) {
        I(R) && T.push(R.group);
      });
      return {
        groups: T
      };
    }
    function d(I, T) {
      var R = e.options,
        W = R.Qi,
        ha = R.Pi;
      R = R.Rd;
      var x = 0 < W + ha ? R : 0,
        C = [];
      Ua.u(I, Ua.i(I, e.options.Td), function (F, L, K) {
        L = "groups" === e.options.Sd ? K : L;
        F.m && (F = l.K.A(F).wait(x * (ha + W * L)).call(T).done(), C.push(F));
      });
      return l.K.A({}).Ra(C).Ua();
    }
    function c(I) {
      ca || (ca = !0, t.once(function () {
        ca = !1;
        e.j.D("repaint:before");
        E.Qd(this.qg);
      }, {
        qg: I
      }));
    }
    function p(I) {
      function T(C, F) {
        var L = C.W;
        C.W = F <= R;
        C.Ic = F <= W;
        C.W !== L && Qa.pe(C, function (K) {
          K.Yd = !0;
        });
        C.open || C.Wa || F++;
        if (C = C.m) for (L = 0; L < C.length; L++) T(C[L], F);
      }
      var R = e.options.Ze,
        W = Math.min(e.options.Ze, e.options.Th);
      if (I) for (var ha = 0; ha < I.length; ha++) {
        var x = I[ha];
        T(x, a(x));
      } else T(u, 0);
    }
    function k(I, T) {
      var R = [];
      I = b(I, T);
      I.Xh && e.j.D("model:childrenAttached", Qa.xc(u));
      I.Ki && z.complete(function (ha) {
        N.fb(ha);
        R.push(ha);
      });
      for (T = I = 0; T < R.length; T++) {
        var W = R[T];
        W.m && (I += W.m.length);
        W.xa = !0;
        O.i(W);
      }
      return I;
    }
    function b(I, T) {
      function R(L, K) {
        var J = !L.attribution && K - (L.Wa ? 1 : 0) < W;
        x = x || J;
        L.Ja = L.Ja || J;
        L.open || L.Wa || K++;
        var V = L.m;
        !V && J && (ha = v.T(L) || ha, V = L.m, F && (L.Na = !0));
        if (V) for (L = 0; L < V.length; L++) C.push(V[L], K);
      }
      var W = T || e.options.Uh,
        ha = !1,
        x = !1,
        C,
        F = "flattened" === q.nb;
      for (I ? C = I.reduce(function (L, K) {
        L.push(K, 1);
        return L;
      }, []) : C = [u, 1]; 0 < C.length;) R(C.shift(), C.shift());
      return {
        Xh: ha,
        Ki: x
      };
    }
    function a(I) {
      for (var T = 0; I.parent;) I.open || I.Wa || T++, I = I.parent;
      return T;
    }
    var g = this,
      e = {
        j: new Oa(),
        options: q,
        Fd: m
      },
      u,
      t = new na(),
      l = new Pa(t),
      h = ka.create(),
      r = new Ba(e),
      y = new tb(e),
      w = new Wa(e),
      v = new Xa(e.options),
      z = new gb(e),
      E = new jb(e, t),
      D = new db(e);
    new sb(e);
    var H = new $a(e),
      O = new ab(e),
      G = new bb(e),
      B = new cb(e);
    e.j.subscribe("stage:initialized", function (I, T, R, W) {
      A.Oe(R, W);
    });
    e.j.subscribe("stage:resized", function (I, T, R, W) {
      A.Oi(I, T, R, W);
    });
    e.j.subscribe("foamtree:attachChildren", k);
    e.j.subscribe("openclose:changing", p);
    e.j.subscribe("interaction:reset", function () {
      S(!0);
    });
    e.j.subscribe("foamtree:dirty", c);
    this.M = function () {
      e.j.D("timeline:initialized", l);
      u = v.M();
      r.M(n);
      w.M();
      E.M();
      D.M();
      H.M();
      O.M();
      y.M();
      G.M();
      B.M();
    };
    this.$a = function () {
      l.i();
      ja.stop();
      t.i();
      r.$a();
    };
    var M = "groupLabelFontFamily groupLabelFontStyle groupLabelFontVariant groupLabelFontWeight groupLabelLineHeight groupLabelHorizontalPadding groupLabelVerticalPadding groupLabelDottingThreshold groupLabelMaxTotalHeight groupLabelMinFontSize groupLabelMaxFontSize groupLabelDecorator".split(" "),
      Q = "rainbowColorDistribution rainbowLightnessDistribution rainbowColorDistributionAngle rainbowLightnessDistributionAngle rainbowColorModelStartPoint rainbowLightnessCorrection rainbowSaturationCorrection rainbowStartColor rainbowEndColor rainbowHueShift rainbowHueShiftCenter rainbowSaturationShift rainbowSaturationShiftCenter rainbowLightnessShift rainbowLightnessShiftCenter attributionTheme".split(" "),
      X = !1,
      Y = ["groupBorderRadius", "groupBorderRadiusCorrection", "groupBorderWidth", "groupInsetWidth", "groupBorderWidthScaling"],
      aa = ["maxGroupLevelsDrawn", "maxGroupLabelLevelsDrawn"];
    this.jg = function (I) {
      e.j.D("options:changed", I);
      P.cb(I, M) && Qa.L(u, function (T) {
        T.Na = !0;
      });
      P.cb(I, Q) && (u.Ga = !0);
      P.cb(I, Y) && (X = !0);
      P.cb(I, aa) && (p(), k());
    };
    this.reload = function () {
      U.reload();
    };
    this.kg = function (I, T) {
      P.defer(function () {
        if (X) A.Rh(I), X = !1;else {
          if (T) for (var R = v.u(T), W = R.length - 1; 0 <= W; W--) R[W].N = !0;else u.N = !0;
          c(I);
        }
      });
    };
    this.ga = function () {
      r.u();
    };
    this.update = function (I) {
      I = I ? v.u(I) : [u];
      var T = I.reduce(function (R, W) {
        R[W.id] = W;
        return R;
      }, {});
      I = I.filter(function (R) {
        for (R = R.parent; R;) {
          if (P.has(T, R.id)) return !1;
          R = R.parent;
        }
        return !0;
      });
      v.update(I);
      A.hj(I);
    };
    this.reset = function () {
      return S(!1);
    };
    this.T = E.i;
    this.Ka = function () {
      var I = {};
      return function (T, R) {
        return (T = v.i(T)) ? w.Zc(I, T, R) : null;
      };
    }();
    this.wa = function () {
      var I = {
          x: 0,
          y: 0
        },
        T = {
          x: 0,
          y: 0
        };
      return function (R, W) {
        return (R = v.i(R)) ? (I.x = W.x, I.y = W.y, R.transformPoint(I, I), y.Xc(I, I), T.x = I.x, T.y = I.y, T) : null;
      };
    }();
    this.sa = function () {
      var I = {};
      return function (T) {
        return (T = v.i(T)) ? w.ad(I, T) : null;
      };
    }();
    this.ig = function () {
      var I = {};
      return function (T) {
        return (T = v.i(T)) ? w.$c(I, T) : null;
      };
    }();
    this.ta = function () {
      var I = {};
      return function () {
        return y.i(I);
      };
    }();
    this.mg = function () {
      this.H({
        groups: f(function (I) {
          return I.group.selected;
        }),
        newState: !0,
        keepPrevious: !1
      });
      this.u({
        groups: f(function (I) {
          return I.group.open;
        }),
        newState: !0,
        keepPrevious: !1
      });
      this.i({
        groups: f(function (I) {
          return I.group.exposed;
        }),
        newState: !0,
        keepPrevious: !1
      });
    };
    this.La = function () {
      return f(function (I) {
        return I.U;
      });
    };
    this.i = function (I) {
      return U.submit(function () {
        return H.Xb(v.H(I, "exposed", !1), !1, !0, !1);
      });
    };
    this.qb = function () {
      return f(function (I) {
        return I.open;
      });
    };
    this.u = function (I) {
      return U.submit(function () {
        return G.Db(v.H(I, "open", !0), !1, !1);
      });
    };
    this.Nb = function () {
      return f(function (I) {
        return I.selected;
      });
    };
    this.H = function (I) {
      return U.submit(function () {
        B.select(v.H(I, "selected", !0), !1);
        return new Fa().resolve().promise();
      });
    };
    this.og = function (I) {
      return (I = v.i(I)) ? I === u ? y.reset(q.pb, La.ia(q.Mb)) : y.dg(I, q.$b, q.pb, La.ia(q.Mb)) : new Fa().resolve().promise();
    };
    this.ua = function (I, T) {
      return (I = v.u(I)) ? (T = k(I, T), p(I), T) : 0;
    };
    this.Yc = function (I) {
      return D.Nb[I];
    };
    this.ng = function () {
      var I = pa;
      return {
        frames: I.frames,
        totalTime: I.totalTime,
        lastFrameTime: I.ud,
        lastInterFrameTime: I.vd,
        fps: I.xe
      };
    };
    var A = function () {
        function I(ha, x) {
          var C = ha || R,
            F = x || W;
          R = C;
          W = F;
          (ha = q.Tb && q.Tb.boundary) && 2 < ha.length ? u.C = ha.map(function (L) {
            return {
              x: C * L.x,
              y: F * L.y
            };
          }) : u.C = [{
            x: 0,
            y: 0
          }, {
            x: C,
            y: 0
          }, {
            x: C,
            y: F
          }, {
            x: 0,
            y: F
          }];
          T();
        }
        function T() {
          u.Z = !0;
          u.G = u.C;
          u.F = wa.F(u.C, u.F);
          u.O = u;
          wa.Ka(u.C, u.O);
        }
        var R, W;
        return {
          Oe: I,
          Oi: function (ha, x, C, F) {
            N.stop();
            var L = C / ha,
              K = F / x;
            Qa.qe(u, function (J) {
              J.x = J.x * L + (Math.random() - .5) * C / 1E3;
              J.y = J.y * K + (Math.random() - .5) * F / 1E3;
            });
            I(C, F);
            u.Ea = !0;
            z.step(N.fb, !0, !1, function (J) {
              var V = J.m;
              if (V) {
                z.Gb(J);
                for (var Z = V.length - 1; 0 <= Z; Z--) {
                  var ia = V[Z];
                  ia.w = ia.jc;
                }
                J.Ea = !0;
              }
            }) ? c(!1) : (z.ic(u), e.options.Pd ? (c(!1), ja.Mf(), ja.Rc()) : (z.complete(N.fb), u.Ga = !0, c(!1)));
          },
          Rh: function (ha) {
            var x = !1;
            u.empty() || (T(), ja.zb() || (x = z.step(N.fb, !1, !1), c(ha)));
            return x;
          },
          hj: function (ha) {
            ha.forEach(function (x) {
              Qa.za(x, function (C) {
                C.empty() || z.Gb(C);
              });
              z.ic(x);
              e.options.Pd ? (ja.Mf(), Qa.za(x, function (C) {
                C.empty() || N.grow(C);
              })) : (Qa.za(x, function (C) {
                C.empty() || N.fb(C);
              }), z.complete(N.fb), x.Ga = !0, c(!1));
            });
          }
        };
      }(),
      U = function () {
        function I() {
          0 === q.Jd && y.reset(0);
          e.options.Cf(q.Tb);
          A.Oe();
          v.load(q.Tb);
          b();
          p();
          e.j.D("model:loaded", u, Qa.xc(u));
          if (!u.empty()) {
            u.open = !0;
            u.Ja = !0;
            if (q.Pd) var F = ja.Rc();else ja.bi(), F = W();
            T();
            0 < q.Rd ? (E.clear(), r.i(1)) : F = Ga([F, R(1)]);
          }
          e.options.Bf(q.Tb);
          F && (e.options.Ff(), F.then(function () {
            E.u(function () {
              t.once(e.options.Ef);
            });
          }));
        }
        function T() {
          var F = q.Pa,
            L = q.Nc;
          q.Pa = 0;
          q.Nc = 0;
          g.mg();
          q.Pa = F;
          q.Nc = L;
        }
        function R(F, L) {
          return 0 === q.te || L ? (r.i(F), new Fa().resolve().promise()) : l.K.A({
            opacity: r.i()
          }).$d(2).fa({
            duration: q.te,
            P: {
              opacity: {
                end: F,
                easing: La.ia(q.Gg)
              }
            },
            ba: function () {
              r.i(this.opacity);
            }
          }).Ua();
        }
        function W() {
          Qa.za(u, function (K) {
            K.xa = !1;
          });
          var F = new Fa(),
            L = new Ha(F.resolve);
          L.i();
          u.xa = !0;
          O.i(u).then(L.u);
          d(u, function J() {
            this.R && this.C && (this.Z = this.xa = !0, L.i(), O.i(this).then(L.u), L.i(), d(this, J).then(L.u));
          });
          return F.promise();
        }
        function ha() {
          for (var F = 0; F < C.length; F++) {
            var L = C[F],
              K = L.action();
            P.has(K, "then") ? K.then(L.je.resolve) : L.je.resolve();
          }
          C = [];
        }
        var x = !1,
          C = [];
        return {
          reload: function () {
            x || (u.empty() ? I() : (N.stop(), l.i(), ja.stop(), x = !0, Ga(0 < q.Jd ? [O.u(), S(!1)] : [R(0)]).then(function () {
              R(0, !0);
              x = !1;
              I();
              P.defer(ha);
            })));
          },
          submit: function (F) {
            if (x) {
              var L = new Fa();
              C.push({
                action: F,
                je: L
              });
              return L.promise();
            }
            return F();
          }
        };
      }(),
      fa,
      ba = new Ha(function () {
        fa.resolve();
      }),
      ja = function () {
        function I() {
          W || (ba.initial() && (fa = new Fa()), ba.i(), T(), W = !0, t.repeat(R));
          return fa.promise();
        }
        function T() {
          ha = h.now();
        }
        function R() {
          var x = h.now() - ha > q.Ni;
          x = z.step(function (C) {
            C.xa = !0;
            N.grow(C);
            ba.i();
            O.i(C).then(ba.u);
            ba.i();
            d(C, function () {
              this.Ja = !0;
              I();
            }).then(ba.u);
          }, !0, x) || x;
          c(!0);
          x && (W = !1, ba.u());
          return x;
        }
        var W = !1,
          ha;
        return {
          bi: function () {
            z.complete(N.fb);
          },
          Rc: I,
          Mf: T,
          zb: function () {
            return !ba.initial();
          },
          stop: function () {
            t.cancel(R);
            W = !1;
            ba.clear();
          }
        };
      }(),
      N = function () {
        function I(R) {
          var W = !R.empty();
          R.xa = !0;
          if (W) {
            for (var ha = R.m, x = ha.length - 1; 0 <= x; x--) {
              var C = ha[x];
              C.w = C.jc;
            }
            R.Ea = !0;
          }
          return W;
        }
        var T = [];
        return {
          grow: function (R) {
            var W = e.options,
              ha = W.$g;
            0 < ha ? Ua.u(R, Ua.i(R, e.options.Td), function (x, C, F) {
              C = "groups" === e.options.Sd ? F : C;
              ba.i();
              T.push(l.K.A(x).wait(C * W.Zg * ha).fa({
                duration: ha,
                P: {
                  w: {
                    start: x.Xf,
                    end: x.jc,
                    easing: La.ia(W.ah)
                  }
                },
                ba: function () {
                  this.w = Math.max(0, this.w);
                  this.parent.Ea = !0;
                  ja.Rc();
                }
              }).Ya(ba.u).start());
            }) : I(R) && ja.Rc();
          },
          fb: I,
          stop: function () {
            for (var R = T.length - 1; 0 <= R; R--) T[R].stop();
            T = [];
          }
        };
      }(),
      S = function () {
        var I = !1;
        return function (T) {
          if (I) return new Fa().resolve().promise();
          I = !0;
          var R = [];
          R.push(y.reset(q.pb, La.ia(q.Mb)));
          var W = new Fa();
          H.Xb({
            m: [],
            Ca: !1,
            Ba: !1
          }, T, !1, !0).then(function () {
            G.Db({
              m: [],
              Ca: !1,
              Ba: !1
            }, T, !1).then(W.resolve);
          });
          R.push(W.promise());
          return Ga(R).then(function () {
            I = !1;
            T && q.Gf();
          });
        };
      }(),
      ca = !1;
  }
  function qb() {
    return {
      version: "3.5.0",
      build: "bugfix/3.5.x/e3b91c8e",
      brandingAllowed: !0
    };
  }
  ;
  ea.pd(function () {
    window.CarrotSearchFoamTree = function (n) {
      function q(u, t) {
        if (!k || k.exists(u)) switch (u) {
          case "selection":
            return g.Nb();
          case "open":
            return g.qb();
          case "exposure":
            return g.La();
          case "state":
            return g.sa.apply(this, t);
          case "geometry":
            return g.Ka.apply(this, t);
          case "hierarchy":
            return g.ig.apply(this, t);
          case "containerCoordinates":
            return g.wa.apply(this, t);
          case "imageData":
            return g.T.apply(this, t);
          case "viewport":
            return g.ta();
          case "times":
            return g.ng();
          case "onModelChanged":
          case "onRedraw":
          case "onRolloutStart":
          case "onRolloutComplete":
          case "onRelaxationStep":
          case "onGroupHover":
          case "onGroupOpenOrCloseChanging":
          case "onGroupExposureChanging":
          case "onGroupSelectionChanging":
          case "onGroupSelectionChanged":
          case "onGroupClick":
          case "onGroupDoubleClick":
          case "onGroupHold":
            return u = b[u], Array.isArray(u) ? u : [u];
          default:
            return b[u];
        }
      }
      function m(u) {
        function t(y, w) {
          return P.has(l, y) ? (w(l[y]), delete l[y], 1) : 0;
        }
        if (0 === arguments.length) return 0;
        if (1 === arguments.length) var l = P.extend({}, arguments[0]);else 2 === arguments.length && (l = {}, l[arguments[0]] = arguments[1]);
        k && k.validate(l, a.Sh);
        var h = 0;
        g && (h += t("selection", g.H), h += t("open", g.u), h += t("exposure", g.i));
        var r = {};
        P.Aa(l, function (y, w) {
          if (b[w] !== y || P.yb(y)) r[w] = y, h++;
          b[w] = y;
        });
        0 < h && d(r);
        return h;
      }
      function f(u, t) {
        u = "on" + u.charAt(0).toUpperCase() + u.slice(1);
        var l = b[u];
        b[u] = t(Array.isArray(l) ? l : [l]);
        t = {};
        t[u] = b[u];
        d(t);
      }
      function d(u) {
        (function () {
          function t(l, h) {
            return P.has(u, l) || void 0 === h ? Na.A(b[l], p) : h;
          }
          a.Sh = b.logging;
          a.Tb = b.dataObject;
          a.B = b.pixelRatio;
          a.ob = b.wireframePixelRatio;
          a.nb = b.stacking;
          a.Dg = b.descriptionGroup;
          a.Vb = b.descriptionGroupType;
          a.tc = b.descriptionGroupPosition;
          a.Eg = b.descriptionGroupDistanceFromCenter;
          a.Ub = b.descriptionGroupSize;
          a.le = b.descriptionGroupMinHeight;
          a.ke = b.descriptionGroupMaxHeight;
          a.me = b.descriptionGroupPolygonDrawn;
          a.Gc = b.layout;
          a.cc = b.layoutByWeightOrder;
          a.$i = b.showZeroWeightGroups;
          a.Ee = b.groupMinDiameter;
          a.Od = b.rectangleAspectRatioPreference;
          a.Mi = b.initializer || b.relaxationInitializer;
          a.Ni = b.relaxationMaxDuration;
          a.Pd = b.relaxationVisible;
          a.Kf = b.relaxationQualityThreshold;
          a.sh = b.groupResizingBudget;
          a.$g = b.groupGrowingDuration;
          a.Zg = b.groupGrowingDrag;
          a.ah = b.groupGrowingEasing;
          a.Kg = b.groupBorderRadius;
          a.ab = b.groupBorderWidth;
          a.Ma = b.groupBorderWidthScaling;
          a.md = b.groupInsetWidth;
          a.Lg = b.groupBorderRadiusCorrection;
          a.bb = b.groupStrokeWidth;
          a.Bc = b.groupSelectionOutlineWidth;
          a.wh = b.groupSelectionOutlineColor;
          a.nd = b.groupSelectionOutlineShadowSize;
          a.Fe = b.groupSelectionOutlineShadowColor;
          a.th = b.groupSelectionFillHueShift;
          a.vh = b.groupSelectionFillSaturationShift;
          a.uh = b.groupSelectionFillLightnessShift;
          a.He = b.groupSelectionStrokeHueShift;
          a.Je = b.groupSelectionStrokeSaturationShift;
          a.Ie = b.groupSelectionStrokeLightnessShift;
          a.Yg = b.groupFillType;
          a.Ug = b.groupFillGradientRadius;
          a.Rg = b.groupFillGradientCenterHueShift;
          a.Tg = b.groupFillGradientCenterSaturationShift;
          a.Sg = b.groupFillGradientCenterLightnessShift;
          a.Vg = b.groupFillGradientRimHueShift;
          a.Xg = b.groupFillGradientRimSaturationShift;
          a.Wg = b.groupFillGradientRimLightnessShift;
          a.od = b.groupStrokeType;
          a.bb = b.groupStrokeWidth;
          a.Ke = b.groupStrokePlainHueShift;
          a.Me = b.groupStrokePlainSaturationShift;
          a.Le = b.groupStrokePlainLightnessShift;
          a.Bh = b.groupStrokeGradientRadius;
          a.xh = b.groupStrokeGradientAngle;
          a.Ch = b.groupStrokeGradientUpperHueShift;
          a.Eh = b.groupStrokeGradientUpperSaturationShift;
          a.Dh = b.groupStrokeGradientUpperLightnessShift;
          a.yh = b.groupStrokeGradientLowerHueShift;
          a.Ah = b.groupStrokeGradientLowerSaturationShift;
          a.zh = b.groupStrokeGradientLowerLightnessShift;
          a.bh = b.groupHoverFillHueShift;
          a.eh = b.groupHoverFillSaturationShift;
          a.dh = b.groupHoverFillLightnessShift;
          a.Be = b.groupHoverStrokeHueShift;
          a.De = b.groupHoverStrokeSaturationShift;
          a.Ce = b.groupHoverStrokeLightnessShift;
          a.Qa = b.groupExposureScale;
          a.Qg = b.groupExposureShadowColor;
          a.Ae = b.groupExposureShadowSize;
          a.$b = b.groupExposureZoomMargin;
          a.Gh = b.groupUnexposureLightnessShift;
          a.Hh = b.groupUnexposureSaturationShift;
          a.Fh = b.groupUnexposureLabelColorThreshold;
          a.Pa = b.exposeDuration;
          a.Yb = b.exposeEasing;
          a.Nc = b.openCloseDuration;
          a.Mg = Na.A(b.groupColorDecorator, p);
          a.Ng = b.groupColorDecorator !== P.qa;
          a.hh = Na.A(b.groupLabelDecorator, p);
          a.ih = b.groupLabelDecorator !== P.qa;
          a.nh = Na.A(b.groupLabelLayoutDecorator, p);
          a.oh = b.groupLabelLayoutDecorator !== P.qa;
          a.Og = Na.A(b.groupContentDecorator, p);
          a.Ac = b.groupContentDecorator !== P.qa;
          a.Pg = b.groupContentDecoratorTriggering;
          a.Ii = b.rainbowStartColor;
          a.Bi = b.rainbowEndColor;
          a.zi = b.rainbowColorDistribution;
          a.Ai = b.rainbowColorDistributionAngle;
          a.Ei = b.rainbowLightnessDistributionAngle;
          a.Fi = b.rainbowLightnessShift;
          a.Gi = b.rainbowLightnessShiftCenter;
          a.Hi = b.rainbowSaturationCorrection;
          a.Di = b.rainbowLightnessCorrection;
          a.Hf = b.parentFillOpacity;
          a.ai = b.parentStrokeOpacity;
          a.If = b.parentLabelOpacity;
          a.Jf = b.parentOpacityBalancing;
          a.rh = b.groupLabelUpdateThreshold;
          a.jh = b.groupLabelFontFamily;
          a.kh = b.groupLabelFontStyle;
          a.lh = b.groupLabelFontVariant;
          a.mh = b.groupLabelFontWeight;
          a.qh = b.groupLabelMinFontSize;
          a.rj = b.groupLabelMaxFontSize;
          a.qj = b.groupLabelLineHeight;
          a.pj = b.groupLabelHorizontalPadding;
          a.tj = b.groupLabelVerticalPadding;
          a.sj = b.groupLabelMaxTotalHeight;
          a.gh = b.groupLabelDarkColor;
          a.ph = b.groupLabelLightColor;
          a.fh = b.groupLabelColorThreshold;
          a.jj = b.wireframeDrawMaxDuration;
          a.kj = b.wireframeLabelDrawing;
          a.ij = b.wireframeContentDecorationDrawing;
          a.fg = b.wireframeToFinalFadeDuration;
          a.lj = b.wireframeToFinalFadeDelay;
          a.Hg = b.finalCompleteDrawMaxDuration;
          a.Ig = b.finalIncrementalDrawMaxDuration;
          a.ve = b.finalToWireframeFadeDuration;
          a.bd = b.androidStockBrowserWorkaround;
          a.Ne = b.incrementalDraw;
          a.Vh = b.maxGroups;
          a.Uh = b.maxGroupLevelsAttached;
          a.Ze = b.maxGroupLevelsDrawn;
          a.Th = b.maxGroupLabelLevelsDrawn;
          a.Td = b.rolloutStartPoint;
          a.Sd = b.rolloutMethod;
          a.Ri = b.rolloutEasing;
          a.Rd = b.rolloutDuration;
          a.Of = b.rolloutScalingStrength;
          a.Qf = b.rolloutTranslationXStrength;
          a.Rf = b.rolloutTranslationYStrength;
          a.Nf = b.rolloutRotationStrength;
          a.Pf = b.rolloutTransformationCenter;
          a.Vi = b.rolloutPolygonDrag;
          a.Wi = b.rolloutPolygonDuration;
          a.Si = b.rolloutLabelDelay;
          a.Ti = b.rolloutLabelDrag;
          a.Ui = b.rolloutLabelDuration;
          a.Qi = b.rolloutChildGroupsDrag;
          a.Pi = b.rolloutChildGroupsDelay;
          a.si = b.pullbackStartPoint;
          a.li = b.pullbackMethod;
          a.hi = b.pullbackEasing;
          a.xj = b.pullbackType;
          a.Jd = b.pullbackDuration;
          a.ri = b.pullbackScalingStrength;
          a.ui = b.pullbackTranslationXStrength;
          a.vi = b.pullbackTranslationYStrength;
          a.pi = b.pullbackRotationStrength;
          a.ti = b.pullbackTransformationCenter;
          a.mi = b.pullbackPolygonDelay;
          a.ni = b.pullbackPolygonDrag;
          a.oi = b.pullbackPolygonDuration;
          a.ii = b.pullbackLabelDelay;
          a.ji = b.pullbackLabelDrag;
          a.ki = b.pullbackLabelDuration;
          a.ei = b.pullbackChildGroupsDelay;
          a.fi = b.pullbackChildGroupsDrag;
          a.gi = b.pullbackChildGroupsDuration;
          a.te = b.fadeDuration;
          a.Gg = b.fadeEasing;
          a.mj = b.zoomMouseWheelFactor;
          a.pb = b.zoomMouseWheelDuration;
          a.Mb = b.zoomMouseWheelEasing;
          a.Wh = b.maxLabelSizeForTitleBar;
          a.cj = b.titleBarFontFamily;
          a.$f = b.titleBarBackgroundColor;
          a.ag = b.titleBarTextColor;
          a.dj = b.titleBarMinFontSize;
          a.Zd = b.titleBarMaxFontSize;
          a.ej = b.titleBarTextPaddingLeftRight;
          a.fj = b.titleBarTextPaddingTopBottom;
          a.bj = b.titleBarDecorator;
          a.de = b.attributionText;
          a.sb = b.attributionLogo;
          a.ug = b.attributionLogoScale;
          a.pc = b.attributionUrl;
          a.ce = b.attributionPosition;
          a.tg = b.attributionDistanceFromCenter;
          a.wg = b.attributionWeight;
          a.vg = b.attributionTheme;
          a.Pe = b.interactionHandler;
          a.Cf = t("onModelChanging", a.Cf);
          a.Bf = t("onModelChanged", a.Bf);
          a.Df = t("onRedraw", a.Df);
          a.Ff = t("onRolloutStart", a.Ff);
          a.Ef = t("onRolloutComplete", a.Ef);
          a.Dd = t("onRelaxationStep", a.Dd);
          a.Gf = t("onViewReset", a.Gf);
          a.uf = t("onGroupOpenOrCloseChanging", a.uf);
          a.tf = t("onGroupOpenOrCloseChanged", a.tf);
          a.lf = t("onGroupExposureChanging", a.lf);
          a.kf = t("onGroupExposureChanged", a.kf);
          a.wf = t("onGroupSelectionChanging", a.wf);
          a.vf = t("onGroupSelectionChanged", a.vf);
          a.nf = t("onGroupHover", a.nf);
          a.qf = t("onGroupMouseMove", a.qf);
          a.ef = t("onGroupClick", a.ef);
          a.ff = t("onGroupDoubleClick", a.ff);
          a.mf = t("onGroupHold", a.mf);
          a.sf = t("onGroupMouseWheel", a.sf);
          a.rf = t("onGroupMouseUp", a.rf);
          a.pf = t("onGroupMouseDown", a.pf);
          a.jf = t("onGroupDragStart", a.jf);
          a.gf = t("onGroupDrag", a.gf);
          a.hf = t("onGroupDragEnd", a.hf);
          a.zf = t("onGroupTransformStart", a.zf);
          a.xf = t("onGroupTransform", a.xf);
          a.yf = t("onGroupTransformEnd", a.yf);
          a.Af = t("onKeyUp", a.Af);
        })();
        a.Ji = Ea.u(a.Ii);
        a.Ci = Ea.u(a.Bi);
        a.Ge = Ea.u(a.Fe);
        a.sb ? P.has(u, "attributionLogo") && (a.sb instanceof Image ? a.Fa = a.sb : (a.Fa = new Image(), a.Fa.src = a.sb)) : a.Fa = null;
        g && (g.jg(u), P.has(u, "dataObject") && g.reload());
      }
      function c(u) {
        return function () {
          return u.apply(this, arguments).Jg(p);
        };
      }
      var p = this,
        k = window.CarrotSearchFoamTree.asserts,
        b = P.extend({}, window.CarrotSearchFoamTree.defaults),
        a = {};
      m(n);
      (n = b.element || document.getElementById(b.id)) || Ca.i("Element to embed FoamTree in not found.");
      b.element = n;
      var g = new ub(n, a, b);
      g.M();
      var e = {
        get: function (u) {
          return 0 === arguments.length ? P.extend({}, b) : q(arguments[0], Array.prototype.slice.call(arguments, 1));
        },
        set: m,
        on: function (u, t) {
          f(u, function (l) {
            l.push(t);
            return l;
          });
        },
        off: function (u, t) {
          f(u, function (l) {
            return l.filter(function (h) {
              return h !== t;
            });
          });
        },
        resize: g.ga,
        redraw: g.kg,
        update: g.update,
        attach: g.ua,
        select: c(g.H),
        expose: c(g.i),
        open: c(g.u),
        reset: c(g.reset),
        zoom: c(g.og),
        trigger: function (u, t) {
          (u = g.Yc(u)) && u(t);
        },
        dispose: function () {
          function u() {
            throw "FoamTree instance disposed";
          }
          g.$a();
          P.Aa(e, function (t, l) {
            "dispose" !== l && (p[l] = u);
          });
        }
      };
      P.Aa(e, function (u, t) {
        p[t] = u;
      });
      g.reload();
    };
    window["CarrotSearchFoamTree.asserts"] && (window.CarrotSearchFoamTree.asserts = window["CarrotSearchFoamTree.asserts"], delete window["CarrotSearchFoamTree.asserts"]);
    window.CarrotSearchFoamTree.supported = !0;
    window.CarrotSearchFoamTree.version = qb;
    window.CarrotSearchFoamTree.defaults = Object.freeze({
      id: void 0,
      element: void 0,
      logging: !1,
      dataObject: void 0,
      pixelRatio: 1,
      wireframePixelRatio: 1,
      layout: "relaxed",
      layoutByWeightOrder: !0,
      showZeroWeightGroups: !0,
      groupMinDiameter: 10,
      rectangleAspectRatioPreference: -1,
      relaxationInitializer: "fisheye",
      relaxationMaxDuration: 3E3,
      relaxationVisible: !1,
      relaxationQualityThreshold: 1,
      stacking: "hierarchical",
      descriptionGroup: "auto",
      descriptionGroupType: "stab",
      descriptionGroupPosition: 225,
      descriptionGroupDistanceFromCenter: 1,
      descriptionGroupSize: .125,
      descriptionGroupMinHeight: 35,
      descriptionGroupMaxHeight: .5,
      descriptionGroupPolygonDrawn: !1,
      maxGroups: 5E4,
      maxGroupLevelsAttached: 4,
      maxGroupLevelsDrawn: 4,
      maxGroupLabelLevelsDrawn: 3,
      groupGrowingDuration: 0,
      groupGrowingEasing: "bounce",
      groupGrowingDrag: 0,
      groupResizingBudget: 2,
      groupBorderRadius: .15,
      groupBorderWidth: 4,
      groupBorderWidthScaling: .6,
      groupInsetWidth: 6,
      groupBorderRadiusCorrection: 1,
      groupSelectionOutlineWidth: 5,
      groupSelectionOutlineColor: "#222",
      groupSelectionOutlineShadowSize: 0,
      groupSelectionOutlineShadowColor: "#fff",
      groupSelectionFillHueShift: 0,
      groupSelectionFillSaturationShift: 0,
      groupSelectionFillLightnessShift: 0,
      groupSelectionStrokeHueShift: 0,
      groupSelectionStrokeSaturationShift: 0,
      groupSelectionStrokeLightnessShift: -10,
      groupFillType: "gradient",
      groupFillGradientRadius: 1,
      groupFillGradientCenterHueShift: 0,
      groupFillGradientCenterSaturationShift: 0,
      groupFillGradientCenterLightnessShift: 20,
      groupFillGradientRimHueShift: 0,
      groupFillGradientRimSaturationShift: 0,
      groupFillGradientRimLightnessShift: -5,
      groupStrokeType: "plain",
      groupStrokeWidth: 1.5,
      groupStrokePlainHueShift: 0,
      groupStrokePlainSaturationShift: 0,
      groupStrokePlainLightnessShift: -10,
      groupStrokeGradientRadius: 1,
      groupStrokeGradientAngle: 45,
      groupStrokeGradientUpperHueShift: 0,
      groupStrokeGradientUpperSaturationShift: 0,
      groupStrokeGradientUpperLightnessShift: 20,
      groupStrokeGradientLowerHueShift: 0,
      groupStrokeGradientLowerSaturationShift: 0,
      groupStrokeGradientLowerLightnessShift: -20,
      groupHoverFillHueShift: 0,
      groupHoverFillSaturationShift: 0,
      groupHoverFillLightnessShift: 20,
      groupHoverStrokeHueShift: 0,
      groupHoverStrokeSaturationShift: 0,
      groupHoverStrokeLightnessShift: -10,
      groupExposureScale: 1.15,
      groupExposureShadowColor: "rgba(0, 0, 0, 0.5)",
      groupExposureShadowSize: 50,
      groupExposureZoomMargin: .1,
      groupUnexposureLightnessShift: 65,
      groupUnexposureSaturationShift: -65,
      groupUnexposureLabelColorThreshold: .35,
      exposeDuration: 700,
      exposeEasing: "squareInOut",
      groupColorDecorator: P.qa,
      groupLabelDecorator: P.qa,
      groupLabelLayoutDecorator: P.qa,
      groupContentDecorator: P.qa,
      groupContentDecoratorTriggering: "onLayoutDirty",
      openCloseDuration: 500,
      rainbowColorDistribution: "radial",
      rainbowColorDistributionAngle: -45,
      rainbowLightnessDistributionAngle: 45,
      rainbowSaturationCorrection: .1,
      rainbowLightnessCorrection: .4,
      rainbowStartColor: "hsla(0, 100%, 55%, 1)",
      rainbowEndColor: "hsla(359, 100%, 55%, 1)",
      rainbowLightnessShift: 30,
      rainbowLightnessShiftCenter: .4,
      parentFillOpacity: .7,
      parentStrokeOpacity: 1,
      parentLabelOpacity: 1,
      parentOpacityBalancing: !0,
      wireframeDrawMaxDuration: 15,
      wireframeLabelDrawing: "auto",
      wireframeContentDecorationDrawing: "auto",
      wireframeToFinalFadeDuration: 500,
      wireframeToFinalFadeDelay: 300,
      finalCompleteDrawMaxDuration: 80,
      finalIncrementalDrawMaxDuration: 100,
      finalToWireframeFadeDuration: 200,
      androidStockBrowserWorkaround: !1,
      incrementalDraw: "fast",
      groupLabelFontFamily: "sans-serif",
      groupLabelFontStyle: "normal",
      groupLabelFontWeight: "normal",
      groupLabelFontVariant: "normal",
      groupLabelLineHeight: 1.05,
      groupLabelHorizontalPadding: 1,
      groupLabelVerticalPadding: 1,
      groupLabelMinFontSize: 6,
      groupLabelMaxFontSize: 160,
      groupLabelMaxTotalHeight: .9,
      groupLabelUpdateThreshold: .05,
      groupLabelDarkColor: "#000",
      groupLabelLightColor: "#fff",
      groupLabelColorThreshold: .35,
      rolloutStartPoint: "center",
      rolloutEasing: "squareOut",
      rolloutMethod: "groups",
      rolloutDuration: 2E3,
      rolloutScalingStrength: -.7,
      rolloutTranslationXStrength: 0,
      rolloutTranslationYStrength: 0,
      rolloutRotationStrength: -.7,
      rolloutTransformationCenter: .7,
      rolloutPolygonDrag: .1,
      rolloutPolygonDuration: .5,
      rolloutLabelDelay: .8,
      rolloutLabelDrag: .1,
      rolloutLabelDuration: .5,
      rolloutChildGroupsDrag: .1,
      rolloutChildGroupsDelay: .2,
      pullbackStartPoint: "center",
      pullbackEasing: "squareIn",
      pullbackMethod: "groups",
      pullbackDuration: 1500,
      pullbackScalingStrength: -.7,
      pullbackTranslationXStrength: 0,
      pullbackTranslationYStrength: 0,
      pullbackRotationStrength: -.7,
      pullbackTransformationCenter: .7,
      pullbackPolygonDelay: .3,
      pullbackPolygonDrag: .1,
      pullbackPolygonDuration: .8,
      pullbackLabelDelay: 0,
      pullbackLabelDrag: .1,
      pullbackLabelDuration: .3,
      pullbackChildGroupsDelay: .1,
      pullbackChildGroupsDrag: .1,
      pullbackChildGroupsDuration: .3,
      fadeDuration: 700,
      fadeEasing: "cubicInOut",
      zoomMouseWheelFactor: 1.5,
      zoomMouseWheelDuration: 500,
      zoomMouseWheelEasing: "squareOut",
      maxLabelSizeForTitleBar: 8,
      titleBarFontFamily: null,
      titleBarFontStyle: "normal",
      titleBarFontWeight: "normal",
      titleBarFontVariant: "normal",
      titleBarBackgroundColor: "rgba(0, 0, 0, 0.5)",
      titleBarTextColor: "rgba(255, 255, 255, 1)",
      titleBarMinFontSize: 10,
      titleBarMaxFontSize: 40,
      titleBarTextPaddingLeftRight: 20,
      titleBarTextPaddingTopBottom: 15,
      titleBarDecorator: P.qa,
      attributionText: null,
      attributionLogo: null,
      attributionLogoScale: .5,
      attributionUrl: null,
      attributionPosition: "bottomright",
      attributionDistanceFromCenter: 1,
      attributionWeight: .025,
      attributionTheme: "light",
      interactionHandler: ea.Kh() ? "hammerjs" : "builtin",
      onModelChanging: [],
      onModelChanged: [],
      onRedraw: [],
      onRolloutStart: [],
      onRolloutComplete: [],
      onRelaxationStep: [],
      onViewReset: [],
      onGroupOpenOrCloseChanging: [],
      onGroupOpenOrCloseChanged: [],
      onGroupExposureChanging: [],
      onGroupExposureChanged: [],
      onGroupSelectionChanging: [],
      onGroupSelectionChanged: [],
      onGroupHover: [],
      onGroupMouseMove: [],
      onGroupClick: [],
      onGroupDoubleClick: [],
      onGroupHold: [],
      onGroupMouseWheel: [],
      onGroupMouseUp: [],
      onGroupMouseDown: [],
      onGroupDragStart: [],
      onGroupDrag: [],
      onGroupDragEnd: [],
      onGroupTransformStart: [],
      onGroupTransform: [],
      onGroupTransformEnd: [],
      onKeyUp: [],
      selection: null,
      open: null,
      exposure: null,
      imageData: null,
      hierarchy: null,
      geometry: null,
      containerCoordinates: null,
      state: null,
      viewport: null,
      times: null
    });
    window.CarrotSearchFoamTree.geometry = Object.freeze(function () {
      return {
        rectangleInPolygon: function (n, q, m, f, d, c, p) {
          d = P.I(d, 1);
          c = P.I(c, .5);
          p = P.I(p, .5);
          n = wa.La(n, {
            x: q,
            y: m
          }, f, c, p) * d;
          return {
            x: q - n * f * c,
            y: m - n * p,
            w: n * f,
            h: n
          };
        },
        circleInPolygon: function (n, q, m) {
          return wa.qb(n, {
            x: q,
            y: m
          });
        },
        stabPolygon: function (n, q, m, f) {
          return wa.ua(n, {
            x: q,
            y: m
          }, f);
        },
        polygonCentroid: function (n) {
          n = wa.u(n, {});
          return {
            x: n.x,
            y: n.y,
            area: n.ha
          };
        },
        boundingBox: function (n) {
          for (var q = n[0].x, m = n[0].y, f = n[0].x, d = n[0].y, c = 1; c < n.length; c++) {
            var p = n[c];
            p.x < q && (q = p.x);
            p.y < m && (m = p.y);
            p.x > f && (f = p.x);
            p.y > d && (d = p.y);
          }
          return {
            x: q,
            y: m,
            w: f - q,
            h: d - m
          };
        }
      };
    }());
  }, function () {
    window.CarrotSearchFoamTree = function () {
      window.console.error("FoamTree is not supported on this browser.");
    };
    window.CarrotSearchFoamTree.supported = !1;
  });
})();
const FoamTree = window.CarrotSearchFoamTree;
export { FoamTree };
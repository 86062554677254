import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'itq-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
  standalone: true
})
export class SvgComponent implements OnInit {
  @Input() icon: string;

  constructor() { }

  ngOnInit() {
  }

}

<div class="flex flex-col w-full">
  <span class="px-4 py-2 bg-border font-bold mb-4 rounded-md">WIDGET TYPE</span>
  <div class="grid grid-cols-4 gap-4">
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'comment'"
      [title]="'Posts timeline'"
      [description]="'A stream of posts from a tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TIMELINE)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'search'"
      [title]="'Tag analysis'"
      [description]="'Analyse within specific tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TAG_ANALYSIS)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'chart-pie'"
      [title]="'Tag comparison'"
      [description]="'Compare one or more tags'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TAG_COMPARISON)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'arrow-trend-up'"
      [title]="'Tag trends'"
      [description]="'Analyse tag trends over time'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TIME_SERIES)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'user'"
      [title]="'Top actors'"
      [description]="'Top actors within a specific tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TOP_ACTORS)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'hashtag'"
      [title]="'Top channels'"
      [description]="'Top channels within a specific tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TOP_CHANNELS)"
    ></itq-thumbnail>
    <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'ripl'"
      [title]="'Topic clustering'"
      [iconType]="IconType.SVG"
      [description]="'Topics discussed within a tag'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.TOPIC_CLUSTERS)"
    ></itq-thumbnail>
    <!-- <itq-thumbnail
      class="p-4 bg-white"
      [icon]="'calendar-days'"
      [title]="'Event tracker'"
      [description]="'Analyse events over time'"
      [disabled]="disabled"
      (add)="onAdd(AnalysisTypes.EVENT_TRACKER)"
    ></itq-thumbnail> -->
    @if (userService.userPreferences.defaultEcosystemId | hasEventsEnabled) {
      <itq-thumbnail
        class="p-4 bg-white"
        [icon]="'timeline'"
        [title]="'Events timeline'"
        [description]="'Analyse timeline of events'"
        [disabled]="disabled"
        (add)="onAdd(AnalysisTypes.EVENT_TIMELINE)"
      ></itq-thumbnail>
    }
  </div>
</div>

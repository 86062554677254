export const slackTemplate = [
  {
    color: "#1de62a",
    blocks: [
      {
        type: "divider",
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*DOCUMENT_HEADLINE*\n*DOCUMENT_DATE - DOCUMENT_ACTOR*\n*DOCUMENT_SOURCE - DOCUMENT_CHANNEL*\nDOCUMENT_BODY",
        },
      },
      {
        type: "actions",
        elements: [
          {
            type: "button",
            text: {
              type: "plain_text",
              text: "View in platform",
            },
            value: "click_me_123",
            url: "DOCUMENT_ACTION",
            action_id: "actionId-0",
          },
        ],
      },
      {
        type: "divider",
      },
    ],
  },
];

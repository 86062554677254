<div class="flex flex-col overflow-hidden">
  <div class="flex justify-between p-3">
    <div class="flex items-center overflow-hidden relative">
      <span
        class="pl-5 text-17 font-semibold text-md overflow-ellipsis overflow-hidden whitespace-nowrap"
        [matTooltip]="widget?.name"
      >
        {{ widget?.name }}
      </span>
      @if (widget?.description) {
        <fa-icon
          class="mx-1"
          [icon]="['far', 'circle-info']"
          [matTooltip]="widget.description"
        ></fa-icon>
      }

      @if (timelineStatus === TaggingStatus.IN_PROGRESS) {
        <mat-icon
          class="text-lg text-primary mx-2 flex-important items-center"
          fontIcon="downloading"
          [matTooltip]="
            'Tagging in progress, please refresh to see new content'
          "
        ></mat-icon>
      }
    </div>
    <div class="flex items-center">
      @if (count > 0) {
        <itq-pill
          class="mr-1"
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md '"
          >{{ count | number }}</itq-pill
        >
      }
      <itq-nav-button
        class="mr-1"
        [padding]="Sizes['X-SM']"
        [width]="220"
        [openDirection]="{ vertical: 'down', horizontal: 'right' }"
        [openPosition]="'inside'"
        [template]="tagActionsTemplate"
        [materialStyle]="'basic'"
        [matTooltipPosition]="'above'"
        [matTooltip]="'Tag options'"
      >
        <mat-icon
          class="text-xl flex-important items-center"
          fontIcon="settings"
        ></mat-icon>
      </itq-nav-button>
      <itq-nav-button
        [padding]="Sizes['X-SM']"
        [width]="220"
        [openDirection]="{ vertical: 'down', horizontal: 'right' }"
        [openPosition]="'inside'"
        [template]="widgetMoreTemplate"
        [templateData]="ProfileDrildownScope.TAG"
        [materialStyle]="'basic'"
        [matTooltip]="'Widget options'"
        [matTooltipPosition]="'above'"
      >
        <mat-icon
          class="text-lg flex-important items-center"
          fontIcon="widgets"
        ></mat-icon>
      </itq-nav-button>
    </div>
  </div>
</div>

<ng-template #tagActionsTemplate>
  <itq-timeline-tag-actions
    [widget]="widget"
    [initialState]="initialState"
    (edit)="onEdit()"
    (explore)="onExplore()"
  ></itq-timeline-tag-actions>
</ng-template>

<ng-template #widgetMoreTemplate let-data>
  <itq-timeline-widget-actions
    [data]="data"
    [widget]="widget"
    [board]="board"
    (delete)="onDeleteWidget()"
  ></itq-timeline-widget-actions>
</ng-template>

import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatListOption, MatSelectionListChange } from '@angular/material/list';
import { QueryFilters, VirtualScrollService } from '@intorqa-ui/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectionListComponent,
    },
  ],
})
export class SelectionListComponent implements OnInit, OnDestroy {
  private scrollTop = 0;
  private touched = false;
  public allSelected = false;
  public selections = new SelectionModel<string>(true, []);
  private dataBoundSubscription: Subscription;
  private scrollSubscription: Subscription;
  public query: string;

  @Input() selectAllTooltip: string;
  @Input() label: string;
  @Input() disabled = false;
  @Input() dataSource: Array<string>;
  @Input() httpBinding = false;
  @Input() initialState = new QueryFilters(
    10,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );

  @Output() dataBound = new EventEmitter<QueryFilters>();
  @Output() select = new EventEmitter<Array<string>>();

  @ViewChild('selectionsList') selectionsList: any;

  onChange = (items: any) => {};

  onTouched = () => {};

  writeValue(items: Array<string>): void {
    this.selections.clear();
    items?.forEach((item: string) => {
      this.selections.select(item);
    });
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  constructor(private virtualScrollService: VirtualScrollService) {}

  ngOnInit(): void {
    this.query = this.initialState?.query?.toString();
    this.dataBoundSubscription =
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        this.scrollTop = 0;
        this.selectionsList?._element?.nativeElement?.scrollTo(0, 0);
      });
  }

  ngOnDestroy(): void {
    this.dataBoundSubscription?.unsubscribe();
    this.scrollSubscription?.unsubscribe();
  }

  private onDataBound(): void {
    this.initialState.query = this.query;
    this.dataBound.emit(this.initialState);
  }

  public onSelectionChange(event: MatSelectionListChange): void {
    const optionValue = event.options.map((item: MatListOption) => item.value);
    const value = optionValue[0] as string;
    if (this.selections.isSelected(value)) {
      this.selections.deselect(value);
    } else {
      this.selections.select(value);
    }
    this.onChange(this.selections.selected);
    this.select.emit(this.selections.selected);
  }

  public onScroll(): void {
    if (this.initialState) {
      this.virtualScrollService
        .scrollDown(
          this.selectionsList._element.nativeElement,
          this.initialState.pageSize,
          this.dataSource.length,
          this.scrollTop,
          undefined,
          this.initialState.page,
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.onDataBound();
          }
        });
    }
  }

  public onSearch(): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });

    if (this.httpBinding) {
      this.onDataBound();
    } else {
      this.dataSource = this.dataSource.filter(
        (item: string) =>
          item.toLowerCase().trim().indexOf(this.query.toLowerCase().trim()) >
          -1,
      );
    }
  }

  public masterToggle(event: MatCheckboxChange): void {
    if (this.isAllSelected()) {
      this.selections.clear();
    } else {
      this.dataSource.forEach((row: string) => {
        if (event.checked) {
          if (!this.selections.isSelected(row)) {
            this.selections.select(row);
          }
        } else {
          if (this.selections.isSelected(row)) {
            this.selections.deselect(row);
          }
        }
      });
    }
    this.onChange(this.selections);
    this.select.emit(this.selections.selected);
  }

  public isAllSelected(): boolean {
    const numSelected = this.selections.selected.length;
    const numRows = this.dataSource?.length;
    return numSelected === numRows;
  }
}

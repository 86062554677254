export enum EventTeams {
  SECURITY_TEAM = 'SECURITY_TEAM',
  CHEATER_TEAM = 'CHEATER_TEAM',
}

export enum TimelineOrientation {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}
export enum EventTimelineItemPosition {
  ON_LEFT = 'ON_LEFT',
  ON_RIGHT = 'ON_RIGHT',
}

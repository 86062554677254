import { Pipe, PipeTransform } from '@angular/core';
import { IDropdownItem } from './dropdown.interface';

@Pipe({
  name: 'getLabel',
})
export class GetLabelPipe implements PipeTransform {
  transform(
    data: Array<any>,
    dataFields: IDropdownItem,
    selection: string,
  ): string {
    if (!selection || data?.length === 0) return undefined;
    const dataItem = data?.find(
      (item: any) => item[dataFields?.value] === selection,
    );
    return (dataItem && dataItem[dataFields.name]) || undefined;
  }
}

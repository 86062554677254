import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Align, Sizes } from "../../enums/shared.enum";

@Component({
  selector: "itq-button-toggle",
  templateUrl: "./button-toggle.component.html",
  styleUrls: ["./button-toggle.component.scss"],
})
export class ButtonToggleComponent implements OnInit {
  @Input() size: Sizes;
  @Input() disabled = false;
  @Input() active = false;
  @Input() materialStyle = "basic";
  @Input() align = Align.START;
  @Input() activeTooltip: string;
  @Input() inactiveTooltip: string;
  @Input() padding = Sizes["X-SM"];

  @Output() clickEvent = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {}

  public onClick(): void {
    this.active = !this.active;
    this.clickEvent.emit();
  }
}

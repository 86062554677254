<div
  class="menu__container"
  (mouseleave)="showProfileTypes = false; showProfilesList = false"
>
  <div class="menu__wrapper">
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      [disabled]="this.widget | isWidgetDisabled"
      [matTooltipPosition]="'right'"
      [matTooltip]="this.widget | getWidgetTooltip"
      (mouseDownEvent)="onEdit()"
      (mouseover)="showProfilesList = false; showProfileTypes = false"
    >
      <fa-icon [icon]="['far', 'pencil']"></fa-icon>
      Edit tag
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      [disabled]="this.widget | isWidgetDisabled"
      [matTooltipPosition]="'right'"
      [matTooltip]="this.widget | getWidgetTooltip"
      (mouseDownEvent)="onExplore()"
      (mouseover)="showProfilesList = false; showProfileTypes = false"
    >
      <fa-icon [icon]="['far', 'expand-wide']"></fa-icon>
      Explore tag
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [type]="'button'"
      [padding]="Sizes['X-SM']"
      [align]="Align.START"
      [materialStyle]="'basic'"
      [disabled]="this.widget | isWidgetDisabled"
      [matTooltip]="
        (this.widget | isWidgetDisabled)
          ? 'You don\'t have permissions to clone this tag'
          : undefined
      "
      [matTooltipPosition]="'right'"
      (mouseDownEvent)="onClone()"
      (mouseover)="showProfilesList = false; showProfileTypes = false"
    >
      <fa-icon [icon]="['far', 'clone']"></fa-icon>
      Clone tag
    </itq-mat-button>

    @if (keycloakService.isUserInRole('saas-profiles')) {
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [padding]="Sizes['X-SM']"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        (mouseover)="showProfilesList = false; showProfileTypes = true"
      >
        <div class="flex justify-between w-full">
          <div class="flex items-center">
            <fa-icon [icon]="['far', 'plus']"></fa-icon>Create new profile
          </div>
          <fa-icon
            [icon]="[
              'far',
              showProfilesList ? 'chevron-down' : 'chevron-right'
            ]"
          ></fa-icon>
        </div>
      </itq-mat-button>
    }
    @if (keycloakService.isUserInRole('saas-profiles')) {
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        (mouseover)="
          showProfilesList = true;
          showProfileTypes = false;
          onMouseOverLinkProfile()
        "
      >
        <div class="flex justify-between w-full">
          <div class="flex items-center">
            <fa-icon [icon]="['far', 'plus']"></fa-icon>
            Add existing profile
          </div>
          <fa-icon
            [icon]="[
              'far',
              showProfileTypes ? 'chevron-down' : 'chevron-right'
            ]"
          ></fa-icon>
        </div>
      </itq-mat-button>
    }
  </div>
  @if (showProfilesList) {
    <div class="menu__sidebar">
      <itq-dropdown-results
        class="min-h-[120px]"
        [dataSource]="profilesDataSource"
        [httpBinding]="true"
        [dataFields]="{ name: 'name', value: 'profileId' }"
        [initialState]="profileInitialState"
        (dataBound)="onProfileDataBound($event)"
        (changeValue)="onLinkProfile($event)"
      ></itq-dropdown-results>
    </div>
  }
  @if (showProfileTypes) {
    <div class="menu__sidebar" [ngStyle]="{ width: '170px' }">
      @for (item of profileTypesDataSource; track item.id) {
        <itq-mat-button
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          [type]="'button'"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          (clickEvent)="onCreateProfileTag(item)"
        >
          <fa-icon [icon]="['far', item.name | getProfileTypeIcon]"></fa-icon>
          <span>
            {{ item.name }}
          </span>
        </itq-mat-button>
      }
    </div>
  }
</div>

import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FAwesomeModule, IconType, SvgComponent } from '@intorqa-ui/core';

@Component({
  selector: 'itq-library-sidebar-item',
  templateUrl: './library-sidebar-item.component.html',
  styleUrls: ['./library-sidebar-item.component.scss'],
  standalone: true,
  imports: [RouterModule, FAwesomeModule, SvgComponent],
})
export class LibrarySidebarItemComponent implements OnInit {
  @Input() public title: string;
  @Input() public url: string;
  @Input() public icon: string;
  @Input() public iconType: IconType;
  @Input() public totalCount: number;

  readonly IconType = IconType;

  constructor() {}

  ngOnInit() {}
}

<ng-container *ngIf="iconType === 'font-awesome'">
  <fa-icon
    [icon]="[iconName === 'discord' ? 'fab' : 'far', iconName | castIconName]"
    [size]="iconSize | castIconSize"
  ></fa-icon>
</ng-container>
<ng-container *ngIf="iconType === 'sprite'">
  <itq-svg
    [ngStyle]="{ width: iconSize + 'px', height: iconSize + 'px' }"
    [icon]="iconName"
  ></itq-svg>
</ng-container>

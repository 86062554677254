<div class="flex flex-col overflow-hidden">
  <div class="flex justify-between p-3">
    <div class="flex items-center overflow-hidden">
      <span
        class="pl-5 text-17 font-semibold text-md overflow-ellipsis overflow-hidden whitespace-nowrap"
        [matTooltip]="widget?.name"
      >
        {{ widget?.name }}
      </span>
      @if (widget?.description) {
        <fa-icon
          class="mx-1"
          [icon]="['far', 'circle-info']"
          [matTooltip]="widget.description"
        ></fa-icon>
      }
    </div>
    <div class="flex items-center">
      @if (count > 0) {
        <itq-pill
          class="mr-1"
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md '"
          >{{ count | number }}</itq-pill
        >
      }

      @if (selectedDisplayType) {
        <itq-nav-button
          class="mr-1"
          [padding]="Sizes['X-SM']"
          [width]="170"
          [openDirection]="{ vertical: 'down', horizontal: 'right' }"
          [openPosition]="'inside'"
          [template]="displayTypesTemplate"
          [templateData]="widget"
          [disabled]="count === 0"
          [materialStyle]="'basic'"
          [matTooltipPosition]="'above'"
          [matTooltip]="'Change display type'"
        >
          @if (selectedDisplayType?.icon) {
            <fa-icon [size]="'lg'" [icon]="selectedDisplayType?.icon"></fa-icon>
          } @else {
            <itq-svg
              [icon]="selectedDisplayType?.svgIcon"
              class="h-5 w-5"
            ></itq-svg>
          }
        </itq-nav-button>
      }
      <itq-nav-button
        [padding]="Sizes['X-SM']"
        [width]="220"
        [openDirection]="{ vertical: 'down', horizontal: 'right' }"
        [openPosition]="'inside'"
        [template]="widgetMoreTemplate"
        [templateData]="ProfileDrildownScope.TAG"
        [materialStyle]="'basic'"
        [matTooltip]="'More options'"
        [matTooltipPosition]="'above'"
      >
        <mat-icon
          class="text-lg flex-important items-center"
          fontIcon="widgets"
        ></mat-icon>
      </itq-nav-button>
    </div>
  </div>
</div>

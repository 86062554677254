import { Pipe, PipeTransform } from "@angular/core";
import { Category, Sections } from "@intorqa-ui/core";

@Pipe({
  name: "filterBySection",
})
export class FilterBySectionPipe implements PipeTransform {
  constructor() {}

  transform(data: Array<Category>, section: Sections): Array<Category> {
    return data.filter((item: Category) => item.section === section);
  }
}

<div class="flex flex-col" [formGroup]="form">
  <span class="mb-1 text-13 font-medium">TAG</span>
  <itq-multiple-dropdown
    [placeholder]="'Tag...'"
    [dataSource]="dataPointsDataSource"
    [initialState]="initialState"
    formControlName="dataPoints"
    [dataFields]="{ name: 'name', value: 'id' }"
    [emptyDataSourceMessage]="'No tags found'"
    [httpBinding]="true"
    (changeValue)="onDataBound()"
    (dataBound)="onGetTags($event)"
  >
  </itq-multiple-dropdown>
  @if (
    form.controls['dataPoints']?.touched &&
    form.controls['dataPoints']?.hasError('required')
  ) {
    <mat-error class="text-13 pt-1">Data points is required </mat-error>
  }
</div>

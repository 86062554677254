<itq-sidebar
  class="container__left p-4"
  [isExpanded]="expandedFilters"
  [width]="800"
>
  <itq-headline-simple
    class="mb-7"
    [iconName]="navigationItem.icon"
    [iconSize]="'2x'"
    [iconType]="IconType.FONT_AWESOME"
    [title]="infoForm?.controls?.name?.value || 'Create tag'"
    [description]="infoForm?.controls?.description?.value"
  >
  </itq-headline-simple>

  <mat-stepper
    class="left__body"
    [linear]="
      navigationItem?.action === WidgetActions.CREATE ||
      navigationItem?.action === WidgetActions.CLONE
        ? true
        : false
    "
    #stepper
  >
    <mat-step state="info" [stepControl]="infoForm">
      <itq-form [formGroup]="infoForm" class="left__stepper stepper__container">
        <ng-template matStepLabel>Tag details</ng-template>
        <itq-timeline-wizard-details
          [widget]="widget"
          [form]="infoForm"
          [metadataForm]="metadataForm"
          [query]="query"
          [navigationItem]="navigationItem"
          (changeQueryType)="onChangeQueryType()"
        ></itq-timeline-wizard-details>
      </itq-form>
    </mat-step>
    <mat-step state="content" label="Build your tag">
      <itq-form
        class="content__container stepper__container"
        [formGroup]="contentForm"
      >
        <itq-timeline-query
          [formControlName]="'query'"
          [queryType]="query?.type"
          [navigationItem]="navigationItem"
          [query]="query"
          [widget]="widget"
          [required]="true"
          [dataSource]="categoriesDataSource"
          [initialState]="initialState"
          (dataBound)="onDataBound($event)"
          (clearFilters)="onDataBound($event)"
        ></itq-timeline-query>
      </itq-form>
    </mat-step>
    <mat-step state="metadata" [stepControl]="metadataForm" [optional]="true">
      <ng-template matStepLabel>Config</ng-template>
      <itq-form [formGroup]="metadataForm" class="left__stepper">
        <itq-timeline-wizard-settings
          [navigationItem]="navigationItem"
          [boardIds]="boardIds"
          [widget]="widget"
          [form]="metadataForm"
        ></itq-timeline-wizard-settings>
      </itq-form>
    </mat-step>
    <mat-step
      *ngIf="metadataForm?.get('alertTypeId')?.value"
      state="alerts"
      [stepControl]="form?.get('alerts')"
      [optional]="true"
    >
      <div class="left__stepper stepper__container">
        <ng-template matStepLabel>Alerts</ng-template>
        <itq-tag-alerts
          *ngIf="alertType"
          [alertType]="alertType"
          [navigationItem]="navigationItem"
          [form]="form"
          [metadataForm]="metadataForm"
          [widget]="this.widget"
        ></itq-tag-alerts>
      </div>
    </mat-step>
    <ng-template matStepperIcon="info">
      <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
    </ng-template>
    <ng-template matStepperIcon="content">
      <fa-icon [icon]="['far', 'tags']"></fa-icon>
    </ng-template>
    <ng-template matStepperIcon="metadata">
      <fa-icon [icon]="['far', 'database']"></fa-icon>
    </ng-template>
    <ng-template matStepperIcon="alerts">
      <fa-icon [icon]="['far', 'bell']"></fa-icon>
    </ng-template>
  </mat-stepper>
</itq-sidebar>
<div class="container__right">
  <div class="right__body">
    <div class="body__header">
      <div class="widget_settings__toolbar">
        <itq-pill
          *ngIf="this.query.hasRules() && timelineDataSource?.count > 0"
          [type]="PillType.CUSTOM"
          [class]="'secondary rounded-md'"
          >{{ timelineDataSource?.count | number }}</itq-pill
        >
        <div class="toolbar__container">
          <itq-mat-button
            [padding]="Sizes.SM"
            [materialStyle]="'basic'"
            [disabled]="timelineDataSource?.count === 0"
            (clickEvent)="getTimelineData()"
          >
            <fa-icon [icon]="['far', 'sync']"></fa-icon>
            <span class="text-wrapper">Refresh</span>
          </itq-mat-button>
          <itq-date-range
            [dates]="this.initialState?.where"
            (search)="onChangeDates($event)"
          ></itq-date-range>
        </div>
      </div>
      <div class="widget_settings__content">
        @if (this.query.hasRules()) {
          @if (timelineDataSource) {
            <itq-virtual-table
              [initialState]="initialState"
              [theme]="'tile'"
              [headerVisible]="false"
              [tableColumns]="tableColumns"
              [dataSource]="timelineDataSource?.result"
              [totalCount]="timelineDataSource?.count"
              [trackBy]="'id'"
              [emptyMessage]="'Please update your filters and try again'"
              [emptyMessageDescription]="
                'Please update your filters and try again'
              "
              (dataBound)="onSearch($event)"
            ></itq-virtual-table>
          }
        } @else {
          <itq-panel-action
            [label]="'No filters selected!'"
            [description]="
              'Type in the search box and add some filters for targeted results.'
            "
          ></itq-panel-action>
        }
        @if (articleDetail) {
          <itq-document-item-detail
            [viewParent]="false"
            [form]="form"
            [navigationItem]="navigationItem"
            [allowDrilldown]="false"
            [segment]="articleDetail"
            [action]="navigationItem?.action"
          ></itq-document-item-detail>
        }
      </div>
    </div>
    <div class="body__footer">
      <itq-nav-button
        *ngIf="navigationItem.action === WidgetActions.CREATE"
        [disabled]="
          !form.valid ||
          !infoForm.valid ||
          !metadataForm.valid ||
          !contentForm.valid
        "
        [width]="170"
        [openDirection]="{ vertical: 'up', horizontal: 'right' }"
        [openPosition]="'inside'"
        [template]="actionsTemplate"
        [materialStyle]="'flat'"
        [color]="'primary'"
        [padding]="Sizes.MD"
        >Save</itq-nav-button
      >
      <itq-mat-button
        *ngIf="navigationItem.action === WidgetActions.CLONE"
        [disabled]="
          !form.valid ||
          !infoForm.valid ||
          !metadataForm.valid ||
          !contentForm.valid
        "
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [style]="'primary'"
        [materialStyle]="'flat'"
        (mouseDownEvent)="onSave()"
      >
        <span class="text-wrapper">CLONE</span>
      </itq-mat-button>
      <itq-mat-button
        *ngIf="navigationItem.action === WidgetActions.EDIT"
        [disabled]="
          !infoForm.valid ||
          !form.valid ||
          !metadataForm.valid ||
          !contentForm.valid ||
          (form.pristine &&
            infoForm.pristine &&
            metadataForm.pristine &&
            !contentForm.touched)
        "
        [type]="'button'"
        [padding]="Sizes['X-SM']"
        [style]="'primary'"
        [materialStyle]="'flat'"
        (mouseDownEvent)="onEdit()"
      >
        <span class="text-wrapper">UPDATE</span>
      </itq-mat-button>
    </div>
  </div>
</div>
<ng-template #template let-data>
  <itq-document-item-tile
    [item]="data"
    [allowDrilldown]="false"
    [form]="form"
    [query]="initialState?.query"
    [navigationItem]="navigationItem"
    [query]="initialState?.query"
  >
  </itq-document-item-tile>
</ng-template>

<ng-template #actionsTemplate>
  <div class="actions_container">
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [type]="'button'"
      [padding]="Sizes['X-SM']"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (mouseDownEvent)="onSave()"
    >
      <span class="text-wrapper">Create tag</span>
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [padding]="Sizes.SM"
      [type]="'button'"
      [padding]="Sizes['X-SM']"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (mouseDownEvent)="onSave(true)"
    >
      <span class="text-wrapper">Save & Create New</span>
    </itq-mat-button>
  </div>
</ng-template>

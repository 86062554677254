<itq-mat-button
  class="btn--toggle"
  [rounded]="true"
  [size]="Sizes.SM"
  [type]="'button'"
  [materialStyle]="'stroked'"
  (clickEvent)="onToggle()"
>
  <fa-icon
    [icon]="classes !== '' ? ['far', 'chevron-left'] : ['far', 'chevron-right']"
  ></fa-icon>
</itq-mat-button>
<section class="sidebar__navigation_container">
  <h1 *ngIf="title">{{ title }}</h1>
  <ng-content></ng-content>
</section>

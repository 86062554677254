export enum ExtensionDataFieldsText {
  nickname = "Nickname",
  id = "Id",
  name = "Name",
  isBot = "Is Bot",
  topic = "Topic",
  category = "Category",
  discriminator = "Discriminator",
  avatarUrl = "Avatar",
  userDetails = "User details",
  serverDetails = "Server details",
  channelDetails = "Channel details",
  phoneNumber = "Phone number",
  lastName = "Last name",
  firstName = "First name",
  username = "Username",
  activeUsernames = "Active usernames",
  disabledUsernames = "Disabled usernames",
  editableUsername = "Editable usernames",
  urls = "Urls",
}

export enum ExtensionDataFields {
  NICKNAME = "nickname",
  ID = "id",
  NAME = "name",
  ISBOT = "isBot",
  TOPIC = "topic",
  CATEGORY = "category",
  DISCRIMINATOR = "discriminator",
  AVATAR_URL = "avatarUrl",
  USER_DETAILS = "userDetails",
  SERVER_DETAILS = "serverDetails",
  CHANNEL_DETAILS = "channelDetails",
}

import { Component, Inject, Optional } from "@angular/core";
import { CUSTOM_OVERLAY_DATA } from "../custom-overlay/custom-overlay.tokens";
import { CustomOverlayConfig } from "../custom-overlay/custom-overlay.interface";
import { CustomOverlayRef } from "../custom-overlay/custom-overlay.ref";
import { DialogTypes } from "./dialog.enum";

export class ConfirmDialogModel {
  constructor() {}
}

@Component({
  selector: "itq-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent {
  public DialogTypes = DialogTypes;
  constructor(
    @Optional() @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    @Optional() public customOverlayRef: CustomOverlayRef
  ) {}

  onConfirm(): void {
    this.customOverlayRef.close(true);
  }

  onDismiss(): void {
    this.customOverlayRef.close(false);
  }
}

import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Category,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DialogComponent,
  DialogTypes,
  IError,
  IPresetQuery,
  IconType,
  PillType,
  QueryFilters,
  Sections,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { WidgetService } from '@portal/boards/services/widget.service';
import {
  ToolbarActions,
  ViewMode,
} from '@portal/shared/components/toolbar/toolbar.enum';
import { IToolbarAction } from '@portal/shared/components/toolbar/toolbar.interface';
import { SEARCH_TYPES } from '@portal/shared/const/timeline.const';
import { QueryType } from '@portal/shared/enums/timeline-query.enum';
import { IData } from '@portal/shared/interfaces/document.interface';
import { IDataPoint } from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { CategoryService } from '@portal/shared/services/category.service';
import { DocumentItemService } from '@portal/shared/services/document-item.service';
import { TagService } from '@portal/shared/services/tag.service';
import { UserService } from '@portal/shared/services/user.service';
import { ApiSettingsComponent } from '@portal/widget-settings/components/api-settings/api-settings.component';
import {
  SegmentScope,
  SettingsActions,
} from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { DiscordNavigationItem } from '@portal/widget-settings/models/discord-navigation-item.model';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { TimelineNavigationItem } from '@portal/widget-settings/models/timeline-navigation-item.model';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { cloneDeep } from 'lodash';

import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'itq-timeline-explore',
  templateUrl: './timeline-explore.component.html',
  styleUrls: ['./timeline-explore.component.scss'],
})
export class TimelineExploreComponent implements OnInit, OnDestroy, OnChanges {
  @Input() navigationItem: TimelineNavigationItem;
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;

  public widget: Timeline;
  public initialState: QueryFilters;
  public data: Array<IDataPoint>;
  public name: string;
  public timelineData: IData;
  public error: IError;
  public expandedFilters = true;
  public queryModel = new Query();
  public viewMode = ViewMode.TABLE;
  public toolbarActions: Array<IToolbarAction>;
  private scrollSubscription: Subscription;
  public categoriesDataSource: Array<Category>;
  private page: number;
  private scrollDirection = 'down';

  readonly searchTypes = SEARCH_TYPES;
  readonly SegmentScope = SegmentScope;
  readonly Sizes = Sizes;
  readonly AnalysisTypes = AnalysisTypes;
  readonly ViewMode = ViewMode;
  readonly SettingsActions = SettingsActions;
  readonly QueryType = QueryType;
  readonly IconType = IconType;
  readonly WidgetActions = WidgetActions;
  readonly PillType = PillType;
  readonly ChartType = ChartType;

  constructor(
    public customOverlayRef: CustomOverlayRef,
    public documentService: DocumentItemService,
    public tagService: TagService,
    private widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    private categoryService: CategoryService,
    private virtualScrollService: VirtualScrollService,
    readonly cdr: ChangeDetectorRef,
    readonly userService: UserService,
    readonly widgetSettingsService: WidgetSettingsService,
  ) {}

  ngOnInit(): void {
    this.initToolbar();
    this.getFilters();
    this.setDrilldownFilters();
    this.bindScrollSubscription();
    this.form.addControl(
      'query',
      new FormControl(undefined, [Validators.required]),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.navigationItem?.previousValue !==
      changes?.navigationItem?.currentValue
    ) {
      this.viewMode = ViewMode.TABLE;
      this.widget = changes?.navigationItem?.currentValue?.item as Timeline;
      this.initialState = cloneDeep(
        changes?.navigationItem?.currentValue?.initialState,
      );
      this.form.addControl(
        'searchType',
        new FormControl(QueryType.QUICK_SEARCH),
      );
      if (
        this.queryModel.hasRules() ||
        this.navigationItem?.rules?.length > 0
      ) {
        this.widgetSettingsService.loader$.next(true);
        this.getTimelineData().subscribe((response: IData) => {
          this.getDataCallback(response);
          this.widgetSettingsService.loader$.next(false);
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.scrollSubscription.unsubscribe();
  }

  private initToolbar(): void {
    this.toolbarActions = [
      {
        action: ToolbarActions.REFRESH,
      },
      {
        action: ToolbarActions.ADD,
      },
      {
        action: ToolbarActions.API,
      },
    ];
  }

  public onChangeDates(params: IPresetQuery): void {
    this.initialState.where = params;
    this.onDataBound(this.queryModel, this.initialState);
  }

  private bindScrollSubscription(): void {
    this.scrollSubscription = this.virtualScrollService.scroll$.subscribe(
      (response: { query: QueryFilters; direction: string }) => {
        this.scrollDirection = response.direction;
        this.initialState.page = cloneDeep(response.query.page);
        this.widgetSettingsService.loader$.next(true);
        this.getTimelineData().subscribe((response: IData) => {
          this.getDataCallback(response);
          this.widgetSettingsService.loader$.next(false);
        });
      },
    );
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.scope === ProfileDrildownScope.TAG
        ? this.widget.name
        : profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? this.widget.name
          : profileDrilldown.value,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? profileDrilldown.value
          : `Field field:${profileDrilldown.value}:${
              profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onChangeEcosystem(): void {
    if (this.queryModel.hasRules()) {
      this.customOverlayService.openCustom(
        {
          title: 'Change ecosystem?',
          message: `In order to change ecosystem you need to reset your query.<br><br>Do you wish to continue?`,
          icon: ['far', 'question-circle'],
          cssClass: 'w-[90%]',
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.initialState.resetPagination().then(() => {
              this.virtualScrollService.dataBoundObservable.next();
            });
            this.clearQuery();
            this.getFilters();
          } else {
          }
        },
      );
    } else {
      this.getFilters();
    }
  }

  private getFilters(): void {
    this.categoryService
      .getCategories(this.userService.userPreferences.defaultEcosystemId)
      .then((response: Array<Category>) => {
        this.clearQuery();
        this.categoriesDataSource = response;
        this.cdr.detectChanges();
        this.tagService.getSearchResults$.next();
        this.loadFilters();
      });
  }

  private loadFilters(): void {
    this.tagService.reloadFilters$.next({
      queryFilters: new QueryFilters(
        100,
        1,
        this.initialState.where,
        undefined,
        undefined,
        undefined,
      ),
      query: this.queryModel,
    });
  }

  private setDrilldownFilters(): void {
    if (this.initialState?.query) {
      this.queryModel.addRule(
        new QueryRule(
          DTOQueryFieldType.content,
          DTOQueryConditionOperator.contains,
          [this.initialState.query],
        ),
      );
    }
  }

  private updateNavigationItem(): void {
    const query = new Query();
    let rules = this.queryModel.getRules();
    if (this.navigationItem?.rules?.length > 0) {
      rules = this.queryModel.getRules().filter((rule: QueryRule) => {
        return rule.value !== this.navigationItem?.rules[0]?.value;
      });
    }
    rules.forEach((item: QueryRule) => {
      query.addRule(item);
    });
    this.onUpdateWidget({ prop: '_extras', value: query });
  }

  public onUpdateWidget(params: { prop: string; value: any }): void {
    this.widget[params.prop] = params.value;
    this.navigationItem.form = cloneDeep(this.form);
    this.navigationItem.initialState = cloneDeep(this.initialState);
    this.navigationItem.item[params.prop] = params.value;
  }

  public onToggleFilters(): void {
    this.expandedFilters = !this.expandedFilters;
    this.widgetService.updateSegmentObservable.next(undefined);
    this.widgetService.responsivePanels.filters = this.expandedFilters;
    this.widgetService.responsivePanels$.next();
  }

  private getTimelineData(): Observable<IData> {
    const queryModel = this.queryModel.cloneDeep();
    this.navigationItem.rules?.forEach((rule: QueryRule) => {
      queryModel.addRule(rule);
    });
    if (this.navigationItem instanceof DiscordNavigationItem) {
      if (this.initialState.page) {
        if (this.page < this.initialState.page) {
          this.navigationItem.segment.context = {
            document: this.navigationItem.context.document,
            before:
              this.timelineData.result[this.timelineData.result.length - 1]
                .emitDocumentDate,
          };
        } else {
          this.navigationItem.segment.context = {
            document: this.navigationItem.context.document,
            after: this.timelineData.result[0].emitDocumentDate,
          };
        }
      } else {
        this.navigationItem.segment.context = {
          document: this.navigationItem.context.document,
        };
      }
      return this.tagService.getContext(
        this.initialState,
        queryModel,
        this.userService.userPreferences.defaultEcosystemId,
        this.navigationItem.segment.context,
      );
    } else {
      return this.tagService.execute(
        this.initialState,
        queryModel,
        this.userService.userPreferences.defaultEcosystemId,
      );
    }
  }

  private getDataCallback(response: IData): void {
    this.updateNavigationItem();
    const existingResults =
      this.timelineData?.result?.length > 0 ? this.timelineData.result : [];
    const newResults = response.result || [];

    if (response.count !== null) {
      this.timelineData = {
        result:
          this.scrollDirection === 'up'
            ? [...newResults, ...existingResults]
            : this.initialState.page > 1
              ? [...existingResults, ...newResults]
              : newResults,
        count: response.count,
      };

      if (response.page) {
        this.initialState.page = response.page;
        this.page = response.page;
      }
    } else {
      const resultsOrder = this.navigationItem.segment?.context?.before
        ? [...existingResults, ...newResults]
        : [...newResults, ...existingResults];
      this.timelineData = {
        result: resultsOrder,
        count: response.count,
      };
      this.page = this.initialState.page;
    }
  }

  public onDataBound(query: Query, params: QueryFilters): void {
    this.articleDetail = undefined;
    this.queryModel = query;
    if (this.queryModel.hasRules()) {
      this.form.controls.query.setValue(this.queryModel);
    } else {
      this.form.controls.query.setValue(undefined);
    }
    this.initialState = params;
    this.tagService.reloadFilters$.next({
      queryFilters: new QueryFilters(
        100,
        1,
        this.initialState.where,
        undefined,
        undefined,
        undefined,
      ),
      query: this.queryModel,
    });
    this.widgetSettingsService.loader$.next(true);
    this.getTimelineData().subscribe((response: IData) => {
      this.getDataCallback(response);
      this.widgetSettingsService.loader$.next(false);
    });
  }

  public onAdd(): void {
    const widget = cloneDeep(this.navigationItem?.item) as Timeline;
    widget.name = undefined;
    widget.categoryId = undefined;
    widget._extras.query.rules = [
      ...widget._extras.query.rules,
      this.navigationItem.rules[0],
    ];

    const rule = [
      new QueryRule(
        DTOQueryFieldType.filter,
        DTOQueryConditionOperator.in,
        this.navigationItem.rules[0].value,
      ),
    ];
    const navHitoryItem = new NavigationHistoryItem(
      `${WidgetActions.CREATE}_${this.navigationItem.rules[0].value[0]}`,
      widget,
      WidgetActions.CREATE,
      rule,
      this.initialState,
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      this.navigationItem.boardId,
    );
    this.widgetService.drilldownObservable.next(navHitoryItem);
  }

  public onChangeQueryType(): void {
    if (this.queryModel.hasRules()) {
      this.customOverlayService.openCustom(
        {
          title: 'Change search type',
          message:
            'In order to change your search type, you need to reset your query.<br><br>Do you wish to continue?',
          icon: ['far', 'question-circle'],
          size: '4x',
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.clearQuery();
            this.onDataBound(this.queryModel, this.initialState);
          } else {
            this.form.controls.searchType.setValue(this.queryModel.type);
          }
        },
      );
    } else {
      this.clearQuery();
      this.onDataBound(this.queryModel, this.initialState);
    }
  }

  private clearQuery(): void {
    if (this.form.controls.searchType.value === QueryType.QUICK_SEARCH) {
      this.queryModel = new Query([], this.form.controls.searchType.value);
      this.form.controls.searchTerm?.setValue('');
      this.form.controls.section?.setValue(Sections['My Tags']);
    }
    if (this.form.controls.searchType.value === QueryType.QUERY_BUILDER) {
      this.queryModel = new Query(
        [
          new QueryRule(
            DTOQueryFieldType.content,
            DTOQueryConditionOperator.contains,
            [],
          ),
        ],
        this.form.controls.searchType.value,
      );
    } else {
      this.queryModel = new Query([], this.form.controls.searchType.value);
    }
    this.form.controls.query.setValue(this.queryModel);
  }

  public onShowApi(): void {
    this.customOverlayService.open({
      data: {
        widget: this.widget,
      },
      type: CustomOverlayType['slide-right'],
      component: ApiSettingsComponent,
      disposeOnNavigation: true,
    });
  }

  public onDrilldown(segment: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.DISCORD:
        this.drilldownDiscord(segment);
        break;
      case SegmentScope.CONTEXT:
        this.drilldownContext(segment);
        break;
      case SegmentScope.REPLIES:
        this.drilldownReplies(segment);
        break;
      case SegmentScope.ARTICLE_DETAIL:
        this.openArticleDetail(segment);
        break;
      default:
        break;
    }
  }

  private openArticleDetail(segment: ISegment): void {
    this.widgetService.updateSegmentObservable.next(segment);
  }

  private drilldownContext(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      `Context: ${segment.context.document.emitType} by ${segment.context.document.emitActor}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new DiscordNavigationItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(
        30,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      segment,
      undefined,
      segment.context,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownReplies(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitType === 'Comment'
        ? `${segment.value.emitType} by ${segment.value.emitActor} `
        : `Replies of ${segment.value.emitHeadline}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.id}:ReplyTo`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${segment.value.id}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'file',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownDiscord(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.data.name,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:Discord:Source`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_discord_${segment.value.data.name}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
        {
          field: DTOQueryFieldType.content,
          operator: DTOQueryConditionOperator.in,
          value: [segment.value.data.id],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'discord',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      this.articleDetail,
      undefined,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }
}

@if (data) {
  <itq-virtual-table
    class="flex-1 overflow-hidden"
    [initialState]="initialState"
    [tableColumns]="tableColumns"
    [headerVisible]="false"
    [theme]="'tile'"
    [dataSource]="data?.result"
    [totalCount]="data?.count"
    [active]="segment?.value?.id"
    [trackBy]="'id'"
    [emptyMessageDescription]="'Please update your filters and try again'"
    (dataBound)="onDataBound($event)"
  ></itq-virtual-table>
  @if (showFooter) {
    <footer class="flex p-4 border-t border-border">
      <itq-mat-button
        [matTooltip]="'Scroll to top'"
        [disabled]="
          count === 0 ||
          (timelineStatus === TaggingStatus.IN_PROGRESS && count === 0)
        "
        [padding]="Sizes.NONE"
        [type]="'button'"
        [materialStyle]="'stroked'"
        (mousedown)="scrollToTop()"
      >
        <fa-icon [icon]="['far', 'chevron-up']" [size]="'lg'"></fa-icon>
      </itq-mat-button>

      <itq-mat-button
        class="flex-1 ml-2"
        [size]="Sizes.BLOCK"
        [disabled]="
          count === 0 ||
          (timelineStatus === TaggingStatus.IN_PROGRESS && count === 0)
        "
        [type]="'button'"
        [materialStyle]="'flat'"
        [style]="'primary'"
        (mouseDownEvent)="onExplore()"
      >
        EXPLORE
      </itq-mat-button>
    </footer>
  }
}

<ng-template #template let-data>
  <itq-document-item-tile-v2
    [document]="data"
    [form]="form"
    [allowDrilldown]="allowDrilldown"
    (drilldown)="onDrilldown($event)"
    (createProfile)="onCreateProfile($event)"
    (addNote)="onAddNote($event)"
    (addConnection)="onAddConnection($event)"
  ></itq-document-item-tile-v2>
</ng-template>

import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AliasNote } from '@portal/profiles/models/alias-note';
import { Profile } from '@portal/profiles/models/profile';

@Component({
  selector: "itq-view-alias",
  templateUrl: "./view-alias.component.html",
  styleUrls: ["./view-alias.component.scss"],
})
export class ViewAliasComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: AliasNote;

  constructor() {}

  ngOnInit() {}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sections, TagCategory, TagCategoryId } from '@intorqa-ui/core';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import { ListSearchAction } from '../list-search/list-search.enum';

@Component({
  selector: 'itq-tags-boolean-selection',
  templateUrl: './tags-boolean-selection.component.html',
  styleUrls: ['./tags-boolean-selection.component.scss'],
})
export class TagsBooleanSelectionComponent implements OnInit {
  @Input() dataSource: Array<ICustomTag> = [];
  @Input() category: TagCategory;
  @Input() section: Sections;

  @Output() changeValue = new EventEmitter<ITagMetadata>();

  readonly ListSearchAction = ListSearchAction;
  readonly TagCategory = TagCategory;
  readonly TagCategoryId = TagCategoryId;

  constructor() {}

  ngOnInit() {}

  public onChange(event: MouseEvent, params: ITagMetadata): void {
    event.stopImmediatePropagation();
    this.changeValue.emit(params);
  }

  public trackByMethod(index: number): number {
    return index;
  }
}

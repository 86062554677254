<youtube-player
  *ngIf="videoId; else iframeTemplate"
  [videoId]="videoId"
></youtube-player>
<ng-template #iframeTemplate>
  <iframe
    *ngIf="sanitizedUrl"
    [src]="url"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>
</ng-template>

import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ApiConfigService } from '@intorqa-ui/api';
import { DocumentItem } from '../../shared/interfaces/document.interface';
import { slackTemplate } from '@portal/boards/enums/slack/document.const';

@Injectable({
  providedIn: 'root',
})
export class SlackService {
  constructor(
    @Inject(ApiConfigService) private config,
    private datePipe: DatePipe,
  ) {}

  async postSlackMessage(result: any, item: DocumentItem): Promise<any> {
    const attachment = this.generateAttachment(item);
    const form = new FormData();
    form.append('token', result.authed_user.access_token);
    form.append('channel', result.incoming_webhook.channel_id);
    form.append('attachments', attachment);
    const requestOptions: RequestInit = {
      method: 'POST',
      body: form,
    };
    return await fetch(
      'https://slack.com/api/chat.postMessage',
      requestOptions,
    );
  }

  async getSlackToken(code: string): Promise<Response> {
    const form = new FormData();
    form.append('client_id', this.config.slackConfig.clientId);
    form.append('client_secret', this.config.slackConfig.clientSecret);
    form.append('code', code);
    const requestOptions: RequestInit = {
      method: 'POST',
      body: form,
    };
    return await fetch('https://slack.com/api/oauth.v2.access', requestOptions);
  }

  public generateAttachment(document: DocumentItem): string {
    const documentAction = `https://${window.location.host}/document/${document.id}`;
    const documentHeadline = document.emitHeadline.replace(/\\n/g, '');
    const documentDate = this.datePipe.transform(
      document.emitDocumentDate?.toString(),
      'short',
    );
    const documentActor = document.emitActor
      ? ` by ${document.emitActor.replace(/\\n/g, '')}`
      : '';
    const documentSource = document.emitSource.replace(/\\n/g, '');
    const documentChannel = document.emitChannel.replace(/\\n/g, '');
    const documentBody =
      document.emitBodyText?.length > 300
        ? document.emitBodyText?.substring(0, 300) + '...'
        : document.emitBodyText || '';
    const apiHost = window.location.host;

    let template = JSON.stringify(slackTemplate);
    template = template.replace('DOCUMENT_HEADLINE', documentHeadline);
    template = template.replace('DOCUMENT_DATE', documentDate);
    template = template.replace('DOCUMENT_ACTOR', documentActor);
    template = template.replace('DOCUMENT_SOURCE', documentSource);
    template = template.replace('DOCUMENT_CHANNEL', documentChannel);
    template = template.replace('DOCUMENT_BODY', documentBody);
    template = template.replace('API_HOST', apiHost);
    template = template.replace('DOCUMENT_ACTION', documentAction);

    return template;
  }
}

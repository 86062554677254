import { IQueryDTO, TagCategory } from '@intorqa-ui/core';
import { cloneDeep } from 'lodash';
import { FilterTypes, TagTypes } from '../const/tag.const';
import {
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DTOQueryOperator,
} from '../../../../../core/src/lib/enums/query.enum';
import { QueryType } from '../enums/timeline-query.enum';
import {
  DTOQuery,
  DTOQueryCondition,
} from '../../../../../core/src/lib/interfaces/query-dtos';
import { ITagMetadata } from '../interfaces/tag.interface';
import { QueryRule } from './query-rule';

export class DTOQueryImpl implements DTOQuery {
  operator: DTOQueryOperator;
  conditions: Array<QueryRule> = [];
  queries: Array<DTOQuery> = [];
}

export class Query {
  public query = {
    condition: 'and',
    rules: [],
  };
  public type = QueryType.QUICK_SEARCH;
  public selections: Array<ITagMetadata> = [];

  constructor(
    rules?: Array<QueryRule>,
    type?: QueryType,
    selections?: Array<ITagMetadata>,
  ) {
    if (rules) {
      this.query.rules = rules;
    }
    if (type) {
      this.type = type;
    }
    if (selections) {
      this.selections = selections;
    }
  }

  public addQueryBuilderSelection(selection: ITagMetadata): void {
    if (selection.categoryName === TagCategory.content) {
      let contentSelection = this.selections.find(
        (item: ITagMetadata) => item.categoryName === selection.categoryName,
      );
      if (contentSelection) {
        this.selections = this.selections.map((item: ITagMetadata) => {
          return item.categoryName === contentSelection.categoryName
            ? selection
            : item;
        });
      } else {
        this.selections.push(selection);
      }
    } else {
      this.selections.push(selection);
    }
    this.mapQueryBuilderMetadata();
  }

  public addSelection(selection: ITagMetadata): void {
    if (selection.categoryName === TagCategory.content) {
      let contentSelection = this.selections.find(
        (item: ITagMetadata) => item.categoryName === selection.categoryName,
      );
      if (contentSelection) {
        this.selections = this.selections.map((item: ITagMetadata) => {
          return item.categoryName === contentSelection.categoryName
            ? selection
            : item;
        });
      } else {
        this.selections.push(selection);
      }
    } else {
      this.selections.push(selection);
    }
    this.mapSelectionsToRules();
  }

  public removeSelection(selection: ITagMetadata): void {
    if (selection.included) {
      if (selection.categoryName === TagCategory.content) {
        this.selections = this.selections.filter((item: ITagMetadata) => {
          return item.categoryName === selection.categoryName ? false : true;
        });
      } else {
        this.selections = this.selections.filter((item: ITagMetadata) => {
          return item.categoryName === selection.categoryName &&
            item.included === selection.included &&
            item.tagId === selection.tagId
            ? false
            : true;
        });
      }
    } else {
      this.selections = this.selections.filter((item: ITagMetadata) => {
        return item.categoryName === selection.categoryName &&
          item.excluded === selection.excluded &&
          item.tagId === selection.tagId
          ? false
          : true;
      });
    }
    this.mapSelectionsToRules();
  }

  public removeQueryBuilderSelection(selection: ITagMetadata): void {
    if (selection.included) {
      if (selection.categoryName === TagCategory.content) {
        this.selections = this.selections.filter((item: ITagMetadata) => {
          return item.categoryName === selection.categoryName ? false : true;
        });
      } else {
        this.selections = this.selections.filter((item: ITagMetadata) => {
          return item.categoryName === selection.categoryName &&
            item.included === selection.included &&
            item.tagId === selection.tagId
            ? false
            : true;
        });
      }
    } else {
      this.selections = this.selections.filter((item: ITagMetadata) => {
        return item.categoryName === selection.categoryName &&
          item.excluded === selection.excluded &&
          item.tagId === selection.tagId
          ? false
          : true;
      });
    }
    this.mapQueryBuilderMetadata();
  }

  private mapSelectionsToRules(): void {
    this.query.rules = [];
    const groups = this.selections.reduce(
      (groups, item) => ({
        ...groups,
        [item.categoryName]: [...(groups[item.categoryName] || []), item],
      }),
      {},
    );

    Object.keys(groups).forEach((item: TagCategory) => {
      let included = groups[item].filter(
        (selection: ITagMetadata) => selection.included,
      );
      let excluded = groups[item].filter(
        (selection: ITagMetadata) => selection.excluded,
      );
      const includedItems = included?.map(
        (selection: ITagMetadata) => selection.tagId,
      );
      const excludedItems = excluded?.map(
        (selection: ITagMetadata) => selection.tagId,
      );
      if (includedItems?.length > 0) {
        if (item === TagCategory.content) {
          this.addRule(
            new QueryRule(
              DTOQueryFieldType.content,
              DTOQueryConditionOperator.in,
              includedItems,
            ),
          );
        } else {
          this.addRule(
            new QueryRule(
              FilterTypes.includes(item)
                ? DTOQueryFieldType.filter
                : DTOQueryFieldType.tag,
              DTOQueryConditionOperator.in,
              includedItems,
            ),
          );
        }
      }
      if (excludedItems?.length > 0) {
        this.addRule(
          new QueryRule(
            FilterTypes.includes(item)
              ? DTOQueryFieldType.filter
              : DTOQueryFieldType.tag,
            DTOQueryConditionOperator.notin,
            excludedItems,
          ),
        );
      }
    });
  }

  public mapQueryBuilderMetadata(): void {
    const groups = this.selections.reduce(
      (groups, item) => ({
        ...groups,
        [item.categoryName]: [...(groups[item.categoryName] || []), item],
      }),
      {},
    );

    Object.keys(groups).forEach(() => {
      this.getRules().forEach((item: QueryRule) => {
        if (Array.isArray(item.value)) {
          item.value = item.value?.map((value: string) => {
            const selection = this.selections.find(
              (selection: ITagMetadata) =>
                (selection.tagId === value &&
                  item.operator === DTOQueryConditionOperator.in &&
                  selection.included) ||
                (selection.tagId === value &&
                  item.operator === DTOQueryConditionOperator.notin &&
                  selection.excluded),
            );
            return selection.tagId;
          });
        }
      });
    });
  }

  public reverseMapQueryBuilderMetadata(): void {
    this.getNestedMapQueryBuilderMetadata(this.query.rules);
  }

  public getNestedMapQueryBuilderMetadata(rules: Array<any>): void {
    rules.forEach((item: any) => {
      if (item.condition) {
        this.getNestedMapQueryBuilderMetadata(item.rules);
      } else {
        if (Array.isArray(item.value)) {
          item.value = item.value?.map((value: string) => {
            const selection = this.selections.find(
              (selection: ITagMetadata) =>
                (selection.tagId === value &&
                  item.operator === DTOQueryConditionOperator.in &&
                  selection.included) ||
                (selection.tagId === value &&
                  item.operator === DTOQueryConditionOperator.notin &&
                  selection.excluded) ||
                (selection.tagId === value &&
                  selection.categoryName === TagCategory.content),
            );
            if (item.field === DTOQueryFieldType.content) {
              return selection.tagId;
            } else {
              item.field = selection.categoryName;
              return {
                name: selection?.tagName,
                id: selection?.tagId,
                type: undefined,
                count: undefined,
              };
            }
          });
        }
      }
    });
  }

  public getRules(): Array<QueryRule> {
    return this.query.rules;
  }

  public hasRules(): boolean {
    const model = this.modelToDTO();
    return this.hasQueries(model);
  }

  private hasQueries(model: any): boolean {
    let result = false;
    if (model.queries?.length > 0) {
      model.queries.forEach((query: any) => {
        if (!result) {
          result = this.hasQueries(query);
        }
      });
    }
    if (result) {
      return result;
    } else {
      return model.conditions?.length > 0 ? true : false;
    }
  }

  public addRule(rule: QueryRule): void {
    this.query.rules.push(rule);
  }

  public clear(): void {
    this.query.rules = [];
    this.selections = [];
  }

  public getRuleByValue(value: string): QueryRule {
    return this.getRules()?.find((item: QueryRule) =>
      item.value.includes(value),
    );
  }

  public getRuleValueByField(field: DTOQueryFieldType): QueryRule {
    return this.getRules()?.find((item: QueryRule) => item.field === field);
  }

  public getActors(): Array<QueryRule> {
    return this.getRules()?.filter((item: QueryRule) => {
      return item.value[0].toString().indexOf('Actor') > -1;
    });
  }

  public getChannels(): Array<QueryRule> {
    return this.getRules()?.filter((item: QueryRule) => {
      return item.value[0].toString().indexOf('Channel') > -1;
    });
  }

  public getSources(): Array<QueryRule> {
    return this.getRules()?.filter((item: QueryRule) => {
      return item.value[0].toString().indexOf('Source') > -1;
    });
  }

  public removeActors(): void {
    this.query.rules = this.getRules()?.filter((item: QueryRule) => {
      return item.value[0].toString().indexOf('Actor') === -1;
    });
  }

  public removeChannels(): void {
    this.query.rules = this.getRules()?.filter((item: QueryRule) => {
      return item.value[0].toString().indexOf('Channel') === -1;
    });
  }

  public filterRules(rules: Array<string>): Array<QueryRule> {
    let result = cloneDeep(this.query.rules);
    rules.forEach((item: string) => {
      if (item === 'query') {
        result = result.filter((item: QueryRule) => {
          return item.field !== DTOQueryFieldType.content;
        });
      }
      if (item === 'bookmarks') {
        result = result.filter((item: QueryRule) => {
          return (item.value[0] as string).indexOf('bookmarks') === -1;
        });
      }
    });
    return result;
  }

  public modelToDTO(): DTOQuery {
    if (!this.query) {
      console.error('Model cannot/should not be null');
      return null;
    }
    const result = new DTOQueryImpl();
    if (this.query.condition && this.query.condition.toLowerCase() === 'and') {
      result.operator = DTOQueryOperator.and;
    } else if (
      this.query.condition &&
      this.query.condition.toLowerCase() === 'or'
    ) {
      result.operator = DTOQueryOperator.or;
    }
    if (this.query.rules) {
      this.query.rules.forEach((rule: any) => {
        if (rule['condition']) {
          const rules = rule.rules.map((item: any) => {
            if (item.condition) {
              return item;
            } else {
              return new QueryRule(item.field, item.operator, item.value);
            }
          });
          const query = new Query(rules, QueryType.QUERY_BUILDER, undefined);
          query.query.condition = rule['condition'];
          const model = query.modelToDTO();
          if (model.conditions?.length > 0 || model.queries?.length > 0) {
            result.queries.push(model);
          }
        } else {
          let field = DTOQueryFieldType[rule['field'] + ''];
          if (!field) {
            if (TagTypes.includes(rule['field'])) {
              field = DTOQueryFieldType.tag;
            } else if (FilterTypes.includes(rule['field'])) {
              field = DTOQueryFieldType.filter;
            } else {
              field = DTOQueryFieldType.tag;
            }
          }
          const operator = DTOQueryConditionOperator[rule['operator']];
          const value = rule['value'];
          const values = [];
          if (value) {
            if (Array.isArray(value)) {
              value.forEach((item) => {
                if (typeof item === 'string') {
                  values.push('' + item);
                } else {
                  values.push('' + item[0]);
                }
              });
            } else {
              if (value.length > 0) {
                values.push('' + value);
              }
            }
            const queryCondition = new QueryRule(field, operator, values);
            if (
              (Array.isArray(queryCondition.value) &&
                queryCondition.value.length > 0) ||
              !Array.isArray(queryCondition.value)
            ) {
              result.conditions.push(queryCondition);
            }
          }
        }
      });
    }

    return result;
  }

  public dtoToModel(query: DTOQuery): IQueryDTO {
    let queryDTO: IQueryDTO = {
      condition: query.operator,
      rules: query.conditions?.map((item: any) => {
        if (item.field === DTOQueryFieldType.content) {
          return {
            field: item.field,
            operator: item.operator,
            value: item.value[0],
          };
        } else {
          return item;
        }
      }),
    };
    if (query.queries?.length > 0) {
      queryDTO.rules = [
        ...queryDTO.rules,
        ...this.getNestedQueries(query.queries),
      ];
    }
    return queryDTO;
  }

  private getNestedQueries(
    query: Array<DTOQuery>,
  ): Array<IQueryDTO | QueryRule | DTOQueryCondition> {
    return query.map((item: DTOQuery) => {
      let queryDTO: IQueryDTO = {
        condition: item.operator,
        rules: item.conditions,
      };
      if (item.queries?.length > 0) {
        queryDTO.rules = [
          ...queryDTO.rules,
          ...this.getNestedQueries(item.queries),
        ];
      }
      return queryDTO;
    });
  }

  public cloneDeep(): Query {
    const rules: Array<QueryRule> = [];
    this.getRules()?.forEach((item: any) => {
      if (item.condition) {
        rules.push(item);
      } else {
        rules.push(
          new QueryRule(
            structuredClone(item).field,
            structuredClone(item.operator),
            structuredClone(item.value),
          ),
        );
      }
    });
    const query = new Query(
      rules,
      cloneDeep(this.type),
      cloneDeep(this.selections),
    );
    query.query.condition = this.query.condition;
    return query;
  }

  public isValid(): boolean {
    let isValid = true;
    this.getRules()?.forEach((rule: QueryRule) => {
      if (!rule.field || !rule.operator || !rule.value[0]) {
        isValid = false;
      }
    });
    return isValid;
  }

  public getTagIds(): { included: Array<string>; excluded: Array<string> } {
    let tagIds = { included: [], excluded: [] };
    tagIds = this.getIds(this.getRules());

    return tagIds;
  }

  private getIds(rules: Array<any>): {
    included: Array<string>;
    excluded: Array<string>;
  } {
    let tagIds = { included: [], excluded: [] };
    rules.forEach((item: any) => {
      if (item.condition) {
        const result = this.getIds(item.rules);
        tagIds.included = [...tagIds.included, ...result.included];
        tagIds.excluded = [...tagIds.excluded, ...result.excluded];
      } else {
        if (
          item.field === DTOQueryFieldType.tag ||
          (!FilterTypes.includes(item.field) &&
            item.field !== DTOQueryFieldType.content)
        ) {
          item.value.forEach((value: string) => {
            if (item.operator === DTOQueryConditionOperator.in) {
              tagIds.included.push(value);
            } else {
              tagIds.excluded.push(value);
            }
          });
        }
      }
    });
    return tagIds;
  }

  public getContentTag(): { included: Array<string>; excluded: Array<string> } {
    let tagIds = { included: [], excluded: [] };
    tagIds = this.getContentTagIds(this.getRules());
    return tagIds;
  }

  private getContentTagIds(rules: Array<any>): {
    included: Array<string>;
    excluded: Array<string>;
  } {
    let tagIds = { included: [], excluded: [] };
    rules.forEach((item: any) => {
      if (item.condition) {
        const result = this.getContentTagIds(item.rules);
        tagIds.included = [...tagIds.included, ...result.included];
        tagIds.excluded = [...tagIds.excluded, ...result.excluded];
      } else {
        if (item.field == DTOQueryFieldType.content) {
          tagIds.included.push(
            Array.isArray(item.value) ? item.value[0] : item.value,
          );
        }
      }
    });
    return tagIds;
  }

  public getFieldFilterTagIds(): {
    included: Array<string>;
    excluded: Array<string>;
  } {
    let tagIds = { included: [], excluded: [] };
    tagIds = this.getFilterTagIds(this.getRules());
    return tagIds;
  }

  public getFilterTagIds(rules: Array<any>): {
    included: Array<string>;
    excluded: Array<string>;
  } {
    let tagIds = { included: [], excluded: [] };
    rules.forEach((item: any) => {
      if (item.condition) {
        const result = this.getFilterTagIds(item.rules);
        tagIds.included = [...tagIds.included, ...result.included];
        tagIds.excluded = [...tagIds.excluded, ...result.excluded];
      } else {
        if (
          item.field === DTOQueryFieldType.filter ||
          FilterTypes.includes(item.field)
        ) {
          item.value.forEach((value: string) => {
            const splittedValue = value.split(':');
            if (FilterTypes.includes(splittedValue[2])) {
              if (item.operator === DTOQueryConditionOperator.in) {
                tagIds.included.push(value);
              } else {
                tagIds.excluded.push(value);
              }
            }
          });
        }
      }
    });
    return tagIds;
  }
}

export enum OpenStatus {
  CLOSE = "close",
  OPEN = "open",
}

export enum Styles {
  FIT = "fit",
  AUTO = "auto",
}

export enum OpenDirection {
  UP = "up",
  DOWN = "down",
}

import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "itq-youtube-player",
  templateUrl: "./youtube-player.component.html",
  styleUrls: ["./youtube-player.component.scss"],
})
export class YoutubePlayerComponent implements OnInit {
  public videoId: string;
  public sanitizedUrl: SafeResourceUrl;

  @Input() url: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const params = new URL(this.url).searchParams;
    this.videoId = params.get("v");
    if (!this.videoId) {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        this.url
      );
    }
  }
}

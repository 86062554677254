import { TOPIC_CLUSTERS_CHART_TYPES } from '@portal/boards/const/topic-clusters.const';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { AnalysisTypes } from '@portal/shared/enums/widget.enum';
import {
  IDisplayType,
  ITopicClustering,
} from '@portal/shared/interfaces/widget.interface';
import { Widget } from './widget';

export class TopicClustering extends Widget implements ITopicClustering {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public dataSource: Array<string>,
    public chartType: ChartType,
    public ecosystemId: string,
    public updatedDate: number,
    public options: { [key: string]: any },
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return TOPIC_CLUSTERS_CHART_TYPES;
  }

  public getMinWidth(): number {
    return 25;
  }

  public getMinHeight(): number {
    return 5;
  }

  public generateGetDocsState(params: { [key: string]: any }): any {
    return {
      pageSize: 30,
      page: params.initialState?.page || 1,
      where: params.initialState?.where,
    };
  }

  public getDisplayType(): IDisplayType {
    return TOPIC_CLUSTERS_CHART_TYPES.find(
      (displayType: IDisplayType) =>
        displayType.type === this.chartType &&
        this.options.layout === displayType.options.layout,
    );
  }

  public hasMetrics(): boolean {
    return this.dataSource?.length > 0 ? true : false;
  }

  public cloneDeep(): TopicClustering {
    return new TopicClustering(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.width,
      this.height,
      this.x,
      this.y,
      this.dataSource,
      this.chartType,
      this.ecosystemId,
      this.updatedDate,
      this.options,
    );
  }
}

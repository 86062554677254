import { VirtualScrollService } from '../../../services/virtual-scrolling.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { QueryFilters } from '../../../models/query-filters';
import { CoreModule, FAwesomeModule } from '@intorqa-ui/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'itq-dropdown-results-v2',
  templateUrl: './dropdown-results-v2.component.html',
  styleUrls: ['./dropdown-results-v2.component.scss'],
  standalone: true,
  imports: [CoreModule, CommonModule, FormsModule, FAwesomeModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownResultsV2Component,
    },
  ],
})
export class DropdownResultsV2Component implements OnInit, OnDestroy {
  @Input() emptyDataSourceMessage = 'No results found';
  @Input() direction = 'down';
  @Input() dataSource: Array<any>;
  @Input() dataFields: { name: string; value: string };
  @Input() showHeader = true;
  @Input() selection: any;
  @Input() initialState: QueryFilters;
  @Input() httpBinding = false;

  @Output() changeValue = new EventEmitter<any>();
  @Output() dataBound = new EventEmitter<QueryFilters>();

  @ViewChild('inputControl') inputControl: ElementRef;

  private keyupSubscription: Subscription;
  public disabled = false;
  private touched = false;
  public query: string;
  public scrollTop = 0;
  private dataSourceCopy: Array<any>;
  public dataBoundSubscription: Subscription;

  @Input() showLoader: boolean;

  @ViewChild('listSearch') listSearch: any;

  constructor(private virtualScrollService: VirtualScrollService) {}

  onChange = (items: any) => {};

  onTouched = () => {};

  writeValue(items: any): void {
    this.selection = items;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnInit(): void {
    this.dataSourceCopy = structuredClone(this.dataSource);
    this.dataBoundSubscription =
      this.virtualScrollService.dataBoundObservable.subscribe(() => {
        this.scrollTop = 0;
        this.listSearch?.nativeElement?.scrollTo(0, 0);
      });
  }

  ngOnDestroy(): void {
    this.keyupSubscription?.unsubscribe();
    this.dataBoundSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.dataSource?.previousValue !== changes?.dataSource?.currentValue
    ) {
      this.showLoader = false;
    }
  }

  public onChangeValue(item: any): void {
    this.selection = item;
    this.onChange(this.selection);
    this.changeValue.emit(item);
  }

  public onDataBound(): void {
    if (this.httpBinding) {
      this.showLoader = true;
      this.initialState.resetPagination().then(() => {
        this.virtualScrollService.dataBoundObservable.next();
      });
      const params = this.initialState.cloneDeep();
      params.query = this.query;
      this.dataBound.emit(params);
    } else {
      const value = this.query?.toLowerCase().trim();
      if (!value || value === '') {
        this.dataSource = cloneDeep(this.dataSourceCopy);
      } else {
        this.dataSource = cloneDeep(this.dataSourceCopy).filter((elem: any) => {
          return elem[this.dataFields.name]
            ?.toLowerCase()
            .trim()
            .includes(value);
        });
      }
    }
  }

  public onScroll(): void {
    if (this.httpBinding) {
      this.virtualScrollService
        .scrollDown(
          this.listSearch.nativeElement,
          this.initialState.pageSize,
          this.dataSource.length,
          this.scrollTop,
          this.showLoader,
          this.initialState.page,
        )
        .then((response: { scroll: boolean; scrollTop: number }) => {
          if (response.scroll) {
            this.initialState.page += 1;
            this.scrollTop = response.scrollTop;
            this.showLoader = true;
            this.dataBound.emit(this.initialState);
          }
        });
    }
  }

  public onClick(event: MouseEvent, item: any): void {
    event.stopPropagation();
    if (this.httpBinding) {
      this.changeValue.emit(item);
    } else {
      this.changeValue.emit(item[this.dataFields.value] || item);
    }
  }
}

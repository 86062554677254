<form
  class="p-7 flex flex-col h-full bg-background"
  (ngSubmit)="form.valid && onSubmit()"
  [formGroup]="form"
>
  <div class="flex flex-col flex-1">
    <div class="flex flex-col pb-7">
      <div class="flex items-center">
        <fa-icon [size]="'3x'" [icon]="['far', 'envelope']"></fa-icon>
        <span class="text-3xl pl-2 font-semibold">Share by email</span>
      </div>
      <p class="italic pt-2">
        Please add recipient details and custom message to share your items
      </p>
    </div>

    <div class="flex flex-col mb-4">
      <span class="mb-1 text-13 font-medium">EMAIL</span>
      <itq-input
        formControlName="email"
        [placeholder]="'Please enter your email address'"
      ></itq-input>
      @if (
        form.controls['email'].touched &&
        form.controls['email'].hasError('required')
      ) {
        <mat-error> Email address is <strong>required</strong> </mat-error>
      }
      @if (
        form.controls['email'].touched &&
        form.controls['email'].hasError('email')
      ) {
        <mat-error> Email address is <strong>invalid</strong> </mat-error>
      }
    </div>

    <div class="flex flex-col mb-4">
      <span class="mb-1 text-13 font-medium">MESSAGE</span>
      <quill-editor
        #Editor
        [styles]="{ height: '200px' }"
        formControlName="content"
      ></quill-editor>
    </div>
    @if (document) {
      <div class="flex flex-col mb-4">
        <span class="mb-1 text-13 font-medium">DOCUMENT</span>
        <itq-document-item-tile
          [allowDrilldown]="false"
          [item]="document"
          [shareByEmail]="false"
        >
        </itq-document-item-tile>
      </div>
    }
  </div>
  <footer class="flex justify-end">
    <itq-mat-button
      [type]="'submit'"
      [style]="'primary'"
      [materialStyle]="'flat'"
      [disabled]="!form.valid"
    >
      SEND
    </itq-mat-button>
  </footer>
</form>
@if (showLoader) {
  <itq-loader></itq-loader>
}

import { IUserPreferences } from "../interfaces/user-preferences.interface";

export class UserPreferences implements IUserPreferences {
  constructor(
    public defaultEcosystemId: string,
    public id: string,
    public organisationId: string,
    public username: string
  ) {}
}

@if (showHeader) {
  <div
    class="px-2 py-1 search__container border-b border-border"
    (mousedown)="$event.stopImmediatePropagation()"
  >
    <itq-input
      class="w-full"
      #inputControl
      [(ngModel)]="query"
      [placeholder]="'Search...'"
      (search)="onDataBound()"
      (clear)="(true)"
    ></itq-input>
  </div>
}

<div class="dropdown-container w-full bg-white overflow-auto">
  @if (dataSource?.length > 0) {
    <ul (scroll)="onScroll()" #listSearch class="overflow-auto max-h-[253px]">
      @for (item of dataSource; track item[dataFields.value]) {
        <li
          (click)="onClick($event, item)"
          [ngClass]="{
            'bg-background text-primary':
              item[this.dataFields.value] === selection,
            'hover:bg-background flex w-full border-b border-border items-center justify-between': true
          }"
        >
          <div class="flex">
            @if (item?.icon) {
              <fa-icon [icon]="item.icon" class="pr-2"></fa-icon>
            }
            {{ item[dataFields?.name] }}
          </div>
          @if (item.template) {
            <ng-container
              *ngTemplateOutlet="item.template; context: { $implicit: item }"
            ></ng-container>
          }
        </li>
      }
    </ul>
  } @else {
    @if (dataSource?.length === 0) {
      <span
        class="font-semibold italic text-sm text-gray-700 m-2 flex items-center"
      >
        <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
        {{ emptyDataSourceMessage }}
      </span>
    } @else {
      <itq-loader class="p-4"></itq-loader>
    }
  }
</div>
@if (showLoader) {
  <itq-loader class="p-4"></itq-loader>
}

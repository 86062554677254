import { Pipe, PipeTransform } from "@angular/core";
import { Sections } from "../enums/category.enum";
import { Category } from "../models/category";
import { Section } from "../models/section";

@Pipe({
  name: "getSections",
})
export class GetSectionsPipe implements PipeTransform {
  constructor() {}

  transform(categories: Array<Category>): Array<Section> {
    const sectionsOrder = [
      Sections["My Tags"],
      Sections["Communities and Sources"],
      Sections.Classifiers,
    ];
    let groupedCategories = categories.reduce((groups, item) => {
      const group = groups[item.section] || [];
      group.push(item);
      groups[item.section] = group;
      return groups;
    }, {});
    const sections = Object.keys(groupedCategories).map(
      (key: Sections) => new Section(key, key, groupedCategories[key])
    );
    let result: Array<Section> = [];
    sectionsOrder.forEach((item: Sections) => {
      const targetSection = sections.find(
        (section: Section) => section.name === item
      );
      if (targetSection) {
        result.push(targetSection);
      }
    });
    return result;
  }
}

import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { CoreModule, DynamicPlaceholderDirective } from '@intorqa-ui/core';
import { Board } from '@portal/boards/models/board';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';

@Component({
  selector: 'itq-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  standalone: true,
  imports: [CoreModule],
})
export class WidgetComponent implements OnInit {
  @Input() widget: Widget;
  @Input() board: Board;

  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  constructor(private injector: Injector) {}

  ngOnInit() {
    this.createComponent();
  }

  private createComponent(): void {
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const dynamicComponent = WidgetFactory.getComponent(this.widget.type);
    const componentRef = viewContainerRef.createComponent(dynamicComponent, {
      injector: this.injector,
    });
    componentRef.instance.widget = this.widget;
    componentRef.instance.board = this.board;
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  Align,
  IPreset,
  QueryFilters,
  SharedService,
  Sizes,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { ToolbarActions, ViewMode } from './toolbar.enum';
import { IToolbarAction } from './toolbar.interface';
import { FindActionPipe } from './toolbar.pipe';

@Component({
  selector: 'itq-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnChanges {
  @Output() toggleView = new EventEmitter<any>();
  @Output() search = new EventEmitter<QueryFilters>();
  @Output() refresh = new EventEmitter<void>();
  @Output() save = new EventEmitter();
  @Output() export = new EventEmitter();
  @Output() showFilters = new EventEmitter();
  @Output() resetFilters = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() showApi = new EventEmitter();
  @Output() changeDisplayType = new EventEmitter<any>();

  @Input() actions: Array<IToolbarAction>;
  @Input() widget: any;
  @Input() filtersApplied: boolean;
  @Input() viewMode: ViewMode;
  @Input() query: string;
  @Input() count: number;
  @Input() form: FormGroup;
  @Input() initialState: QueryFilters;
  @Input() selectedDisplayType: any;
  @Input() displayTypes: Array<IDisplayType>;
  @Input() showTooltip = false;

  readonly ViewMode = ViewMode;
  readonly Sizes = Sizes;
  readonly ToolbarActions = ToolbarActions;
  readonly Align = Align;

  constructor(
    public sharedService: SharedService,
    private findActionPipe: FindActionPipe,
    private virtualScrollService: VirtualScrollService,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.actions?.previousValue !== changes?.actions?.currentValue) {
      const searchAction = this.findActionPipe.transform(
        this.actions,
        ToolbarActions.SEARCH,
      );
      if (searchAction) {
        this.form?.controls?.searchTerm?.setValue(searchAction?.data);
      }
    }
  }

  private createForm(): void {
    const searchAction = this.findActionPipe.transform(
      this.actions,
      ToolbarActions.SEARCH,
    );
    if (searchAction) {
      this.form.addControl('searchTerm', new FormControl(searchAction.data));
    }
  }

  public onToggleView(view: ViewMode): void {
    this.toggleView.emit(view);
  }

  public onExport(): void {
    this.export.emit();
  }

  public onSearchQuery(): void {
    const initialState = this.initialState.cloneDeep();
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });
    initialState.query = this.form?.controls?.searchTerm?.value;
    this.search.emit(initialState);
  }

  public onSearch(dates: IPreset): void {
    const initialState = this.initialState.cloneDeep();
    this.initialState.resetPagination().then(() => {
      this.virtualScrollService.dataBoundObservable.next();
    });
    this.search.emit(initialState);
  }

  public onRefresh(): void {
    this.refresh.emit();
  }

  public onResetFilters(): void {
    this.filtersApplied = false;
    this.form.controls.searchTerm.setValue(undefined);

    const searchAction = this.findActionPipe.transform(
      this.actions,
      ToolbarActions.SEARCH,
    );
    if (searchAction) {
      searchAction.expanded = false;
      this.query = undefined;
      this.form.controls.searchTerm.setValue(this.query);
    }
    this.resetFilters.emit();
  }

  public onAdd(): void {
    this.add.emit();
  }

  public onShowApi(): void {
    this.showApi.emit();
  }

  public onChangeDisplay(params: IDisplayType): void {
    this.changeDisplayType.emit(params);
  }
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  Align,
  CoreModule,
  CustomOverlayRef,
  CustomOverlayService,
  CustomOverlayType,
  DialogComponent,
  DialogTypes,
  FAwesomeModule,
  IQueryColumn,
  QueryFilters,
  SearchFieldType,
  SharedService,
  Sizes,
  TagCategory,
  Utils,
} from '@intorqa-ui/core';
import { WidgetService } from '@portal/boards/services/widget.service';
import { NotificationsService } from '@portal/notifications/services/notifications.service';
import { SlackService } from '@portal/notifications/services/slack.service';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { IAddConnection } from '@portal/profiles/interfaces/profile-connection.interface';
import { IAddNote } from '@portal/profiles/interfaces/profile-note.interface';
import { IProfileData } from '@portal/profiles/interfaces/profile.interface';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileConnection } from '@portal/profiles/models/profile-connection';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { GetProfileTypeIconPipe } from '@portal/profiles/pipes/vendors.pipe';
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { DateFormats } from '@portal/shared/enums/document.enum';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import {
  DocumentItem,
  IContentLink,
  IExtensionField,
} from '@portal/shared/interfaces/document.interface';
import { DocumentItemService } from '@portal/shared/services/document-item.service';
import { UserService } from '@portal/shared/services/user.service';
import { SharedModule } from '@portal/shared/shared.module';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { KeycloakService } from 'keycloak-angular';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentExtensionFieldsComponent } from '../document-extension-fields/document-extension-fields.component';
import { ShareEmailComponent } from '../share-email/share-email.component';

@Component({
  selector: 'itq-document-item-tile-v2',
  templateUrl: './document-item-tile-v2.component.html',
  styleUrls: ['./document-item-tile-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CoreModule,
    SharedModule,
    FAwesomeModule,
    MatTooltipModule,
    CommonModule,
    GetProfileTypeIconPipe,
    DocumentExtensionFieldsComponent,
  ],
})
export class DocumentItemTileV2Component implements OnInit {
  @Input() document: DocumentItem;
  @Input() query: string | IQueryColumn[];
  @Input() allowDrilldown = true;
  // @Input() showReplies = false;
  @Input() profile: Profile;
  @Input() form: FormGroup;
  @Input() shareByEmail = true;
  @Input() navigationItem: NavigationHistoryItem;

  @Output() drilldown = new EventEmitter<ISegment>();
  @Output() createProfile = new EventEmitter<ProfileDrilldown>();
  @Output() addNote = new EventEmitter<IAddNote>();
  @Output() addConnection = new EventEmitter<IAddConnection>();

  public customOverlay: CustomOverlayRef;
  // public repliesCount: number;
  public isExpanded = false;
  public showNestedNavbar = false;
  public showListProfile = false;
  public profileTypesDataSource: Array<ProfileType> = [];
  public profilesDataSource: Array<Profile>;
  public profileInitialState: QueryFilters;
  public isLoadingProfiles = false;
  public contentLinks: Array<IContentLink> = [];
  private subscription = new Subscription();
  public translatedContent: string;
  public translatedTitle: string;

  readonly SegmentScope = SegmentScope;
  readonly ProfileDrildownScope = ProfileDrildownScope;
  readonly Sizes = Sizes;
  readonly Align = Align;
  readonly TagCategory = TagCategory;
  readonly DateFormats = DateFormats;

  @ViewChild('itemdetailcontainer', { static: true }) container: ElementRef;

  constructor(
    public customOverlayService: CustomOverlayService,
    private documentService: DocumentItemService,
    public widgetService: WidgetService,
    private profileService: ProfileService,
    private linkTagsService: LinkTagsService,
    private snackBar: MatSnackBar,
    public keycloakService: KeycloakService,
    readonly slackService: SlackService,
    readonly notificationService: NotificationsService,
    readonly _snackBar: MatSnackBar,
    readonly sharedService: SharedService,
    readonly userService: UserService,
    readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.bindToggleDocumentSubscription();
    this.contentLinks = this.getContentLinks();
    this.profileTypesDataSource = this.profileService.types;
    // if (this.showReplies) {
    //   this.getRepliesCount();
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.document?.currentValue?.id !==
      changes?.document?.previousValue?.id
    ) {
      this.contentLinks = this.getContentLinks();
      this.translatedContent = undefined;
      this.translatedTitle = undefined;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.translatedTitle = undefined;
    this.translatedContent = undefined;
  }

  private bindToggleDocumentSubscription(): void {
    this.subscription.add(
      this.documentService.toggleDocument$.subscribe(
        (response: { documentId: string; isExpanded: boolean }) => {
          if (response?.documentId !== this.document.id) {
            this.isExpanded = response.isExpanded;
          }
        },
      ),
    );
  }

  public getContentLinks(): Array<IContentLink> {
    let contentLinks: any;
    if (this.document?.emitExtensionFields?.length > 0) {
      if (this.document.emitSource === 'Discord') {
        contentLinks = this.document?.emitExtensionFields.find(
          (extensionField: any) => {
            if (extensionField.name === 'contentLinks') {
              return extensionField;
            }
          },
        );
        return contentLinks?.data?.map((item: { url: string }) => ({
          href: item.url,
        }));
      } else {
        contentLinks = this.document?.emitExtensionFields.find(
          (extensionField: any) => {
            if (extensionField['content links']?.length > 0) {
              return extensionField;
            }
          },
        );
        if (contentLinks && contentLinks['content links']) {
          let uniqueHrefs: Array<string> = [];
          return contentLinks['content links'].filter((link: IContentLink) => {
            if (link.href?.length > 0) {
              if (!uniqueHrefs.includes(link.href.trim())) {
                uniqueHrefs.push(link.href);
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        }
      }
    }
    return [];
  }

  // public getRepliesCount(): void {
  //   this.documentService
  //     .getRepliesCount(this.document)
  //     .then((response: number) => {
  //       this.repliesCount = response;
  //       this.cdr.detectChanges();
  //     });
  // }

  public onShareEmail(event: MouseEvent, document: DocumentItem): void {
    event.stopImmediatePropagation();

    if (this.shareByEmail) {
      this.customOverlayService.open({
        data: {
          item: document,
        },
        type: CustomOverlayType['slide-right'],
        component: ShareEmailComponent,
        disposeOnNavigation: true,
      });
    }
  }

  shareSlack(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.customOverlayService.openCustom(
      {
        title: 'Share to slack?',
        message: `Are you sure you want to share the document to slack?`,
        icon: ['far', 'question-circle'],
        size: '4x',
        dialog: {
          type: DialogTypes.CONFIRM,
        },
      },
      DialogComponent,
      (result: boolean) => {
        if (result === true) {
          window.localStorage.setItem(
            'document',
            JSON.stringify(this.document),
          );
          const attachment = this.slackService.generateAttachment(
            this.document,
          );
          this.notificationService
            .postSlackMessage(attachment)
            .subscribe(() => {
              this._snackBar.open('Document shared succssefully!', 'Close', {
                horizontalPosition: 'right',
                duration: 5000,
                verticalPosition: 'top',
              });
            });
        }
      },
    );
  }

  public getDocumentDate(dateItem: any): any {
    if (typeof dateItem === 'number') {
      return new Date(dateItem);
    } else if (typeof dateItem === 'string') {
      return new Date(dateItem);
    } else if (dateItem) {
      return dateItem;
    } else {
      return null;
    }
  }

  public onTranslate(): void {
    this.documentService
      .detectLanguage(this.document.emitBodyText)
      .then((response) => response.json())
      .then((result: any) => {
        if (result?.data?.detections?.length > 0) {
          forkJoin([
            this.translate(
              result.data.detections[0][0].language,
              this.document.emitBodyText,
            ),
            this.translate(
              result.data.detections[0][0].language,
              this.document.emitHeadline,
            ),
          ]).subscribe({
            next: (response: Array<string>) => {
              this.translatedTitle = response[0];
              this.translatedContent = response[1];
              this.cdr.detectChanges();
            },
            error: (error) => {
              this.notificationService.loader$.next(false);
              this.snackBar.open(
                'Translation failed. Please try again.',
                'Close',
                {
                  horizontalPosition: 'right',
                  duration: 5000,
                  verticalPosition: 'top',
                },
              );
            },
          });
        }
      })
      .catch((error) => console.log('error', error));
  }

  private translate(lang: string, text: string): Observable<string> {
    return new Observable((observer) => {
      this.documentService
        .translate(text, lang)
        .then((response) => response.json())
        .then((response: any) => {
          observer.next(response.data.translations[0].translatedText);
          observer.complete();
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  public onExpand(): void {
    this.isExpanded = !this.isExpanded;
    this.documentService.toggleDocument$.next({
      documentId: this.document.id,
      isExpanded: false,
    });
  }

  public onDrilldownContext(): void {
    const extensionField = this.document?.emitExtensionFields?.find(
      (item: IExtensionField) => {
        return item.name === 'channelDetails';
      },
    );
    this.drilldown.emit({
      scope: SegmentScope.CONTEXT,
      value: extensionField,
      context: { document: this.document },
    });
  }

  public onDrilldownDiscord(extensionField): void {
    this.drilldown.emit({
      scope: SegmentScope.DISCORD,
      value: extensionField,
      context: { document: this.document },
    });
  }

  // public onDrilldownReplies(): void {
  //   const extensionField = this.document?.emitExtensionFields?.find(
  //     (item: IExtensionField) => {
  //       return item.name === 'channelDetails';
  //     },
  //   );
  //   this.drilldown.emit({
  //     scope: SegmentScope.REPLIES,
  //     value: extensionField || document,
  //   });
  // }

  onDrilldown(scope: SegmentScope): void {
    this.drilldown.emit({ scope, value: this.document });
  }

  public onCreateProfile(
    scope: ProfileDrildownScope,
    profileType: ProfileType,
  ): void {
    this.createProfile.emit(
      new ProfileDrilldown(
        scope,
        profileType,
        scope === ProfileDrildownScope.ACTOR
          ? this.document.emitActor
          : this.document.emitChannel,
      ),
    );
  }

  public onLinkProfile(profile: Profile, scope: ProfileDrildownScope): void {
    const link = new LinkTag(
      undefined,
      undefined,
      scope === ProfileDrildownScope.ACTOR
        ? TagCategory.Actor
        : TagCategory.Channel,
      true,
      scope === ProfileDrildownScope.ACTOR
        ? this.document.emitActor
        : this.document.emitChannel,
      undefined,
      undefined,
      undefined,
    );
    this.linkTagsService.linkTags(profile.profileId, [link]).subscribe(() => {
      this.snackBar.open(
        `${
          scope === ProfileDrildownScope.ACTOR
            ? this.document.emitActor
            : this.document.emitChannel
        } has been linked tp profile ${profile.name}!`,
        'Close',
        {
          horizontalPosition: 'right',
          duration: 5000,
          verticalPosition: 'top',
        },
      );
      this.profilesDataSource = this.profilesDataSource.filter(
        (item: Profile) => {
          return item.profileId !== profile.profileId;
        },
      );
    });
  }

  public onProfileDataBound(params: QueryFilters): void {
    this.onGetProfiles(params).subscribe();
  }

  public onGetProfiles(params: QueryFilters): Observable<IProfileData> {
    if (typeof params.query === 'string') {
      params.addQueryColumn({
        searchValues: [params.query],
        searchField: 'name',
        searchFieldType: SearchFieldType.TEXT,
      });
    }
    return this.profileService
      .getProfiles(params, this.userService.userPreferences.defaultEcosystemId)
      .pipe(
        map((response: IProfileData) => {
          this.profilesDataSource = response.items;
          return response;
        }),
      );
  }

  public onMouseOverLinkProfile(scope: TagCategory): void {
    if (!this.isLoadingProfiles) {
      this.isLoadingProfiles = true;
      this.profileInitialState = new QueryFilters(
        30,
        1,
        undefined,
        undefined,
        {
          direction: 'desc',
          active: 'updatedDate',
        },
        undefined,
      );
      forkJoin([
        this.getSelections(scope),
        this.onGetProfiles(this.profileInitialState),
      ]).subscribe((response: Array<any>) => {
        this.profilesDataSource = this.profilesDataSource.filter(
          (item: Profile) => !response[0].includes(item.profileId),
        );
        this.showListProfile = true;
        this.showNestedNavbar = false;
        this.isLoadingProfiles = false;
      });
    }
  }

  private getSelections(scope: TagCategory): Observable<Array<string>> {
    return this.linkTagsService.getTargetLinks(
      undefined,
      scope === TagCategory.Actor
        ? this.document.emitActor
        : this.document.emitChannel,
      scope,
    );
  }

  public onAddConnection(event: MouseEvent): void {
    event.stopImmediatePropagation();
    const params = {
      connection: new ProfileConnection(
        Utils.generateUUID(),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ),
      profile: this.profile,
      navigationItem: this.navigationItem,
      form: this.form,
      action: WidgetActions.CREATE,
    };

    this.addConnection.emit(params);
  }

  public onAddNote(event: MouseEvent): void {
    event.stopImmediatePropagation();
    const params = {
      segment: {
        segment: SegmentScope.ARTICLE_DETAIL,
        value: this.document,
      },
      ecosystemId: this.userService.userPreferences.defaultEcosystemId,
      profile: this.profile,
    };

    this.addNote.emit(params);
  }
}

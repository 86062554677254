import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  Align,
  CastIconNamePipe,
  CoreModule,
  FAwesomeModule,
  IconType,
  Sizes,
  SvgComponent,
} from '@intorqa-ui/core';
import { LibraryService } from '@portal/shared/services/library.service';
import { ILibraryItem } from './library-thumbnail.interface';
import { ThumbnailTitle } from './library-thumbnail.enum';
import { ProfileType } from '@portal/profiles/models/profile-type';
import { ProfileService } from '@portal/profiles/services/vendors.service';
import { GetProfileTypeIconPipe } from '@portal/profiles/pipes/vendors.pipe';

@Component({
  selector: 'itq-library-thumbnail',
  templateUrl: './library-thumbnail.component.html',
  styleUrls: ['./library-thumbnail.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    SvgComponent,
    FAwesomeModule,
    FormsModule,
    CastIconNamePipe,
    GetProfileTypeIconPipe,
  ],
})
export class LibraryThumbnailComponent implements OnInit {
  @Input() title: string;
  @Input() dataSource: Array<ILibraryItem>;
  @Input() totalCount: number;
  @Input() icon: string;
  @Input() iconType: IconType;
  @Input() dateLabel: string;

  @Output() action: EventEmitter<void> = new EventEmitter<void>();
  @Output() create: EventEmitter<string> = new EventEmitter<string>();
  @Output() dataBound: EventEmitter<string> = new EventEmitter<string>();

  public showLoader = false;
  public query: string;
  public typesDataSource: Array<ProfileType> = [];

  readonly Sizes = Sizes;
  readonly IconType = IconType;
  readonly Align = Align;
  readonly ThumbnailTitle = ThumbnailTitle;

  constructor(
    readonly libraryService: LibraryService,
    readonly profileService: ProfileService,
  ) {}

  ngOnInit() {
    this.typesDataSource = this.profileService.types;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dataSource?.previousValue !== changes.dataSource?.currentValue
    ) {
      this.showLoader = false;
    }
  }

  public onActionClick(): void {
    this.action.emit();
  }

  public onCreate(id?: string): void {
    this.create.emit(id);
  }

  public onSearch(): void {
    this.showLoader = true;
    this.dataBound.emit(this.query);
  }
}

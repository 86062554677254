import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IconName, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { IconComponent, IconType } from '@intorqa-ui/core';

@Component({
  selector: 'itq-headline-simple',
  templateUrl: './headline-simple.component.html',
  styleUrls: ['./headline-simple.component.scss'],
  standalone: true,
  imports: [IconComponent, CommonModule],
})
export class HeadLineSimpleComponent implements OnInit {
  @Input() iconName: IconName | string;
  @Input() iconSize: SizeProp | string;
  @Input() title = '';
  @Input() description: string;
  @Input() iconType: IconType = IconType.FONT_AWESOME;

  constructor() {}

  ngOnInit(): void {}
}

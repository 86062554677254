import {
  ChartType,
  ChartOrientation,
  BarMode,
  LineMode,
} from '@portal/shared/enums/chart.enum';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';

export const TAG_TRENDS_CHART_TYPES: Array<IDisplayType> = [
  {
    id: 'StackedVerticalBar',
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.VERTICAL,
      mode: BarMode.STACK,
    },
    svgIcon: 'stacked-vertical-bar',
    tooltip: 'Stacked Bar',
  },
  {
    id: 'GroupedVerticalBar',
    type: ChartType.BAR,
    options: {
      orientation: ChartOrientation.VERTICAL,
      mode: BarMode.GROUP,
    },
    svgIcon: 'grouped-vertical-bar',
    tooltip: 'Grouped Bar',
  },
  {
    id: 'Area',
    type: ChartType.LINE,
    options: {
      mode: LineMode.AREA,
    },
    svgIcon: 'area',
    tooltip: 'Area',
  },
  {
    id: 'Line',
    type: ChartType.LINE,
    options: {
      mode: LineMode.LINE,
    },
    svgIcon: 'line',
    tooltip: 'Line',
  },
];

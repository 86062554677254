import { SimpleChanges } from '@angular/core';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from '../../interfaces/table.interface';

@Component({
  selector: 'itq-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>([]);
  public displayedColumns: string[];
  public query: string;
  public activeColumn: string;

  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  @ViewChild('ref') ref: ElementRef;

  @Input() footerTemplate: TemplateRef<any>;
  @Input() sortParameters: Sort;
  @Input() emptyMessage = 'No results found';
  @Input() emptyMessageDescription =
    'Please update search term to show some results.';
  @Input() tableColumns: TableColumn[];
  @Input() trackBy: string;
  @Input() selectRow: string;
  @Input() active: string;
  @Input() showHeader = true;

  @Input() set tableData(data: any[]) {
    this.onDataBound(data);
  }

  constructor() {}

  ngOnInit(): void {
    this.initTable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.tableColumns?.previousValue !==
      changes?.tableColumns?.currentValue
    ) {
      this.initTable();
    }
  }

  private initTable(): void {
    if (this.tableColumns?.length > 0) {
      this.displayedColumns = this.tableColumns.map(
        (tableColumn: TableColumn) => tableColumn.name,
      );

      if (this.selectRow) {
        this.displayedColumns = [this.selectRow, ...this.displayedColumns];
      }
    }
  }

  onDataBound(data: any): void {
    if (!Array.isArray(data)) {
      data = [];
    }
    this.dataSource = new MatTableDataSource<any>(data);
    this.dataSource.sort = this.matSort;
  }

  public onSearch(event: MouseEvent, activeColumn: string): void {
    event.stopPropagation();
    if (this.activeColumn === activeColumn) {
      this.activeColumn = undefined;
    } else {
      this.activeColumn = activeColumn;
    }
  }

  public onApplySearch(): void {
    this.dataSource.filter = this.query?.trim().toLowerCase();
  }

  public onSort(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      const aVal = a[sort.active];
      const bVal = b[sort.active];

      if (typeof aVal === 'string') {
        return isAsc ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
      } else {
        return isAsc ? aVal - bVal : bVal - aVal;
      }
    });
  }

  getTrackBy(index: number, row: any): string {
    return row[this.trackBy];
  }
}

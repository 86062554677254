import moment from 'moment';
import { DateQueryType } from '../enums/date-range.enum';
import { IDateRange, IPreset } from '../interfaces/date-range.interface';
import { IPresetQuery } from '../interfaces/query-filters';

export class DateRangeHelper implements IDateRange {
  public customPresets: Array<IPreset>;

  constructor(public preset: IPreset) {
    this.customPresets = DateRangeHelper.getDatePresets(preset?.end?.toDate());
  }

  public getPresetByLabel(label: DateQueryType): IPreset {
    return this.customPresets.find((item: IPreset) => item.label === label);
  }

  public static getDatePresets(date = new Date()): Array<IPreset> {
    return [
      {
        label: DateQueryType.Last15Minutes,
        start: moment(date).subtract(15, 'minutes'),
        end: moment(date),
      },
      {
        label: DateQueryType.LastHour,
        start: moment(date).subtract(1, 'hour'),
        end: moment(date),
      },
      {
        label: DateQueryType.Last12Hours,
        start: moment(date).subtract(12, 'hours'),
        end: moment(date),
      },
      {
        label: DateQueryType.LastDay,
        start: moment(date).subtract(1, 'day'),
        end: moment(date),
      },
      {
        label: DateQueryType.LastWeek,
        start: moment(date).subtract(1, 'week'),
        end: moment(date),
      },
      {
        label: DateQueryType.LastFortnight,
        start: moment(date).subtract(2, 'weeks'),
        end: moment(date),
      },
      {
        label: DateQueryType.LastMonth,
        start: moment(date).subtract(1, 'month'),
        end: moment(date),
      },
      {
        label: DateQueryType.LastQuarter,
        start: moment(date).subtract(1, 'quarter'),
        end: moment(date),
      },
      {
        label: DateQueryType.LastYear,
        start: moment(date).subtract(1, 'year'),
        end: moment(date),
      },
      {
        label: DateQueryType.LastThreeYears,
        start: moment(date).subtract(3, 'year'),
        end: moment(date),
      },
      {
        label: DateQueryType.Custom,
        start: undefined,
        end: undefined,
      },
    ];
  }

  public static getDateLabels(): Array<DateQueryType> {
    return this.getDatePresets(new Date()).map((item: IPreset) => item.label);
  }

  public static convertToEpochSec(date: Date): number {
    return Math.floor(date.getTime());
  }

  public static convertFromEpochSeconds(utcSeconds: number): Date {
    const date = new Date(0);
    date.setMilliseconds(utcSeconds);
    return date;
  }

  public static getDateQueryText(label: DateQueryType): DateQueryType {
    let result: DateQueryType;
    Object.keys(DateQueryType).forEach((key: DateQueryType) => {
      if (DateQueryType[key] === label) {
        result = key;
      }
    });

    return result;
  }

  public static findPresetByLabel(label: DateQueryType): IPreset {
    return this.getDatePresets(new Date()).find(
      (item: IPreset) => item.label === label,
    );
  }

  public static getRelativeDate(unixDate: number): string {
    const today = new Date();
    unixDate = DateRangeHelper.convertFromEpochSeconds(unixDate).setHours(
      0,
      0,
      0,
      0,
    );
    today.setHours(0, 0, 0, 0);
    const todayUnixTime = DateRangeHelper.convertToEpochSec(today);
    if (unixDate === todayUnixTime) return 'Today';
    const yesterdayUnixTime = today.setDate(today.getDate() - 1);
    if (unixDate === yesterdayUnixTime) return 'Yesterday';
    return 'Older';
  }

  public static convertToPresetQuery(preset: IPreset): IPresetQuery {
    return {
      label: preset.label,
      start: this.convertToEpochSec(preset.start.toDate()),
      end: this.convertToEpochSec(preset.end.toDate()),
    };
  }
}

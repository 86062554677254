import { OverlayRef } from "@angular/cdk/overlay";
import { Subject } from "rxjs";

export class CustomOverlayRef {
  constructor(private overlayRef: OverlayRef) {}

  afterClosed: Subject<any> = new Subject();

  close(data?: any): void {
    this.overlayRef.addPanelClass("cdk-overlay-pane-closing");
    if (this.overlayRef.backdropElement) {
      this.overlayRef.backdropElement.classList.add(
        "cdk-overlay-backdrop-closing"
      );
    }
    this.overlayRef.dispose();
    this.afterClosed.next(data);
  }
}

export const defaultTheme = {
  version: 1,
  themeName: "customed",
  theme: {
    seriesCnt: "18",
    backgroundColor: "rgba(0, 0, 0, 0)",
    titleColor: "#464646",
    subtitleColor: "#6E7079",
    textColorShow: false,
    textColor: "#333",
    markTextColor: "#eee",
    color: [
      "#5470c6",
      "#91cc75",
      "#fac858",
      "#ee6666",
      "#6929c4",
      "#9f1853",
      "#198038",
      "#b28600",
      "#8a3800",
      "#1192e8",
      "#d24148",
      "#002d9c",
      "#009d9a",
      "#a56eff",
      "#005d5d",
      "#570408",
      "#e76393",
      "#012749",
    ],
    borderColor: "#ccc",
    borderWidth: 0,
    visualMapColor: ["#bf444c", "#d88273", "#f6efa6"],
    legendTextColor: "#333",
    kColor: "#eb5454",
    kColor0: "#47b262",
    kBorderColor: "#eb5454",
    kBorderColor0: "#47b262",
    kBorderWidth: 1,
    lineWidth: 2,
    symbolSize: 4,
    symbol: "emptyCircle",
    symbolBorderWidth: 1,
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: "#aaa",
    mapLabelColor: "#000",
    mapLabelColorE: "rgb(100,0,0)",
    mapBorderColor: "#444",
    mapBorderColorE: "#444",
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: "#eee",
    mapAreaColorE: "rgba(255,215,0,0.8)",
    axes: [
      {
        type: "all",
        name: "通用坐标轴",
        axisLineShow: true,
        axisLineColor: "#6E7079",
        axisTickShow: true,
        axisTickColor: "#6E7079",
        axisLabelShow: true,
        axisLabelColor: "#6E7079",
        splitLineShow: true,
        splitLineColor: ["#E0E6F1"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
      },
      {
        type: "category",
        name: "类目坐标轴",
        axisLineShow: true,
        axisLineColor: "#6E7079",
        axisTickShow: true,
        axisTickColor: "#6E7079",
        axisLabelShow: true,
        axisLabelColor: "#6E7079",
        splitLineShow: false,
        splitLineColor: ["#E0E6F1"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
      },
      {
        type: "value",
        name: "数值坐标轴",
        axisLineShow: false,
        axisLineColor: "#6E7079",
        axisTickShow: false,
        axisTickColor: "#6E7079",
        axisLabelShow: true,
        axisLabelColor: "#6E7079",
        splitLineShow: true,
        splitLineColor: ["#E0E6F1"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
      },
      {
        type: "log",
        name: "对数坐标轴",
        axisLineShow: false,
        axisLineColor: "#6E7079",
        axisTickShow: false,
        axisTickColor: "#6E7079",
        axisLabelShow: true,
        axisLabelColor: "#6E7079",
        splitLineShow: true,
        splitLineColor: ["#E0E6F1"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
      },
      {
        type: "time",
        name: "时间坐标轴",
        axisLineShow: true,
        axisLineColor: "#6E7079",
        axisTickShow: true,
        axisTickColor: "#6E7079",
        axisLabelShow: true,
        axisLabelColor: "#6E7079",
        splitLineShow: false,
        splitLineColor: ["#E0E6F1"],
        splitAreaShow: false,
        splitAreaColor: ["rgba(250,250,250,0.2)", "rgba(210,219,238,0.2)"],
      },
    ],
    axisSeperateSetting: true,
    toolboxColor: "#999",
    toolboxEmphasisColor: "#666",
    tooltipAxisColor: "#ccc",
    tooltipAxisWidth: 1,
    timelineLineColor: "#DAE1F5",
    timelineLineWidth: 2,
    timelineItemColor: "#A4B1D7",
    timelineItemColorE: "#FFF",
    timelineCheckColor: "#316bf3",
    timelineCheckBorderColor: "fff",
    timelineItemBorderWidth: 1,
    timelineControlColor: "#A4B1D7",
    timelineControlBorderColor: "#A4B1D7",
    timelineControlBorderWidth: 1,
    timelineLabelColor: "#A4B1D7",
  },
};

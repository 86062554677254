import {
  CurrencyDescription,
  CurrencyPrefix,
} from "../enums/currencies.enum.js";
import { IExchangeRate } from "../interfaces/exchange-rate.interface";

export class ExchangeRate implements IExchangeRate {
  constructor(
    public prefix: CurrencyPrefix,
    public value: number,
    public currency: CurrencyDescription
  ) {}
}

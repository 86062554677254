import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "itq-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
})
export class TooltipComponent {
  @Input() tooltipContent: string;
  @Input() top: number;
  @Input() left: number;
  @Input() show: boolean;

  @HostBinding("class") componentClass: string;
  @HostBinding("style.top") topPosition: string;
  @HostBinding("style.left") leftPosition: string;

  @ViewChild("wrapper", { static: true }) wrapper: ElementRef;

  constructor() {
    this.topPosition = `${this.top}px`;
    this.leftPosition = `${this.left}px`;
    this.componentClass = ` ${this.show ? "show" : "hide"}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.show?.currentValue !== changes?.show?.previousValue) {
      setTimeout(() => {
        this.componentClass = ` ${this.show ? "show" : "hide"}`;
        this.topPosition = `${
          this.top - this.wrapper.nativeElement.clientHeight
        }px`;
        this.leftPosition = `${this.left}px`;
      }, 10);
    }
  }
}

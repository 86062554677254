import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { QueryFilters } from '../models/query-filters';
@Injectable({
  providedIn: 'root',
})
export class VirtualScrollService {
  public dataBoundObservable: Subject<void> = new Subject();
  public scroll$ = new Subject<{ query: QueryFilters; direction: string }>();

  constructor() {}

  public scrollDown(
    element: any,
    pageSize: number,
    totalCount: number,
    scrollTop: number,
    showLoader: boolean,
    page: number,
  ): Promise<{ scroll: boolean; scrollTop: number }> {
    return new Promise((resolve, reject) => {
      const isLastPage = totalCount < pageSize * page;
      const elementScrollTop = element.scrollTop;
      if (scrollTop <= elementScrollTop || scrollTop === 0) {
        const isScrollEnd =
          element.offsetHeight + element.scrollTop >= element.scrollHeight - 1;
        if (!isLastPage && isScrollEnd && !showLoader) {
          resolve({ scroll: true, scrollTop: elementScrollTop });
        } else {
          resolve({ scroll: false, scrollTop: undefined });
        }
      } else {
        resolve({ scroll: false, scrollTop: undefined });
      }
    });
  }

  public scrollUp(
    element: any,
    showLoader: boolean,
    page: number,
  ): Promise<{ scroll: boolean; scrollTop: number }> {
    return new Promise((resolve, reject) => {
      if (page > 1 && element.scrollTop <= 0 && !showLoader) {
        resolve({ scroll: true, scrollTop: element.scrollTop });
      } else {
        resolve({ scroll: false, scrollTop: undefined });
      }
    });
  }
}

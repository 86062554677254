import { ISerie } from '@portal/shared/interfaces/widget.interface';
import { EChartsOption } from 'echarts';

export abstract class Chart {
  constructor() {}

  public abstract getOptions(
    data: Array<ISerie>,
    settings: { [key: string]: any },
    segment: any,
  ): EChartsOption;
}

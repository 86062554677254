import { Component, HostBinding, Input, OnInit } from "@angular/core";

@Component({
  selector: "itq-form-element",
  templateUrl: "./form-element.component.html",
  styleUrls: ["./form-element.component.scss"],
})
export class FormElementComponent implements OnInit {
  @Input() size: "block" | "auto" = "block";
  @Input() description: string;
  @Input() inline = false;

  @HostBinding("class") componentCssClass: string;

  constructor() {}

  ngOnInit(): void {
    this.componentCssClass = this.size;
  }
}

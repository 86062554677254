export enum ReloadFilters {
  QUERY = 'QUERY',
  ALL = 'ALL',
  NONE = 'NONE',
}

export enum FilterType {
  ALL = 'all',
  USER = 'mytags',
  SHARED = 'shared',
  COMMUNITIES = 'systemTags',
  ALERTS = 'alerts',
}

export enum TagNodeType {
  TAG = 'TAG',
  SYSTEM_TAG = 'SYSTEM_TAG',
  WIDGET = 'WIDGET',
  BOARD = 'BOARD',
}

export enum TaggingStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_RESULTS = 'NO_RESULTS',
}

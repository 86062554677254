import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CoreModule,
  CustomOverlayService,
  CustomOverlayType,
  IconType,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { Profile } from '@portal/profiles/models/profile';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { ChartType } from '@portal/shared/enums/chart.enum';
import { AnalysisTypes, WidgetActions } from '@portal/shared/enums/widget.enum';
import { IData } from '@portal/shared/interfaces/document.interface';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { UserService } from '@portal/shared/services/user.service';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { WidgetSettingsComponent } from '@portal/widget-settings/widget-settings.component';
import { TaggingStatus } from '@portal/shared/enums/tag.enum';
import { TimelineFeedV2Component } from '@portal/shared/components/timeline-feed-v2/timeline-feed-v2.component';

@Component({
  selector: 'itq-timeline-widget-body',
  templateUrl: './timeline-widget-body.component.html',
  styleUrls: ['./timeline-widget-body.component.scss'],
  standalone: true,
  imports: [CoreModule, TimelineFeedV2Component],
})
export class TimelineWidgetBodyComponent implements OnInit {
  @Input() timelineStatus: TaggingStatus;
  @Input() widget: Timeline;
  @Input() data: IData;
  @Input() noContentTitle: string;
  @Input() noContentMessage: string;
  @Input() segment: any;
  @Input() query: string;
  @Input() initialState: QueryFilters;

  @Output() databound = new EventEmitter<QueryFilters>();
  @Output() drilldown = new EventEmitter<any>();

  readonly TaggingStatus = TaggingStatus;
  readonly ChartType = ChartType;

  constructor(
    private customOverlayService: CustomOverlayService,
    readonly userService: UserService,
  ) {}

  ngOnInit() {}

  public onDataBound(params: QueryFilters): void {
    this.databound.emit(params);
  }

  public onDrilldown(segment?: ISegment): void {
    this.drilldown.emit(segment);
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.scope === ProfileDrildownScope.TAG
        ? this.widget.name
        : profileDrilldown.value,
      undefined,
      undefined,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? this.widget.name
          : profileDrilldown.value,
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? profileDrilldown.value
          : `Field field:${profileDrilldown.value}:${
              profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.customOverlayService.open({
      data: {
        navigationItem,
      },
      closeBtnStyle: 'basic',
      type: CustomOverlayType['almost-full'],
      size: 'md',
      component: WidgetSettingsComponent,
      disposeOnNavigation: true,
    });
  }
}

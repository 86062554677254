import { Pipe, PipeTransform } from '@angular/core';
import {
  ISyncFusionTreeNode,
  ISyncFusionTree,
} from '@portal/boards/interfaces/tree.interface';
import { BoardService } from '@portal/boards/services/board.service';

@Pipe({
  name: 'containsDefaultBoard',
  standalone: true,
})
export class ContainsDefaultBoardPipe implements PipeTransform {
  transform(parentNode: ISyncFusionTreeNode): boolean {
    return parentNode?.child?.find(
      (item: ISyncFusionTreeNode) => item.defaultBoard,
    )
      ? true
      : false;
  }
}

@Pipe({
  name: 'adaptTreeDataSource',
  standalone: true,
})
export class AdaptTreeDataSourcePipe implements PipeTransform {
  constructor(private boardService: BoardService) {}

  transform(data: Array<ISyncFusionTreeNode>, query: string): ISyncFusionTree {
    return {
      dataSource: this.boardService.searchTree({ dataset: data, query }),
      id: 'id',
      parentID: 'pid',
      text: 'name',
      iconCss: 'icon',
      hasChildren: 'hasChild',
      selected: 'isSelected',
      isGroup: 'isGroup',
      type: 'Type',
    };
  }
}

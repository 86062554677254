import { IDisplayType } from '../../shared/interfaces/widget.interface';
import { ChartType } from '@portal/shared/enums/chart.enum';

export const TIMELINE_CHART_TYPES: Array<IDisplayType> = [
  {
    id: 'Timeline',
    type: ChartType.TIMELINE,
    svgIcon: 'board',
    tooltip: 'Timeline',
  },
];

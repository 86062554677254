<itq-timeline-type-all
  *ngIf="queryType === QueryType.QUICK_SEARCH"
  [widget]="widget"
  [initialState]="initialState"
  [required]="required"
  [dataSource]="dataSource"
  [navigationItem]="navigationItem"
  [query]="query"
  [timeline]="widget"
  (dataBound)="onDataBound($event)"
  (clearFilters)="onClearFilters($event)"
></itq-timeline-type-all>
<itq-timeline-type-list
  *ngIf="queryType === QueryType.CHANNEL || queryType === QueryType.ACTOR"
  [type]="
    queryType === QueryType.CHANNEL ? TagCategory.Channel : TagCategory.Actor
  "
  [query]="query"
  [required]="required"
  [dates]="initialState?.where"
  [navigationItem]="navigationItem"
  [timeline]="widget"
  (dataBound)="onDataBound($event)"
  (clearFilters)="onClearFilters($event)"
></itq-timeline-type-list>
<itq-query-builder
  *ngIf="queryType === QueryType.QUERY_BUILDER && dataSource?.length > 0"
  [query]="query"
  [required]="required"
  [navigationItem]="navigationItem"
  [dataSource]="dataSource"
  [widget]="widget"
  [dates]="initialState?.where"
  (dataBound)="onDataBound($event)"
  (clearFilters)="onClearFilters($event)"
></itq-query-builder>

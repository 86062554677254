import { EventTeams } from '@intorqa-ui/core';
import { IEvent } from '../interfaces/event.interface';

export class Event implements IEvent {
  constructor(
    public id: string,
    public name: string,
    public typeId: string,
    public typeName: string,
    public createdDate: number,
    public ecosystemId: string,
    public description: string,
    public createdBy: string,
    public date: number,
    public updatedBy: string,
    public updatedDate: number,
    public team: EventTeams,
  ) {}
}

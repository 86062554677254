import { Routes } from '@angular/router';
import { ErrorComponent } from '@intorqa-ui/core';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { VendorsResolver } from './profiles/vendors.resolver';
import { BoardsComponent } from './boards/components/boards/boards.component';
import { PageDocumentComponent } from './document/page-document.component';
import { BoardComponent } from './boards/components/board/board.component';
import { TagsComponent } from './tags/tags.component';
import { LibraryComponent } from './library/components/library/library.component';
import { LibraryManagerComponent } from './library/components/library-manager/library-manager.component';
import { VendorsComponent } from './profiles/vendors.component';
import { EventsComponent } from './events/components/events/events.component';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'boards', pathMatch: 'full' },
  {
    path: 'boards',
    component: BoardsComponent,
    children: [
      {
        path: '',
        component: BoardComponent,
      },
      {
        path: ':id',
        component: BoardComponent,
      },
    ],
  },
  {
    path: 'document/:id',
    component: PageDocumentComponent,
  },
  {
    path: 'library',
    component: LibraryComponent,
  },
  {
    path: 'manager',
    component: LibraryManagerComponent,
    children: [
      {
        path: 'events',
        component: EventsComponent,
      },
      {
        path: 'tags',
        component: TagsComponent,
      },
      {
        path: 'vendors',
        resolve: { resolveVendors: VendorsResolver },
        canActivate: [AuthGuardService],
        component: VendorsComponent,
      },
    ],
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

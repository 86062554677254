import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Profile } from '@portal/profiles/models/profile';
import { SocialChannelNote } from "@portal/profiles/models/social-channel-note";

@Component({
  selector: "itq-view-social-channels",
  templateUrl: "./view-social-channels.component.html",
  styleUrls: ["./view-social-channels.component.scss"],
})
export class ViewSocialChannelsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: SocialChannelNote;

  constructor() {}

  ngOnInit() {}
}

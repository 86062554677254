import { Injectable } from '@angular/core';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Alert,
  TagMatchAlert,
  TagThresholdAlert,
} from '../../../../../core/src/lib/models/alert';
import { AlertType } from '../models/alert-type';
import {
  IAlertsTrendData,
  IAlertType,
  ICreateTagThresholdAlertDTO,
  ICreateTagMatchAlertDTO,
  ITagThresholdAlert,
  ITagMatchAlert,
  IAlertsTrend,
} from '../../../../../core/src/lib/interfaces/alerts.interface';
import { DTOQuery } from '@intorqa-ui/core';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  public loadTypes$ = new Subject<void>();
  public trend$ = new Subject<IAlertsTrendData>();
  public loadAlert$ = new Subject<Alert>();

  private _types: Array<AlertType>;

  get types(): Array<AlertType> {
    return this._types;
  }

  set types(value: Array<AlertType>) {
    this._types = value;
  }

  constructor(readonly apiRequestService: ApiRequestService) {}

  public getTypes(): Observable<Array<AlertType>> {
    return this.apiRequestService
      .getToObservable(
        '/alerts/types',
        new DTOTypeConverter<Array<IAlertType>>(),
      )
      .pipe(
        map((response: Array<IAlertType>) => {
          this.types = response?.map(
            (item: IAlertType) => new AlertType(item.id, item.label),
          );
          this.loadTypes$.next();
          return this.types;
        }),
      );
  }

  public getTypeById(typeId: string): AlertType {
    return this.types?.find((item: AlertType) => item.id === typeId);
  }

  public create(
    payload: ICreateTagThresholdAlertDTO | ICreateTagMatchAlertDTO,
  ): Observable<TagThresholdAlert | TagMatchAlert> {
    return this.apiRequestService
      .postToObservable(
        '/alerts',
        new DTOTypeConverter<ITagThresholdAlert | ITagMatchAlert>(),
        payload,
      )
      .pipe(
        map((response: ITagThresholdAlert | ITagMatchAlert) => {
          if ((response as ITagMatchAlert).delay) {
            response = response as ITagMatchAlert;
            new TagMatchAlert(
              response.id,
              response.active,
              response.priority,
              response.message,
              response.postSlack,
              response.emailMe,
              response.delay,
              response.ecosystemId,
            );
          } else {
            response = response as ITagThresholdAlert;
            return new TagThresholdAlert(
              response.id,
              response.active,
              response.priority,
              response.message,
              response.postSlack,
              response.emailMe,
              response.dailyAverage,
              response.condition,
              response.count,
              response.period,
              response.ecosystemId,
            );
          }
        }),
      );
  }

  public delete(alertId: string): Observable<boolean> {
    return this.apiRequestService.deleteToObserable(`/alerts/${alertId}`);
  }

  public update(
    alertId: string,
    properties: { [key: string]: any },
  ): Observable<TagMatchAlert | TagThresholdAlert> {
    return this.apiRequestService
      .putToObservable(`/alerts/${alertId}`, properties)
      .pipe(
        map((response: ITagThresholdAlert | ITagMatchAlert) => {
          if ((response as ITagMatchAlert).delay) {
            response = response as ITagMatchAlert;
            return new TagMatchAlert(
              response.id,
              response.active,
              response.priority,
              response.message,
              response.postSlack,
              response.emailMe,
              response.delay,
              response.ecosystemId,
            );
          } else {
            response = response as ITagThresholdAlert;
            return new TagThresholdAlert(
              response.id,
              response.active,
              response.priority,
              response.message,
              response.postSlack,
              response.emailMe,
              response.dailyAverage,
              response.condition,
              response.count,
              response.period,
              response.ecosystemId,
            );
          }
        }),
      );
  }

  public getAlertByTagId(
    tagId: string,
  ): Observable<TagMatchAlert | TagThresholdAlert> {
    return this.apiRequestService
      .getToObservable(
        `/alerts/tags/${tagId}`,
        new DTOTypeConverter<ITagMatchAlert | ITagThresholdAlert>(),
      )
      .pipe(
        map((response: ITagMatchAlert | ITagThresholdAlert) => {
          let alert: TagMatchAlert | TagThresholdAlert;
          if ((response as ITagMatchAlert).delay) {
            response = response as ITagMatchAlert;
            alert = new TagMatchAlert(
              response.id,
              response.active,
              response.priority,
              response.message,
              response.postSlack,
              response.emailMe,
              response.delay,
              response.ecosystemId,
            );
          } else {
            response = response as ITagThresholdAlert;
            alert = new TagThresholdAlert(
              response.id,
              response.active,
              response.priority,
              response.message,
              response.postSlack,
              response.emailMe,
              response.dailyAverage,
              response.condition,
              response.count,
              response.period,
              response.ecosystemId,
            );
          }
          this.loadAlert$.next(alert);
          return alert;
        }),
      );
  }

  public getTrend(payload: {
    ecosystemId: string;
    query: DTOQuery;
  }): Observable<IAlertsTrendData> {
    return this.apiRequestService
      .postToObservable(
        '/alerts/trend',
        new DTOTypeConverter<IAlertsTrend>(),
        payload,
      )
      .pipe(
        map((response: IAlertsTrend) => {
          const result = {
            series: [
              {
                data: response.data,
              },
            ],
            widgetId: undefined,
            totalHits: response.totalHits,
            dailyAverage: response.dailyAverage,
          };
          this.trend$.next(result);
          return result;
        }),
      );
  }
}

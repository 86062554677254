<ng-container *ngIf="!expanded; else expandedTemplate">
  <itq-mat-button
    [materialStyle]="'basic'"
    [disabled]="disabled"
    [padding]="padding"
    (mousedown)="onToggle($event)"
  >
    <fa-icon [icon]="['far', 'search']"></fa-icon>
    <span class="text-wrapper">
      {{ label }}
    </span>
  </itq-mat-button>
</ng-container>
<ng-template #expandedTemplate>
  <itq-input
    #input
    [placeholder]="placeholder"
    [disabled]="disabled"
    [autoFocus]="autoFocus"
    [showIcon]="showIcon"
    [type]="'text'"
    [(ngModel)]="searchTerm"
    [padding]="padding"
    (ngModelChange)="onModelChange($event)"
    (clear)="onSearch()"
    (search)="onSearch()"
  ></itq-input>
</ng-template>

<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addNote'">
    <itq-form-row>
      <itq-form-element>
        <span class="label mb-1 required">Select storefront type</span>
        <itq-dropdown
          [formControlName]="'provider'"
          [showHeader]="false"
          [clearSelection]="false"
          [dataSource]="subTypesDataSource"
          [dataFields]="{ name: 'name', value: 'name' }"
          (changeValue)="onChangeSubType()"
        ></itq-dropdown>
      </itq-form-element>
      <mat-error
        *ngIf="
          form.controls?.provider?.touched && form.controls?.provider?.invalid
        "
      >
        Storefront type is required
      </mat-error>
    </itq-form-row>
    <itq-form-row *ngIf="form?.get('addNote.type')?.value">
      <itq-form-element>
        <span class="label mb-1 required">Enter storefront link</span>
        <itq-input
          formControlName="value"
          (search)="onValueChanged()"
          (clear)="onValueChanged()"
        ></itq-input>
      </itq-form-element>
      <mat-error
        *ngIf="form.controls?.value?.touched && form.controls?.value?.invalid"
      >
        Storefront link is required
      </mat-error>
    </itq-form-row>
    <itq-form-row
      class="type__container"
      *ngIf="form?.get('addNote.type')?.value"
    >
      <itq-form-element>
        <span class="label mb-1">Text notes</span>
        <quill-editor
          [styles]="{
            height: '100%',
            minHeight: '400px',
            width: '100%',
            maxHeight: '400px'
          }"
          [formControlName]="'description'"
          [modules]="editorModules"
          (onContentChanged)="onContentChanged()"
        ></quill-editor>
      </itq-form-element>
    </itq-form-row>
  </ng-container>
</ng-container>

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Event, EventTeams } from '@intorqa-ui/core';
import { EventsService } from '@portal/shared/services/events.service';

@Component({
  selector: 'itq-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class EventComponent implements OnInit {
  @Input() events: Array<Event>;
  @Input() allowDrilldown = true;

  readonly EventTeams = EventTeams;

  public expandedEvent: string;

  constructor(readonly eventService: EventsService) {}

  ngOnInit() {}

  public onClickEvent(event: Event): void {
    if (this.expandedEvent === event.id) {
      this.expandedEvent = undefined;
    } else {
      this.expandedEvent = event.id;
    }
    this.eventService.drilldown$.next(event);
  }
}

<div class="displayTypes__container">
  @for (item of displayTypes; track item.id) {
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [type]="'button'"
      [align]="Align.START"
      [padding]="Sizes['X-SM']"
      [materialStyle]="'basic'"
      (clickEvent)="onChangeDisplay(item)"
    >
      <div
        [ngClass]="{
          'fill-primary': item.id === selectedDisplayType.id,
          'text-primary': item.id === selectedDisplayType.id,
          flex: true,
          'items-center': true
        }"
      >
        <div class="flex items-center">
          @if (item.icon) {
            <fa-icon class="mr-1" [icon]="item.icon"></fa-icon>
          } @else {
            <div class="w-[24px] h-[24px] flex items-center justify-center">
              <itq-svg [icon]="item.svgIcon" class="w-5 h-5 mr-1"></itq-svg>
            </div>
          }
        </div>
        {{ item.tooltip }}
      </div>
    </itq-mat-button>
  }
</div>

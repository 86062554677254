<table
  #ref
  mat-table
  [dataSource]="dataSource | filterByQuery: query : activeColumn"
  matSort
  (matSortChange)="onSort($event)"
  [ngClass]="{ 'show-header': showHeader }"
>
  @for (tableColumn of tableColumns; track tableColumn.dataKey) {
    <ng-container [matColumnDef]="tableColumn.name">
      @if (tableColumn.isSortable) {
        <th
          mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="tableColumn.dataKey"
          [arrowPosition]="'after'"
          [ngStyle]="{
            width:
              tableColumn.width && tableColumn.width !== 'fit'
                ? tableColumn.width
                : undefined,
            flex:
              tableColumn.width && tableColumn.width === 'fit' ? 1 : undefined
          }"
        >
          <div
            class="header__container"
            [class.text-right]="tableColumn.position == 'right'"
            [class.text-center]="tableColumn.position == 'center'"
            [class.text-left]="tableColumn.position == 'left'"
          >
            {{ tableColumn.name || '' }}
            @if (tableColumn.isFilterable) {
              <fa-icon
                class="search--icon"
                [icon]="['far', 'search']"
                (click)="onSearch($event, tableColumn.dataKey)"
              ></fa-icon>
            }
          </div>
          @if (
            tableColumn.isFilterable && activeColumn === tableColumn.dataKey
          ) {
            <div class="flex w-full mt-1">
              <itq-input
                [autoFocus]="true"
                [(ngModel)]="query"
                [placeholder]="'Search...'"
                (search)="onApplySearch()"
                (clear)="onApplySearch()"
              ></itq-input>
            </div>
          }
        </th>
      } @else {
        <th
          mat-header-cell
          *matHeaderCellDef
          [class.text-right]="tableColumn.position == 'right'"
          [class.text-center]="tableColumn.position == 'center'"
          [class.text-left]="tableColumn.position == 'left'"
          [ngStyle]="{
            width:
              tableColumn.width && tableColumn.width !== 'fit'
                ? tableColumn.width
                : undefined,
            flex:
              tableColumn.width && tableColumn.width === 'fit' ? 1 : undefined
          }"
        >
          {{ tableColumn.name || '' }}
        </th>
      }

      <!-- column data -->
      <td
        mat-cell
        *matCellDef="let element; let i = index"
        [class.text-right]="tableColumn.position === 'right'"
        [class.text-center]="tableColumn.position === 'center'"
        [class.text-left]="tableColumn.position === 'left'"
        [class.ellipsis]="!tableColumn.customRender"
        [ngStyle]="{
          width:
            tableColumn.width && tableColumn.width !== 'fit'
              ? tableColumn.width
              : undefined,
          flex: tableColumn.width && tableColumn.width === 'fit' ? 1 : undefined
        }"
      >
        @if (tableColumn?.customRender && tableColumn?.template) {
          <ng-container
            *ngTemplateOutlet="
              tableColumn.template;
              context: { $implicit: element, index: i }
            "
          ></ng-container>
        } @else {
          {{ element | dataPropertyGetter: tableColumn.dataKey }}
        }
      </td>
    </ng-container>
  }
  @if (showHeader) {
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  }
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    [ngClass]="{
      success: row?.success,
      highlight: row?.highlight,
      error: row?.error,
      active: active && active === row[trackBy]
    }"
  ></tr>
  <tr *matNoDataRow>
    <itq-panel-info
      [label]="emptyMessage"
      [message]="emptyMessageDescription"
      [icon]="'exclamation-circle'"
    ></itq-panel-info>
  </tr>
  <ng-container matColumnDef="footerTemplate">
    <td
      mat-footer-cell
      *matFooterCellDef
      [attr.colspan]="displayedColumns?.length"
    >
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </td>
  </ng-container>
  @if (footerTemplate) {
    <tr mat-footer-row *matFooterRowDef="['footerTemplate']"></tr>
  }
</table>

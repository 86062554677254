<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addNote'">
    <itq-form-row *ngIf="form?.get('addNote.type')?.value">
      <itq-form-element>
        <span class="label mb-1 required">Text notes</span>
        <quill-editor
          [modules]="editorModules"
          [styles]="{
            height: '100%',
            minHeight: '400px',
            width: '100%',
            maxHeight: '400px'
          }"
          [formControlName]="'description'"
          (onContentChanged)="onContentChanged()"
        ></quill-editor>
      </itq-form-element>
    </itq-form-row>
  </ng-container>
</ng-container>

import { Pipe, PipeTransform } from "@angular/core";
import { Category, TagCategory } from "@intorqa-ui/core";

@Pipe({
  name: "filterCategories",
})
export class FilterCategoriesPipe implements PipeTransform {
  constructor() {}

  transform(categories: Array<Category>): Array<Category> {
    const excludedList = [
      TagCategory["My Tags"],
      TagCategory.Actor,
      TagCategory.Channel,
      TagCategory.Community,
    ];
    return categories.filter(
      (item: Category) => !excludedList.includes(item.name)
    );
  }
}

export enum DataSourceType {
  ALL = 'ALL',
  SET = 'SET',
}

export enum SegmentScope {
  ARTICLE_DETAIL = 'ARTICLE_DETAIL',
  ACTOR = 'ACTOR',
  CHANNEL = 'CHANNEL',
  DISCORD = 'DISCORD',
  REPLIES = 'REPLIES',
  MODAL = 'MODAL',
  CONTEXT = 'CONTEXT',
}

export enum SettingsActions {
  DOCS = 'DOCS',
}

@if (segment && allowClearSegment) {
  <div class="flex items-center pb-2">
    <span class="font-semibold mr-2">Segment:</span>
    <mat-chip-set>
      <mat-chip>
        <div class="flex items-center justify-between">
          <span class="font-medium">{{ segment?.name }}</span>
          <button matChipRemove (click)="onRemoveSegment()">
            <fa-icon
              class="text-black flex items-center"
              [icon]="['far', 'circle-xmark']"
            ></fa-icon>
          </button>
        </div>
      </mat-chip>
    </mat-chip-set>
  </div>
}
@if (dataSource?.totalCount > 0) {
  <div
    [ngClass]="{
      'flex w-full h-full relative': true,
      'flex-col ': orientation === TimelineOrientation.VERTICAL
    }"
  >
    <div
      [ngClass]="{
        'flex font-medium absolute': true,
        'my-4 justify-center w-full':
          orientation === TimelineOrientation.VERTICAL,
        'mx-4 left-0 h-full flex-col':
          orientation === TimelineOrientation.HORIZONTAL
      }"
    >
      <div class="flex-1 flex items-center justify-center">
        <span class="flex w-[10px] h-[10px] bg-danger mr-1"></span>Security team
      </div>
      <div class="flex-1 flex items-center justify-center">
        <span class="flex w-[10px] h-[10px] bg-link mr-1"></span>Cheats team
      </div>
    </div>
    <div
      [ngClass]="{
        'flex group': true,
        'flex-col mt-4': orientation === TimelineOrientation.VERTICAL,
        'ml-4': orientation === TimelineOrientation.HORIZONTAL
      }"
      *ngFor="let groupedMonthEvents of dataSource.items | groupByMonth"
    >
      <div
        [ngClass]="{
          'flex ': true,
          'items-center': orientation === TimelineOrientation.HORIZONTAL,
          'justify-center w-full': orientation === TimelineOrientation.VERTICAL
        }"
      >
        <span
          [ngClass]="{
            'bg-primary px-2 py-1 font-medium rounded-md ': true,
            'whitespace-nowrap': orientation === TimelineOrientation.HORIZONTAL
          }"
          >{{ groupedMonthEvents[0].date | date: 'MMMM YYYY' }}</span
        >
      </div>
      <div class="flex py-4 w-full">
        <itq-event-item
          [ngClass]="{
            'flex w-full h-full': true,
            'flex-col': orientation === TimelineOrientation.VERTICAL
          }"
          class=""
          [orientation]="orientation"
          [allowDrilldown]="allowDrilldown"
          [events]="groupedMonthEvents"
          [segment]="segment"
        >
        </itq-event-item>
      </div>
    </div>
  </div>
} @else {
  @if (dataSource && dataSource?.totalCount === 0) {
    <itq-panel-info
      class="w-full"
      [label]="'No events found!'"
      [message]="'Please update your filters and try again...'"
      [icon]="'exclamation-circle'"
    ></itq-panel-info>
  }
}

import { Injectable } from '@angular/core';
import { CurrencyService } from '@intorqa-ui/core';

@Injectable({
  providedIn: 'root',
})
export class VendorsResolver {
  constructor(private currencyService: CurrencyService) {}

  resolve(): void {
    this.currencyService.getExchangeRates().subscribe();
  }
}

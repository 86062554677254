import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import {
  CoreModule,
  DateRangeComponent,
  IPresetQuery,
  Sizes,
  TagCategory,
} from '@intorqa-ui/core';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetService } from '@portal/boards/services/widget.service';
import { Tag } from '@portal/shared/models/tag';
import { DataSourceType } from '@portal/widget-settings/enums/widget-settings.enum';
import { AllTagsDatasourceComponent } from '../all-tags-datasource/all-tags-datasource.component';
import { SpecificTagDatasourceComponent } from '../specific-tag-datasource/specific-tag-datasource.component';

@Component({
  selector: 'itq-tag-comparison-metrics',
  templateUrl: './tag-comparison-metrics.component.html',
  styleUrls: ['./tag-comparison-metrics.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CoreModule,
    AllTagsDatasourceComponent,
    SpecificTagDatasourceComponent,
    MatRadioModule,
    DateRangeComponent,
  ],
})
export class TagComparisonMetricsComponent implements OnInit {
  @Input() set widget(widget: Widget) {
    this.tagComparisonWidget = widget as TagComparison;
    if (!this.tagComparisonWidget.dataSource) {
      this.tagComparisonWidget.dataSource = [];
    }
    if (!this.tagComparisonWidget.dataType) {
      this.tagComparisonWidget.dataType = TagCategory['My Tags'];
    }
  }
  @Input() form: FormGroup;
  @Input() dates: IPresetQuery;

  public dataPointsDataSource: Array<Tag>;
  public options: echarts.EChartsOption;
  public tagComparisonWidget: TagComparison;

  readonly Sizes = Sizes;
  readonly DataSourceType = DataSourceType;
  readonly TagCategory = TagCategory;

  constructor(readonly widgetService: WidgetService) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.removeControl('typeDataSource');
  }

  private createForm(): void {
    this.form.addControl(
      'typeDataSource',
      new FormControl(
        this.tagComparisonWidget.dataSource.length === 0
          ? DataSourceType.ALL
          : DataSourceType.SET,
        [Validators.required],
      ),
    );
  }

  public onChangeDataValues(value: Array<string>): void {
    this.tagComparisonWidget.dataValues = value;
    this.widgetService.updateWidget$.next({
      widget: this.tagComparisonWidget,
      reloadData: true,
    });
  }

  public onChangeDataType(value: string): void {
    this.tagComparisonWidget.dataValues = [];
    this.tagComparisonWidget.dataType = value as TagCategory;
    this.widgetService.updateWidget$.next({
      widget: this.tagComparisonWidget,
      reloadData: true,
    });
  }

  public onChangeDataSources(value: Array<string>): void {
    this.tagComparisonWidget.dataValues = [];
    this.tagComparisonWidget.dataType = undefined;
    this.tagComparisonWidget.dataSource = value;
    this.widgetService.updateWidget$.next({
      widget: this.tagComparisonWidget,
      reloadData: true,
    });
  }

  public onChangeDataSourceType(event: MatRadioChange): void {
    if (event.value === DataSourceType.ALL) {
      this.tagComparisonWidget.dataSource = [];
      this.tagComparisonWidget.dataType = TagCategory['My Tags'];
      this.tagComparisonWidget.dataValues = [];
    } else {
      this.tagComparisonWidget.dataType = undefined;
      this.tagComparisonWidget.dataValues = [];
    }
    this.widgetService.updateWidget$.next({
      widget: this.tagComparisonWidget,
      reloadData: true,
    });
  }

  public onChangeDate(params: IPresetQuery): void {
    this.dates = params;
    this.widgetService.updateWidget$.next({
      widget: this.tagComparisonWidget,
      dates: this.dates,
      reloadData: true,
    });
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SharedService } from "@intorqa-ui/core";
import { ConnectionsService } from "@portal/profiles/services/connections.service";
import { LinkTagsService } from '@portal/profiles/services/link-tags.service';
import { NotesService } from "@portal/profiles/services/notes.service";
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';

@Component({
  selector: "itq-widget-settings-profiles",
  templateUrl: "./widget-settings-profiles.component.html",
  styleUrls: ["./widget-settings-profiles.component.scss"],
  providers: [SharedService, NotesService, ConnectionsService, LinkTagsService],
})
export class WidgetSettingsProfilesComponent implements OnInit {
  @Input()
  set navigationItem(value: NavigationHistoryItem) {
    this._navigationItem = value as ProfilesNavigationItem;
  }

  get navigationItem(): ProfilesNavigationItem {
    return this._navigationItem;
  }
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;

  public _navigationItem: ProfilesNavigationItem;

  readonly WidgetActions = WidgetActions;

  constructor() {}

  ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public loader$ = new Subject<boolean>();

  public mobile: boolean;
  public tablet: boolean;
  public laptop: boolean;
  public xlLaptop: boolean;

  constructor() {
    this.mobile = window.innerWidth <= 768;
    this.tablet = window.innerWidth <= 1024 && window.innerWidth > 768;
    this.laptop = window.innerWidth > 1024;
    this.xlLaptop = window.innerWidth > 1024 && window.innerWidth <= 1440;
  }
}

<div
  class="tags__container"
  *ngIf="
    metadata | getMetadataItemByGroupName: metadataGroup.name as metadataList
  "
>
  <ng-container *ngFor="let metadataItem of metadataList">
    <ng-container *ngIf="metadataItem.name !== MetadataFields.CHEAT_STATUS">
      <ng-container
        *ngIf="
          metadataItem?.name === MetadataFields.CHEAT_FEATURE ||
            metadataItem?.name === MetadataFields.PLATFORMS_AND_TOOLS ||
            metadataItem?.name === MetadataFields.PRODUCT_FOCUS ||
            metadataItem?.name === MetadataFields.PAYMENTS_SUPPORTED;
          else singleValueTemplate
        "
      >
        <itq-tags-list
          class="mb-3"
          [dataSource]="metadataItem | transformMetadataValues"
          [disabled]="false"
          [label]="metadataItem.name"
          [size]="Sizes.SM"
          [action]="true"
        >
        </itq-tags-list>
      </ng-container>
      <ng-template #singleValueTemplate>
        <ng-container
          *ngIf="metadataItem?.values | jsonParse as parsedMetadataValues"
        >
          <div class="mb-3">
            <span class="font-normal"
              >{{
                metadataItem?.name | parseMetadataLabel: metadataList
              }}:</span
            >
            <ng-container
              *ngIf="
                metadataItem.name === MetadataFields.FIRST_SIGHTED;
                else othersMetadataTemplate
              "
            >
              {{
                parsedMetadataValues
                  | convertFromEpochMillis
                  | date: 'yyyy MMM dd'
              }}
            </ng-container>
            <ng-template #othersMetadataTemplate>
              {{
                parsedMetadataValues | renderSingleMetadata: metadataItem?.name
              }}
            </ng-template>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-container
    *ngIf="
      (metadata | getMetadataField: MetadataFields.PRICING_CURRENCY)?.values
        | jsonParse as currencyPrice
    "
  >
    <div
      class="mb-3"
      *ngIf="
        metadataGroup.name === ProfileMetadataGroups.PRICING &&
        currencyPrice !== CurrencyDescription.USD
      "
    >
      <span class="font-normal"
        >{{ MetadataFields.PRICE_PER_MONTH }} ({{
          CurrencyPrefixDescription['USD-US Dollar']
        }}):</span
      >
      {{
        (metadata | getMetadataField: MetadataFields.PRICE_PER_MONTH)?.values
          | jsonParse
          | convertToUSD
            : CurrencyPrefixDescription[
                (metadata | getMetadataField: MetadataFields.PRICING_CURRENCY)
                  .values | jsonParse
              ]
      }}
    </div>
  </ng-container>
</div>

import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Profile } from '@portal/profiles/models/profile';
import { VideoLinkNote } from '@portal/profiles/models/video-link';

@Component({
  selector: "itq-view-video-link",
  templateUrl: "./view-video-link.component.html",
  styleUrls: ["./view-video-link.component.scss"],
})
export class ViewVideoLinkComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: VideoLinkNote;

  constructor() {}

  ngOnInit() {}
}

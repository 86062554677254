import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CoreModule, IDropdownItem, TagMatchAlert } from '@intorqa-ui/core';
import {
  Priority_DataSource,
  Delays_DataSource,
} from '@portal/notifications/const/alerts.const';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { TagService } from '@portal/shared/services/tag.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-tag-match-alert',
  templateUrl: './tag-match-alert.component.html',
  styleUrls: ['./tag-match-alert.component.scss'],
  standalone: true,
  imports: [
    CoreModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    CommonModule,
  ],
})
export class TagMatchAlertComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() navigationItem: NavigationHistoryItem;

  public delayDataSource: Array<{ name: string; value: number }>;
  private loadAlertSubscription: Subscription;
  public priorityDataSource: Array<IDropdownItem>;

  readonly WidgetActions = WidgetActions;

  constructor(
    readonly alertsService: AlertsService,
    readonly tagService: TagService,
  ) {
    this.priorityDataSource = Priority_DataSource;
    this.delayDataSource = Delays_DataSource;
  }

  ngOnInit() {
    this.loadAlertSubscription = this.alertsService.loadAlert$.subscribe(
      (response: TagMatchAlert) => {
        this.form.get('alerts.delay').setValue(response.delay);
        this.form.get('alerts.active').setValue(response.active);
        this.form.get('alerts.priority').setValue(response.priority);
      },
    );
    this.createForm();
  }

  ngOnDestroy(): void {
    this.loadAlertSubscription.unsubscribe();
    const alertControls = (this.form?.get('alerts') as FormGroup)?.controls;
    for (const name in alertControls) {
      if (alertControls.hasOwnProperty(name)) {
        (this.form?.get('alerts') as FormGroup).removeControl(name);
      }
    }
  }

  private createForm(): void {
    (this.form.get('alerts') as FormGroup).addControl(
      'priority',
      new FormControl(undefined, [Validators.required]),
    );
    (this.form.get('alerts') as FormGroup).addControl(
      'delay',
      new FormControl(undefined, [Validators.required]),
    );
    if (this.navigationItem?.action !== WidgetActions.CREATE) {
      (this.form.get('alerts') as FormGroup).addControl(
        'active',
        new FormControl(true),
      );
    }
  }
}

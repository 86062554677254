import { Injectable } from '@angular/core';
import {
  ApiRequestService,
  DTOMessage,
  DTOTypeConverter,
} from '@intorqa-ui/api';
import { IError, IPresetQuery } from '@intorqa-ui/core';
import { Timeline } from '@portal/boards/models/widgets/timeline';
import { Widget } from '@portal/boards/models/widgets/widget';
import { WidgetFactory } from '@portal/shared/factories/widget.factory';
import { ITagTreeNode } from '@portal/shared/interfaces/tag.interface';
import { IWidget } from '@portal/shared/interfaces/widget.interface';
import { Observable, Subject, map } from 'rxjs';
import { NodeType } from '../enums/board.enum';
import { IBoard, ITree, ITreeNode } from '../interfaces/board.interface';
import { IGroup } from '../interfaces/group.interface';
import { ISyncFusionTreeNode } from '../interfaces/tree.interface';
import { Board } from '../models/board';

@Injectable({
  providedIn: 'root',
})
export class BoardService {
  public loadTree$ = new Subject<void>();
  public loadBoard$ = new Subject<void>();
  public changeBoard$ = new Subject<Board>();
  public removeTimelines$ = new Subject<Timeline>();

  public boards: Array<Board> = [];
  public widgets: Array<Widget> = [];
  public board: Board;
  public tree: ITree;
  public preventUpdate = false;
  public groups: Array<IGroup> = [];
  private _treeVersion: number;

  public get treeVersion(): number {
    return this._treeVersion;
  }

  public set treeVersion(value: number) {
    if (value === undefined || value === null) {
      alert('tree version is undefined!');
    }
    this._treeVersion = value;
  }

  constructor(public apiRequestService: ApiRequestService) {}

  public getBoards(ecosystemId: string): Promise<Array<IBoard>> {
    return new Promise((resolve) => {
      if (this.boards?.length > 0) {
        resolve(this.boards);
      }
      this.apiRequestService
        .get(
          `/boards?ecosystemId=${ecosystemId}`,
          {},
          new DTOTypeConverter<Array<IBoard>>(),
          undefined,
          'v2.0',
        )
        .then((response: Array<Board>) => {
          this.boards = response;
          resolve(response);
        });
    });
  }

  public getDefaultBoard(ecosystemId: string): Observable<Board> {
    return this.apiRequestService
      .getToObservable(
        `/boards/default?ecosystemId=${ecosystemId}`,
        new DTOTypeConverter<IBoard>(),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IBoard) => {
          this.board = new Board(
            response.id,
            response.name,
            response.description,
            response.defaultBoard,
            response.filter,
            response.widgetIds,
            response.updatedDate || 0,
          );
          return this.board;
        }),
      );
  }

  public getBoardById(boardId: string): Promise<Board> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .get(
          '/boards/' + boardId,
          {},
          new DTOTypeConverter<IBoard>(),
          undefined,
          'v2.0',
        )
        .then((response: IBoard) => {
          this.board = new Board(
            response.id,
            response.name,
            response.description,
            response.defaultBoard,
            response.filter,
            response.widgetIds,
            response.updatedDate || 0,
          );
          resolve(this.board);
        });
    });
  }

  public createBoard(
    board: {
      name: string;
      description: string;
      default: boolean;
      filter?: {
        date: IPresetQuery;
      };
      widgetIds: Array<string>;
    },
    ecosystemId: string,
  ): Observable<Board> {
    const payload = {
      ...board,
      treeVersion: this.treeVersion,
      ecosystemId,
    };
    return this.apiRequestService
      .postToObservable(
        '/boards',
        new DTOTypeConverter<IBoard>(),
        JSON.stringify(payload),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IBoard) => {
          return new Board(
            response.id,
            response.name,
            response.description,
            response.defaultBoard,
            response.filter,
            response.widgetIds,
            response.updatedDate || 0,
          );
        }),
      );
  }

  public getWidgets(board: IBoard): Promise<Array<Widget>> {
    return new Promise((resolve) => {
      this.apiRequestService
        .get(
          `/boards/${board?.id}/widgets`,
          {},
          new DTOTypeConverter<Array<IWidget>>(),
          undefined,
          'v2.0',
        )
        .then((response: Array<IWidget>) => {
          const widgets = response.map((item: IWidget) =>
            WidgetFactory.createWidget(item),
          );
          resolve(widgets);
        });
    });
  }

  public updateBoard(
    id: string,
    params: {
      [key: string]: any;
    },
  ): Observable<IBoard> {
    return this.apiRequestService
      .putToObservable(
        '/boards/' + id,
        JSON.stringify({
          ...params,
          ...{
            treeVersion: this.treeVersion,
          },
        }),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IBoard) => {
          this.board = new Board(
            response.id,
            response.name,
            response.description,
            response.defaultBoard,
            response.filter,
            response.widgetIds,
            response.updatedDate || 0,
          );
          this.boards = this.boards.map((item: Board) =>
            item.id === id ? this.board : item,
          );
          return this.board;
        }),
      );
  }

  public deleteBoard(boardId: string): Observable<void> {
    return this.apiRequestService.deleteToObserable(
      '/boards/' + boardId,
      { treeVersion: this.treeVersion },
      'v2.0',
    );
  }

  public findBoardById(id: string): Board {
    return this.boards.find((board: Board) => {
      return board.id === id;
    });
  }

  public findGroupById(id: string): IGroup {
    return this.groups.find((group: IGroup) => {
      return group.uuid === id;
    });
  }

  public getTree(ecosystemId: string): Promise<ITree> {
    return new Promise((resolve) => {
      this.apiRequestService
        .get(
          `/boards/tree?ecosystemId=${ecosystemId}`,
          {},
          new DTOTypeConverter<ITree>(),
          undefined,
          'v2.0',
        )
        .then((response: ITree) => {
          this.treeVersion = response.version;
          this.updateTreeData(response);
          resolve(this.tree);
        });
    });
  }

  public transformTree(
    tree: Array<ITreeNode>,
    boards: Array<IBoard>,
    groups: Array<IGroup>,
  ): Array<ITreeNode> {
    const findBoardById = (list: Array<IBoard>, id: string) => {
      return list.find((board: IBoard) => {
        return board.id === id;
      });
    };
    const findGroupById = (list: Array<IGroup>, id: string) => {
      return list.find((group: IGroup) => {
        return group.uuid === id;
      });
    };
    return tree.map((item: ITreeNode) => {
      let board: IBoard;
      let childBoard: IBoard;
      let group: IGroup;

      if (item.type === NodeType.BOARD) {
        board = findBoardById(boards, item.id);
      } else {
        group = findGroupById(groups, item.id);
      }
      if (item.children?.length > 0) {
        item.children = item.children.map((node: ITreeNode) => {
          childBoard = findBoardById(boards, node.id);
          return {
            ...node,
            ...{
              name: childBoard?.name,
              icon: 'WIDGET',
              defaultBoard: board?.defaultBoard,
            },
          };
        });
      }
      return {
        ...item,
        ...{
          name: board?.name || group?.name,
          icon: item.type === NodeType.BOARD ? NodeType.BOARD : NodeType.GROUP,
          defaultBoard: board?.defaultBoard,
        },
      };
    });
  }

  public updateTreeData(response: ITree): void {
    this.tree = {
      tree: this.transformTree(response.tree, response.boards, response.groups),
      boards: response.boards,
      groups: response.groups,
      version: response.version,
    };
    this.boards = response.boards;
    this.groups = response.groups;
    this.treeVersion = response.version;
  }

  public updateTreeVersion(version: number): void {
    this.treeVersion = version;
    this.tree.version = version;
  }

  private validateNoDuplicateNodes(tree: Array<any>): boolean {
    const nodeIds = new Set<string>();

    function traverse(node: any): boolean {
      if (nodeIds.has(node.id)) {
        return false;
      }
      nodeIds.add(node.id);

      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          if (!traverse(child)) {
            return false;
          }
        }
      }
      return true;
    }

    for (const node of tree) {
      if (!traverse(node)) {
        return false;
      }
    }

    return true;
  }

  public updateTree(tree: Array<any>, ecosystemId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const apiTree = tree.map((node: ISyncFusionTreeNode) => {
        return {
          type: node.isGroup ? NodeType.GROUP : NodeType.BOARD,
          id: node.id,
          children:
            node.child?.length > 0
              ? node.child.map((child: any) => {
                  return {
                    id: child.id,
                    type: NodeType.BOARD,
                    children: [],
                  };
                })
              : [],
        };
      });
      const isValid = this.validateNoDuplicateNodes(apiTree);
      if (isValid) {
        this.apiRequestService
          .put(
            '/boards/tree',
            new DTOTypeConverter<DTOMessage>(),
            JSON.stringify({
              tree: apiTree,
              version: this.treeVersion,
              ecosystemId,
            }),
            undefined,
            'v2.0',
          )
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: IError) => {
            reject(error);
          });
      } else {
        reject({
          error: 'DUPLICATE_TREE_NODE',
          code: 500,
          title: 'Duplicate node!',
          description: 'Duplicate node found in the tree.',
        });
      }
    });
  }

  public getTreeShape(
    tree: ITree,
    selectedBoardId: string,
  ): Array<ISyncFusionTreeNode> {
    const getChildren = (node: ITreeNode) => {
      return node.children?.map((child: ITreeNode) => {
        const board = this.findBoardById(child.id);
        return {
          id: child.id,
          name: child.name,
          icon: child.icon,
          isGroup: false,
          child: [],
          hasChildren: false,
          type: child.type,
          isSelected: selectedBoardId === child?.id,
          defaultBoard: board.defaultBoard,
        };
      });
    };
    const isExpanded = (node: ITreeNode) => {
      let foundNode = false;
      node.children?.forEach((child: ITreeNode) => {
        if (child.id === selectedBoardId) {
          foundNode = true;
        }
      });

      return foundNode;
    };
    let result: ISyncFusionTreeNode;
    return tree?.tree?.map((node: ITreeNode) => {
      result = {
        id: node.id,
        name: node.name,
        hasChildren: node.children?.length > 0,
        expanded: isExpanded(node),
        child: getChildren(node) || [],
        isGroup: node.type === NodeType.GROUP,
        icon: node.type === NodeType.GROUP ? 'FOLDER' : 'WIDGET',
        isSelected: selectedBoardId === node?.id,
        defaultBoard: node.defaultBoard,
      };
      return result;
    });
  }

  public findTreeIndexById(id: string): number {
    let index: number;
    this.tree.tree.forEach((item: ITreeNode, i: number) => {
      if (item.id === id) {
        index = i;
      }
    });

    return index;
  }

  public updateGroup(group: IGroup): Promise<void> {
    return new Promise((resolve) => {
      this.apiRequestService
        .put(
          '/boards/group/' + group.uuid,
          new DTOTypeConverter<DTOMessage>(),
          JSON.stringify({ ...group, treeVersion: this.treeVersion }),
          undefined,
          'v2.0',
        )
        .then(() => {
          resolve();
        });
    });
  }

  public deleteGroup(groupId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .delete(
          '/boards/group/' + groupId,
          { body: JSON.stringify({ treeVersion: this.treeVersion }) },
          'v2.0',
        )
        .then(() => {
          resolve();
        })
        .catch((error: IError) => reject(error));
    });
  }

  public createGroup(name: string, ecosystemId: string): Observable<IGroup> {
    const payload = { name, treeVersion: this.treeVersion, ecosystemId };
    return this.apiRequestService.postToObservable(
      '/boards/group',
      new DTOTypeConverter<IGroup>(),
      JSON.stringify(payload),
      undefined,
      'v2.0',
    );
  }

  public findBoardByProp(key: string, value: any): IBoard {
    return this.boards.find((item: IBoard) => {
      return item[key] === value;
    });
  }

  public addWidgetsToBoard(
    boardId: string,
    payload: { add?: Array<string>; delete?: Array<string> },
  ): Observable<Board> {
    return this.apiRequestService
      .putToObservable(
        `/boards/${boardId}/widgets`,
        JSON.stringify({
          widgets: payload,
        }),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: IBoard) => {
          this.board = new Board(
            response.id,
            response.name,
            response.description,
            response.defaultBoard,
            response.filter,
            response.widgetIds,
            response.updatedDate || 0,
          );
          this.boards = this.boards.map((item: Board) =>
            item.id === boardId ? this.board : item,
          );
          return this.board;
        }),
      );
  }

  public getDefault(): Board {
    return this.boards.find((item: Board) => item.defaultBoard);
  }

  public getTags(id: string): Promise<Array<string>> {
    return this.apiRequestService.get(
      `/tags/${id}/boards`,
      {},
      new DTOTypeConverter<Array<string>>(),
      undefined,
      'v1.0',
    );
  }

  /**
   * Searches the tree dataset for nodes that match the given query.
   * If the query is empty, returns the entire dataset.
   *
   * @param dataset - The array of tree nodes to search.
   * @param query - The query string to match against node names.
   * @returns An array of tree nodes that match the query.
   */
  public searchTree({
    dataset,
    query,
  }: {
    dataset: Array<ISyncFusionTreeNode>;
    query: string;
  }): Array<ISyncFusionTreeNode> {
    if (!query) {
      return dataset;
    }
    const result: Array<ISyncFusionTreeNode> = [];
    dataset.forEach((node: ISyncFusionTreeNode) => {
      const children: Array<ISyncFusionTreeNode> = node.child?.filter(
        (childNode: ISyncFusionTreeNode) =>
          childNode.name
            .toLowerCase()
            .trim()
            .includes(query.toLowerCase().trim()),
      );
      if (node.name.toLowerCase().trim().includes(query.toLowerCase().trim())) {
        result.push({ ...node, ...{ child: node.child } });
      } else if (children.length > 0) {
        result.push({ ...node, ...{ child: children } });
      }
    });
    return result;
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/boards/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string,
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/boards/${unlinkId}/unlink/tag/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public unlinkWidget(
    unlinkId: string,
    dependencyId: string,
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/boards/${unlinkId}/unlink/widget/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      undefined,
      'v2.0',
    );
  }

  public updateLayout(widgets: Array<Widget>): Observable<Array<Widget>> {
    return this.apiRequestService
      .putToObservable(
        `/boards/${this.board.id}/layout`,
        JSON.stringify({
          widgets,
        }),
        undefined,
        'v2.0',
      )
      .pipe(
        map((response: Array<IWidget>) => {
          return response.map((item: IWidget) => {
            return WidgetFactory.createWidget(item);
          });
        }),
      );
  }
}

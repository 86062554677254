/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */

import { Injectable } from "@angular/core";
import { ApiRequestService } from "@intorqa-ui/api";
import { DynamicFlatNode } from "./tag-dependencies.model";

@Injectable({ providedIn: "root" })
export class DynamicDatabase {
  dataMap: Map<string, DynamicFlatNode[]>;
  rootLevelNodes: DynamicFlatNode[];

  constructor(readonly apiRequestService: ApiRequestService) {}

  /** Initial data from database */
  initialData(): DynamicFlatNode[] {
    return this.rootLevelNodes;
  }

  getChildren(node: string): DynamicFlatNode[] | undefined {
    return this.dataMap.get(node);
  }

  isExpandable(node: string): boolean {
    return this.dataMap.has(node);
  }

  clear(): void {
    this.dataMap = undefined;
    this.rootLevelNodes = undefined;
  }

  deleteNode(node: DynamicFlatNode): void {
    for (const [key, nodes] of this.dataMap.entries()) {
      const index = nodes.indexOf(node);

      if (index !== -1) {
        // The value was found in the array associated with this key
        nodes.splice(index, 1); // Remove the value

        this.dataMap.delete(node.id);
        // Assuming you want to remove only the first occurrence, you can break here
        break;
      }
    }
  }
}

<div class="field__container">
  <h1>Platform type</h1>
  <div>{{ note.subType }}</div>
</div>
<div class="field__container">
  <h1>Video link</h1>
  <itq-youtube-player
    *ngIf="note.subType === 'Youtube'; else otherTypesTemplate"
    [url]="note.value"
  ></itq-youtube-player>
  <ng-template #otherTypesTemplate>
    <iframe
      [src]="note.value | safe"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </ng-template>
</div>
<div class="field__container" *ngIf="note.textNote">
  <h1>Text notes</h1>
  <div [innerHTML]="note.textNote | urlify"></div>
</div>

<itq-sidebar
  class="wrapper__left p-4"
  [isExpanded]="expandedFilters"
  [width]="450"
>
  <div class="left__header">
    <itq-headline-simple
      [iconName]="navigationItem?.icon"
      [iconSize]="'2x'"
      [iconType]="IconType.FONT_AWESOME"
      [title]="profile?.name"
      [description]="profile?.description"
    ></itq-headline-simple>
    <itq-mat-button
      *ngIf="navigationItem?.action === WidgetActions.EXPLORE"
      [type]="'button'"
      [materialStyle]="'basic'"
      (mouseDownEvent)="onEdit(ProfileEditAction.DETAILS, undefined)"
    >
      <fa-icon [icon]="['far', 'pencil']"></fa-icon>
    </itq-mat-button>
  </div>
  <ng-container
    *ngIf="navigationItem?.action === WidgetActions.EXPLORE; else editProfile"
  >
    <mat-accordion class="basic" [multi]="true">
      <mat-expansion-panel [hideToggle]="false" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title
            ><fa-icon [icon]="['far', 'info-circle']"></fa-icon
            >Info</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="mb-3">
          <span class="font-semibold">Vendor Type:</span>
          <span class="ml-2">{{ profileTypeName }}</span>
        </div>
        <div
          class="mb-3"
          *ngIf="
            profile?.metadata
              | getMetadataField: MetadataFields.PRIORITY as priorityMetadata
          "
        >
          <span class="font-semibold">Priority:</span>
          <span class="ml-2">{{ priorityMetadata.values | jsonParse }}</span>
        </div>
        <div
          class="mb-3"
          *ngIf="
            profile?.metadata
              | getMetadataField
                : MetadataFields.CHEAT_STATUS as cheatStatusMetadata
          "
        >
          <span class="font-semibold">Cheat Status:</span>
          <span class="ml-2">{{ cheatStatusMetadata.values | jsonParse }}</span>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [hideToggle]="false" [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title
            ><fa-icon [icon]="['far', 'info-circle']"></fa-icon
            >Metrics</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div class="mb-3 flex items-center">
          <span class="font-semibold">Posts in last 7 days:</span>
          <div class="ml-2 h-12 w-full flex relative">
            @if (profile?.activity) {
              <itq-pill
                [class]="'secondary rounded-md'"
                [type]="PillType.CUSTOM"
                >{{ profile?.activity }}</itq-pill
              >
            }
            @if (profile?.activity > 0 && activityData) {
              <itq-chart
                [id]="'profile-metadata'"
                [settings]="options"
                [type]="ChartType.LINE"
                [data]="activityData"
              ></itq-chart>
            } @else {
              <itq-loader
                *ngIf="profile?.activity > 0"
                [type]="'controls'"
              ></itq-loader>
            }
          </div>
        </div>
        <div class="mb-3 flex items-center">
          <span class="font-semibold">Vs Previous week:</span>
          <span class="ml-2 trend__container">
            <div
              class="flex justify-center flex-col items-center"
              *ngIf="profile.trend !== undefined; else updatingTemplate"
              [ngStyle]="{ color: profile.trend | getTrendColor }"
            >
              <fa-icon
                [size]="'lg'"
                [icon]="['far', profile.trend | getTrendIcon]"
              ></fa-icon>
              <span class="trend__label">{{ profile.trend }}%</span>
            </div>
            <ng-template #updatingTemplate>
              <div class="flex relative justify-center">
                <fa-icon [icon]="['far', 'dash']"></fa-icon>
              </div>
            </ng-template>
          </span>
        </div>
      </mat-expansion-panel>
      <ng-container *ngFor="let item of metadata">
        <mat-expansion-panel
          [hideToggle]="
            (profile?.metadata | getMetadataItemByGroupName: item.name)
              ? false
              : true
          "
          [expanded]="profile?.metadata | getMetadataItemByGroupName: item.name"
        >
          <mat-expansion-panel-header>
            <mat-panel-title
              ><fa-icon [icon]="['far', MetadataIcons[item.name]]"></fa-icon
              >{{ item.name }}</mat-panel-title
            >
            <mat-panel-description
              ><itq-mat-button
                *ngIf="navigationItem?.action === WidgetActions.EXPLORE"
                [type]="'button'"
                [materialStyle]="'basic'"
                (mouseDownEvent)="onEdit(ProfileEditAction.METADATA, item.name)"
              >
                <fa-icon [icon]="['far', 'pencil']"></fa-icon> </itq-mat-button
            ></mat-panel-description>
          </mat-expansion-panel-header>
          <itq-explore-metadata
            [metadata]="profile?.metadata"
            [metadataGroup]="item"
          ></itq-explore-metadata>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </ng-container>
  <ng-template #editProfile>
    <div class="edit_metadata__body">
      <itq-edit-profile
        [editMetadataAction]="editMetadataAction"
        [action]="editAction"
        [form]="form"
        [navigationItem]="navigationItem"
        [profile]="profile"
        [closeOnUpdate]="closeOnUpdate"
      ></itq-edit-profile>
    </div>
  </ng-template>
</itq-sidebar>
<itq-form class="wrapper__right" [formGroup]="form">
  <mat-tab-group
    mat-align-tabs="start"
    mat-stretch-tabs="false"
    #tabGroup
    animationDuration="0ms"
    [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="selectedTabIndex = $event"
    (selectedTabChange)="onTabChange()"
  >
    <mat-tab class="tags_tab">
      <ng-template mat-tab-label>
        <div class="content_tab__label">
          <div class="content_tab__wrapper">
            <ng-container *ngIf="selectedTag; else linkTagTemplate">
              <div class="tab__wrapper_left">
                <fa-icon [icon]="['far', 'database']" class="mr-1"></fa-icon>
                <span class="tab__name">
                  {{ selectedTag?.tagName }}
                </span>
              </div>
              <itq-mat-button
                *ngIf="
                  tagsDataSource?.items?.length === 1 &&
                  selectedTag?.type === TagCategory['My Tags']
                "
                (click)="$event.stopImmediatePropagation()"
                [materialStyle]="'basic'"
                [padding]="Sizes.NONE"
                [matTooltipPosition]="'right'"
                [matTooltip]="'Edit tag'"
                (clickEvent)="onEditTag(selectedTag)"
              >
                <fa-icon [icon]="['far', 'pencil']"></fa-icon>
              </itq-mat-button>
            </ng-container>
            <ng-template #linkTagTemplate>
              <ng-container *ngIf="tagsDataSource; else loaderTemplate">
                <div class="tab__wrapper_left">
                  <fa-icon [icon]="['far', 'database']"></fa-icon>
                  TAGS
                </div>
              </ng-container>
              <ng-template #loaderTemplate>
                <itq-loader [type]="'controls'"></itq-loader>
              </ng-template>
            </ng-template>
          </div>
          <itq-nav-button
            *ngIf="selectedTag && tagsDataSource?.items?.length > 1"
            (click)="$event.stopImmediatePropagation()"
            [padding]="Sizes.NONE"
            [width]="200"
            [materialStyle]="'basic'"
            [openDirection]="{
              vertical: 'down',
              horizontal: 'right'
            }"
            [openPosition]="'inside'"
            [template]="tagActionsTemplate"
          >
            <fa-icon [icon]="['far', 'chevron-down']"></fa-icon>
          </itq-nav-button>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <itq-profiles-tags
          [form]="form"
          [articleDetail]="articleDetail"
          [profile]="profile"
          [navigationItem]="navigationItem"
          [showLinkTags]="showLinkTags"
          [action]="action"
        ></itq-profiles-tags>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab__wrapper_left">
          <fa-icon [icon]="['far', 'code-merge']"></fa-icon>

          Connections
        </div>
      </ng-template>
      <ng-template matTabContent>
        <itq-connections-network
          *ngIf="selectedTabIndex === 1"
          [showConnectionSettings]="showConnectionSettings"
          [profile]="profile"
          [navigationItem]="navigationItem"
          [form]="form"
          [action]="action"
        ></itq-connections-network>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="tab__wrapper_left">
          <fa-icon [icon]="['far', 'note']"></fa-icon>
          Notes
        </div>
      </ng-template>
      <ng-template matTabContent>
        <itq-profiles-notes
          [profile]="profile"
          [navigationItem]="navigationItem"
          [action]="action"
          [form]="form"
        ></itq-profiles-notes>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</itq-form>
<ng-template #tagActionsTemplate>
  <ul class="tags__actions_container">
    <li
      *ngFor="let item of tagsDataSource?.items"
      [ngClass]="{ active: selectedTag?.id === item.id }"
    >
      <itq-mat-button
        class="tag_name__button"
        [materialStyle]="'basic'"
        [padding]="Sizes.NONE"
        [size]="Sizes.BLOCK"
        (clickEvent)="onChangeTag(item)"
      >
        {{ item?.tagName }}
      </itq-mat-button>
      <itq-mat-button
        *ngIf="item.type === TagCategory['My Tags']"
        [materialStyle]="'basic'"
        [padding]="Sizes.NONE"
        [matTooltipPosition]="'right'"
        [matTooltip]="'Edit tag'"
        (clickEvent)="onEditTag(item)"
      >
        <fa-icon [icon]="['far', 'pencil']"></fa-icon>
      </itq-mat-button>
    </li>
  </ul>
</ng-template>

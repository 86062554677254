import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Profile } from '@portal/profiles/models/profile';
import { ScreenshotNote } from "@portal/profiles/models/screenshot";

@Component({
  selector: "itq-view-screenshot",
  templateUrl: "./view-screenshot.component.html",
  styleUrls: ["./view-screenshot.component.scss"],
})
export class ViewScreenshotComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() note: ScreenshotNote;

  constructor() {}

  ngOnInit() {}
}

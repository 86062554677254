import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CoreModule,
  IPresetQuery,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { TagComparison } from '@portal/boards/models/widgets/tag-comparison';
import { TimeSeries } from '@portal/boards/models/widgets/time-series';
import { WidgetService } from '@portal/boards/services/widget.service';
import {
  ICustomTag,
  ITagMetadata,
} from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { TagService } from '@portal/shared/services/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { UserService } from '@portal/shared/services/user.service';
import { Subscription } from 'rxjs';
import { TagAnalysis } from '@portal/boards/models/widgets/tag-analysis';
import { Timeline } from '@portal/boards/models/widgets/timeline';

@Component({
  selector: 'itq-all-tags-datasource',
  templateUrl: './all-tags-datasource.component.html',
  styleUrls: ['./all-tags-datasource.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CoreModule, MatFormFieldModule],
})
export class AllTagsDatasourceComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() widget: TimeSeries | TagComparison;
  @Input() dates: IPresetQuery;

  @Output() changeDataValue = new EventEmitter<Array<string>>();

  public dataPointsDataSource: Array<ICustomTag>;
  public dataValues: Array<any> = [];
  public initialState = new QueryFilters(
    30,
    1,
    undefined,
    undefined,
    undefined,
    undefined,
  );
  private subscriptions = new Subscription();

  constructor(
    private categoryService: CategoryService,
    private tagService: TagService,
    public widgetService: WidgetService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.initialState.where = this.dates;
    this.createForm();
    this.loadMetadata();
    this.bindReloadWidgetDatesSubscription();
  }

  ngOnDestroy(): void {
    this.form.removeControl('dataPoints');
    this.subscriptions.unsubscribe();
  }

  private bindReloadWidgetDatesSubscription(): void {
    this.subscriptions.add(
      this.widgetService.updateWidget$.subscribe(
        (params: {
          widget: TagAnalysis | TagComparison | TimeSeries | Timeline;
          dates?: IPresetQuery;
        }) => {
          this.initialState.where = params.dates;
        },
      ),
    );
  }

  private loadMetadata(): void {
    let tagIds = this.widget.getTagIds();
    let fieldFilterIds = this.widget.getFieldFilterTagIds();
    if (
      tagIds?.included?.length > 0 ||
      tagIds?.excluded?.length > 0 ||
      fieldFilterIds?.included?.length > 0 ||
      fieldFilterIds?.excluded?.length > 0
    ) {
      this.tagService
        .getSelections(
          tagIds,
          fieldFilterIds,
          undefined,
          this.categoryService.categories,
        )
        .then((response: Array<ITagMetadata>) => {
          this.dataValues = response.map((item: ITagMetadata) => ({
            name: item.tagName,
            id: item.tagId,
          }));
          this.form.controls.dataPoints.setValue(this.dataValues || []);

          this.onGetTags(this.initialState);
        });
    }
  }

  public createForm(): void {
    this.form.addControl(
      'dataPoints',
      new FormControl([], Validators.required),
    );
  }

  public onGetTags(params: QueryFilters): void {
    this.initialState = params;
    this.categoryService
      .getTags(
        params.query,
        this.initialState,
        new Query().modelToDTO(),
        TagCategory['My Tags'],
        this.userService.userPreferences.defaultEcosystemId,
        params.page > 1
          ? this.dataPointsDataSource[this.dataPointsDataSource.length - 1].name
          : undefined,
      )
      .then((response: { items: Array<ICustomTag>; totalCount: number }) => {
        this.dataPointsDataSource =
          params.page > 1
            ? [...this.dataPointsDataSource, ...response.items]
            : response.items;
      });
  }

  private updateFormStatus(): void {
    if (this.form.get('dataPoints').value) {
      this.form.get('name').enable();
      this.form.get('description').enable();
    }
  }

  public onDataBound(): void {
    this.updateFormStatus();
    this.changeDataValue.emit(
      this.form.controls.dataPoints.value?.map((item: ICustomTag) => item.id) ||
        [],
    );
  }
}

<div class="menu__container" (mouseleave)="showCreateNewWidget = false">
  <div class="menu__wrapper">
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [type]="'button'"
      [padding]="Sizes['X-SM']"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (mouseDownEvent)="onDeleteWidget()"
      (mouseover)="showCreateNewWidget = false"
    >
      <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
      Remove widget
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [cdkCopyToClipboard]="tagShareLink"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (clickEvent)="onShareLink()"
      (mouseover)="showCreateNewWidget = false"
    >
      <fa-icon [icon]="['far', 'link']"></fa-icon>
      Share link
    </itq-mat-button>
    <itq-mat-button
      [size]="Sizes.BLOCK"
      [padding]="Sizes['X-SM']"
      [type]="'button'"
      [align]="Align.START"
      [materialStyle]="'basic'"
      (mouseover)="showCreateNewWidget = true"
    >
      <div class="flex justify-between w-full">
        <div class="flex items-center">
          <fa-icon [icon]="['far', 'plus']"></fa-icon>
          Create new widget
        </div>
        <fa-icon
          [icon]="[
            'far',
            showCreateNewWidget ? 'chevron-down' : 'chevron-right'
          ]"
        ></fa-icon>
      </div>
    </itq-mat-button>
  </div>

  @if (showCreateNewWidget) {
    <div class="menu__sidebar" [ngStyle]="{ width: '200px' }">
      @for (item of transformTypesDataSource; track item.id) {
        <itq-mat-button
          [size]="Sizes.BLOCK"
          [align]="Align.START"
          [type]="'button'"
          [materialStyle]="'basic'"
          [padding]="Sizes['X-SM']"
          (clickEvent)="onCreateWidget(item.id)"
        >
          @if (item.iconType === IconType.SVG) {
            <itq-svg [icon]="item.icon" class="w-4 h-4 mr-1"></itq-svg>
          } @else {
            <fa-icon [icon]="['far', item.icon]"></fa-icon>
          }
          <span class="capitalize">
            {{ item.name }}
          </span>
        </itq-mat-button>
      }
    </div>
  }
</div>

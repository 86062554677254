<ng-container [formGroup]="form">
  <ng-container [formGroupName]="'addNote'">
    <itq-form-row>
      <itq-form-element>
        <span class="label mb-1 required">Upload file or paste image</span>
        <quill-editor
          #typeEditor
          [styles]="{
            height: '100%',
            minHeight: '400px',
            width: '100%',
            maxHeight: '400px'
          }"
          [placeholder]="'Insert image here...'"
          [formControlName]="'value'"
          [modules]="editorModules"
          (onContentChanged)="onValueChanged()"
        ></quill-editor>
      </itq-form-element>
      <mat-error
        *ngIf="form.controls?.value?.touched && form.controls?.value?.invalid"
      >
        File field is required
      </mat-error>
    </itq-form-row>
    <itq-form-row>
      <itq-form-element>
        <span class="label mb-1">Text notes</span>
        <quill-editor
          [modules]="textNoteModules"
          [styles]="{
            height: '100%',
            minHeight: '400px',
            width: '100%',
            maxHeight: '400px'
          }"
          [formControlName]="'description'"
          (onContentChanged)="onContentChanged()"
        ></quill-editor>
      </itq-form-element>
    </itq-form-row>
  </ng-container>
</ng-container>
